import { Skeleton } from '@mui/material';
import React from 'react';

const MotivationQuote = React.lazy(() => import(/* webpackChunkName: "MotivationQuote" */ './MotivationQuote'));

type Props = {};

const LazyMotivationQuote: React.FC<Props> = () => {
  return (
    <React.Suspense
      fallback={
        <Skeleton
          variant="text"
          sx={{ fontSize: '1rem', width: 300, float: 'right' }}
        />
      }
    >
      <MotivationQuote />
    </React.Suspense>
  );
};

export default LazyMotivationQuote;
