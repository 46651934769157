import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import TRANSLATIONS_DE from './de.json';
import TRANSLATIONS_EN from './en.json';

const LANG_RESOURCES = {
  // t('en')
  en: {
    translation: TRANSLATIONS_EN,
  },
  // t('de')
  de: {
    translation: TRANSLATIONS_DE,
  },
};

export const supportedLangs = Object.keys(LANG_RESOURCES);

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['localStorage', 'navigator'],
      lookupLocalStorage: 'user:lang',
      caches: ['localStorage'],
    },
    // allows . in keys
    keySeparator: false,
    // allows : in keys
    nsSeparator: false,
    supportedLngs: supportedLangs,
    resources: LANG_RESOURCES,
    // use the key as fallback
    fallbackLng: 'en',
    // allow an empty value to count as invalid (by default is true)
    returnEmptyString: false,
  });

export default i18n;
