import { Avatar, Box, Skeleton, SxProps, Tooltip } from '@mui/material';
import React from 'react';
import { useUser } from '../../../services/user-service/user.service';
import UserAvatar from '../../../shared/components/UserAvatar';
import { Person } from '@mui/icons-material';

type Props = {
  userId: number | null;
  onlyAvatar?: boolean;
  sx?: SxProps;
};

const UserLabel: React.FC<Props> = ({ userId, onlyAvatar, sx }) => {
  const user = useUser(userId);

  if (user.isLoading) {
    return onlyAvatar ? (
      <Skeleton
        variant="circular"
        width={40}
        height={40}
      />
    ) : (
      <Skeleton>Foobar Foobar</Skeleton>
    );
  }

  if (!user.data) {
    return (
      <Avatar>
        <Person />
      </Avatar>
    );
  }

  const { firstName, lastName } = user.data;

  if (onlyAvatar) {
    return (
      <Tooltip title={`${firstName} ${lastName}`}>
        <span>
          <UserAvatar
            user={user.data}
            sx={sx}
          />
        </span>
      </Tooltip>
    );
  }

  return (
    <span>
      <Box
        component="span"
        sx={{ display: 'inline-block', verticalAlign: 'top' }}
      >
        <UserAvatar
          user={user.data}
          sx={{
            width: '1.2rem',
            height: '1.2rem',
            fontSize: '0.7em',
            ...sx,
          }}
        />
      </Box>{' '}
      {firstName} {lastName}
    </span>
  );
};

export default UserLabel;
