import React from 'react';
import { ShipmentStateIcon } from '../../shared/components/shipment-state-icon/ShipmentStateIcon';
import { ShipmentState } from '../../shared/backend';

type Props = {
  shipmentState: ShipmentState | null;
};

const ShipmentStateCircle: React.FC<Props> = ({ shipmentState }) => {
  return (
    <ShipmentStateIcon
      state={shipmentState}
      variant="avatar"
    />
  );
};

export default ShipmentStateCircle;
