import { MenuItem, TextField, TextFieldProps } from '@mui/material';
import React from 'react';
import { Control, Controller, FieldPath, FieldPathValue, FieldValues, RegisterOptions } from 'react-hook-form';

type Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<TextFieldProps, 'onChange' | 'value' | 'error'> & {
  name: TName;
  options: { value: string | number; label: string | React.JSX.Element }[];
  rules?: Omit<RegisterOptions<TFieldValues, TName>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
  defaultValue?: FieldPathValue<TFieldValues, TName>;
  control?: Control<TFieldValues>;
};

const FormSelect = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  rules,
  defaultValue,
  control,
  options,
  ...textFieldProps
}: Props<TFieldValues, TName>) => {
  return (
    <Controller
      name={name}
      rules={{ required: textFieldProps.required, ...rules }}
      defaultValue={defaultValue}
      control={control}
      render={({ field: { name, onChange, value }, fieldState: { error, isDirty } }) => (
        <TextField
          select
          name={name}
          error={!!error}
          onChange={onChange}
          value={value ?? ''}
          {...textFieldProps}
          sx={{ '& .MuiInputBase-input': { color: isDirty ? 'warning.main' : undefined }, ...textFieldProps.sx }}
          helperText={error?.message ?? textFieldProps.helperText}
        >
          {options.map(({ value, label }) => (
            <MenuItem
              value={value}
              key={value}
            >
              {label}
            </MenuItem>
          ))}
        </TextField>
      )}
    />
  );
};

export default FormSelect;
