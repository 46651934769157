import React from 'react';
import { OrderDto, OrderUpdateDto } from '../../services/order-service/order.service';
import { Box, Button, Stack, Typography } from '@mui/material';
import BlockIcon from '@mui/icons-material/Block';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { OrderStateIcon } from '../orders/all-orders/components/OrderStateIcons';
import { useAuthentication } from '../../hooks/useAuthentication';
import OrderForm from './OrderForm';
import { OrderState } from '../../shared/backend';
import HistoryIconButton from '../../shared/components/HistoryIconButton';

type Props = {
  order: OrderDto;
  editing: boolean;
  saveOrder: (update: OrderUpdateDto) => Promise<boolean>;
};

const OrderDetails: React.FC<Props> = ({ order, editing, saveOrder }) => {
  const auth = useAuthentication();

  return (
    <>
      {' '}
      <Stack
        spacing={2}
        direction="row"
        flexWrap="wrap"
        alignContent="center"
        alignItems="center"
      >
        <Typography
          variant="h5"
          component="h2"
        >
          <Box
            component="span"
            sx={{ '>*': { verticalAlign: 'middle' } }}
          >
            {OrderStateIcon[order.state]}
          </Box>{' '}
          Details
        </Typography>

        <Box>
          <HistoryIconButton
            action="order"
            entityId={order.orderId}
          />
        </Box>

        <Box flexGrow={1}></Box>

        {(auth.isPermanentEmployee() || auth.isCustomer()) &&
          (order.state === OrderState.BLOCKED ? (
            <Button
              startIcon={<ArrowRightIcon />}
              color="success"
              variant="outlined"
              size="small"
              disabled={!editing}
              onClick={() => saveOrder({ state: OrderState.NEW })}
            >
              Freigeben
            </Button>
          ) : (
            <Button
              startIcon={<BlockIcon />}
              color="error"
              variant="contained"
              size="small"
              disabled={!editing}
              onClick={() => saveOrder({ state: OrderState.BLOCKED })}
            >
              Blockieren
            </Button>
          ))}
      </Stack>
      <OrderForm
        order={order}
        editing={editing}
        saveOrder={saveOrder}
      />
    </>
  );
};

export default OrderDetails;
