import React from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Grid, Breadcrumbs, Typography, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';

type Props = {
  items: { href?: string; label: string }[];
};

const BreadcrumbsGridItem: React.FC<Props> = ({ items }) => {
  return (
    <>
      <Grid
        item
        xs={12}
      >
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {items.map(({ href, label }, index) => {
            return href ? (
              <Link
                key={`${href}-${label}`}
                variant="body1"
                underline="hover"
                color="inherit"
                component={RouterLink}
                to={href}
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                {index === 0 && (
                  <HomeIcon
                    sx={{ mr: 0.5, mt: -0.5 }}
                    fontSize="inherit"
                  />
                )}
                {label}
              </Link>
            ) : (
              <Typography
                key={`${href}-${label}`}
                variant="body1"
                color="text.primary"
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                {index === 0 && (
                  <HomeIcon
                    sx={{ mr: 0.5, mt: -0.5 }}
                    fontSize="inherit"
                  />
                )}
                {label}
              </Typography>
            );
          })}
        </Breadcrumbs>
      </Grid>
    </>
  );
};

export default BreadcrumbsGridItem;
