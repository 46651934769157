import { IUseArrayResponse, SchemaType, api } from '../../shared/client';

export interface ProcessTypeDto extends SchemaType<'ProcessTypeDto'> {}

export namespace ProcessTypeService {
  export const useProcessTypes = (): IUseArrayResponse<ProcessTypeDto> => {
    const { data, error, isLoading, mutate } = api.useApi('/v1/process-type');
    return {
      data: data ?? [],
      mutate,
      error,
      isLoading,
      isError: !!error,
    };
  };
}

export const getProcessTypeLabel = (processType: ProcessTypeDto): string =>
  processType.label || `${processType.dispatchCountry} -> ${processType.destinationCountry}`;
