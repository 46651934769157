import React, { useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import QrCode from '@mui/icons-material/QrCode';
import { useNotifications } from '../../hooks/useNotifications';
import Close from '@mui/icons-material/Close';

type Props = {
  processId: number;
  show: boolean;
  create: (note: string) => Promise<void>;
  close: () => void;
};

const CreateShippingUnitDialog: React.FC<Props> = ({ processId, show, create, close }) => {
  const { t } = useTranslation();
  const notifications = useNotifications();

  const [note, setNote] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Dialog open={show}>
      <form
        onSubmit={async (ev) => {
          ev.preventDefault();

          setIsLoading(true);

          try {
            await create(note);
          } catch (error) {
            notifications.addError(error);
          } finally {
            setIsLoading(false);
          }
        }}
      >
        <DialogTitle>{t('Create new shipping unit')}</DialogTitle>

        <DialogContent>
          <DialogContentText>
            <Trans>By clicking the button below you will create a new shipping unit.</Trans>
          </DialogContentText>

          <TextField
            autoFocus
            sx={{ mt: 2 }}
            value={note}
            onChange={(ev) => setNote(ev.target.value)}
            name="note"
            label={t('Optional note')}
            fullWidth
            disabled={isLoading}
          />
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => close()}
            color="error"
            startIcon={<Close />}
          >
            {t('Abort')}
          </Button>

          <Box flexGrow={1}></Box>

          <Button
            disabled={isLoading}
            type="submit"
            color="primary"
            startIcon={isLoading ? <CircularProgress size="1em" /> : <QrCode />}
          >
            {t('Create new shipping unit')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateShippingUnitDialog;
