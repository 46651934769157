import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import UserLabel from '../activity/components/UserLabel';
import { ShipmentDto } from '../../services/shipment-service/shipment.service';
import { useAuthentication } from '../../hooks/useAuthentication';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { SHIPPING_UNIT_BARCODE_PREFIX } from '../../services/shipping-service/shipping.service';

type Props = {
  shipment: ShipmentDto;
};

const ShipmentFooter: React.FC<Props> = ({ shipment }) => {
  const auth = useAuthentication();

  const { shipmentId, externalShipmentId, processedAt, returnPackageIdentifier, shippingUnitId, updatedAt, createdAt } =
    shipment;
  const processedByUserId = auth.isStaff() ? shipment.processedByUserId : null;

  return (
    <Stack
      direction="row"
      alignItems="flex-start"
      justifyContent="space-between"
      marginTop={1}
    >
      <Box
        component="ul"
        sx={{ p: 0, m: 0, listStyle: 'none' }}
      >
        {[
          t('Shipment ID: {{shipmentId}}', { shipmentId }),

          shipment.externalShipmentId
            ? `${t('External ID: {{externalShipmentId}}', { externalShipmentId })}`
            : undefined,

          shipment.returnPackageIdentifier
            ? `${t('Return package no.: {{returnPackageIdentifier}}', { returnPackageIdentifier })}`
            : undefined,

          !!shippingUnitId
            ? `${t('Shipping unit: {{shippingUnitId}}', { shippingUnitId: `${SHIPPING_UNIT_BARCODE_PREFIX}${shippingUnitId}` })}`
            : undefined,
        ]
          .filter((item) => !!item)
          .map((item) => (
            <li key={item}>
              <Typography
                variant="body2"
                color="text.disabled"
              >
                {item}
              </Typography>
            </li>
          ))}
      </Box>

      <Box
        component="ul"
        sx={{ p: 0, m: 0, listStyle: 'none' }}
      >
        {[
          processedAt && processedByUserId ? (
            <Trans shouldUnescape={true}>
              Processed by <UserLabel userId={processedByUserId} /> at{' '}
              {{ processedAt: new Date(processedAt).toLocaleString() }}
            </Trans>
          ) : processedByUserId ? (
            <Trans>
              Processed by <UserLabel userId={processedByUserId} />
            </Trans>
          ) : processedAt ? (
            <>{t('Processed at {{processedAt}}', { processedAt: new Date(processedAt).toLocaleString() })}</>
          ) : null,
          t('Updated at: {{updatedAt}}', {
            updatedAt: new Date(updatedAt).toLocaleString(),
            interpolation: { escapeValue: false },
          }),
          t('Created at: {{createdAt}}', {
            createdAt: new Date(createdAt).toLocaleString(),
            interpolation: { escapeValue: false },
          }),
        ]
          .filter((item) => !!item)
          .map((item) => (
            <li>
              <Typography
                variant="body2"
                color="text.disabled"
              >
                {item}
              </Typography>
            </li>
          ))}
      </Box>
    </Stack>
  );
};

export default ShipmentFooter;
