import React from 'react';
import { confirmable, ConfirmDialog } from 'react-confirm';
import { ShippingService } from '../../services/shipping-service/shipping.service';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import Clear from '@mui/icons-material/Clear';
import Print from '@mui/icons-material/Print';
import { createConfirmation } from '../../shared/confirm-dialog';
import ButtonAsync from '../../shared/components/ButtonAsync';
import { printBase64PDF } from '../../shared/helper/print';
import { useNotifications } from '../../hooks/useNotifications';

type Props = {
  barcode: string;
};

const CloseShippingUnitDialog: ConfirmDialog<Props, void> = ({ barcode, proceed, cancel, show }) => {
  const { t } = useTranslation();
  const notifications = useNotifications();
  const units = ShippingService.useShippingUnits({ barcode });

  if (units.isLoading) {
    return (
      <Dialog open={show}>
        <CircularProgress />
      </Dialog>
    );
  }

  const unit = units.data[0];

  if (units.data.length === 0 || !unit) {
    return (
      <Dialog open={show}>
        <DialogTitle>{t('Found no shipping unit')}</DialogTitle>

        <DialogContent>
          <DialogContentText>
            <Trans>We could not find any shipping unit with barcode {{ barcode }}.</Trans>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => cancel()}
            color="error"
            startIcon={<Clear />}
          >
            {t('Cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Dialog open={show}>
      <DialogTitle>{t('Close shipping unit')}</DialogTitle>

      <DialogContent>
        <DialogContentText gutterBottom>
          <Trans>
            Do you really want to close the shipping unit with barcode {{ barcode }}? It should contain{' '}
            {{ size: unit.size }} shipments. If you close a unit, a new carrier label will be printed.
          </Trans>
        </DialogContentText>

        <DialogContentText>
          {t('Address')}: {unit.returnWarehouseAddress.street}, {unit.returnWarehouseAddress.zip}{' '}
          {unit.returnWarehouseAddress.city}, {unit.returnWarehouseAddress.country}.
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => cancel()}
          color="error"
          startIcon={<Clear />}
        >
          {t('Cancel')}
        </Button>

        <Box flexGrow={1}></Box>

        <ButtonAsync
          autoFocus
          onClick={async () => {
            try {
              const { label } = await ShippingService.closeShippingUnit(unit.shippingUnitId);

              await printBase64PDF(label);

              notifications.addSuccess(t('Shipment unit was closed.'));

              proceed();
            } catch (error) {
              notifications.addError(error);
            }
          }}
          color="success"
          startIcon={<Print />}
        >
          {t('Close unit and print label')}
        </ButtonAsync>
      </DialogActions>
    </Dialog>
  );
};

export const confirmCloseShippingUnit = createConfirmation(confirmable(CloseShippingUnitDialog));
