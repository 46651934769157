import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Skeleton,
  SxProps,
  TableCellProps,
} from '@mui/material';
import React, { ReactNode } from 'react';
import DataTableRow from './DataTableRow';
import { useTranslation } from 'react-i18next';

export type TDataTableAction<T> = {
  tooltip: string;
  icon: (row: T) => React.JSX.Element;
  onClick: (row: T) => void;
  disabled?: (row: T) => boolean;
  hidden?: (row: T) => boolean;
};

export type TDataTableColumn<T> = { hidden?: boolean; cellProps?: TableCellProps } & (
  | {
      title: string;
      render: (order: T) => ReactNode;
    }
  | {
      title: string;
      field: keyof T;
    }
);

type Props<T> = {
  columns: TDataTableColumn<T>[];
  rows: T[];
  actions?: TDataTableAction<T>[];
  detailPanel?: (row: T) => React.JSX.Element;
  isLoading?: boolean;
  page?: number;
  highlightPredicate?: (row: T) => SxProps | boolean | undefined;
  getRowKey?: (row: T) => string | number;
};

const DataTable = <T,>({
  columns,
  rows,
  actions = [],
  detailPanel,
  isLoading = false,
  page = 0,
  highlightPredicate,
  getRowKey,
}: Props<T>) => {
  const { t } = useTranslation();
  const numberOfColumns =
    Math.min(actions.length, 1) + columns.filter((column) => !column.hidden).length + (!!detailPanel ? 1 : 0);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {!!detailPanel && <TableCell></TableCell>}
            {actions.length > 0 && <TableCell></TableCell>}
            {columns.map((column, index) => {
              return (
                !column.hidden && (
                  <TableCell
                    {...column.cellProps}
                    key={index}
                  >
                    {column.title}
                  </TableCell>
                )
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            [0, 1, 2].map((key) => (
              <TableRow key={key}>
                <TableCell
                  padding="none"
                  sx={{ borderBottom: 'unset' }}
                  colSpan={numberOfColumns}
                >
                  <Skeleton
                    variant="rectangular"
                    height={55}
                    sx={{ m: 1 }}
                  ></Skeleton>
                </TableCell>
              </TableRow>
            ))
          ) : rows.length === 0 ? (
            <TableRow>
              <TableCell
                colSpan={numberOfColumns}
                align="center"
                sx={{ py: 3 }}
              >
                <em>{t('No results found.')}</em>
              </TableCell>
            </TableRow>
          ) : (
            rows.map((row, index) => (
              <DataTableRow
                key={getRowKey ? getRowKey(row) : `${page}-${index}`}
                {...{
                  numberOfColumns,
                  row,
                  columns,
                  actions,
                  detailPanel,
                  highlight: highlightPredicate ? highlightPredicate(row) : false,
                }}
              />
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DataTable;
