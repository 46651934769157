import { Alert, AlertTitle } from '@mui/material';
import QrCodeIcon from '@mui/icons-material/QrCode';
import React from 'react';

type Props = {};

const MissingExportoBarcodeAlert: React.FC<Props> = () => {
  return (
    <Alert
      severity="error"
      icon={<QrCodeIcon />}
    >
      <AlertTitle>Fehlende Exporto Barcodes</AlertTitle>
      Ohne Barcodes kann die Tour nicht verzollt werden.
    </Alert>
  );
};

export default MissingExportoBarcodeAlert;
