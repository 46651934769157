const PRINT_IFRAME_ID = 'exporto-printer';

/**
 * Prints PDF document.
 *
 * @param label PDF label encoded as base64 string.
 * @returns Promise
 */
export function printBase64PDF(label: string) {
  return new Promise<void>((resolve, reject) => {
    document.getElementById(PRINT_IFRAME_ID)?.remove();

    const iframe = document.createElement('iframe');

    iframe.id = PRINT_IFRAME_ID;

    iframe.setAttribute('title', 'print');
    iframe.setAttribute('style', 'visibility: hidden; height: 0; width: 0; position: absolute; border: 0');

    iframe.onload = () => {
      const contentWindow = iframe.contentWindow;

      if (!contentWindow) {
        reject();

        return;
      }

      contentWindow.onafterprint = () => resolve();

      contentWindow.print();

      // just in case the after print event is not triggered
      window.setTimeout(resolve, 1000);
    };

    const bytesArray = Uint8Array.from(window.atob(label), (c) => c.charCodeAt(0));
    const pdfBlob = new window.Blob([bytesArray], { type: 'application/pdf' });

    iframe.src = window.URL.createObjectURL(pdfBlob);

    document.getElementsByTagName('body')[0].appendChild(iframe);
  });
}
