import { Note } from '@mui/icons-material';
import Edit from '@mui/icons-material/Edit';
import { Alert, AlertTitle, Box, IconButton } from '@mui/material';
import React, { useState } from 'react';
import TourNoteDialog from './TourNoteDialog';

type Props = {
  notes: string[];
  tourIds?: number[];
  mutate?: () => void;
};

const TourNotesAlert: React.FC<Props> = ({ notes, tourIds, mutate }) => {
  const [isEditing, setEditing] = useState(false);

  return (
    <>
      <Alert
        severity="info"
        icon={<Note />}
        action={
          !!tourIds?.length ? (
            <IconButton onClick={() => setEditing(true)}>
              <Edit />
            </IconButton>
          ) : undefined
        }
      >
        <AlertTitle>Tour Notizen</AlertTitle>
        {notes.length === 1 ? (
          notes[0]
        ) : (
          <Box
            component="ul"
            margin={0}
            paddingLeft={3}
          >
            {notes.map((note) => (
              <li key={note}>{note}</li>
            ))}
          </Box>
        )}
      </Alert>

      {!!tourIds?.length && isEditing && (
        <TourNoteDialog
          value={notes.join('; ')}
          tourIds={tourIds}
          onClose={() => setEditing(false)}
          mutate={mutate}
        />
      )}
    </>
  );
};

export default TourNotesAlert;
