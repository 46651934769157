import { Login } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Stack, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useNotifications } from '../../../hooks/useNotifications';
import { useTranslation } from 'react-i18next';

type Props = {
  onSubmit: (email: string) => Promise<undefined | false>;
};

const FidoForm: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(false);
  const notificationHandler = useNotifications();

  return (
    <form
      onSubmit={(ev) => {
        ev.preventDefault();

        setDisabled(true);

        const formData = new FormData(ev.currentTarget);
        const email = formData.get('email')?.toString() ?? '';

        props
          .onSubmit(email)
          .then((success) => {
            if (!success) {
              setDisabled(false);
            }
          })
          .catch((error) => {
            notificationHandler.addError(error);

            setDisabled(false);
          });
      }}
    >
      <Stack
        direction="column"
        spacing={2}
      >
        <TextField
          name="email"
          variant="outlined"
          label={t('E-mail')}
          type="email"
          disabled={disabled}
        />

        <LoadingButton
          loading={disabled}
          loadingPosition="start"
          startIcon={<Login />}
          type="submit"
          variant="contained"
          color="secondary"
          disabled={disabled}
        >
          {t('Log in')}
        </LoadingButton>
      </Stack>
    </form>
  );
};

export default FidoForm;
