import { TextField, TextFieldProps } from '@mui/material';
import React from 'react';
import { Control, Controller, FieldPath, FieldPathValue, FieldValues, RegisterOptions } from 'react-hook-form';

type Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<TextFieldProps, 'onChange' | 'value' | 'error'> & {
  name: TName;
  rules?: Omit<RegisterOptions<TFieldValues, TName>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
  defaultValue?: FieldPathValue<TFieldValues, TName>;
  control?: Control<TFieldValues>;
};

const FormTextField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  rules,
  defaultValue,
  control,
  ...textFieldProps
}: Props<TFieldValues, TName>) => {
  return (
    <Controller
      name={name}
      rules={{ required: textFieldProps.required, ...rules }}
      defaultValue={defaultValue}
      control={control}
      render={({ field: { name, onChange, value }, fieldState: { error, isDirty } }) => (
        <TextField
          name={name}
          sx={{ input: { color: isDirty ? 'warning.main' : undefined } }}
          error={!!error}
          onChange={(event) =>
            textFieldProps.type === 'number' ? onChange(parseFloat(event.target.value)) : onChange(event)
          }
          value={value ?? ''}
          {...textFieldProps}
          helperText={error?.message ?? textFieldProps.helperText}
        />
      )}
    />
  );
};

export default FormTextField;
