import React from 'react';
import { ProcessService, ProcessWithCustomerDto } from '../../../../services/process-service/process.service';
import { Card, CardHeader, Typography, CardContent } from '@mui/material';
import { ProcessTechnicalUser as ProcessTechnicalUserForm } from '../../customers/components/customer-details/components/technical-user/ProcessTechnicalUser';
import { useNotifications } from '../../../../hooks/useNotifications';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import { ReauthTimeInMinutes, assertAuthentication } from '../../../../shared/components/ReAuthenticationDialog';

type Props = {
  process: ProcessWithCustomerDto;
};

const ProcessTechnicalUser: React.FC<Props> = ({ process }) => {
  const notifications = useNotifications();
  const { mutate } = ProcessService.useProcess(process.processId);

  return (
    <Card sx={{ p: 1 }}>
      <CardHeader
        title={<Typography variant="h5">API Benutzer</Typography>}
        avatar={<SmartToyIcon sx={{ fontSize: '2.5rem' }} />}
      />

      <CardContent>
        <ProcessTechnicalUserForm
          process={process}
          handleProcessUpdate={async (data, processId) => {
            try {
              await assertAuthentication(ReauthTimeInMinutes.EDIT_PROCESS);

              const updatedProcess = await ProcessService.putProcess(data, processId);

              await mutate({ ...process, ...updatedProcess });

              notifications.addSuccess('Technischer User gespeichert');

              return updatedProcess;
            } catch (error) {
              notifications.addError(error);
            }
          }}
        />
      </CardContent>
    </Card>
  );
};

export default ProcessTechnicalUser;
