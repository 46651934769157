import { Box, Button, Stack, Typography } from '@mui/material';
import React, { FunctionComponent, useState } from 'react';
import { Link } from 'react-router-dom';
import ExportoIcon from '../../shared/ExportoIcon';
import kitten from '../../assets/img/kitten.jpg';

export const NotFoundPage: FunctionComponent<{}> = () => {
  const [showKitten, setShowKitten] = useState(false);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100vh',
        padding: 3,
        textAlign: 'center',
      }}
    >
      <Typography
        variant="h2"
        gutterBottom
      >
        Page not found
      </Typography>
      <Typography variant="body1">We are sorry, but we can not find the page you are looking for.</Typography>

      <Stack
        direction="row"
        spacing={1}
        sx={{ my: 3 }}
      >
        <Button
          component={Link}
          to="/"
          variant="contained"
          startIcon={<ExportoIcon />}
        >
          Take me back
        </Button>
        {!showKitten && (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setShowKitten(true)}
          >
            Show me a kitten
          </Button>
        )}
      </Stack>

      {showKitten && (
        <Box
          component="img"
          sx={{ maxWidth: '100%' }}
          src={kitten}
          alt="Kitten"
          title="Photo by Kote Puerto"
        />
      )}
    </Box>
  );
};
