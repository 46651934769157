import React, { Fragment, FC, useEffect, useReducer, useRef, useState } from 'react';
import { CustomerFilter } from '../customer-filter/CustomerFilter';
import { ProcessTypeDto, ProcessTypeService } from '../../../services/process-type-service/process-type.service';
import { ProcessTypeFilter } from '../../../shared/components/process-type-filter/ProcessTypeFilter';
import { CircularProgress, Stack } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { isEqualArray } from '../../helper/helper';
import { CustomerDto, CustomerService } from '../../../services/customer-service/customer.service';
import WarehouseFilter from '../process-type-filter/WarehouseFilter';
import { ProcessDto, ProcessService } from '../../../services/process-service/process.service';

export interface ProcessFilterContainerValue {
  processTypes: ProcessTypeDto[];
  customers: CustomerDto[];
  customerProcesses: ProcessDto[];
}

interface ProcessFilterContainerProps {
  onChange: (value: ProcessFilterContainerValue) => Promise<void>;
  multipleCustomers?: boolean;
  disableCustomerSelection?: boolean;
  warehouse?: 'inbound' | 'outbound';
}

interface FilterState {
  customerIds: number[];
  processTypeIds: number[];
}

enum FilterActionType {
  customers = 'customerIds',
  processTypes = 'processTypeIds',
}

type FilterAction = { type: FilterActionType; data: number[] };

const filterReducer = (state: FilterState, action: FilterAction): FilterState => {
  if (isEqualArray(state[action.type], action.data)) {
    return state;
  }

  if (action.type === FilterActionType.processTypes) {
    return {
      processTypeIds: action.data,
      customerIds: [],
    };
  }

  return {
    ...state,
    [action.type]: action.data,
  };
};

const CUSTOMER_FILTER_KEY = 'customer:filter';

const getStoredFilter = (): FilterState => {
  return (
    JSON.parse(localStorage.getItem(CUSTOMER_FILTER_KEY) || 'false') || {
      customerIds: [],
      processTypeIds: [],
    }
  );
};

const storeFilter = (filterState: FilterState) => {
  // remove deprecated field (April 2023)
  localStorage.removeItem('process:filter');
  localStorage.setItem(CUSTOMER_FILTER_KEY, JSON.stringify(filterState));
};

const getIntegersFromSearch = (search: string, parameter: string): number[] => {
  const searchParams = new URLSearchParams(search);

  return (
    searchParams
      .get(parameter)
      ?.split(',')
      .map((i) => parseInt(i, 10))
      .filter((i) => !isNaN(i)) || []
  );
};

export const CUSTOMER_IDS_SEARCH_PARAM = 'customerIds';
export const PROCESS_TYPE_IDS_SEARCH_PARAM = 'processTypeIds';

const getInitialFilterState = (search: string): FilterState => {
  const storedFilter = getStoredFilter();

  const searchParamCustomerIds = getIntegersFromSearch(search, CUSTOMER_IDS_SEARCH_PARAM);
  const searchParamProcessTypeIds = getIntegersFromSearch(search, PROCESS_TYPE_IDS_SEARCH_PARAM);

  return {
    customerIds: searchParamCustomerIds,
    processTypeIds: searchParamProcessTypeIds.length ? searchParamProcessTypeIds : storedFilter.processTypeIds,
  };
};

/**
 * Combines CustomerFilter component and ProcessTypeFilter component,
 * so that only customer processes will be selected that belong to the selected process type
 * @param props
 */
export const ProcessFilterContainer: FC<ProcessFilterContainerProps> = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { onChange } = props;

  const processTypes = ProcessTypeService.useProcessTypes();
  const customers = CustomerService.useCustomers();
  const processes = ProcessService.useProcesses();

  const [customerIdsHistory, setCustomerIdsHistory] = useState<number[][]>([]);
  const [customerIdsHistoryPointer, setCustomerIdsHistoryPointer] = useState<number>(0);

  const [filter, dispatch] = useReducer(filterReducer, getInitialFilterState(location.search));

  const prevFilter = useRef<FilterState>({
    customerIds: [],
    processTypeIds: [],
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    if (searchParams.has(CUSTOMER_IDS_SEARCH_PARAM) || searchParams.has(PROCESS_TYPE_IDS_SEARCH_PARAM)) {
      searchParams.delete(CUSTOMER_IDS_SEARCH_PARAM);
      searchParams.delete(PROCESS_TYPE_IDS_SEARCH_PARAM);

      navigate(`?${searchParams.toString()}`);
    }
  }, [navigate, location.search]);

  useEffect(() => {
    if (
      isEqualArray(prevFilter.current.customerIds, filter.customerIds) &&
      isEqualArray(prevFilter.current.processTypeIds, filter.processTypeIds)
    ) {
      return;
    }

    if (!customers.data?.length || !processTypes.data?.length || !processes.data?.length) {
      return;
    }

    storeFilter(filter);
    prevFilter.current = filter;

    const newCustomers = customers.data.filter((customer) => filter.customerIds.includes(customer.customerId));
    const newProcessTypes = processTypes.data.filter((processType) =>
      filter.processTypeIds.includes(processType.processTypeId),
    );
    const newProcessIds = processes.data.filter(
      (process) =>
        filter.processTypeIds.includes(process.processTypeId) && filter.customerIds.includes(process.customerId),
    );

    onChange({
      customers: newCustomers,
      processTypes: newProcessTypes,
      customerProcesses: newProcessIds,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.customerIds, filter.processTypeIds, processTypes.data, customers.data, processes.data]);

  useEffect(() => {
    if (!processTypes.data?.length || filter.processTypeIds.length) {
      return;
    }

    dispatch({
      type: FilterActionType.processTypes,
      data: [processTypes.data[0].processTypeId],
    });
  }, [processTypes.data, filter.processTypeIds]);

  useEffect(() => {
    const handler = (ev: KeyboardEvent) => {
      const tagName = (ev.target as HTMLElement)?.tagName;

      if (tagName !== 'BODY' && tagName !== 'INPUT') {
        return;
      }

      let index = customerIdsHistoryPointer;

      if (ev.key === 'ArrowDown') {
        index++;
      } else if (ev.key === 'ArrowUp') {
        index--;
      } else {
        return;
      }

      if (index < 0 || index >= customerIdsHistory.length || !customerIdsHistory[index]) {
        return;
      }

      dispatch({
        type: FilterActionType.customers,
        data: customerIdsHistory[index],
      });
      setCustomerIdsHistoryPointer(index);
    };

    window.addEventListener('keydown', handler);

    return () => {
      window.removeEventListener('keydown', handler);
    };
  }, [customerIdsHistory, customerIdsHistoryPointer]);

  const getCustomerProcessesForProcessType = (customer: CustomerDto): ProcessDto[] => {
    const customerProcesses = processes.data.filter((process) => process.customerId === customer.customerId);
    return customerProcesses.filter((process) => filter.processTypeIds.includes(process.processTypeId));
  };

  return (
    <Fragment>
      {processTypes.isLoading || customers.isLoading ? (
        <CircularProgress
          color="secondary"
          size="1em"
        />
      ) : (
        <form onSubmit={(event) => event.preventDefault()}>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="center"
            height="100%"
          >
            {props.warehouse ? (
              <WarehouseFilter
                onChange={(processTypeIds) => {
                  dispatch({
                    type: FilterActionType.processTypes,
                    data: processTypeIds,
                  });

                  setCustomerIdsHistory([]);
                  setCustomerIdsHistoryPointer(0);
                }}
                processTypes={processTypes.data || []}
                selectedProcessTypeIds={filter.processTypeIds}
                direction={props.warehouse}
              />
            ) : (
              <ProcessTypeFilter
                onChange={(processTypeIds) => {
                  dispatch({
                    type: FilterActionType.processTypes,
                    data: processTypeIds,
                  });

                  setCustomerIdsHistory([]);
                  setCustomerIdsHistoryPointer(0);
                }}
                processTypes={processTypes.data || []}
                selectedProcessTypeIds={filter.processTypeIds}
              />
            )}
            {!props.disableCustomerSelection && (
              <CustomerFilter
                onChange={(customerIds) => {
                  dispatch({
                    type: FilterActionType.customers,
                    data: customerIds,
                  });

                  if (customerIds.length > 0) {
                    setCustomerIdsHistory((history) => [customerIds, ...history]);
                    setCustomerIdsHistoryPointer(0);
                  } else {
                    setCustomerIdsHistoryPointer(-1);
                  }
                }}
                multiple={props.multipleCustomers}
                customers={(customers.data || [])
                  .map((customer) => {
                    return {
                      ...customer,
                      compatibleProcesses: getCustomerProcessesForProcessType(customer),
                    };
                  })
                  .filter((customer) => customer.compatibleProcesses.length > 0)}
                selectedCustomerIds={filter.customerIds}
              />
            )}
          </Stack>
        </form>
      )}
    </Fragment>
  );
};
