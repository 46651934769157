import { Button, CircularProgress, Stack } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import React, { useCallback, useEffect, useState } from 'react';
import SearchField from './SearchField';
import { useScanInput } from '../../shared/hooks/keypress';
import { t } from 'i18next';

const MIN_SCAN_LENGTH = 4;
const IGNORED_SEARCH_TERMS = ['0509', '0203']; // special post barcodes without meaning for us

type Props = {
  searchQuery: string;
  onSubmit: (value: string, isFormSubmitted?: boolean) => void;
  placeholder?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  disableButton?: boolean;
  isLoading?: boolean;
};

const SearchForm: React.FC<Props> = (props) => {
  const [prevValue, setPrevValue] = useState('');
  const [value, setValue] = useState(props.searchQuery);

  const { searchQuery, disabled, onSubmit } = props;

  const scanCallback = useCallback(
    (buffer: string) => {
      if (disabled) {
        return;
      }

      if (IGNORED_SEARCH_TERMS.includes(buffer)) {
        setValue('');
      } else if (buffer.length >= MIN_SCAN_LENGTH) {
        setPrevValue('');
        setValue(buffer);
      }
    },
    [disabled],
  );

  useScanInput(scanCallback);

  useEffect(() => {
    setValue(searchQuery);
    setPrevValue('');
  }, [searchQuery]);

  useEffect(() => {
    const isPastedInput = value.length >= MIN_SCAN_LENGTH && value.length - prevValue.length > MIN_SCAN_LENGTH;

    // this should help to debug why sometimes the submit is not triggered
    console.log('Search form value changed', { isPastedInput, value, prevValue });

    if (isPastedInput || value.length === 0) {
      if (IGNORED_SEARCH_TERMS.includes(value)) {
        setValue('');

        return;
      }

      onSubmit(value);
    }

    setPrevValue(value);
  }, [onSubmit, value, prevValue]);

  return (
    <form
      onSubmit={(ev) => {
        ev.preventDefault();

        onSubmit(value, true);
      }}
    >
      <Stack
        direction="row"
        spacing={1}
      >
        <SearchField
          value={value}
          onChange={(value) => setValue(value)}
          disabled={props.disabled}
          isLoading={props.isLoading}
          autoFocus={props.autoFocus}
          placeholder={props.placeholder}
        />

        <Button
          disableElevation
          variant="contained"
          color="secondary"
          type={props.disableButton ? 'button' : 'submit'} // if the submit button is disabled, no submit event is fired
          disabled={props.isLoading || props.disabled || props.disableButton || value === searchQuery || !value}
          startIcon={props.isLoading ? <CircularProgress size="1em" /> : <SearchIcon />}
        >
          {t('Search')}
        </Button>
      </Stack>
    </form>
  );
};

export default SearchForm;
