import { Send } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Stack, TextField } from '@mui/material';
import React, { useState } from 'react';

type Props = {
  onSubmit: (token: string) => Promise<undefined | false>;
};

const SecondFactorForm: React.FC<Props> = (props) => {
  const [disabled, setDisabled] = useState(false);

  return (
    <form
      onSubmit={(ev) => {
        ev.preventDefault();

        setDisabled(true);

        const formData = new FormData(ev.currentTarget);
        const token = formData.get('token')?.toString() ?? '';

        props.onSubmit(token).then((success) => {
          if (!success) {
            setDisabled(false);
          }
        });
      }}
    >
      <Stack
        direction="column"
        spacing={2}
      >
        <TextField
          name="token"
          variant="outlined"
          label="Zweiter Faktor"
          disabled={disabled}
        />

        <LoadingButton
          loading={disabled}
          loadingPosition="start"
          startIcon={<Send />}
          type="submit"
          variant="contained"
          color="secondary"
          disabled={disabled}
        >
          Weiter
        </LoadingButton>
      </Stack>
    </form>
  );
};

export default SecondFactorForm;
