import {
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  Stack,
  IconButton,
  InputAdornment,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Chip from '@mui/material/Chip';
import InputLabel from '@mui/material/InputLabel';
import { Theme } from '@mui/material/styles';
import ListItemText from '@mui/material/ListItemText';
import { default as React, FC, useEffect, Fragment } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { ShipmentState } from '../../backend';
import ShipmentStateLabel from './ShipmentStateLabel';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '60px',
      display: 'flex',
      alignItems: 'center',
    },
  }),
);

export const shipmentStateOptions: ShipmentState[] = [
  ShipmentState.RETOURE_ANNOUNCED,
  ShipmentState.RETOURE_TOUR_READY,
  ShipmentState.RETOURE_TOUR_ASSIGNED,
  ShipmentState.RETOURE_TOUR_CLOSED,
  ShipmentState.RETOURE_REJECTED,
  ShipmentState.DISPOSED,
  ShipmentState.TO_BE_CLARIFIED,
];

interface ShipmentFilterProps {
  onChange: (orders: ShipmentState[]) => void;
}

export const ShipmentFilter: FC<ShipmentFilterProps> = (props) => {
  const classes = useStyles();

  const [shipmentState, setShipmentState] = React.useState<ShipmentState[]>([]);

  const handleSelectChange = (event: SelectChangeEvent<unknown>) => {
    setShipmentState(event.target.value as ShipmentState[]);
  };

  const renderValue = (selected: unknown) => {
    return (
      <Stack
        direction="row"
        spacing={1}
      >
        {shipmentState.map((state) => (
          <Chip
            size="small"
            color="secondary"
            key={state}
            label={<ShipmentStateLabel state={state} />}
          />
        ))}
      </Stack>
    );
  };

  useEffect(() => {
    if (shipmentState) {
      props.onChange(shipmentState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipmentState]);

  return (
    <div className={classes.root}>
      <Fragment>
        <form onSubmit={(event) => event.preventDefault}>
          <FormControl
            size="small"
            variant="outlined"
            sx={{ minWidth: 120 }}
          >
            {shipmentState.length === 0 && (
              <InputLabel
                sx={{ mt: 0.5 }}
                id="shipment-filter-label"
              >
                Status
              </InputLabel>
            )}
            <Select
              sx={{ minHeight: 41 }}
              labelId="shipment-filter-label"
              multiple
              onChange={handleSelectChange}
              value={shipmentState}
              renderValue={(selected) => renderValue(selected)}
              startAdornment={
                shipmentState.length > 0 && (
                  <InputAdornment position="start">
                    <IconButton
                      onClick={() => setShipmentState([])}
                      edge="start"
                      size="small"
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                )
              }
            >
              {shipmentStateOptions.map((state: ShipmentState) => (
                <MenuItem
                  key={state}
                  value={state}
                  sx={{ pl: 0 }}
                >
                  <Checkbox checked={shipmentState.indexOf(state) > -1} />
                  <ListItemText primary={<ShipmentStateLabel state={state} />} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </form>
      </Fragment>
    </div>
  );
};
