import { DateTime } from 'luxon';

export const DATETIME_FORMAT = 'yyyy-MM-dd HH:mm:ss';
export const DATE_FORMAT = 'yyyy-MM-dd';

export function isToday(candidate: Date): boolean {
  const today = DateTime.now();
  const c = DateTime.fromJSDate(candidate);

  return c.hasSame(today, 'year') && c.hasSame(today, 'month') && c.hasSame(today, 'day');
}
