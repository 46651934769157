import { Box, Grid, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNotifications } from '../../../hooks/useNotifications';
import * as ProcessSettingsService from '../../../services/process-settings-service/processSettings.service';
import { useTranslation } from 'react-i18next';

export interface IFormTranslation {
  languageId: number;
  translation: string;
}

export interface IFormReason {
  index: number;
  reasonId?: string;
  translations: IFormTranslation[];
}

export interface EditReasonsProps {
  reasons: ProcessSettingsService.ReasonDto[];
  languages: ProcessSettingsService.LanguageDto[];
  processId: number;
  onUpdateSettings: () => void;
}

const EditReasons: React.FC<EditReasonsProps> = ({ reasons, languages, processId, onUpdateSettings }) => {
  const { t } = useTranslation();
  const notificationHandler = useNotifications();
  const [formFields, setFormFields] = useState<IFormReason[]>([]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    reasonIndex: number,
    languageId: number,
  ) => {
    const translationText = event.target.value;
    setFormFields((prev) => {
      const newFields = [...prev];
      const reason = newFields.find((field) => field.index === reasonIndex);
      const translation = reason?.translations.find((t) => t.languageId === languageId);
      if (translation) {
        translation.translation = translationText;
      }
      return newFields;
    });
  };

  const handleBlur = async (index: number) => {
    const formField = formFields.find((field) => field.index === index);
    if (formField) {
      const existingReason = reasons.find((r) => r.index === index);
      if (existingReason && formField.translations.every((translation) => !translation.translation)) {
        await ProcessSettingsService.deleteReason(processId, existingReason.reasonId);
      } else {
        const updateValues: ProcessSettingsService.UpdateReasonDto = {
          translations: formField.translations,
          index,
        };
        await ProcessSettingsService.upsertReasonWithTranslations(processId, updateValues);
      }
      notificationHandler.addInfo(`Reason saved`);
      onUpdateSettings();
    }
  };

  useEffect(() => {
    const buildFormField = (index: number): IFormReason => {
      const formField: IFormReason = {
        index,
        translations: [],
      };
      for (const language of languages) {
        formField.translations.push({
          languageId: language.languageId,
          translation:
            reasons
              .find((r) => r.index === index)
              ?.translations.find((translation) => translation.languageId === language.languageId)?.translation ?? '',
        });
      }
      return formField;
    };

    const maxExistingReasonIndex = Math.max(...reasons.map((reason) => reason.index));
    const formFieldsToGenerate = maxExistingReasonIndex < 10 ? 10 : maxExistingReasonIndex + 1;

    const newFormFields: IFormReason[] = [];
    for (let i = 1; i <= formFieldsToGenerate; i++) {
      newFormFields.push(buildFormField(i));
    }
    setFormFields(newFormFields);
  }, [reasons, languages]);

  const getLanguage = (languageId: number): ProcessSettingsService.LanguageDto | undefined => {
    return languages.find((language) => language.languageId === languageId);
  };

  return (
    <Box>
      <Typography
        variant="h3"
        mb={1}
      >
        {t('Return reasons')}
      </Typography>

      <Stack spacing={4}>
        {formFields.map((reason: IFormReason) => (
          <Box
            m={0}
            key={reason.index}
          >
            <Typography
              variant="h6"
              marginY={1}
              paddingLeft={1}
            >
              {t('Reason {{index}}', { index: reason.index })}
            </Typography>
            <Grid
              container
              spacing={2}
            >
              {reason.translations.map((translation) => (
                <Grid
                  item
                  key={`${reason.index} ${translation.languageId}`}
                  sm={12}
                  md={reason.translations.length === 1 ? 12 : 6}
                >
                  <TextField
                    fullWidth
                    value={translation.translation}
                    variant="outlined"
                    label={getLanguage(translation.languageId)?.languageCode}
                    onChange={(event) => handleChange(event, reason.index, translation.languageId)}
                    onBlur={(event) => handleBlur(reason.index)}
                  ></TextField>
                </Grid>
              ))}
            </Grid>
          </Box>
        ))}
      </Stack>
    </Box>
  );
};

export default EditReasons;
