import { Alert, AlertTitle, Box, Button, IconButton, Stack, TextField } from '@mui/material';
import React, { FC } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DeleteIcon from '@mui/icons-material/Delete';
import ButtonAsync from '../../../../shared/components/ButtonAsync';
import { ProcessService, ProcessWithCustomerDto } from '../../../../services/process-service/process.service';
import { useNavigate } from 'react-router-dom';
import { useNotifications } from '../../../../hooks/useNotifications';

export interface ProcessDeletionProps {
  process: ProcessWithCustomerDto;
}

const ProcessDeletion: FC<ProcessDeletionProps> = ({ process }) => {
  const navigateTo = useNavigate();
  const notifications = useNotifications();

  const [isActive, setIsActive] = React.useState<boolean>(false);
  const [isRunning, setIsRunning] = React.useState<boolean>(false);
  const [company, setCompany] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');

  const isCompanyError = !!company && company !== process.customer.company;

  const handleDeleteProcess = async () => {
    setIsRunning(true);

    try {
      await ProcessService.deleteProcess(process.processId, company, password);

      setPassword('');
      navigateTo(`/crm/customers/${process.customerId}`);
    } catch (error) {
      notifications.addError(error);
    } finally {
      setIsRunning(false);
    }
  };

  return (
    <div>
      {isActive ? (
        <>
          <Alert
            severity="error"
            sx={{ mb: 3 }}
          >
            <AlertTitle>Achtung</AlertTitle>

            <Box
              component="ul"
              sx={{ pl: 3 }}
            >
              <li>Aktuell werden beim Löschen eines Prozess dessen Touren nicht archiviert!</li>
              <li>Der Löschvorgang kann bis zu einer Minute dauern.</li>
            </Box>
          </Alert>

          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
          >
            <TextField
              size="small"
              variant="outlined"
              label="Company-Name"
              value={company}
              onChange={(ev) => setCompany(ev.target.value)}
              error={isCompanyError}
            />

            {/* This prevents chrome to autofill both inputs. Additionally we do not want
                to delete  a process on submit, therefore this is fine. */}
            <form onSubmit={(ev) => ev.preventDefault()}>
              <TextField
                size="small"
                variant="outlined"
                type="password"
                label="Log-In-Passwort"
                value={password}
                onChange={(ev) => setPassword(ev.target.value)}
              />
            </form>

            <ButtonAsync
              variant="contained"
              color="error"
              disabled={!company || !password || isCompanyError}
              onClick={() => handleDeleteProcess()}
              startIcon={<DeleteForeverIcon />}
            >
              Prozess jetzt wirklich löschen
            </ButtonAsync>

            <IconButton
              disabled={isRunning}
              onClick={() => setIsActive(false)}
            >
              <CancelIcon />
            </IconButton>
          </Stack>

          {isCompanyError && (
            <Alert
              severity="warning"
              sx={{ mt: 2 }}
            >
              Company name ist falsch.
            </Alert>
          )}
        </>
      ) : (
        <Button
          variant="contained"
          color="error"
          disabled={isRunning}
          onClick={() => setIsActive(true)}
          startIcon={<DeleteIcon />}
        >
          Prozess löschen
        </Button>
      )}
    </div>
  );
};

export default ProcessDeletion;
