import { CircularProgress } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ReturnSettingsStack } from '../../../../../../return-settings/components/ReturnSettingsStack';

type Props = {};

const CustomerReturnSettings: React.FC<Props> = () => {
  const { processId } = useParams();
  const navigate = useNavigate();

  const parsedProcessId = processId && !isNaN(parseInt(processId, 10)) ? parseInt(processId, 10) : null;

  useEffect(() => {
    if (parsedProcessId === null || isNaN(parsedProcessId)) {
      navigate('/');
    }
  }, [parsedProcessId, navigate]);

  if (parsedProcessId === null) {
    return <CircularProgress color="secondary" />;
  }

  return <ReturnSettingsStack processId={parsedProcessId} />;
};

export default CustomerReturnSettings;
