import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

interface IBuildInfo {
  gitId: string;
}

const buildInfoAtom = atomWithStorage<IBuildInfo | null>('system:build', null, undefined, { getOnInit: true });

export const useBuildInfo = () => useAtom(buildInfoAtom);
