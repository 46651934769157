import { backendUri } from '../../shared/helper/env/helper';
import useSWR from 'swr';
import client, { IUseArrayResponse, IUseObjectResponse, SchemaType, api, useApi } from '../../shared/client';
import { ConsignmentType, IsoCountryCode, TourCustomsState, TourState, TourType } from '../../shared/backend';
import { ITourCustomsStates } from '@exporto/types-customs';

export interface TourBatchDto extends SchemaType<'TourBatchDto'> {}
export interface TourAndChecksDto extends SchemaType<'TourAndChecksDto'> {}
export interface TourChecksDto extends SchemaType<'TourChecksDto'> {}
export interface TourDto extends SchemaType<'TourDto'> {}

export type TourCustomsStateProperties = Pick<
  TourDto,
  | 'exportCustomsState'
  | 'importCustomsState'
  | 'transitCustomsState'
  | 'transitManifestCustomsState'
  | 'transitDepartureCustomsState'
  | 'transitArrivalCustomsState'
  | 'sumACustomsState'
>;

export type TourCustomsStateProperty = keyof TourCustomsStateProperties;

export interface CreateTourBodyDto extends SchemaType<'CreateTourBodyDto'> {}
export interface UpdateTourDto extends SchemaType<'UpdateTourDto'> {}

export interface GenerateToursQuery {
  type: TourType;
  processTypeId?: number;
  processIds?: number[];
}

export interface ICreateTourResult {
  tours: TourDto[];
  weight: number;
}

export namespace TourService {
  export async function createTourBatch(tourIds: number[]): Promise<string> {
    const response = await api.post<'/v1/tour/batch'>(`${backendUri}/v1/tour/batch`, { tourIds });

    return response.data.tourBatchId;
  }

  export async function mergeTourBatches(tourBatchIds: string[]): Promise<string> {
    const response = await api.post<'/v1/tour/batches/merge'>(`${backendUri}/v1/tour/batches/merge`, { tourBatchIds });

    return response.data.tourBatchId;
  }

  export async function assignTour(tourId: number): Promise<void> {
    await api.put<'/v1/tour/{tourId}/assign'>(`${backendUri}/v1/tour/${tourId}/assign`);
  }

  export async function unassignTour(tourId: number): Promise<void> {
    await api.put<'/v1/tour/{tourId}/unassign'>(`${backendUri}/v1/tour/${tourId}/unassign`);
  }

  export async function assignTourBatch(tourBatchId: string): Promise<void> {
    await api.put<'/v1/tour/batch/{batchId}/assign'>(`${backendUri}/v1/tour/batch/${tourBatchId}/assign`);
  }

  export async function getDeImportCsv(tourBatchId: string): Promise<string> {
    const res = await api.get<'/v1/tour/batch/{tourBatchId}/deImportCsv'>(
      `${backendUri}/v1/tour/batch/${tourBatchId}/deImportCsv`,
      {
        responseType: 'arraybuffer',
      },
    );

    return res.data;
  }

  export async function getDeBatchImportPositionsCsv(tourBatchId: string): Promise<string> {
    const res = await api.get<'/v1/tour/batch/{tourBatchId}/deBatchImportPositionsCsv'>(
      `${backendUri}/v1/tour/batch/${tourBatchId}/deBatchImportPositionsCsv`,
      {
        responseType: 'arraybuffer',
      },
    );

    return res.data;
  }

  export async function unassignTourBatch(tourBatchId: string): Promise<void> {
    await api.put<'/v1/tour/batch/{batchId}/unassign'>(`${backendUri}/v1/tour/batch/${tourBatchId}/unassign`);
  }

  export function hasTourWarnings(checks: TourAndChecksDto['checks']): boolean {
    return (
      checks.faultyShipmentWeight ||
      Object.keys(checks.articlesWithWarnings ?? {}).length > 0 ||
      !!checks.missingArticles?.length ||
      !!checks.incompleteArticleIds?.length ||
      !!checks.emptyShipments?.length ||
      Object.keys(checks.shipmentLineItemsPriceWarnings ?? {}).length > 0 ||
      !!checks.missingShipmentWeights?.length ||
      !!checks.missingExportoBarcode.length
    );
  }

  export const useTourDetails = (tourId: number | null) => {
    const url = tourId ? `${backendUri}/v1/tour/${tourId}` : null;
    const { data, error, mutate, isValidating } = useSWR<TourAndChecksDto>(url);

    return {
      data,
      error,
      isLoading: !!url && !error && typeof data === 'undefined',
      isValidating,
      isError: !!error,
      hasWarnings: data?.checks && hasTourWarnings(data.checks),
      mutate,
    };
  };

  export const useTourBatches = (options?: {
    limit?: number;
    offset?: number;
    tourState?: TourState;
    tourType?: TourType;
    dispatchCountry?: IsoCountryCode;
    destinationCountry?: IsoCountryCode;
    customerIds?: number[];
    processTypeIds?: number[];
  }): IUseArrayResponse<TourBatchDto> => {
    const { data, error, mutate, isLoading, isValidating } = useApi(`/v1/tour/batches`, options);

    return {
      data: data ?? [],
      error,
      isLoading,
      isValidating,
      isError: !!error,
      mutate,
    };
  };

  export const useTourBatch = (batchId: string): IUseObjectResponse<TourBatchDto> & { isValidating: boolean } => {
    const { data, error, mutate, isLoading, isValidating } = useApi({ key: '/v1/tour/batch/{batchId}', batchId });

    return {
      data: data ?? null,
      error,
      isLoading,
      isValidating,
      isError: !!error,
      mutate,
    };
  };

  export const useToursByBatch = (
    batchId: string | null,
  ): IUseArrayResponse<TourAndChecksDto> & { isValidating: boolean } => {
    const { data, error, mutate, isLoading, isValidating } = useSWR<TourAndChecksDto[]>(
      batchId ? `/v1/tour/batch/${batchId}/tours` : null,
      { refreshInterval: 60_000 },
    );

    return {
      data: data ?? [],
      error,
      isLoading,
      isValidating,
      isError: !!error,
      mutate,
    };
  };

  export const closeTour = async (id: number) => {
    const res = await client.post(`${backendUri}/v1/tour/${id}/close`, {});

    return res.data;
  };

  export const openTour = async (id: number) => {
    const res = await client.post(`${backendUri}/v1/tour/${id}/reopen`, {});

    return res.data;
  };

  export const updateTour = async (tourId: number, data: UpdateTourDto) => {
    const response = await client.put<TourDto>(`${backendUri}/v1/tour/${tourId}`, data);

    return response.data;
  };

  export async function generateTours(query: CreateTourBodyDto) {
    const response = await api.post<'/v1/tour'>('/v1/tour', query);

    return response.data;
  }

  const consignmentTypeTourCustomsStateMap: Partial<Record<ConsignmentType, keyof ITourCustomsStates>> = {
    [ConsignmentType.EXPORT]: 'exportCustomsState',
    [ConsignmentType.IMPORT]: 'importCustomsState',
    [ConsignmentType.POST_NORD_IMPORT]: 'importCustomsState',
    [ConsignmentType.TRANSIT_MANIFEST]: 'transitManifestCustomsState',
    [ConsignmentType.TRANSIT_DEPARTURE]: 'transitDepartureCustomsState',
    [ConsignmentType.TRANSIT_ARRIVAL]: 'transitArrivalCustomsState',
    [ConsignmentType.SUMA]: 'sumACustomsState',
  };

  export const toursByBatchMutatorFactory =
    (consignmentType: ConsignmentType, tourIds: number[]) =>
    (currentData: TourAndChecksDto[] | undefined): TourAndChecksDto[] | undefined => {
      if (!currentData) {
        return currentData;
      }

      const customsStateKey = consignmentTypeTourCustomsStateMap[consignmentType];

      if (!customsStateKey) {
        return currentData;
      }

      const unrelatedTours = currentData.filter(({ tour: { tourId } }) => !tourIds.includes(tourId));
      const relatedTours = currentData
        .filter(({ tour: { tourId } }) => tourIds.includes(tourId))
        .map(({ tour, checks }: TourAndChecksDto) => {
          return { tour: { ...tour, [customsStateKey]: TourCustomsState.CONSIGNMENT_PENDING }, checks };
        });

      return [...unrelatedTours, ...relatedTours];
    };
}
