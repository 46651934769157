import React from 'react';
import { diffJson } from 'diff';
import { Box, Typography } from '@mui/material';

type Props = {
  current: Record<string, unknown>;
  old: Record<string, unknown>;
  onlyChanges?: boolean;
};

const JsonDiff: React.FC<Props> = (props) => {
  const diffs = diffJson(props.old, props.current);

  if (diffs.length === 1 && !diffs[0].added && !diffs[0].removed && props.onlyChanges) {
    return <Typography textAlign="center">Keine Änderungen</Typography>;
  }

  return (
    <Box sx={{ whiteSpace: 'pre' }}>
      {diffs.map((diff, i) => {
        if (diff.added) {
          return (
            <Box
              component="span"
              key={i}
              sx={{ backgroundColor: 'rgba(0,255,0,0.3)' }}
            >
              {diff.value}
            </Box>
          );
        } else if (diff.removed) {
          return (
            <Box
              component="span"
              key={i}
              sx={{
                backgroundColor: 'rgba(255,0,0,0.3)',
                textDecoration: 'line-through',
              }}
            >
              {diff.value}
            </Box>
          );
        }

        return <span key={i}>{!props.onlyChanges ? diff.value : ''}</span>;
      })}
    </Box>
  );
};

export default JsonDiff;
