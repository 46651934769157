import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import ShipmentOrderOverview from './ShipmentOrderOverview';
import { t } from 'i18next';

type Props = {};

const ShipmentPage: React.FC<Props> = () => {
  const params = useParams();
  const shipmentId = parseInt(params.shipmentId ?? '', 10);

  if (!isFinite(shipmentId)) {
    return <>{t('Not found')}</>;
  }

  return (
    <>
      <Helmet>
        <title>{t('Shipment {{shipmentId}}', { shipmentId })}</title>
      </Helmet>
      <ShipmentOrderOverview shipmentId={shipmentId} />
    </>
  );
};

export default ShipmentPage;
