import { Tooltip, Box } from '@mui/material';
import React from 'react';
import Report from '@mui/icons-material/Report';
import CheckCircle from '@mui/icons-material/CheckCircle';
import { getSystemLabel, useHealthStatus } from '../../../services/health-service/health-service';

const ReportIcon = () => (
  <Report
    sx={(theme) => ({
      fontSize: '1.2em',
      verticalAlign: 'top',
      color: theme.palette.warning.light,
    })}
  />
);
const CheckIcon = () => <CheckCircle sx={{ fontSize: '1.2em', verticalAlign: 'top' }} />;

type Props = {};

const HealthStatus: React.FC<Props> = () => {
  const healthStatus = useHealthStatus();

  if (!healthStatus.data) {
    return <></>;
  }

  return (
    <Tooltip
      placement="top"
      arrow
      title={
        <>
          {Object.entries(healthStatus.data.details).map(([system, info]) => (
            <p key={system}>
              {info.status === 'up' ? <CheckIcon /> : <ReportIcon />} {getSystemLabel(system)}
            </p>
          ))}
        </>
      }
    >
      {healthStatus.data.status === 'ok' ? (
        <Box
          component="a"
          href="/system/health"
          sx={(theme) => ({ color: theme.palette.success.light, textDecoration: 'none' })}
        >
          <CheckIcon /> Alle Systeme ohne Störung
        </Box>
      ) : (
        <Box
          component="a"
          href="/system/health"
          sx={(theme) => ({ color: theme.palette.warning.light, textDecoration: 'none' })}
        >
          <ReportIcon /> Systeme gestört
        </Box>
      )}
    </Tooltip>
  );
};

export default HealthStatus;
