import { backendUri } from '../../shared/helper/env/helper';
import useSWR, { mutate, Cache } from 'swr';
import { IUseObjectResponse, SchemaType, api } from '../../shared/client';
import { Aeb } from '@exporto/types-customs';
import { swrFetcherWith404 } from '../../contexts/SWRProvider';
import {
  convert,
  convertToBoolean,
  convertToNullableNumber,
  convertToNullableString,
  convertToString,
  drop,
  dropIfEmpty,
  dropIfEmptyObject,
} from '../../shared/converter';
import { Backend, CategoryLock } from '../../shared/backend';

export interface ArticleDto extends SchemaType<'ArticleDto'> {}
export interface ArticleCustomsDataChDto extends SchemaType<'ArticleCustomsDataChDto'> {}
export interface ArticleCustomsDataDeDto extends SchemaType<'ArticleCustomsDataDeDto'> {}
export interface ArticleCustomsDataGbDto extends SchemaType<'ArticleCustomsDataGbDto'> {}
export interface ArticleCustomsDataNoDto extends SchemaType<'ArticleCustomsDataNoDto'> {}

export interface CreateProcessArticleDto extends SchemaType<'CreateProcessArticleDto'> {}
export interface UpdateArticleDto extends SchemaType<'UpdateArticleDto'> {}
export type GetArticleFilterDto = Backend.paths['/article']['get']['parameters']['query'];

export interface ICustomsData {
  customsDataCH: Partial<ArticleCustomsDataChDto>;
  customsDataDE: Partial<ArticleCustomsDataDeDto>;
  customsDataGB: Partial<ArticleCustomsDataGbDto>;
  customsDataNO: Partial<ArticleCustomsDataNoDto>;
}

export type MATCH_PAIR_FIELD_ARTICLE_PROPERTIES =
  | 'name'
  | 'description'
  | 'hsCode'
  | 'categories'
  | 'tags'
  | 'ean'
  | 'raw';

export enum filter {
  INCOMPLETE = 'FALSE',
  COMPLETE = 'TRUE',
}

export enum ControlType {
  TEXT = 'TEXT',
  CHECKBOX = 'CHECKBOX',
  DROPDOWN = 'DROPDOWN',
}

export interface IFieldDescription {
  name: string;
  controlType?: ControlType;
  controlOptions?: string[];
  controlDisabled?: boolean;
  step?: number;
}
export interface IArticleFieldDescription extends IFieldDescription {
  name: keyof ArticleDto;
}
export interface IArticleCustomsDataChFieldDescription extends IFieldDescription {
  name: keyof ArticleCustomsDataChDto;
}
export interface IArticleCustomsDataDeFieldDescription extends IFieldDescription {
  name: keyof ArticleCustomsDataDeDto;
}
export interface IArticleCustomsDataGbFieldDescription extends IFieldDescription {
  name: keyof ArticleCustomsDataGbDto;
}
export interface IArticleCustomsDataNoFieldDescription extends IFieldDescription {
  name: keyof ArticleCustomsDataNoDto;
}

export interface IArticleFields {
  base: IFieldDescription[];
  customsDataCH: IFieldDescription[];
  customsDataDE: IFieldDescription[];
  customsDataGB: IFieldDescription[];
  customsDataNO: IFieldDescription[];
}

export namespace ArticleService {
  export function newArticle(): ArticleDto {
    return {
      raw: {},
      articleId: -1,
      externalArticleId: '',
      name: '',
      description: null,
      hsCode: null,
      weight: null,
      defaultWeight: null,
      defaultPrice: 2,
      warning: null,
      isBlocked: false,
      blockedAt: null,
      lastClassification: '',
      articleCategoryId: null,
      processId: null,
      categoryLock: CategoryLock.NONE,
      originCountry: null,
      ean: null,
      isNotCustomsRelevant: false,
      additionalAmount: null,
      additionalAmountUnit: null,
      categories: null,
      tags: null,
      imageUrl: null,
      customsDataDE: {
        description: null,
        customsTariffNumber: null,
        customsTariffNumberAddition: null,
        vatCode: null,
      },
      customsDataCH: {
        customsTariffNumber: null,
        preferenceCode: null,
        authorizationCode: null,
        nzeMandatoryCode: null,
        statisticalKey: null,
        vatCode: null,
      },
      customsDataGB: {
        description: null,
        customsTariffNumber: null,
        vatCode: null,
      },
      customsDataNO: {
        description: null,
        customsTariffNumber: null,
        vatCode: null,
      },
      createdAt: '',
      updatedAt: '',
    };
  }

  export function getFieldLabel(internalName: string): string {
    const association: Record<string, string> = {
      articleId: 'Artikel ID',
      externalArticleId: 'Externe Artikel ID',
      name: 'Name',
      description: 'Beschreibung',
      weight: 'Eigenmasse',
      defaultWeight: 'Gewicht via Artikelkategorie ',
      defaultPrice: 'Preis via Artikelkategorie',
      articleCategoryId: 'Artikelkategorie ID',
      processId: 'Prozess ID',
      warning: 'Warnung',
      isBlocked: 'Blockiert',
      categoryLock: 'Kategorie sperren',
      raw: 'RAW',
      customsTariffNumber: 'Zolltarifnummer',
      customsTariffNumberAddition: 'Zusatz Zolltarifnummer',
      preferenceCode: 'Preferenzcode',
      authorizationCode: 'Authorisationscode',
      nzeMandatoryCode: 'NZE-Pflichtcode',
      statisticalKey: 'Statistischer Schlüssel',
      vatCode: 'MwSt-Code (VAT-Code)',
      additionalAmount: 'Zusatzmenge',
      additionalAmountUnit: 'Einheit Zusatzmenge',
      isNotCustomsRelevant: 'Nicht Zollrelevant',
      ean: 'EAN',
      originCountry: 'Herstellungsland (ISO-Code)',
      categories: 'Kategorien',
      tags: 'Tags',
      imageUrl: 'Foto URL',
      hsCode: 'HS Code',
    };

    return association[internalName] ?? internalName;
  }

  export const articleCustomsDataChFields: IArticleCustomsDataChFieldDescription[] = [
    { name: 'customsTariffNumber', controlType: ControlType.TEXT },
    { name: 'preferenceCode', controlType: ControlType.DROPDOWN, controlOptions: ['0', '1', ''] },
    { name: 'authorizationCode', controlType: ControlType.DROPDOWN, controlOptions: ['0', '1', '2', ''] },
    { name: 'nzeMandatoryCode', controlType: ControlType.DROPDOWN, controlOptions: ['0', '1', '2', ''] },
    { name: 'statisticalKey', controlType: ControlType.TEXT },
    { name: 'vatCode', controlType: ControlType.DROPDOWN, controlOptions: ['1', '2', ''] },
  ];

  const articleCustomsDataChConverters: Record<keyof ArticleCustomsDataChDto, ((arg: any) => unknown)[]> = {
    customsTariffNumber: [convertToNullableString],
    preferenceCode: [convertToNullableNumber],
    authorizationCode: [convertToNullableNumber],
    nzeMandatoryCode: [convertToNullableNumber],
    statisticalKey: [convertToNullableString],
    vatCode: [convertToNullableNumber],
  };

  export const articleCustomsDataDeFields: IArticleCustomsDataDeFieldDescription[] = [
    { name: 'description', controlType: ControlType.TEXT },
    { name: 'customsTariffNumber', controlType: ControlType.TEXT },
    { name: 'customsTariffNumberAddition', controlType: ControlType.TEXT },
    { name: 'vatCode', controlType: ControlType.DROPDOWN, controlOptions: ['1', '2', ''] },
  ];

  const articleCustomsDataDeConverters: Record<keyof ArticleCustomsDataDeDto, ((arg: any) => unknown)[]> = {
    description: [convertToNullableString],
    customsTariffNumber: [convertToNullableString],
    customsTariffNumberAddition: [convertToNullableString],
    vatCode: [convertToNullableNumber],
  };

  export const articleCustomsDataGbFields: IArticleCustomsDataGbFieldDescription[] = [
    { name: 'description', controlType: ControlType.TEXT },
    { name: 'customsTariffNumber', controlType: ControlType.TEXT },
    { name: 'vatCode', controlType: ControlType.DROPDOWN, controlOptions: ['1', '2', ''] },
  ];

  const articleCustomsDataGbConverters: Record<keyof ArticleCustomsDataGbDto, ((arg: any) => unknown)[]> = {
    description: [convertToNullableString],
    customsTariffNumber: [convertToNullableString],
    vatCode: [convertToNullableNumber],
  };

  export const articleCustomsDataNoFields: IArticleCustomsDataNoFieldDescription[] = [
    { name: 'description', controlType: ControlType.TEXT },
    { name: 'customsTariffNumber', controlType: ControlType.TEXT },
    { name: 'vatCode', controlType: ControlType.DROPDOWN, controlOptions: ['1', '2', '3', ''] },
  ];

  const articleCustomsDataNoConverters: Record<keyof ArticleCustomsDataNoDto, ((arg: any) => unknown)[]> = {
    description: [convertToNullableString],
    customsTariffNumber: [convertToNullableString],
    vatCode: [convertToNullableNumber],
  };

  const createArticleDataFields: IArticleFieldDescription[] = [
    { name: 'externalArticleId' },
    { name: 'name' },
    { name: 'description' },
    { name: 'categories' },
    { name: 'tags' },
    { name: 'imageUrl' },
    { name: 'weight' },
    { name: 'ean' },
    { name: 'originCountry' },
    { name: 'defaultWeight', step: 1 },
    { name: 'defaultPrice', step: 0.01 },
    { name: 'additionalAmount', step: 0.001 },
    {
      name: 'additionalAmountUnit',
      controlType: ControlType.DROPDOWN,
      controlOptions: [...Object.values(Aeb.Unit), ''],
    },
    { name: 'categoryLock', controlType: ControlType.DROPDOWN, controlOptions: [...Object.values(CategoryLock)] },
    { name: 'warning', controlType: ControlType.TEXT },
    { name: 'isBlocked', controlType: ControlType.CHECKBOX },
    { name: 'isNotCustomsRelevant', controlType: ControlType.CHECKBOX },
  ];

  export const createArticleFields: IArticleFields = {
    base: createArticleDataFields,
    customsDataCH: articleCustomsDataChFields,
    customsDataDE: articleCustomsDataDeFields,
    customsDataGB: articleCustomsDataGbFields,
    customsDataNO: articleCustomsDataNoFields,
  };

  export const getCreateArticleDataConverters = (): Record<keyof ArticleDto, ((arg: any) => unknown)[]> => {
    const createArticleConverters: Record<keyof CreateProcessArticleDto, ((arg: any) => unknown)[]> = {
      externalArticleId: [convertToString],
      name: [convertToString],
      description: [convertToNullableString],
      categories: [convertToNullableString],
      tags: [convertToNullableString],
      imageUrl: [convertToNullableString],
      weight: [convertToNullableNumber],
      ean: [dropIfEmpty],
      originCountry: [convertToNullableString],
      defaultWeight: [convertToNullableNumber],
      defaultPrice: [convertToNullableNumber],
      additionalAmount: [convertToNullableNumber],
      additionalAmountUnit: [convertToNullableString],
      categoryLock: [convertToString],
      warning: [convertToNullableString],
      isBlocked: [convertToBoolean],
      isNotCustomsRelevant: [convertToBoolean],
      customsDataCH: [
        (articleDataCH: Record<string, unknown>) => convert(articleCustomsDataChConverters, articleDataCH),
      ],
      customsDataDE: [
        (articleDataDE: Record<string, unknown>) => convert(articleCustomsDataDeConverters, articleDataDE),
      ],
      customsDataGB: [
        (articleDataGB: Record<string, unknown>) => convert(articleCustomsDataGbConverters, articleDataGB),
      ],
      customsDataNO: [
        (articleDataNO: Record<string, unknown>) => convert(articleCustomsDataNoConverters, articleDataNO),
      ],
    };

    const createArticleEraseConverters: Record<
      keyof Omit<ArticleDto, keyof CreateProcessArticleDto>,
      ((arg: any) => unknown)[]
    > = {
      raw: [drop],
      articleId: [drop],
      blockedAt: [drop],
      lastClassification: [drop],
      articleCategoryId: [drop],
      processId: [drop],
      hsCode: [drop],
      createdAt: [drop],
      updatedAt: [drop],
    };

    return { ...createArticleConverters, ...createArticleEraseConverters };
  };

  const updateArticleDataFields: IArticleFieldDescription[] = [
    { name: 'externalArticleId', controlDisabled: true },
    { name: 'name', controlDisabled: true },
    { name: 'description' },
    { name: 'categories' },
    { name: 'tags' },
    { name: 'imageUrl' },
    { name: 'hsCode', controlDisabled: true },
    { name: 'weight' },
    { name: 'ean' },
    { name: 'originCountry' },
    { name: 'defaultWeight' },
    { name: 'defaultPrice' },
    { name: 'additionalAmount' },
    {
      name: 'additionalAmountUnit',
      controlType: ControlType.DROPDOWN,
      controlOptions: [...Object.values(Aeb.Unit), ''],
    },
    { name: 'categoryLock', controlType: ControlType.DROPDOWN, controlOptions: [...Object.values(CategoryLock)] },
    { name: 'warning' },
    { name: 'isBlocked', controlType: ControlType.CHECKBOX },
    { name: 'isNotCustomsRelevant', controlType: ControlType.CHECKBOX },
  ];

  export const updateArticleFields: IArticleFields = {
    base: updateArticleDataFields,
    customsDataCH: articleCustomsDataChFields,
    customsDataDE: articleCustomsDataDeFields,
    customsDataGB: articleCustomsDataGbFields,
    customsDataNO: articleCustomsDataNoFields,
  };

  export const getUpdateArticleDataConverters = (): Record<keyof ArticleDto, ((arg: any) => unknown)[]> => {
    const updateArticleConverters: Record<keyof UpdateArticleDto, ((arg: any) => unknown)[]> = {
      name: [convertToString],
      description: [convertToNullableString],
      categories: [convertToNullableString],
      tags: [convertToNullableString],
      imageUrl: [convertToNullableString],
      weight: [convertToNullableNumber],
      ean: [convertToNullableString],
      originCountry: [convertToNullableString],
      defaultWeight: [convertToNullableNumber],
      defaultPrice: [convertToNullableNumber],
      additionalAmount: [convertToNullableNumber],
      additionalAmountUnit: [convertToNullableString],
      categoryLock: [convertToString],
      warning: [convertToNullableString],
      isBlocked: [convertToBoolean],
      isNotCustomsRelevant: [convertToBoolean],
      customsDataCH: [
        (articleDataCH: Record<string, unknown>) => convert(articleCustomsDataChConverters, articleDataCH),
        dropIfEmptyObject,
      ],
      customsDataDE: [
        (articleDataDE: Record<string, unknown>) => convert(articleCustomsDataDeConverters, articleDataDE),
        dropIfEmptyObject,
      ],
      customsDataGB: [
        (articleDataGB: Record<string, unknown>) => convert(articleCustomsDataGbConverters, articleDataGB),
        dropIfEmptyObject,
      ],
      customsDataNO: [
        (articleDataNO: Record<string, unknown>) => convert(articleCustomsDataNoConverters, articleDataNO),
        dropIfEmptyObject,
      ],
    };
    const updateArticleEraseConverters: Record<
      keyof Omit<ArticleDto, keyof UpdateArticleDto>,
      ((arg: any) => unknown)[]
    > = {
      raw: [drop],
      articleId: [drop],
      articleCategoryId: [drop],
      externalArticleId: [drop],
      blockedAt: [drop],
      lastClassification: [drop],
      processId: [drop],
      hsCode: [drop],
      createdAt: [drop],
      updatedAt: [drop],
    };

    return { ...updateArticleConverters, ...updateArticleEraseConverters };
  };

  export function convertArticleDtoForCreate(articleData: ArticleDto): CreateProcessArticleDto {
    const articleDataRecord = articleData as Record<string, unknown>;
    const convertedArticleDataRecord = convert(getCreateArticleDataConverters(), articleDataRecord);
    return convertedArticleDataRecord as CreateProcessArticleDto;
  }

  export function convertArticleDataForUpdate(articleData: ArticleDto): UpdateArticleDto {
    const articleDataRecord = articleData as Record<string, unknown>;
    const convertedArticleDataRecord = convert(getUpdateArticleDataConverters(), articleDataRecord);
    return convertedArticleDataRecord as UpdateArticleDto;
  }

  export const useArticles = (query: GetArticleFilterDto) => {
    // we just wait for the processTypeId to prevent an unnecessary load. This
    // can be removed after the article refactor.
    const { data, error, mutate, isLoading } = api.useApi<'/article'>(query?.processTypeId ? '/article' : null, query);
    return {
      data: data ?? [],
      mutate,
      error,
      isLoading,
      isError: !!error,
    };
  };

  export const filterArticleIdsByMissingTariffNumber = async (articleIds: number[]): Promise<number[]> => {
    const response = await api.post<'/article/filterArticleIdsByMissingTariffNumber'>(
      `${backendUri}/article/filterArticleIdsByMissingTariffNumber`,
      {
        articleIds,
      },
    );
    return response.data;
  };

  export const useArticleByExternalArticleId = (
    processId: number,
    externalArticleId: string | null,
    isEnabled: boolean = true,
  ): IUseObjectResponse<ArticleDto> => {
    const encodedExternalArticleId = externalArticleId ? encodeURIComponent(externalArticleId) : null;
    const url = `${backendUri}/v1/process/${processId}/article/externalArticleId/${encodedExternalArticleId}`;

    const { data, error, mutate, isLoading } = useSWR<ArticleDto>(
      isEnabled && !!externalArticleId ? url : null,
      swrFetcherWith404,
      {
        shouldRetryOnError: false,
      },
    );

    return {
      data: data ?? null,
      error,
      isLoading,
      isError: !!error,
      mutate,
    };
  };

  export const getCachedArticleByExternalArticleId = async (
    processId: number,
    externalArticleId: string,
    cache: Cache,
  ): Promise<ArticleDto> => {
    const encodedExternalArticleId = encodeURIComponent(externalArticleId);
    const url = `${backendUri}/v1/process/${processId}/article/externalArticleId/${encodedExternalArticleId}`;
    const cachedArticleState = cache.get(url);

    if (cachedArticleState?.data) {
      return cachedArticleState.data;
    }

    const article = await getArticleByExternalArticleId(processId, externalArticleId);

    mutate(url, article);

    return article;
  };

  export const getArticleByExternalArticleId = async (
    processId: number,
    externalArticleId: string,
  ): Promise<ArticleDto> => {
    const encodedExternalArticleId = encodeURIComponent(externalArticleId);
    const res = await api.get<'/v1/process/{processId}/article/externalArticleId/{externalId}'>(
      `${backendUri}/v1/process/${processId}/article/externalArticleId/${encodedExternalArticleId}`,
    );
    return res.data;
  };

  export const postArticle = async (body: CreateProcessArticleDto, processId: number): Promise<ArticleDto> => {
    const res: any = await api.post<'/v1/process/{processId}/article'>(
      `${backendUri}/v1/process/${processId}/article`,
      body,
    );
    return res;
  };

  export const useArticle = (articleId: number | null): IUseObjectResponse<ArticleDto> => {
    const { data, error, mutate, isLoading } = useSWR(articleId ? `/article/${articleId}` : null, swrFetcherWith404);

    return {
      data: data ?? null,
      error,
      isLoading,
      isError: !!error,
      mutate,
    };
  };

  export async function getArticle(articleId: number): Promise<ArticleDto> {
    const articleResponse = await api.get<'/article/{articleId}'>(`${backendUri}/article/${articleId}`);
    return articleResponse.data;
  }

  export async function putArticle(articleId: number, body: UpdateArticleDto): Promise<ArticleDto> {
    const res = await api.put<'/article/{articleId}'>(`${backendUri}/article/${articleId}`, body);
    return res.data;
  }

  export const deleteAllArticlesByProcessId = async (processId: number, today: string) => {
    const url = new URL(`${backendUri}/article/process/${processId}`);
    url.searchParams.set('today', today);
    const response = await api.delete<'/article/process/{processId}'>(url.href);
    return response.data;
  };
}
