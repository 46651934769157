import { IconButton, InputAdornment, ListSubheader, MenuItem, TextField, TextFieldProps } from '@mui/material';
import React, { useRef, useState } from 'react';
import { Control, Controller, FieldPath, FieldPathValue, FieldValues, RegisterOptions } from 'react-hook-form';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';

type Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<TextFieldProps, 'onChange' | 'value' | 'error'> & {
  name: TName;
  rules?: Omit<RegisterOptions<TFieldValues, TName>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
  defaultValue?: FieldPathValue<TFieldValues, TName>;
  control?: Control<TFieldValues>;
};

const FormArrayTextField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  rules,
  defaultValue,
  control,
  ...textFieldProps
}: Props<TFieldValues, TName>) => {
  const [showSelect, setShowSelect] = useState(false);
  const [newValue, setNewValue] = useState('');
  const newValueRef = useRef<HTMLInputElement>();

  return (
    <Controller
      name={name}
      rules={{ required: textFieldProps.required, ...rules }}
      defaultValue={defaultValue}
      control={control}
      render={({ field: { name, onChange, value }, fieldState: { error, isDirty } }) => {
        const values = Array.isArray(value) ? value : typeof value === 'string' ? (value as string).split(',') : [];

        return (
          <TextField
            select
            name={name}
            sx={{ '& .MuiSelect-select': { color: isDirty ? 'warning.main' : undefined } }}
            error={!!error}
            onChange={onChange}
            value={values ?? []}
            {...textFieldProps}
            helperText={error?.message ?? textFieldProps.helperText}
            SelectProps={{
              multiple: true,
              open: showSelect,
              onOpen: () => setShowSelect(true),
              onClose: () => {
                setShowSelect(false);
                setNewValue('');
              },
              MenuProps: {
                autoFocus: false,
              },
            }}
            onAnimationEnd={() => newValueRef.current?.focus()}
          >
            <ListSubheader
              disableGutters
              sx={{ px: 1 }}
            >
              <TextField
                inputRef={newValueRef}
                size="small"
                autoFocus
                placeholder="Zusätzlicher Wert"
                fullWidth
                type={textFieldProps.type}
                value={newValue}
                inputProps={{ autoFocus: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          const newValues = [...values, newValue];

                          onChange(textFieldProps.type === 'number' ? newValues.map((v) => parseFloat(v)) : newValues);
                          setNewValue('');
                        }}
                        edge="end"
                        size="small"
                        disabled={!newValue}
                      >
                        <PlaylistAddIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onChange={(ev) => setNewValue(ev.target.value)}
              />
            </ListSubheader>

            {values.map((v: string | number) => (
              <MenuItem
                key={v}
                value={v}
              >
                {v}
              </MenuItem>
            ))}
          </TextField>
        );
      }}
    />
  );
};

export default FormArrayTextField;
