const ENOUGH_BITS_OF_ENTROPY = 50;

export function getPasswordStrength(value: string): number {
  let numberOfPossibleCharacters = 0;

  if (/[a-z]/.test(value)) {
    numberOfPossibleCharacters += 26;
  }

  if (/[A-Z]/.test(value)) {
    numberOfPossibleCharacters += 26;
  }

  if (/[0-9]/.test(value)) {
    numberOfPossibleCharacters += 10;
  }

  if (/[^a-zA-Z0-9]/.test(value)) {
    numberOfPossibleCharacters += 15; // most common
  }

  let entropy = Math.pow(numberOfPossibleCharacters, value.length);
  let bitsOfEntropy = Math.log2(entropy);
  let strength = Math.min(100, Math.round((bitsOfEntropy / ENOUGH_BITS_OF_ENTROPY) * 100));

  return strength;
}
