import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

const devModeAtom = atomWithStorage<boolean>('dev:mode', process.env.NODE_ENV === 'development');

export const useDevMode = (): [boolean, (devMode: boolean) => void] => {
  return useAtom(devModeAtom);
};

const mockLabelAtom = atomWithStorage<boolean>('dev:mock-label', true);

export const useMockLabel = (): [boolean, (mockLabel: boolean) => void] => {
  return useAtom(mockLabelAtom);
};

const debugViewAtom = atomWithStorage<boolean>('dev:debug-view', false);

export const useDebugView = (): [boolean, (debugView: boolean) => void] => {
  return useAtom(debugViewAtom);
};

const debugScaleAtom = atomWithStorage<boolean>('dev:debug-scale', false);

export const useDebugScale = (): [boolean, (debugScale: boolean) => void] => {
  return useAtom(debugScaleAtom);
};
