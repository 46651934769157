import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      top: `50%`,
      left: `50%`,
      transform: `translate(-50%, -50%)`,
      width: '95%',
      height: '98%',
      backgroundColor: theme.palette.background.default,
      boxShadow: theme.shadows[0],
      overflowX: 'hidden',
    },
    loadingWrapper: {
      //backgroundColor: "purple",
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      width: '100%',
    },
    modalHeader: {
      zIndex: 100,
      position: 'sticky',
      top: 0,
      width: '100%',
      borderBottomWidth: 0,
      borderBottomStyle: 'solid',
      backgroundColor: theme.palette.background.paper,
      borderBottomColor: theme.palette.divider,
      boxShadow: '0 6px 6px rgba(0,0,0,0.23);',
    },

    modalHeaderBody: {
      width: '100%',
      display: 'flex',
    },

    modalBody: {
      padding: theme.spacing(1, 4, 3),
    },

    infoHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(1),
    },
    infoBody: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },

    detailCardWrapper: {
      position: 'relative',
      flexGrow: 3,

      marginLeft: theme.spacing(1),
    },
    detailCard: {
      boxShadow: `0px 0px 0px #fff`,

      padding: theme.spacing(3, 3, 3),
      height: '100%',
    },

    expandButton: {
      borderRadius: '100%',
      width: '42px',
      height: '42px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: `2px solid ${theme.palette.action.selected}`,
      backgroundColor: theme.palette.background.default,
      transition: '0.5s background-color',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.primary.light,
      },
    },
    addShipmentCard: {
      border: `1px solid ${theme.palette.action.active}`,
      marginTop: theme.spacing(2),
      padding: theme.spacing(2, 0),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.background.paper,
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.action.hover,
      },
    },
    shipmentViewWrapper: {
      marginTop: theme.spacing(5),
    },
  }),
);

export default useStyles;
