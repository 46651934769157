import { CircularProgress, Grid, Paper, Stack, Typography } from '@mui/material';
import React, { Fragment } from 'react';
import { useProcessSettings } from '../../../services/process-settings-service/processSettings.service';
import SettingsForm from './SettingsForm';
import EditReasons from './EditReasons';
import { ReturnPortalLink } from './ReturnPortalLink';
import DescriptionPanel from './DescriptionPanel';
import { ProcessService } from '../../../services/process-service/process.service';
import { useTranslation } from 'react-i18next';
import BreadcrumbsGridItem from '../../crm/BreadcrumbsGridItem';
import { useAuthentication } from '../../../hooks/useAuthentication';
import { Helmet } from 'react-helmet-async';

type Props = {
  processId: number;
};

export const ReturnSettingsStack: React.FC<Props> = ({ processId }) => {
  const auth = useAuthentication();
  const { t } = useTranslation();
  const processes = ProcessService.useProcesses();
  const settings = useProcessSettings(processId);

  const process = processes.data.find((p) => p.processId === processId);

  return (
    <Fragment>
      {!!process && (
        <Helmet>
          <title>{`Return settings | Process ${process.processId} (${process.customer.company})`}</title>
        </Helmet>
      )}

      {settings.data && processId && !settings.isLoading ? (
        <Grid
          container
          spacing={3}
          sx={{ maxWidth: 'lg' }}
        >
          {!!process && auth.isOffice() && (
            <BreadcrumbsGridItem
              items={[
                { href: '/crm/customers', label: 'Kunden' },
                {
                  href: `/crm/customers/${process.customerId}`,
                  label: process.customer.company,
                },
                {
                  href: `/crm/process/${process.processId}`,
                  label: `Process ${processId} (${process.processType.label})`,
                },
                { label: 'Return settings' },
              ]}
            />
          )}

          <Grid
            item
            xs={12}
          >
            <Paper sx={{ px: 2, py: 3 }}>
              {!!process && (
                <Typography
                  variant="body1"
                  sx={{ color: 'text.disabled' }}
                >
                  {process.customer.company}, {process.processType.label}
                </Typography>
              )}
              <Typography
                variant="h5"
                gutterBottom
              >
                {t('Return portal settings')}
              </Typography>

              <ReturnPortalLink settings={settings.data} />
            </Paper>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <Paper sx={{ px: 2, py: 3 }}>
              <SettingsForm
                processId={processId}
                settings={settings.data}
                onUpdateSettings={() => {
                  settings.mutate();
                }}
              />
            </Paper>
          </Grid>

          {settings.data.languages.length > 0 && (
            <Grid
              item
              xs={12}
            >
              <Paper sx={{ px: 2, py: 3 }}>
                <Stack
                  direction="column"
                  spacing={6}
                >
                  <DescriptionPanel
                    descriptions={settings.data.descriptions}
                    languages={settings.data.languages}
                    processId={settings.data.processId}
                    onDescriptionChange={() => {
                      settings.mutate();
                    }}
                  />

                  <EditReasons
                    processId={processId}
                    reasons={settings.data.reasons}
                    languages={settings.data.languages}
                    onUpdateSettings={() => {
                      settings.mutate();
                    }}
                  />
                </Stack>
              </Paper>
            </Grid>
          )}
        </Grid>
      ) : (
        <CircularProgress color="secondary" />
      )}
    </Fragment>
  );
};
