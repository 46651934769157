import { SxProps, TablePagination } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { usePagination } from '../../hooks/page';
import DataTable, { TDataTableAction, TDataTableColumn } from './DataTable';

const rowsPerPageOptions = [10, 20, 30];

export type DataTableProps<T> = {
  columns: TDataTableColumn<T>[];
  rows: T[];
  actions?: TDataTableAction<T>[];
  detailPanel?: (row: T) => JSX.Element;
  isLoading?: boolean;
  defaultPageSize?: number;
  highlightPredicate?: (row: T) => SxProps | boolean | undefined;
};

const DataTableWithPagination = <T,>(props: DataTableProps<T>) => {
  const [numberOfRows, setNumberOfRows] = useState<number>(props.rows.length);
  const { page, pageSize: rowsPerPage, setPage, setPageSize: setRowsPerPage } = usePagination(props.defaultPageSize);

  const maxPage = Math.ceil(props.rows.length / rowsPerPage);

  useEffect(() => {
    if (numberOfRows === 0) {
      setNumberOfRows(props.rows.length);
    } else if (props.rows.length > 0 && numberOfRows !== props.rows.length) {
      setNumberOfRows(props.rows.length);
      setPage(0);
    }
  }, [numberOfRows, props.rows.length, setPage]);

  return (
    <>
      <DataTable
        {...props}
        page={page}
        rows={props.rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
        highlightPredicate={props.highlightPredicate}
      />

      <TablePagination
        component="div"
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={
          !props.defaultPageSize || rowsPerPageOptions.includes(props.defaultPageSize)
            ? rowsPerPageOptions
            : [...rowsPerPageOptions, props.defaultPageSize].sort((a, b) => a - b)
        }
        onRowsPerPageChange={(ev) => setRowsPerPage(parseInt(ev.target.value, 10))}
        page={Math.min(maxPage, page)}
        count={props.rows.length}
        onPageChange={(ev, page) => setPage(page)}
        showLastButton={true}
        showFirstButton={true}
      />
    </>
  );
};

export default DataTableWithPagination;
