import React from 'react';
import { createSvgIcon } from '@mui/material';

const PiPIcon = createSvgIcon(
  <>
    <path
      fillOpacity=".3"
      d="M18.539 20.593v-8.394L11.95 9.81 5.364 12.2v8.394L.92 18.98V4.923L11.951.919l11.033 4.004V18.98z"
    />
    <path d="M11.638.054.606 4.06C-.228 4.425.051 5.349 0 6.073v12.906c.137 1.007 1.265 1.019 2.004 1.372l9.634 3.497c.782.115 1.522-.388 2.28-.6 3.172-1.162 6.36-2.289 9.524-3.472.723-.477.38-1.426.46-2.15V4.924c-.016-.604-.64-.93-1.177-1.072L12.265.054Zm.313 1.843 8.338 3.025-8.338 3.026-8.337-3.026zM1.84 6.234l8.627 3.131c-1.808.67-3.648 1.297-5.435 1.994-.834.396-.524 1.369-.586 2.12v5.803l-2.606-.947zm20.225 0v12.101l-2.606.947c-.018-2.43.035-4.868-.027-7.291-.274-.507-.82-.81-1.405-.96l-4.589-1.666zm-11.032 4.882v2.161l-2.975-1.082zm1.839 0 2.975 1.08-2.975 1.081zm-6.587 2.391 4.748 1.726v6.439l-4.748-1.723zm11.335 0v6.442l-4.748 1.723v-6.439z" />
  </>,
  'Pip',
);

export default PiPIcon;
