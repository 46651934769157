import React from 'react';
import { OrderDto } from '../../services/order-service/order.service';
import { Box, Stack, Typography } from '@mui/material';
import { Warning } from '@mui/icons-material';
import ReturnPortalButton from './ReturnPortalButton';
import OrderSearchButton from './OrderSearchButton';
import { ProcessService } from '../../services/process-service/process.service';

const WarningIcon = () => (
  <Warning
    color="warning"
    sx={{ verticalAlign: 'middle' }}
  />
);

const MAX_LENGTH = 25;

type Props = {
  order: OrderDto;
};

const OrderConsumerAddress: React.FC<Props> = ({ order }) => {
  const processes = ProcessService.useProcesses();

  return (
    <Stack direction="row">
      <Box
        sx={{ fontSize: '1rem' }}
        flexGrow={1}
      >
        <Typography variant="h6">
          {(order.name?.length ?? 0) > MAX_LENGTH && <WarningIcon />} {order.name}
        </Typography>
        {!!order.company && <Typography variant="h6">{order.company}</Typography>}
        <Typography variant="body1">
          {(order.address?.length ?? 0) > MAX_LENGTH && <WarningIcon />} {order.address}
        </Typography>
        {order.additionalAddress && <Typography variant="body1">{order.additionalAddress}</Typography>}
        <Typography variant="body1">
          {(order.zip?.length ?? 0) !== 4 && order.countryCode === 'CH' && <WarningIcon />} {order.zip} {order.city}
        </Typography>
        <Typography variant="body1">{order.countryCode}</Typography>
      </Box>
      <Stack
        direction="column"
        justifyContent="flex-end"
        sx={{ mr: -1, mb: -1 }}
      >
        <Box>
          <OrderSearchButton
            order={order}
            processes={processes.data}
          />
        </Box>

        <Box>
          <ReturnPortalButton order={order} />
        </Box>
      </Stack>
    </Stack>
  );
};

export default OrderConsumerAddress;
