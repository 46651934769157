import { useCallback, useMemo, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import { DATE_FORMAT } from '../shared/helper/date';

type TParamType<T> = {
  encode: (param: T) => string;
  decode: (param: string) => T | null;
};

export enum ParamStrategy {
  /** Append to the current list of parameters */
  append = 'append',

  /** Set as only parameter */
  push = 'push',
}

/**
 * @param {boolean} [replace] if true the current url will be replaced and no
 * history entry will be generated. This means you can't go back to the previous
 * url through the browser history.
 */
export type TSetValue<T> = (value: T | undefined, strategy?: ParamStrategy, replace?: boolean) => void;

type TParamReturn<T, S> = [T | S, TSetValue<T>];

export const StringParam: TParamType<string> = {
  encode: (param) => param,
  decode: (param) => param,
};

export const StringListParam: TParamType<string[]> = {
  encode: (param) => param.join(','),
  decode: (param) => param.split(','),
};

export const BooleanParam: TParamType<boolean> = {
  encode: (param) => param.toString(),
  decode: (param) => param === 'true',
};

export const IntParam: TParamType<number> = {
  encode: (param) => param.toFixed(0),
  decode: (param) => parseInt(param, 10),
};

export const IntListParam: TParamType<number[]> = {
  encode: (param) => param.join(','),
  decode: (param) =>
    param
      .split(',')
      .filter((value) => !!value)
      .map((value) => parseInt(value, 10))
      .filter((value) => isFinite(value)),
};

export const EnumListParam = <T extends string | number>(enumObjc: { [key: string]: T }): TParamType<T[]> => ({
  encode: (param) => param.join(','),
  decode: (param) =>
    param
      .split(',')
      .map((paramElement) => {
        const enumOrderStateKeyValue = Object.entries(enumObjc).find(([, value]) => value.toString() === paramElement);

        if (enumOrderStateKeyValue) {
          return enumOrderStateKeyValue[1];
        }

        return null;
      })
      .filter((state) => state !== null) as T[],
});

export const EnumParam = <T extends string | number>(enumObjc: { [key: string]: T }): TParamType<T> => ({
  encode: (param) => param.toString(),
  decode: (param) => (Object.values(enumObjc).includes(param as T) ? (param as T) : null),
});

export const DateTimeParam: TParamType<DateTime> = {
  encode: (param) => encodeURI(param.toISO() ?? ''),
  decode: (param) => DateTime.fromISO(decodeURI(param)),
};

export const getDateTimeParam = (format: string): TParamType<DateTime> => ({
  encode: (param) => encodeURI(param.toFormat(format) ?? ''),
  decode: (param) => DateTime.fromFormat(decodeURI(param), format),
});

export const useParam = <T, S extends T | undefined>(
  name: string,
  paramType: TParamType<T>,
  defaultValue: S,
): TParamReturn<T, S> => {
  const [searchParams, setSearchParams] = useSearchParams();

  const searchParam = searchParams.get(name);

  const defaultValueRef = useRef(defaultValue);
  const paramTypeRef = useRef(paramType);

  const value = useMemo(() => {
    return typeof searchParam === 'string' ? paramTypeRef.current.decode(searchParam) : defaultValueRef.current;
  }, [searchParam]);

  const setValue: TSetValue<T> = useCallback(
    (value, strategy = ParamStrategy.append, replace) => {
      const newSearchParams = new URLSearchParams(strategy === ParamStrategy.append ? window.location.search : {});

      if (
        value === undefined ||
        value?.toString() === defaultValueRef.current?.toString() ||
        (typeof value === 'number' && !isFinite(value))
      ) {
        if (!newSearchParams.has(name) && strategy !== ParamStrategy.push) {
          return;
        }

        newSearchParams.delete(name);
      } else {
        const encodedValue = paramTypeRef.current.encode(value);

        if (newSearchParams.get(name) === encodedValue) {
          return;
        }

        newSearchParams.set(name, encodedValue);
      }

      setSearchParams(newSearchParams, { replace });
    },
    [name, setSearchParams],
  );

  return [value ?? defaultValue, setValue];
};

export function useStringParam(name: string, defaultValue: string): TParamReturn<string, string>;
export function useStringParam(name: string): TParamReturn<string, string | undefined>;
export function useStringParam(name: string, defaultValue?: string) {
  return useParam(name, StringParam, defaultValue);
}

export function useStringListParam(name: string, defaultValue: string[]): TParamReturn<string[], string[]>;
export function useStringListParam(name: string): TParamReturn<string[], string[] | undefined>;
export function useStringListParam(name: string, defaultValue?: string[]) {
  return useParam(name, StringListParam, defaultValue);
}

export function useBooleanParam(name: string, defaultValue: boolean): TParamReturn<boolean, boolean>;
export function useBooleanParam(name: string): TParamReturn<boolean, boolean | undefined>;
export function useBooleanParam(name: string, defaultValue?: boolean) {
  return useParam(name, BooleanParam, defaultValue);
}

export function useIntParam(name: string, defaultValue: number): TParamReturn<number, number>;
export function useIntParam(name: string): TParamReturn<number, number | undefined>;
export function useIntParam(name: string, defaultValue?: number) {
  return useParam(name, IntParam, defaultValue);
}

export function useIntListParam(name: string, defaultValue: number[]): TParamReturn<number[], number[]>;
export function useIntListParam(name: string): TParamReturn<number[], number[] | undefined>;
export function useIntListParam(name: string, defaultValue?: number[]) {
  return useParam(name, IntListParam, defaultValue);
}

export function useEnumListParam<T>(
  name: string,
  enumObjc: {
    [key: string]: T;
  },
  defaultValue: T[],
): TParamReturn<T[], T[]>;
export function useEnumListParam<T>(
  name: string,
  enumObjc: {
    [key: string]: T;
  },
): TParamReturn<T[], T[] | undefined>;
export function useEnumListParam<T extends string | number>(
  name: string,
  enumObjc: {
    [key: string]: T;
  },
  defaultValue?: T[],
) {
  return useParam(name, EnumListParam<T>(enumObjc), defaultValue);
}

export function useEnumParam<T>(
  name: string,
  enumObjc: {
    [key: string]: T;
  },
  defaultValue: T,
): TParamReturn<T, T>;
export function useEnumParam<T>(
  name: string,
  enumObjc: {
    [key: string]: T;
  },
): TParamReturn<T, T | undefined>;
export function useEnumParam<T extends string | number>(
  name: string,
  enumObjc: {
    [key: string]: T;
  },
  defaultValue?: T,
) {
  return useParam(name, EnumParam<T>(enumObjc), defaultValue);
}

export function useProcessTypeIdsParam() {
  return useIntListParam('processTypeIds', []);
}

export function useCustomerIdsParam() {
  return useIntListParam('customerIds', []);
}

export function useDateTimeParam(name: string, defaultValue: DateTime): TParamReturn<DateTime, DateTime | undefined>;
export function useDateTimeParam(name: string): TParamReturn<DateTime, DateTime | undefined>;
export function useDateTimeParam(name: string, defaultValue?: DateTime) {
  return useParam(name, DateTimeParam, defaultValue);
}

export function useDateParam(name: string, defaultValue: DateTime): TParamReturn<DateTime, DateTime | undefined>;
export function useDateParam(name: string): TParamReturn<DateTime, DateTime | undefined>;
export function useDateParam(name: string, defaultValue?: DateTime) {
  return useDateTimeParamWithFormat(name, DATE_FORMAT, defaultValue);
}

export function useDateTimeParamWithFormat(
  name: string,
  format: string,
  defaultValue?: DateTime,
): TParamReturn<DateTime, DateTime | undefined>;
export function useDateTimeParamWithFormat(name: string, format: string): TParamReturn<DateTime, DateTime | undefined>;
export function useDateTimeParamWithFormat(name: string, format: string, defaultValue?: DateTime) {
  return useParam(name, getDateTimeParam(format), defaultValue);
}
