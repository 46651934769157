import React, { FunctionComponent } from 'react';
import { OrderTable } from './components/orderTable';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  tableStyle: {
    marginTop: '20px',
    marginBottom: '20px',
  },
});

export const AllOrders: FunctionComponent<{}> = () => {
  const classes = useStyles();
  return (
    <div className={classes.tableStyle}>
      <OrderTable />
    </div>
  );
};
