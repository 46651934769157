import useSWR from 'swr';
import useSWRInfinite from 'swr/infinite';
import { backendUri } from '../../shared/helper/env/helper';
import { SchemaType } from '../../shared/client';

export interface ActivityDto extends SchemaType<'ActivityDto'> {}

export interface IActivityQuery {
  limit?: number;

  beforeActivityId?: number;

  actions?: string[];

  entityName?: string;

  entityId?: number;

  userId?: number;
}

export interface IActivityContext {
  referrer?: string;

  scaleWeight?: number;
}

export const activityContextToHeader = (context: IActivityContext) => {
  return {
    'x-exporto-referrer': context.referrer,
    'x-exporto-scale': context.scaleWeight,
  };
};

export const useActivitiesInfinite = (query: Omit<IActivityQuery, 'lastActivityId'> | null) => {
  const limit = query?.limit ?? 30;
  const getUrl = (params: IActivityQuery): string => {
    const url = new URL(`${backendUri}/activity`);

    Object.entries({ ...params, limit }).forEach(
      ([key, value]) => value && url.searchParams.set(key, Array.isArray(value) ? value.join(',') : value.toString()),
    );

    return url.href;
  };

  const { data, error, mutate, size, setSize, isLoading } = useSWRInfinite<ActivityDto[]>(
    (index, previousPageData) => {
      if (query === null) {
        return null;
      }

      if (index === 0) {
        return getUrl(query);
      }

      if (previousPageData?.length === limit) {
        const beforeActivityId: number = previousPageData[previousPageData.length - 1].activityId;

        return getUrl({ ...query, beforeActivityId });
      }

      return null;
    },
    {
      revalidateFirstPage: false,
    },
  );

  const isEmpty = data?.[0]?.length === 0;

  return {
    data: data ?? [],
    error,
    isLoading,
    isLoadingMore: isLoading || (size > 0 && data && typeof data[size - 1] === 'undefined'),
    isError: !!error,
    isEmpty,
    isReachingEnd: !query || isEmpty || (data && data[data.length - 1]?.length < limit),
    mutate,
    size,
    setSize,
  };
};

export type TActivityActions = Record<string, string[]>;

export const useActivityActions = () => {
  const { data, error, mutate, isLoading } = useSWR<TActivityActions>('/activity/actions');

  return {
    data: data ?? {},
    error,
    isLoading,
    isError: !!error,
    mutate,
  };
};
