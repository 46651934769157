import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { OrderDto } from '../../services/order-service/order.service';
import { IconButton } from '@mui/material';
import { ProcessDto } from '../../services/process-service/process.service';
import { OrderSearchScope } from '../../shared/backend';

type Props = {
  order: OrderDto;
  processes: ProcessDto[];
};

const OrderSearchButton: React.FC<Props> = ({ order, processes }) => {
  const customerId = processes.find((process) => process.processId === order.processId)?.customerId;

  if (!customerId) {
    return <></>;
  }

  const searchUrl = new URL('/orders/all-orders', window.location.origin);

  if (customerId) {
    searchUrl.searchParams.set('customerIds', customerId.toString());
  }

  searchUrl.searchParams.set('scope', OrderSearchScope.EXTENDED);
  searchUrl.searchParams.set('search', `${order.name} ${order.address}`);

  return (
    <IconButton
      component="a"
      href={searchUrl.href}
      target="_blank"
    >
      <SearchIcon />
    </IconButton>
  );
};

export default OrderSearchButton;
