import { Card, Typography, Button, FormControlLabel, TextField, Grid, Box } from '@mui/material';
import BlockIcon from '@mui/icons-material/Block';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import React, { useState } from 'react';
import Switch from '@mui/material/Switch';
import SuccessToast from '../../../../../../shared/components/success-toast/SuccessToast';
import useStyles from '../OrderDetailModal.style';
import SaveIcon from '@mui/icons-material/Save';
import { OrderDto, OrderUpdateDto, OrderWithCustomerDto } from '../../../../../../services/order-service/order.service';
import { OrderStateIcon } from '../../../../all-orders/components/OrderStateIcons';
import { IsoCurrencyCode, OrderState } from '../../../../../../shared/backend';
import { useAuthentication } from '../../../../../../hooks/useAuthentication';
import { t } from 'i18next';
import HistoryIconButton from '../../../../../../shared/components/HistoryIconButton';

class AddressFormFieldRepo {
  private fields: { id: keyof OrderDto; label: string; editable: boolean }[] = [];

  public addField(id: keyof OrderDto, label: string, editable: boolean = true) {
    this.fields.push({ id, label, editable });
  }

  public getFields() {
    return this.fields;
  }
}

type Props = {
  order: OrderWithCustomerDto;
  isPanelExpaned: boolean;
  editAddressAndShipment: boolean;
  setEditAddressAndShipment: (editable: boolean) => void;
  saveOrder: (update: OrderUpdateDto) => Promise<boolean>;
};

const AddressDetailCard: React.FC<Props> = (props) => {
  const classes = useStyles();
  const auth = useAuthentication();

  const [successToast, setSuccessToast] = useState({ open: false, msg: '' });
  const [inputValues, setInputValues] = useState(props.order);

  const onSaveAddress = async () => {
    const isUpdated = await props.saveOrder({
      city: inputValues.city || '',
      name: inputValues.name || '',
      address: inputValues.address || '',
      additionalAddress: inputValues.additionalAddress || '',
      zip: inputValues.zip || '',
      company: inputValues.company || '',
      email: inputValues.email || '',
      currency: inputValues.currency?.length ? (inputValues.currency as IsoCurrencyCode) : undefined,
      countryCode: inputValues.countryCode || '',
    });

    if (isUpdated) {
      setSuccessToast({ open: true, msg: t('Address successfully changed') });
    }
  };

  const fieldRepo = new AddressFormFieldRepo();
  fieldRepo.addField('externalOrderId', t('External order ID'), false);
  fieldRepo.addField('externalCreatedAt', t('External created at'), false);
  fieldRepo.addField('orderId', t('Order ID'), false);
  fieldRepo.addField('createdAt', t('Created at'), false);

  if (props.isPanelExpaned) {
    fieldRepo.addField('name', t('Name'));
    fieldRepo.addField('company', t('Company'));
    fieldRepo.addField('address', t('Address 1'));
    fieldRepo.addField('additionalAddress', t('Address 2'));
    fieldRepo.addField('zip', t('ZIP'));
    fieldRepo.addField('city', t('City'));
    fieldRepo.addField('countryCode', t('Country code'));
    fieldRepo.addField('phone', t('Phone'));
    fieldRepo.addField('email', t('Contact mail'));
    fieldRepo.addField('totalGross', t('Total gross'), false);
    fieldRepo.addField('currency', t('Currency'));
    fieldRepo.addField('returnTrackingId', t('Return tracking ID'), false);
  }

  return (
    <Card className={classes.detailCard}>
      <Grid
        container
        spacing={2}
        alignItems="center"
      >
        <Grid item>
          <Typography
            variant="h5"
            component="h2"
          >
            <Box
              component="span"
              sx={{ '>*': { verticalAlign: 'middle' } }}
            >
              {OrderStateIcon[props.order.state]}
            </Box>{' '}
            {t('Details')}
          </Typography>
        </Grid>

        <Grid item>
          <HistoryIconButton
            action="order"
            entityId={props.order.orderId}
          />
        </Grid>

        <Grid
          item
          flexGrow={1}
        >
          <SuccessToast
            successMsg={successToast.msg}
            open={successToast.open}
            onClose={() => setSuccessToast({ open: false, msg: '' })}
          />
        </Grid>

        {props.editAddressAndShipment && (
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<SaveIcon />}
              onClick={onSaveAddress}
            >
              {t('Save')}
            </Button>
          </Grid>
        )}
        {auth.isStaff() && (
          <Grid item>
            <FormControlLabel
              sx={{ m: 0, mt: -0.5 }}
              control={
                <Switch
                  disabled={props.order.process.blocked}
                  color="secondary"
                  onChange={(ev) => props.setEditAddressAndShipment(ev.target.checked)}
                  checked={props.editAddressAndShipment}
                ></Switch>
              }
              label={t('Enable editing')}
            />
          </Grid>
        )}

        {auth.isNotPicker() && (
          <Grid item>
            {props.order.state === OrderState.BLOCKED ? (
              <Button
                startIcon={<ArrowRightIcon />}
                color="success"
                variant="outlined"
                size="small"
                disabled={!props.editAddressAndShipment}
                onClick={() => props.saveOrder({ state: OrderState.NEW })}
              >
                {t('Release')}
              </Button>
            ) : (
              <Button
                startIcon={<BlockIcon />}
                color="error"
                variant="contained"
                size="small"
                disabled={!props.editAddressAndShipment}
                onClick={() => props.saveOrder({ state: OrderState.BLOCKED })}
              >
                {t('Block')}
              </Button>
            )}
          </Grid>
        )}
      </Grid>

      <Grid
        container
        spacing={1}
        my={1}
      >
        {fieldRepo.getFields().map((field) => (
          <Grid
            item
            xs={1}
            sm={2}
            md={3}
            key={field.id}
          >
            <TextField
              id={field.id}
              value={
                field.id.endsWith('At') && !field.editable
                  ? inputValues[field.id]
                    ? new Date(inputValues[field.id] as string).toLocaleString()
                    : '-'
                  : inputValues[field.id] || ''
              }
              label={field.label}
              onChange={(ev) =>
                setInputValues({
                  ...inputValues,
                  [field.id]: ev.target.value.toString(),
                })
              }
              variant="outlined"
              fullWidth
              disabled={!props.editAddressAndShipment || !field.editable}
            />
          </Grid>
        ))}
      </Grid>
    </Card>
  );
};

export default AddressDetailCard;
