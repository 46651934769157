import React from 'react';
import { confirmable, ConfirmDialog } from 'react-confirm';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { ProcessDto } from '../../services/process-service/process.service';
import { createConfirmation } from '../../shared/confirm-dialog';
import { Trans, useTranslation } from 'react-i18next';
import {
  SHIPPING_UNIT_BARCODE_PREFIX,
  SHIPPING_UNIT_BARCODE_REGEX,
  ShippingService,
} from '../../services/shipping-service/shipping.service';
import { ShippingUnitState } from '../../shared/backend';
import { BarcodeReader } from './BarcodeReader';
import { useNotifications } from '../../hooks/useNotifications';
import { printBase64PDF } from '../../shared/helper/print';
import CreateShippingUnitDialog from './CreateShippingUnitDialog';
import Clear from '@mui/icons-material/Clear';

type Props = {
  process: ProcessDto;
};

const ShippingUnitDialog: ConfirmDialog<Props, string> = ({ process, proceed, show }) => {
  const { t } = useTranslation();
  const notifications = useNotifications();
  const units = ShippingService.useShippingUnits({
    customerId: process.customerId,
    returnWarehouseAddressId: process.returnWarehouseAddressId,
    state: ShippingUnitState.OPEN,
  });

  if (units.isLoading) {
    return (
      <Dialog open={show}>
        <CircularProgress />
      </Dialog>
    );
  }

  if (units.data.length > 0) {
    const openUnit = units.data[0];

    return (
      <Dialog open={show}>
        <DialogTitle>{t('Add to shipping unit')}</DialogTitle>

        <DialogContent>
          <DialogContentText>
            <Trans shouldUnescape>
              We found an open shipping unit which was created on{' '}
              {{ createdAt: new Date(openUnit.createdAt).toLocaleString() }} and contains {{ size: openUnit.size }}{' '}
              shipments. Please scan the barcode of the unit to assign the shipment.
            </Trans>
          </DialogContentText>

          {!!openUnit.note && (
            <Alert
              severity="info"
              sx={{ mt: 2 }}
            >
              <AlertTitle>{t('Note')}</AlertTitle>

              {openUnit.note}
            </Alert>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => proceed('')}
            color="error"
            startIcon={<Clear />}
          >
            {t('Abort')}
          </Button>

          <Box flexGrow={1}></Box>

          <BarcodeReader
            focus
            handleScanBarcode={async (barcode) => {
              if (!SHIPPING_UNIT_BARCODE_REGEX.test(barcode)) {
                return false;
              }

              if (units.data.find((unit) => unit.barcode === barcode)) {
                proceed(barcode);

                return true;
              }

              notifications.addWarning(t('Our open shipping unit has a different barcode.'));

              return false;
            }}
            onSubmit={() => undefined}
          />
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <CreateShippingUnitDialog
      processId={process.processId}
      show={show}
      close={() => proceed('')}
      create={async (note) => {
        const createdUnit = await ShippingService.createShippingUnit(process.processId, note);

        notifications.addSuccess(
          t('New shipping unit with barcode {{barcode}} created.', {
            barcode: `${SHIPPING_UNIT_BARCODE_PREFIX}${createdUnit.shippingUnitId}`,
          }),
        );

        await printBase64PDF(createdUnit.label);

        await units.mutate();
      }}
    />
  );
};

export const confirmShippingUnit = createConfirmation(confirmable(ShippingUnitDialog));
