import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      //margin: theme.spacing(2),
      minWidth: '25ch',
    },
  }),
);
