import { SchemaType, api } from '../../shared/client';

export interface ShipmentMethodDto extends SchemaType<'ShipmentMethodDto'> {}
export interface CreateShipmentMethodDto extends SchemaType<'CreateShipmentMethodDto'> {}
export interface UpdateShipmentMethodDto extends SchemaType<'UpdateShipmentMethodDto'> {}

export const createShipmentMethod = async (body: CreateShipmentMethodDto, processId: number) => {
  const res = await api.post<'/v1/process/{processId}/shipmentMethod'>(`/v1/process/${processId}/shipmentMethod`, body);

  return res.data;
};

export const updateShipmentMethod = async (
  body: UpdateShipmentMethodDto,
  processId: number,
  shipmentMethodId: number,
) => {
  const res = await api.put<'/v1/process/{processId}/shipmentMethod/{shipmentMethodId}'>(
    `/v1/process/${processId}/shipmentMethod/${shipmentMethodId}`,
    body,
  );

  return res.data;
};

export const deleteShipmentMethod = async (processId: number, shipmentMethodId: number) => {
  await api.delete<'/v1/process/{processId}/shipmentMethod/{shipmentMethodId}'>(
    `/v1/process/${processId}/shipmentMethod/${shipmentMethodId}`,
  );
};

export const restoreShipmentMethod = async (processId: number, shipmentMethodId: number) => {
  const res = await api.put<'/v1/process/{processId}/shipmentMethod/{shipmentMethodId}/restore'>(
    `/v1/process/${processId}/shipmentMethod/${shipmentMethodId}/restore`,
  );
  return res.data;
};

export const useShipmentMethodsByProcess = (processId: number | null | undefined, deleted?: boolean) => {
  const { data, error, mutate } = api.useApi(
    typeof processId === 'number' ? { key: '/v1/process/{processId}/shipmentMethod', processId } : null,
    {
      deleted,
    },
  );

  return {
    data: data || [],
    error,
    isLoading: typeof processId === 'number' && !error && typeof data === 'undefined',
    isError: !!error,
    mutate,
  };
};
