import { TableRow, Tooltip, IconButton, TableCell, Box, Collapse, Stack, SxProps } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import React, { useState } from 'react';
import { TDataTableAction, TDataTableColumn } from './DataTable';
import { useDarkMode } from '../../hooks/dark-mode';

type Props<T> = {
  numberOfColumns: number;
  columns: TDataTableColumn<T>[];
  row: T;
  actions: TDataTableAction<T>[];
  highlight: SxProps | boolean | undefined;
  detailPanel?: (row: T) => React.JSX.Element;
};

const DataTableRow = <T,>({ numberOfColumns, columns, row, actions, detailPanel, highlight }: Props<T>) => {
  const [darkMode] = useDarkMode();
  const [open, setOpen] = useState(false);
  const hasDetailPanel = !!detailPanel;

  const tableRowHighlightStyle: SxProps = {
    fontWeight: 'bold',
    bgcolor: darkMode ? '#0c2c3b' : '#e4ebff',
  };

  const tableCellHighlightStyle = { fontWeight: 'inherit' };

  const tableRowStyle: SxProps = {
    ...(open ? { borderBottom: 'unset' } : {}),
    ...(typeof highlight === 'boolean' ? (highlight ? tableRowHighlightStyle : {}) : highlight ? highlight : {}),
  };

  return (
    <>
      <TableRow sx={tableRowStyle}>
        {hasDetailPanel && (
          <TableCell padding="checkbox">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        )}
        {actions.length > 0 && (
          <TableCell padding="none">
            <Stack
              direction="row"
              spacing={1}
            >
              {actions.map(
                (action, actionIndex) =>
                  (action.hidden === undefined || !action.hidden(row)) && (
                    <Tooltip
                      title={action.tooltip}
                      key={actionIndex}
                    >
                      <span>
                        <IconButton
                          disabled={action.disabled !== undefined ? action.disabled(row) : false}
                          onClick={() => action.onClick(row)}
                        >
                          {action.icon(row)}
                        </IconButton>
                      </span>
                    </Tooltip>
                  ),
              )}
            </Stack>
          </TableCell>
        )}
        {columns.map(
          (column, columnIndex) =>
            !column.hidden && (
              <TableCell
                {...column.cellProps}
                key={columnIndex}
                sx={{ ...column.cellProps?.sx, ...(highlight ? tableCellHighlightStyle : {}) }}
              >
                {'render' in column ? column.render(row) : String(row[column.field] ?? '')}
              </TableCell>
            ),
        )}
      </TableRow>
      {hasDetailPanel && (
        <TableRow>
          <TableCell
            sx={{ padding: 0, borderBottom: open ? undefined : 'unset' }}
            colSpan={numberOfColumns}
          >
            <Collapse
              in={open}
              timeout="auto"
              unmountOnExit
            >
              <Box sx={{ margin: 1 }}>{detailPanel(row)}</Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default DataTableRow;
