import { Avatar, Tooltip } from '@mui/material';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import React, { FC } from 'react';
import { ErrorOutline, SvgIconComponent } from '@mui/icons-material';
import { ShipmentState } from '../../backend';
import { useTranslation } from 'react-i18next';

const stateIconMap: Record<ShipmentState, SvgIconComponent> = {
  [ShipmentState.NEW]: FiberNewIcon,
  [ShipmentState.LABEL_PRINTED]: AssignmentTurnedInIcon,
  [ShipmentState.TOUR_ASSIGNED]: LocalShippingIcon,
  [ShipmentState.CLOSED]: LabelImportantIcon,

  [ShipmentState.REJECTED]: RadioButtonUncheckedIcon,

  [ShipmentState.RETOURE_ANNOUNCED]: FiberNewIcon,
  [ShipmentState.RETOURE_TOUR_READY]: AssignmentTurnedInIcon,
  [ShipmentState.RETOURE_TOUR_ASSIGNED]: LocalShippingIcon,
  [ShipmentState.RETOURE_TOUR_CLOSED]: LabelImportantIcon,

  [ShipmentState.TO_BE_CLARIFIED]: ContactSupportIcon,
  [ShipmentState.DISPOSED]: DeleteForeverIcon,
  [ShipmentState.RETOURE_REJECTED]: RadioButtonUncheckedIcon,
};

const stateColorMap: Record<ShipmentState, 'inbound' | 'outbound' | 'warning'> = {
  [ShipmentState.CLOSED]: 'outbound',
  [ShipmentState.LABEL_PRINTED]: 'outbound',
  [ShipmentState.NEW]: 'outbound',
  [ShipmentState.TOUR_ASSIGNED]: 'outbound',
  [ShipmentState.REJECTED]: 'warning',
  [ShipmentState.RETOURE_TOUR_ASSIGNED]: 'inbound',
  [ShipmentState.RETOURE_REJECTED]: 'warning',
  [ShipmentState.RETOURE_ANNOUNCED]: 'inbound',
  [ShipmentState.RETOURE_TOUR_CLOSED]: 'inbound',
  [ShipmentState.TO_BE_CLARIFIED]: 'warning',
  [ShipmentState.DISPOSED]: 'warning',
  [ShipmentState.RETOURE_TOUR_READY]: 'inbound',
};

export interface ShipmentStateIconProps {
  state: ShipmentState | null;
  fontSize?: 'small' | 'large';
  variant?: 'avatar' | 'icon';
}

export const ShipmentStateIcon: FC<ShipmentStateIconProps> = (props) => {
  const { t } = useTranslation();

  const stateTitleMap: Record<ShipmentState, string> = {
    [ShipmentState.CLOSED]: t('All parcels for the order have been successfully posted in the destination country.'),
    [ShipmentState.LABEL_PRINTED]: t('Label printed.'),
    [ShipmentState.NEW]: t('Order has been received by the system.'),
    [ShipmentState.TOUR_ASSIGNED]: t('Tour assigned.'),
    [ShipmentState.RETOURE_TOUR_ASSIGNED]: t(
      'The return has been declared to customs (if applicable) and is on its way back to the country of origin.',
    ),
    [ShipmentState.REJECTED]: t(
      'There were discrepancies (most likely at customs, if applicable) with at least one parcel relating to the order. The parcel will be delivered to the destination country the following day.',
    ),
    [ShipmentState.RETOURE_REJECTED]: t(
      'There were discrepancies at customs when the parcel was returned. The parcel will be returned to the country of origin the next day.',
    ),
    [ShipmentState.RETOURE_ANNOUNCED]: t(
      'The return registration has been completed and the label has been successfully created.',
    ),
    [ShipmentState.RETOURE_TOUR_CLOSED]: t(
      'The return parcel is back in the country of origin and on its way back to the retailer.',
    ),
    [ShipmentState.TO_BE_CLARIFIED]: t(
      'Clarification was required for the parcel as there were ambiguities during the content check.',
    ),
    [ShipmentState.DISPOSED]: t('Parcel was disposed of by exporto.'),
    [ShipmentState.RETOURE_TOUR_READY]: t(
      'The return has been processed in the warehouse. It will be brought back to the country of origin with the next tour.',
    ),
  };

  const variant = props.variant ?? 'icon';
  const fontSize = props.fontSize ? props.fontSize : 'medium';
  const title = props.state ? stateTitleMap[props.state] : t('The shipment state is not valid.');
  const Icon = props.state ? stateIconMap[props.state] : ErrorOutline;
  const color = props.state ? stateColorMap[props.state] : 'error';

  return (
    <Tooltip title={title}>
      {variant === 'avatar' ? (
        <Avatar sx={{ bgcolor: `${color}.main` }}>
          <Icon fontSize={fontSize} />
        </Avatar>
      ) : (
        <Icon
          fontSize={fontSize}
          color={color}
        />
      )}
    </Tooltip>
  );
};
