import { ExportoEnv } from '../globals';

export const backendUri: string | undefined = process.env.REACT_APP_API;
export const returnPortalUrl: string | undefined = process.env.REACT_APP_RETOUREN_CONSUMER_FRONTEND_URI;

export class Config {
  private static getNumber(value: string, defaultValue: number): number {
    const parsedValue = parseInt(value, 10);

    return isNaN(parsedValue) ? defaultValue : parsedValue;
  }

  public static readonly ENV = process.env.REACT_APP_ENV || ExportoEnv.development;
}
