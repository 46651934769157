import { Stack, TextField, Button, IconButton } from '@mui/material';
import axios, { AxiosError } from 'axios';
import React, { useState } from 'react';
import { ArticleService } from '../../../../services/article-service/article.service';
import { useNotifications } from '../../../../hooks/useNotifications';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';

type Props = {
  processId: number;
};

const ProcessDeleteArticles: React.FC<Props> = ({ processId }) => {
  const notifications = useNotifications();

  const [isDeletingAllArticles, setDeletingAllArticles] = useState(false);

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
    >
      {isDeletingAllArticles ? (
        <form
          onSubmit={(ev) => {
            ev.preventDefault();

            const formData = new FormData(ev.currentTarget);
            const today = formData.get('today')?.toString() ?? '';

            setDeletingAllArticles(false);

            ArticleService.deleteAllArticlesByProcessId(processId, today)
              .then((result) => {
                notifications.addInfo(`${result.count} Artikel gelöscht.`);
              })
              .catch((err) => {
                const errorMessage = axios.isAxiosError(err)
                  ? (err as AxiosError<{ message: string }>).response?.data?.message
                  : err.toString();

                notifications.addError(`Artikel konnten nicht gelöscht werden: ${errorMessage}`);
              });
          }}
        >
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
          >
            <TextField
              size="small"
              required
              name="today"
              placeholder="YYYY-MM-DD"
              inputProps={{ pattern: '\\d{4}-\\d{2}-\\d{2}' }}
            />
            <Button
              size="small"
              variant="contained"
              color="secondary"
              type="submit"
              startIcon={<DeleteIcon />}
            >
              Artikel unwiderruflich löschen
            </Button>
            <IconButton onClick={() => setDeletingAllArticles(false)}>
              <ClearIcon />
            </IconButton>
          </Stack>
        </form>
      ) : (
        <Button
          color="secondary"
          variant="contained"
          startIcon={<DeleteIcon />}
          onClick={() => setDeletingAllArticles(true)}
        >
          Alle Artikel löschen
        </Button>
      )}
    </Stack>
  );
};

export default ProcessDeleteArticles;
