import { Box, CircularProgress, IconButton, Paper, Typography } from '@mui/material';
import React from 'react';
import { OrderService } from '../../services/order-service/order.service';
import OrderConsumerAddress from '../shipment/OrderConsumerAddress';
import { Close } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';

type Props = {
  shipmentId: number;
  onClose: () => void;
};

const LastReturn: React.FC<Props> = ({ shipmentId, onClose }) => {
  const order = OrderService.useOrderByShipmentId(shipmentId);

  return (
    <Paper sx={{ padding: 1 }}>
      <IconButton
        sx={{ float: 'right' }}
        onClick={() => onClose()}
      >
        <Close />
      </IconButton>
      <Typography variant="h6">
        <Trans>
          The last processed shipment <Link to={`/shipment/${shipmentId}`}>{{ shipmentId } as any}</Link> was from...
        </Trans>
      </Typography>

      {order.isLoading && <CircularProgress />}
      {order.data && (
        <Box sx={{ maxWidth: 300, margin: 1, paddingLeft: 1, borderLeft: '2px solid', borderColor: 'info.light' }}>
          <OrderConsumerAddress order={order.data} />
        </Box>
      )}
    </Paper>
  );
};

export default LastReturn;
