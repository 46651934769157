import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import React from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import DeleteIcon from '@mui/icons-material/Delete';
import { LanguageDto } from '../../../services/process-settings-service/processSettings.service';
import { AVAILABLE_LANGUAGES } from './LanguagePicker';

interface Props {
  languagesToDelete: LanguageDto[];
  handleClose: () => void;
  handleConfirm: () => void;
}

export const ConfirmDeleteLanguageModal: React.FC<Props> = ({ languagesToDelete, handleClose, handleConfirm }) => {
  return (
    <>
      <Dialog
        open={true}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: 400,
          },
        }}
      >
        <DialogTitle>
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            justifyContent="center"
          >
            <WarningIcon color="warning" />
            <Typography variant="h6">
              {languagesToDelete.length > 1 ? 'Delete languages' : 'Delete language'}
            </Typography>
          </Stack>
        </DialogTitle>

        <DialogContent>
          <Typography pb={2}>
            Are you sure to delete the following languages? This will also delete all translations for that language.
          </Typography>
          <ul>
            {languagesToDelete.map((language) => (
              <li>
                <strong>
                  {AVAILABLE_LANGUAGES[language.languageCode as unknown as keyof typeof AVAILABLE_LANGUAGES]}
                </strong>
              </li>
            ))}
          </ul>
        </DialogContent>

        <DialogActions sx={{ p: 2, justifyContent: 'space-between' }}>
          <Button
            onClick={handleClose}
            variant="contained"
            size="large"
          >
            Cancel
          </Button>
          <Button
            startIcon={<DeleteIcon />}
            onClick={handleConfirm}
            variant="contained"
            color="error"
            size="large"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
