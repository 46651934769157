import React from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Grid, Breadcrumbs, Typography, Link, CircularProgress } from '@mui/material';
import UpdateIcon from '@mui/icons-material/Update';
import { Link as RouterLink } from 'react-router-dom';
import { CustomsService } from '../../services/customs-service/customs.service';
import HomeIcon from '@mui/icons-material/Home';
import { DateTime } from 'luxon';

type Props = {
  items: { href?: string; label: string }[];
};

const BreadcrumbsGridItem: React.FC<Props> = ({ items }) => {
  const lastDeclarationUpdate = CustomsService.useLastConsignmentUpdate();

  return (
    <>
      <Grid
        item
        xs={12}
        sm={8}
        md={6}
      >
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {items.map(({ href, label }, index) => {
            return href ? (
              <Link
                key={`${href}-${label}`}
                variant="body1"
                underline="hover"
                color="inherit"
                component={RouterLink}
                to={href}
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                {index === 0 && (
                  <HomeIcon
                    sx={{ mr: 0.5, mt: -0.5 }}
                    fontSize="inherit"
                  />
                )}
                {label}
              </Link>
            ) : (
              <Typography
                key={`${href}-${label}`}
                variant="body1"
                color="text.primary"
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                {index === 0 && (
                  <HomeIcon
                    sx={{ mr: 0.5, mt: -0.5 }}
                    fontSize="inherit"
                  />
                )}
                {label}
              </Typography>
            );
          })}
        </Breadcrumbs>
      </Grid>
      <Grid
        item
        xs={12}
        sm={4}
        md={6}
      >
        {!!lastDeclarationUpdate.data && (
          <Typography
            align="right"
            variant="body1"
            color="text.disabled"
          >
            {lastDeclarationUpdate.isValidating ? (
              <CircularProgress
                size={24}
                sx={{ verticalAlign: 'bottom' }}
                color="inherit"
              />
            ) : (
              <UpdateIcon sx={{ verticalAlign: 'bottom', size: 24 }} />
            )}{' '}
            Last declaration update {DateTime.fromISO(lastDeclarationUpdate.data.updatedAt).toRelative()}.
          </Typography>
        )}
      </Grid>
    </>
  );
};

export default BreadcrumbsGridItem;
