import { Card, Typography, IconButton, Tooltip, Box, Stack } from '@mui/material';
import ReplyIcon from '@mui/icons-material/Reply';
import SearchIcon from '@mui/icons-material/Search';
import BlockIcon from '@mui/icons-material/Block';
import React from 'react';
import { CustomerDto } from '../../../../../../services/customer-service/customer.service';
import { OrderWithCustomerDto } from '../../../../../../services/order-service/order.service';
import { returnPortalUrl } from '../../../../../../shared/helper/env/helper';
import useStyles from '../OrderDetailModal.style';
import { Warning } from '@mui/icons-material';
import { generateReturnPortalMac } from '../../../../../../services/authentication-service/authentication.service';
import { useProcessSettings } from '../../../../../../services/process-settings-service/processSettings.service';
import { ProcessService } from '../../../../../../services/process-service/process.service';
import { OrderSearchScope, OrderState } from '../../../../../../shared/backend';
import { useAuthentication } from '../../../../../../hooks/useAuthentication';
import { Link } from 'react-router-dom';
import { t } from 'i18next';

const WarningIcon = () => (
  <Warning
    color="warning"
    sx={{ verticalAlign: 'middle' }}
  />
);

const MAX_LENGTH = 25;

type Props = {
  order: OrderWithCustomerDto;
  customer: CustomerDto;
};

const InfoCard: React.FC<Props> = ({ order, customer }) => {
  const classes = useStyles();
  const settings = useProcessSettings(order.processId);
  const processes = ProcessService.useProcesses();
  const auth = useAuthentication();

  const returnPortalLogin = async () => {
    if (settings.data) {
      const loginMac = await generateReturnPortalMac(order.orderId);
      const loginUrl = new URL(
        `${returnPortalUrl}/${settings.data.processReturnPortalIdentifier}/${order.processId}/login`,
      );
      loginUrl.searchParams.set('mac', loginMac.mac);
      loginUrl.searchParams.set('orderId', order.orderId.toString());
      window.open(loginUrl.href, '_blank', 'noopener,noreferrer');
    }
  };

  const searchUrl = new URL('/orders/all-orders', window.location.origin);

  const customerId = processes.data.find((process) => process.processId === order.processId)?.customerId;
  if (customerId) {
    searchUrl.searchParams.set('customerIds', customerId.toString());
  }

  searchUrl.searchParams.set('scope', OrderSearchScope.EXTENDED);
  searchUrl.searchParams.set('search', `${order.name} ${order.address}`);

  return (
    <Stack
      direction="column"
      sx={{
        flexGrow: 1,
        flexShrink: 0,
        minWidth: '25%',
        maxWidth: '25%',
        marginRight: 1,
      }}
    >
      <Card
        className={classes.infoHeader}
        elevation={0}
        sx={(theme) => ({
          paddingX: 3,
          paddingY: 2,
          borderRadius: 0,
          borderStyle: order.state === OrderState.BLOCKED || order.process.blocked ? 'solid' : 'none',
          borderColor: theme.palette.error.light,
          borderSize: 0.5,
        })}
      >
        <div>
          <Tooltip title={t('Customer facing ID')}>
            <Typography
              variant="h4"
              component="h1"
            >
              {order.customerFacingId}
            </Typography>
          </Tooltip>
          <Typography
            variant="subtitle1"
            component="h2"
            title="company"
          >
            {order.process.blocked && (
              <BlockIcon
                color="error"
                sx={{ fontSize: '1.2em', verticalAlign: 'sub', mr: 0.5 }}
              />
            )}
            {auth.isAdmin() || auth.isCustomerCareAgent() ? (
              <Link to={`/crm/customers/${customer.customerId}`}>
                {customer.company} ({order.processId})
              </Link>
            ) : (
              customer.company
            )}
          </Typography>
        </div>
        {!!order.externalCreatedAt && (
          <div>
            <Box
              sx={{
                paddingLeft: 3,
                flexGrow: 1,
              }}
            >
              <Typography
                align="right"
                variant="body1"
                component="p"
              >
                Bestellt am
              </Typography>
              <Typography
                align="right"
                variant="h6"
                component="p"
              >
                {order.externalCreatedAt ? new Date(order.externalCreatedAt).toLocaleDateString() : '-'}
              </Typography>
            </Box>
          </div>
        )}
      </Card>

      <Card
        className={classes.infoBody}
        elevation={0}
        sx={{ padding: 3, flexGrow: 1 }}
      >
        <Box sx={{ fontSize: '1rem' }}>
          <Typography variant="h6">
            {(order.name?.length ?? 0) > MAX_LENGTH && <WarningIcon />} {order.name}
          </Typography>
          {!!order.company && <Typography variant="h6">{order.company}</Typography>}
          <Typography variant="body1">
            {(order.address?.length ?? 0) > MAX_LENGTH && <WarningIcon />} {order.address}
          </Typography>
          {order.additionalAddress && <Typography variant="body1">{order.additionalAddress}</Typography>}
          <Typography variant="body1">
            {(order.zip?.length ?? 0) !== 4 && order.countryCode === 'CH' && <WarningIcon />} {order.zip} {order.city}
          </Typography>
          <Typography variant="body1">{order.countryCode}</Typography>
        </Box>
        <Stack
          direction="column"
          justifyContent="flex-end"
          sx={{ mr: -1, mb: -1 }}
        >
          <IconButton
            component="a"
            href={searchUrl.href}
            target="_blank"
          >
            <SearchIcon />
          </IconButton>
          <IconButton onClick={returnPortalLogin}>
            <ReplyIcon />
          </IconButton>
        </Stack>
      </Card>
    </Stack>
  );
};

export default InfoCard;
