import React, { useState } from 'react';
import { useUserInfo } from '../../../services/user-service/user.service';
import { IconButton, InputAdornment, Stack, TextField } from '@mui/material';
import { Login, Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useNotifications } from '../../../hooks/useNotifications';
import { LoginResult, reauthenticateUser } from '../../../services/authentication-service/authentication.service';

export interface ReauthenticationFormProps {
  onReauthSuccess: () => void;
}

export const ReauthenticationForm: React.FC<ReauthenticationFormProps> = ({ onReauthSuccess }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const userInfo = useUserInfo();
  const notificationHandler = useNotifications();

  const reauthenticate = async (password: string) => {
    try {
      setDisabled(true);
      const reauthResult = await reauthenticateUser(password);
      if (reauthResult.result === LoginResult.Success) {
        await userInfo.mutate();
        onReauthSuccess();
      }
      setDisabled(false);
    } catch (error) {
      notificationHandler.addError(error);
      setDisabled(false);
    }
  };

  return (
    <form
      onSubmit={(ev) => {
        ev.preventDefault();
        const formData = new FormData(ev.currentTarget);
        const password = formData.get('password')?.toString() ?? '';
        reauthenticate(password);
      }}
    >
      <Stack
        direction="column"
        spacing={2}
      >
        <TextField
          name="password"
          variant="outlined"
          fullWidth
          label="Passwort"
          disabled={disabled}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  size="large"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <LoadingButton
          loading={disabled}
          loadingPosition="start"
          startIcon={<Login />}
          type="submit"
          variant="contained"
          color="secondary"
        >
          Passwort bestätigen
        </LoadingButton>
      </Stack>
    </form>
  );
};
