import { Button, IconButton, Stack, StackProps } from '@mui/material';
import React, { useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';

type Props = {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  label?: string;
  variant?: 'text' | 'contained' | 'outlined';
  size?: 'small';
  justifyContent?: StackProps['justifyContent'];
  icon?: React.ReactElement;
};

const DeleteButton: React.FC<Props> = ({ label, onClick, variant, size, justifyContent, icon }) => {
  const { t } = useTranslation();
  const [confirmation, setConfirmation] = useState(false);

  return confirmation ? (
    <Stack
      direction="row"
      spacing={1}
      justifyContent={justifyContent}
    >
      <Button
        color="error"
        size={size}
        variant={variant}
        startIcon={icon ?? <DeleteIcon />}
        onClick={onClick}
      >
        {label ?? t('Delete')}
      </Button>
      <IconButton onClick={() => setConfirmation(false)}>
        <ClearIcon />
      </IconButton>
    </Stack>
  ) : (
    <IconButton
      onClick={() => setConfirmation(true)}
      color="error"
    >
      {icon ?? <DeleteIcon />}
    </IconButton>
  );
};

export default DeleteButton;
