import useSWR from 'swr';
import client from '../../shared/client';
import { backendUri } from '../../shared/helper/env/helper';

export interface ITotpSecretDto {
  otpAuthUrl: string;
  secret: string;
  mac: string;
}

export const useTotpSecret = (enabled = false) => {
  const url = `${backendUri}/2fa/totp/secret`;
  const { data, error, mutate } = useSWR<ITotpSecretDto>(enabled ? url : null, {
    shouldRetryOnError: false,
  });

  return {
    data,
    error,
    isLoading: enabled && !error && !data,
    isError: !!error,
    mutate,
  };
};

export const registerTotp = async (secret: string, mac: string, token: string) => {
  return await client.put(`${backendUri}/2fa/totp/register`, {
    secret,
    mac,
    token,
  });
};

export const deleteTotp = async (token: string) => {
  const searchParams = new URLSearchParams({ token });

  return await client.delete(`${backendUri}/2fa/totp?${searchParams.toString()}`);
};
