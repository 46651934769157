import { Fade, Box, Zoom } from '@mui/material';
import cat from '../../../assets/img/cat.jpg';
import { Check as CheckIcon } from '@mui/icons-material';
import React from 'react';

type Props = {
  open: boolean;
};

const Catastic: React.FC<Props> = ({ open }) => {
  return (
    <Fade
      in={open}
      mountOnEnter
      unmountOnExit
    >
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0,0,0,0.3)',
          zIndex: 1300,
        }}
      >
        <Zoom
          in={true}
          style={{ transitionDelay: '100ms' }}
        >
          <Box
            sx={(theme) => ({
              borderRadius: '50%',
              overflow: 'hidden',
              boxShadow: `${theme.shadows[15]}`,
              marginTop: '-5%',
              position: 'relative',
            })}
          >
            <Box
              component="img"
              src={cat}
              alt="Cat"
              sx={{ maxWidth: '100%', width: '300px' }}
            />
            <Zoom
              in={true}
              style={{ transitionDelay: '600ms', position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
            >
              <Box
                sx={(theme) => ({
                  height: '100%',
                  backgroundColor: theme.palette.success.main,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                })}
              >
                <CheckIcon sx={{ color: 'success.contrastText', fontSize: '150px' }} />
              </Box>
            </Zoom>
          </Box>
        </Zoom>
      </Box>
    </Fade>
  );
};

export default Catastic;
