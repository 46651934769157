import { IconButton } from '@mui/material';
import ClipboardIcon from '@mui/icons-material/ContentPasteGo';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import DoneIcon from '@mui/icons-material/Done';
import React, { useState } from 'react';

type Props = {
  value: string;
  size?: 'medium' | 'small';
};

const CopyToClipboardButton: React.FC<Props> = ({ value, size }) => {
  const [copied, setCopied] = useState(false);
  const [copiedOnce, setCopiedOnce] = useState(false);

  return (
    <IconButton
      onClick={() => {
        navigator.clipboard
          .writeText(value)
          .then(() => {
            setCopied(true);
            setCopiedOnce(true);

            setTimeout(() => setCopied(false), 2000);
          })
          .catch((err) => {
            console.log('Could not write text to clipboard', err);
          });
      }}
      size="small"
      color={copied ? 'success' : 'default'}
    >
      {copied ? (
        <DoneIcon sx={size === 'small' ? { fontSize: '1em' } : {}} />
      ) : copiedOnce ? (
        <ContentPasteIcon sx={size === 'small' ? { fontSize: '1em' } : {}} />
      ) : (
        <ClipboardIcon sx={size === 'small' ? { fontSize: '1em' } : {}} />
      )}
    </IconButton>
  );
};

export default CopyToClipboardButton;
