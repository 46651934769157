import { Alert, AlertTitle } from '@mui/material';
import React from 'react';
import TourWarningIcon, { TourWarningType } from './TourWarningIcon';

type Props = {};

const EmptyTourAlert: React.FC<Props> = () => {
  return (
    <Alert
      severity="error"
      icon={<TourWarningIcon type={TourWarningType.TOUR_EMPTY} />}
    >
      <AlertTitle>Leere Tour</AlertTitle>
      Alle Sendungen beinhalten keine Artikel.
    </Alert>
  );
};

export default EmptyTourAlert;
