import {
  Card,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Stack,
  Switch,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useAuthentication } from '../../hooks/useAuthentication';
import { useDarkMode } from '../../shared/hooks/dark-mode';
import FidoForm from './FidoForm';
import SessionsForm from './SessionsForm';
import TotpForm from './TotpForm';
import { UpdatePasswordForm } from './UpdatePasswordForm';
import { useClassificationDistribution } from '../../shared/hooks/classification-distribution';
import { useDebugScale, useDebugView, useDevMode, useMockLabel } from '../../shared/hooks/dev-mode';
import { useTranslation } from 'react-i18next';
import { supportedLangs } from '../../translations/i18n';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bodyStyle: {},
    root: {
      padding: '20px 30px',
    },
  }),
);

export const SettingsPage = () => {
  const classes = useStyles();

  const { t, i18n } = useTranslation();
  const auth = useAuthentication();
  const [darkMode, setDarkMode] = useDarkMode();
  const [classificationDistribution, setClassificationDistribution] = useClassificationDistribution();
  const [devMode, setDevMode] = useDevMode();
  const [mockLabel, setMockLabel] = useMockLabel();
  const [debugView, setDebugView] = useDebugView();
  const [debugScale, setDebugScale] = useDebugScale();

  return (
    <Grid
      container
      spacing={3}
    >
      <Grid
        item
        xs={12}
        sm={6}
      >
        <Card className={classes.root}>
          <UpdatePasswordForm />
        </Card>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
      >
        <Card className={classes.root}>
          <TotpForm />
        </Card>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
      >
        <Card className={classes.root}>
          <FidoForm />
        </Card>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
      >
        <Card className={classes.root}>
          <SessionsForm />
        </Card>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
      >
        <Card className={classes.root}>
          <Typography
            variant="h5"
            gutterBottom
          >
            {t('Settings')}
          </Typography>
          <Typography
            variant="body2"
            sx={{ mb: 3 }}
          >
            {t('These settings are only valid in this browser.')}
          </Typography>

          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={darkMode}
                  onChange={(ev) => setDarkMode(ev.target.checked)}
                />
              }
              label="Dark mode"
            />
          </FormGroup>

          {(auth.isAdmin() || auth.isCustomsAgent()) && (
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={classificationDistribution}
                    onChange={(ev) => setClassificationDistribution(ev.target.checked)}
                  />
                }
                label="Classification distribution"
              />
            </FormGroup>
          )}

          {auth.isAdmin() && devMode && (
            <>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={devMode}
                      onChange={(ev) => setDevMode(ev.target.checked)}
                    />
                  }
                  label="Dev mode"
                />
              </FormGroup>

              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={debugView}
                      onChange={(ev) => setDebugView(ev.target.checked)}
                    />
                  }
                  label="Debug view"
                />
              </FormGroup>

              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={debugScale}
                      onChange={(ev) => setDebugScale(ev.target.checked)}
                    />
                  }
                  label="Debug scale"
                />
              </FormGroup>

              {process.env.NODE_ENV === 'development' && (
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={mockLabel}
                        onChange={(ev) => setMockLabel(ev.target.checked)}
                      />
                    }
                    label="Mock label"
                  />
                </FormGroup>
              )}
            </>
          )}

          <Stack
            sx={{ mt: 3 }}
            direction="column"
            spacing={2}
          >
            <TextField
              select
              value={i18n.language}
              onChange={(ev) => i18n.changeLanguage(ev.target.value)}
              fullWidth
              size="small"
              label={t('Language')}
            >
              {supportedLangs.map((l) => (
                <MenuItem
                  key={l}
                  value={l}
                >
                  {t(l)}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
        </Card>
      </Grid>
      {auth.isStaff() && (
        <Grid
          item
          xs={12}
          sm={6}
        >
          <Card className={classes.root}>
            <Typography
              variant="h5"
              gutterBottom
            >
              {t('Experimental settings')}
            </Typography>
            <Typography
              variant="body2"
              sx={{ mb: 3 }}
            >
              {t('The following settings are mainly for testing and can produce unstable behavior.')}
            </Typography>

            <Typography
              variant="body2"
              color="text.disabled"
            >
              {t('Nothing available.')}
            </Typography>
          </Card>
        </Grid>
      )}
    </Grid>
  );
};
