import { backendUri } from '../../shared/helper/env/helper';
import {
  api,
  TApiPostRequestBody,
  TApiPutRequestBody,
  IUseArrayResponse,
  IUseObjectResponse,
  SchemaType,
} from '../../shared/client';

import { ProcessDto } from '../process-service/process.service';

export interface CustomerDto extends SchemaType<'CustomerDto'> {}
export interface CustomerWithProcessesDto extends SchemaType<'CustomerWithProcessesDto'> {}
export interface CustomerWithAddressDto extends SchemaType<'CustomerWithAddressDto'> {}

export type CreateCustomerDto = TApiPostRequestBody<'/customer'>;
export type UpdateCustomerDto = TApiPutRequestBody<'/customer/{customerId}'>;
export type CreateProcessDto = TApiPostRequestBody<'/customer/{customerId}/process'>;

export interface IDeleteCustomerResponse {
  success: boolean;
  message?: string;
}

export namespace CustomerService {
  export const createCustomer = async (body: CreateCustomerDto): Promise<CustomerDto> => {
    const res = await api.post<'/customer'>(`${backendUri}/customer`, body);
    return res.data;
  };

  export const createProcess = async (customerId: number, body: CreateProcessDto): Promise<ProcessDto> => {
    const res = await api.post<'/customer/{customerId}/process'>(`${backendUri}/customer/${customerId}/process`, body);
    return res.data;
  };

  export const updateCustomer = async (body: UpdateCustomerDto, id: number): Promise<CustomerDto> => {
    const res = await api.put<'/customer/{customerId}'>(`${backendUri}/customer/${id}`, body);
    return res.data;
  };

  export const deleteCustomer = async (
    customerId: number | null,
    company: string,
    password: string,
  ): Promise<IDeleteCustomerResponse> => {
    const response = await api.delete<'/customer/{customerId}', { message: string }>(
      `${backendUri}/customer/${customerId}`,
      {
        validateStatus: (status) => status === 200 || status === 400,
        data: { company, password },
      },
    );

    if (response.status === 400) {
      return { success: false, message: response.data.message };
    }

    return { success: true };
  };

  export const useCustomers = (): IUseArrayResponse<CustomerWithProcessesDto> => {
    const { data, error, mutate, isLoading, isValidating } = api.useApi('/customer');

    return {
      data: data ?? [],
      error,
      isLoading,
      isValidating,
      isError: !!error,
      mutate,
    };
  };

  export const useCustomer = (customerId: number | null): IUseObjectResponse<CustomerWithAddressDto> => {
    const { data, error, mutate, isLoading } = api.useApi(
      customerId ? { key: '/customer/{customerId}', customerId } : null,
    );

    return {
      data: data ?? null,
      error,
      isLoading,
      isError: !!error,
      mutate,
    };
  };
}
