import React, { useState } from 'react';
import { TourAndChecksDto, TourBatchDto, TourService } from '../../../services/tour-service/tour.service';
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import { isToday } from '../../../shared/helper/date';
import TourTypeIcon from '../TourTypeIcon';
import { Lock } from '@mui/icons-material';
import CustomsActions from '../CustomsActions';
import MergeIcon from '@mui/icons-material/Merge';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import RefreshIcon from '@mui/icons-material/Refresh';

import { countryToFlag } from '../../../shared/helper/country';
import CloseAllToursIconButton from './CloseAllToursIconButton';
import BlockedArticleAlert from '../BlockedArticleAlert';
import IncompleteArticleAlert from '../IncompleteArticleAlert';
import MissingExportoBarcodeAlert from '../MissingExportoBarcodeAlert';
import NewProcessAlert from '../NewProcessAlert';
import NotificationState from '../NotificationState';
import { useAuthentication } from '../../../hooks/useAuthentication';
import { useNotifications } from '../../../hooks/useNotifications';
import { IsoCountryCode, TourCustomsState, TourType } from '../../../shared/backend';
import MergeDialog from './MergeDialog';
import SpecialTreatmentAlert from '../SpecialTreatmentAlert';
import {
  collectiveReferencePredicate,
  exportGvmsPredicate,
  getHasCustomsStatePredicate,
  getTourIdFromTour,
  getUsesCustomsStatePredicate,
  hasBlockedArticlesPredicate,
  hasEmptyTourPredicate,
  hasIncompleteArticleIdsPredicate,
  hasInvalidArticleIdsPredicate,
  hasMissingArticlePredicate,
  hasMissingExportoBarcodePredicate,
  hasMissingShippingWeightsPredicate,
  hasProcessWithoutClosedTourPredicate,
  hasShipmentsWithEmkPredicate,
  hasSpecialTreatmentPredicate,
  importGvmsPredicate,
  isShipoutAllowedPredicate,
  sumAPredicate,
  transitArrivalPredicate,
  transitDepartureDocumentPredicate,
  transitDeparturePredicate,
  transitConsignmentPredicate,
  transitManifestPredicate,
  isTransitCompletePredicate,
  isVoecHandlingEnabledPredicate,
} from '../../../shared/helper/customs/customs-helper';
import TourNotesAlert from '../TourNotesAlert';
import EmptyTourAlert from '../EmptyTourAlert';
import MissingShippingWeightAlert from '../MissingShippingWeightAlert';
import MissingArticleAlert from '../MissingArticleAlert';
import TourNoteDialog from '../TourNoteDialog';
import UpdateCustomsStatesDialog from '../UpdateCustomsStatesDialog';
import ShipoutState from '../ShipoutState';
import InvalidArticleAlert from '../InvalidArticleAlert';
import { getShortBatchId } from '../../../shared/helper/text';
import CopyToClipboardButton from '../../../shared/components/CopyToClipboardButton';
import VehicleIdentificationsAlert from '../VehicleIdentificationsAlert';

type Props = {
  batch: TourBatchDto;
  tours: TourAndChecksDto[];
  mutate: () => void;
  isValidating: boolean;
  toursAreLoading: boolean;
};

const TourBatch: React.FC<Props> = ({ batch, tours, mutate, isValidating, toursAreLoading }) => {
  const [mergeDialogOpen, setMergeDialogOpen] = useState(false);
  const [tourNoteDialogOpen, setTourNoteDialogOpen] = useState(false);
  const [updateCustomsStatesDialogOpen, setUpdateCustomsStatesDialogOpen] = useState(false);

  const auth = useAuthentication();
  const notifications = useNotifications();
  const createdAt = new Date(batch.date);

  const isReturnBatch = batch.type === 'retour';
  const batchDispatchCountry = !isReturnBatch ? batch.dispatchCountry : batch.destinationCountry;
  const batchDestinationCountry = !isReturnBatch ? batch.destinationCountry : batch.dispatchCountry;

  const isGermanImport = batchDestinationCountry === IsoCountryCode.DE;
  const isGermanyToSwiss = batchDispatchCountry === IsoCountryCode.DE && batchDestinationCountry === IsoCountryCode.CH;
  const isGermanyToGreatBritain =
    batchDispatchCountry === IsoCountryCode.DE && batchDestinationCountry === IsoCountryCode.GB;
  const isGreatBritainToGermany =
    batchDispatchCountry === IsoCountryCode.GB && batchDestinationCountry === IsoCountryCode.DE;
  const isSwissToGermany = batchDispatchCountry === IsoCountryCode.CH && batchDestinationCountry === IsoCountryCode.DE;
  const isGermanyToNorway = batchDispatchCountry === IsoCountryCode.DE && batchDestinationCountry === IsoCountryCode.NO;

  const hasBlockedArticles = tours.some(hasBlockedArticlesPredicate);
  const hasIncompleteArticles = tours.some(hasIncompleteArticleIdsPredicate);
  const hasInvalidArticles = tours.some(hasInvalidArticleIdsPredicate);
  const hasMissingExportoBarcode = tours.some(hasMissingExportoBarcodePredicate);
  const hasEmptyTour = tours.some(hasEmptyTourPredicate);
  const hasMissingShipmentWeights = tours.some(hasMissingShippingWeightsPredicate);
  const hasMissingArticles = tours.some(hasMissingArticlePredicate);
  const hasProcessWithoutClosedTour = tours.some(hasProcessWithoutClosedTourPredicate);
  const hasSpecialTreatment = tours.some(hasSpecialTreatmentPredicate);

  const usesTransitConsignment = tours.every(getUsesCustomsStatePredicate('transitCustomsState'));
  const usesTransitManifestConsignment = tours.every(getUsesCustomsStatePredicate('transitManifestCustomsState'));
  const usesTransitDepartureConsignment = tours.every(getUsesCustomsStatePredicate('transitDepartureCustomsState'));
  const usesTransitArrivalConsignment = tours.every(getUsesCustomsStatePredicate('transitArrivalCustomsState'));
  const usesSumAConsignment = tours.every(getUsesCustomsStatePredicate('sumACustomsState'));

  const usesVoecHandling = tours.every(isVoecHandlingEnabledPredicate);

  const usesCollectiveReference = isGermanyToSwiss;

  const showShipoutState =
    batch.type === TourType.RETOUR || (batch.type === TourType.TOUR && (isSwissToGermany || isGreatBritainToGermany));
  const isShipoutAllowed = tours.every(isShipoutAllowedPredicate);

  const hasErrors =
    hasBlockedArticles ||
    hasIncompleteArticles ||
    hasMissingExportoBarcode ||
    hasEmptyTour ||
    hasMissingShipmentWeights ||
    hasMissingArticles;

  const hasWarnings = hasInvalidArticles;

  return (
    <>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={3}
        flexWrap="wrap"
      >
        <Box pt="1.2em">
          <TourTypeIcon
            tourType={batch.type}
            isLoading={isValidating}
          />
        </Box>
        <Box>
          <Typography
            variant="body2"
            color="text.secondary"
          >
            Zwischentour {batch.processTypeLabels.join(', ')}
          </Typography>
          <Typography variant="h4">
            {getShortBatchId(batch.tourBatchId)}
            <CopyToClipboardButton
              value={getShortBatchId(batch.tourBatchId)}
              size="small"
            />{' '}
            {batch.originalTourBatchIds.length > 0 && (
              <Tooltip
                title={`Formerly known as ${batch.originalTourBatchIds.map((id) => getShortBatchId(id)).join(', ')}`}
              >
                <MergeIcon color="disabled" />
              </Tooltip>
            )}
          </Typography>
          <Typography
            variant="body1"
            color="text.secondary"
            gutterBottom
          >
            {isToday(createdAt) ? createdAt.toLocaleTimeString() : createdAt.toLocaleString()}
          </Typography>
        </Box>

        <Box flexGrow={1}></Box>

        {showShipoutState && !toursAreLoading && <ShipoutState isShipoutAllowed={isShipoutAllowed} />}

        <Box flexGrow={1}></Box>

        <Box
          pl={1}
          pr={2}
        >
          <Typography
            variant="body2"
            color="text.secondary"
          >
            Von
          </Typography>
          <Typography variant="h5">{countryToFlag(batchDispatchCountry)}</Typography>
        </Box>

        <Box
          pl={1}
          pr={2}
        >
          <Typography
            variant="body2"
            color="text.secondary"
          >
            Nach
          </Typography>
          <Typography variant="h5">{countryToFlag(batchDestinationCountry)}</Typography>
        </Box>

        <Box
          pl={1}
          pr={2}
        >
          <Typography
            variant="body2"
            color="text.secondary"
          >
            Paketanzahl
          </Typography>
          <Typography variant="h5">{batch.shipmentCount}</Typography>
        </Box>

        <Box
          pl={1}
          pr={2}
        >
          <Typography
            variant="body2"
            color="text.secondary"
          >
            Gewicht
          </Typography>
          <Typography variant="h5">{(batch.weight / 1000).toFixed(1)}</Typography>
        </Box>

        <Box
          pl={1}
          pr={2}
        >
          <Typography
            variant="body2"
            color="text.secondary"
          >
            Benachrichtigungen
          </Typography>
          <Typography variant="h5">
            <NotificationState batch={batch} />
          </Typography>
        </Box>

        <Box
          pl={1}
          pr={2}
        >
          <Typography
            variant="body2"
            color="text.secondary"
          >
            Geschlossen
          </Typography>
          <Typography variant="h5">
            {batch.closedTourCount === 0 ? (
              <Box sx={{ mt: -0.5, ml: -1 }}>
                <CloseAllToursIconButton
                  batchId={batch.tourBatchId}
                  tourIds={tours.map(getTourIdFromTour)}
                />
              </Box>
            ) : batch.tourCount === batch.closedTourCount ? (
              <Lock sx={{ mt: 0.5 }} />
            ) : (
              `${batch.closedTourCount} / ${batch.tourCount}`
            )}
          </Typography>
        </Box>
      </Stack>

      <Stack
        justifyContent="flex-start"
        alignItems="center"
        direction={{ xs: 'column', sm: 'row' }}
        spacing={1}
        mt={3}
        flexWrap="wrap"
        useFlexGap
      >
        <CustomsActions
          batch={batch}
          type={batch.type}
          isLoading={toursAreLoading}
          importTourIds={
            hasErrors
              ? []
              : usesSumAConsignment &&
                  (usesTransitConsignment || usesTransitManifestConsignment) &&
                  !tours.every(isTransitCompletePredicate)
                ? []
                : isGermanyToNorway && !usesVoecHandling // only show IMPORT button, when VOEC handling is enabled for the process
                  ? undefined
                  : tours
                      .filter(
                        getHasCustomsStatePredicate('importCustomsState', [TourCustomsState.CONSIGNMENT_NOT_SENT]),
                      )
                      .map(getTourIdFromTour)
          }
          deImportTourIds={
            isGermanImport
              ? tours.every(
                  getHasCustomsStatePredicate('importCustomsState', [
                    TourCustomsState.DECLARATIONS_ACCEPTED,
                    TourCustomsState.DECLARATIONS_COMPLETE,
                  ]),
                )
                ? tours.map(getTourIdFromTour)
                : []
              : undefined
          }
          exportTourIds={
            hasErrors
              ? []
              : tours
                  .filter(getHasCustomsStatePredicate('exportCustomsState', [TourCustomsState.CONSIGNMENT_NOT_SENT]))
                  .map(getTourIdFromTour)
          }
          transitManifestTourIds={
            usesTransitManifestConsignment
              ? tours.every(transitManifestPredicate)
                ? tours.map(getTourIdFromTour)
                : []
              : undefined
          }
          transitTourIds={
            usesTransitConsignment
              ? tours.every(transitConsignmentPredicate)
                ? tours.map(getTourIdFromTour)
                : []
              : undefined
          }
          sumATourIds={
            usesSumAConsignment && (usesTransitConsignment || usesTransitManifestConsignment)
              ? tours.every(sumAPredicate)
                ? tours.map(getTourIdFromTour)
                : []
              : undefined
          }
          transitDepartureTourIds={
            usesTransitDepartureConsignment
              ? tours.every(transitDeparturePredicate)
                ? tours.map(getTourIdFromTour)
                : []
              : undefined
          }
          transitArrivalTourIds={
            usesTransitArrivalConsignment
              ? tours.every(transitArrivalPredicate)
                ? tours.map(getTourIdFromTour)
                : []
              : undefined
          }
          importGvmsTourIds={
            isGermanyToGreatBritain && !hasErrors
              ? tours.every(importGvmsPredicate)
                ? tours.map(getTourIdFromTour)
                : []
              : undefined
          }
          exportGvmsTourIds={
            isGreatBritainToGermany && !hasErrors
              ? tours.every(exportGvmsPredicate)
                ? tours.map(getTourIdFromTour)
                : []
              : undefined
          }
          tourIdsWithEmk={
            isGermanyToSwiss ? tours.filter(hasShipmentsWithEmkPredicate).map(getTourIdFromTour) : undefined
          }
          tourIdsForCollectiveReference={
            usesCollectiveReference
              ? tours.every(collectiveReferencePredicate)
                ? tours.map(getTourIdFromTour)
                : []
              : undefined
          }
          tourIdsForTransitDepartureDocument={
            usesTransitDepartureConsignment
              ? tours.every(transitDepartureDocumentPredicate)
                ? tours.map(getTourIdFromTour)
                : []
              : undefined
          }
        />

        <Box flexGrow={1}></Box>

        <Stack
          direction="row"
          spacing={1}
          // Auto for margin left is used to move the element always to the right
          // side, even if the screen is too small and elements wrap
          sx={{ ml: 'auto' }}
        >
          <IconButton
            title="Zollstatus neu berechnen"
            onClick={() => setUpdateCustomsStatesDialogOpen(true)}
          >
            <RefreshIcon />
          </IconButton>

          {tours.every(({ tour: { note } }) => !note) && (
            <IconButton
              title="Add note"
              onClick={() => setTourNoteDialogOpen(true)}
            >
              <NoteAddIcon />
            </IconButton>
          )}

          <IconButton
            title="Zusammenlegen"
            onClick={() => setMergeDialogOpen(true)}
          >
            <MergeIcon />
          </IconButton>

          {auth.userId &&
            (batch.assigneeUserIds.includes(auth.userId) ? (
              <IconButton
                onClick={async () => {
                  try {
                    await TourService.unassignTourBatch(batch.tourBatchId);

                    notifications.addSuccess('Jetzt kann sich jemand anderes darum kümmern.');

                    mutate();
                  } catch (error) {
                    notifications.addError(error);
                  }
                }}
              >
                <PersonRemoveIcon />
              </IconButton>
            ) : batch.assigneeUserIds.includes(null) ? (
              <IconButton
                onClick={async () => {
                  try {
                    await TourService.assignTourBatch(batch.tourBatchId);

                    notifications.addSuccess('Schöner Hut ;-)');

                    mutate();
                  } catch (error) {
                    notifications.addError(error);
                  }
                }}
              >
                <PersonAddIcon />
              </IconButton>
            ) : undefined)}
        </Stack>
      </Stack>

      {(hasErrors ||
        hasWarnings ||
        hasProcessWithoutClosedTour ||
        hasSpecialTreatment ||
        batch.notes.length > 0 ||
        batch.vehicleIdentifications.length > 0) && (
        <Stack
          direction="column"
          spacing={1}
          mt={5}
        >
          {hasBlockedArticles && <BlockedArticleAlert />}

          {hasMissingExportoBarcode && <MissingExportoBarcodeAlert />}

          {hasIncompleteArticles && <IncompleteArticleAlert />}

          {hasInvalidArticles && <InvalidArticleAlert />}

          {hasEmptyTour && <EmptyTourAlert />}

          {hasMissingArticles && <MissingArticleAlert />}

          {hasMissingShipmentWeights && <MissingShippingWeightAlert />}

          {hasProcessWithoutClosedTour && <NewProcessAlert />}

          {hasSpecialTreatment && <SpecialTreatmentAlert />}

          {batch.notes.length > 0 && (
            <TourNotesAlert
              notes={batch.notes}
              tourIds={tours.map(({ tour }) => tour.tourId)}
              mutate={mutate}
            />
          )}

          {batch.vehicleIdentifications.length > 0 && (
            <VehicleIdentificationsAlert vehicleIdentifications={batch.vehicleIdentifications} />
          )}
        </Stack>
      )}

      {mergeDialogOpen && (
        <MergeDialog
          batch={batch}
          open={mergeDialogOpen}
          onClose={() => setMergeDialogOpen(false)}
        />
      )}

      {tourNoteDialogOpen && (
        <TourNoteDialog
          value={''}
          tourIds={tours.map(({ tour: { tourId } }) => tourId)}
          onClose={() => setTourNoteDialogOpen(false)}
          mutate={mutate}
        />
      )}

      {updateCustomsStatesDialogOpen && (
        <UpdateCustomsStatesDialog
          tourBatchId={batch.tourBatchId}
          onClose={() => {
            setUpdateCustomsStatesDialogOpen(false);
            mutate();
          }}
        />
      )}
    </>
  );
};

export default TourBatch;
