import React from 'react';
import { ShipmentStateIcon } from '../../shared/components/shipment-state-icon/ShipmentStateIcon';
import { Avatar, Stack } from '@mui/material';
import { useAuthentication } from '../../hooks/useAuthentication';
import cat from '../../assets/img/cat.jpg';
import devCat from '../../assets/img/dev-cat.jpg';
import { ShipmentState } from '../../shared/backend';

type Props = {};

const ShipmentStateLegend: React.FC<Props> = () => {
  const auth = useAuthentication();

  return (
    <Stack
      direction="row"
      spacing={3}
    >
      <Stack
        direction="row"
        spacing={1}
      >
        {[ShipmentState.NEW, ShipmentState.LABEL_PRINTED, ShipmentState.TOUR_ASSIGNED, ShipmentState.CLOSED].map(
          (state) => (
            <ShipmentStateIcon
              key={state}
              state={state}
            />
          ),
        )}
      </Stack>

      <Stack
        direction="row"
        spacing={1}
      >
        {[
          ShipmentState.RETOURE_ANNOUNCED,
          ShipmentState.RETOURE_TOUR_READY,
          ShipmentState.RETOURE_TOUR_ASSIGNED,
          ShipmentState.RETOURE_TOUR_CLOSED,
        ].map((state) => (
          <ShipmentStateIcon
            key={state}
            state={state}
          />
        ))}
      </Stack>

      {auth.isStaff() && (
        <Stack
          direction="row"
          spacing={1}
        >
          <Avatar
            sx={{
              width: '1em',
              height: '1em',
              transition: 'transform 0.5s',
              '&:hover': { transform: 'scale(5)', zIndex: 1 },
            }}
            alt="Dev Cat"
            src={cat}
          />
          <Avatar
            sx={{
              width: '1em',
              height: '1em',
              transition: 'transform 0.5s',
              '&:hover': { transform: 'scale(5)', zIndex: 1 },
            }}
            alt="Dev Cat"
            src={devCat}
          />
        </Stack>
      )}
    </Stack>
  );
};

export default ShipmentStateLegend;
