import { Box, Stack } from '@mui/material';
import React from 'react';
import CustomsStatusIcon from './CustomsStatusIcon';
import { TourCustomsState } from '../../shared/backend';

type Props = {};

const CustomsStatusIconLegend: React.FC<Props> = () => {
  return (
    <Box
      component="details"
      sx={{ color: 'text.disabled' }}
    >
      <Box
        component="summary"
        sx={{ cursor: 'pointer' }}
      >
        Legende Zoll-Status
      </Box>

      <Stack
        direction="row"
        spacing={1}
      >
        {[
          TourCustomsState.CONSIGNMENT_NOT_SENT,
          TourCustomsState.CONSIGNMENT_PENDING,
          TourCustomsState.CONSIGNMENT_SENT,
          TourCustomsState.CONSIGNMENT_ERROR,
          TourCustomsState.DECLARATIONS_CREATED,
          TourCustomsState.DECLARATIONS_AWAITING_USER_ACTION,
          TourCustomsState.DECLARATIONS_SENT_TO_CUSTOMS,
          TourCustomsState.DECLARATIONS_ACCEPTED,
          TourCustomsState.DECLARATIONS_COMPLETE,
          TourCustomsState.DECLARATIONS_ERROR,
        ].map((status) => (
          <CustomsStatusIcon
            key={status}
            status={status}
          />
        ))}
      </Stack>
    </Box>
  );
};

export default CustomsStatusIconLegend;
