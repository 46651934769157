import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import { UserTable } from './components/userTable';

export const UsersPage = () => {
  return (
    <Paper sx={{ px: 2, py: 3 }}>
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
        >
          <Typography
            variant="h5"
            gutterBottom
          >
            Benutzende
          </Typography>
          <Typography
            variant="body1"
            mb={3}
          >
            Hier können alle Accounts eingesehen und bearbeitet werden. Ein gelöschter Zugang ist nur deaktiviert und
            kann über das Dev-Team wiederhergestellt werden.
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
        >
          <UserTable />
        </Grid>
      </Grid>
    </Paper>
  );
};
