import useSWR from 'swr';
import client from '../../shared/client';
import { backendUri } from '../../shared/helper/env/helper';

export interface IAsset {
  assetId: number;
  customerId: number;
  labelIcon: string;
  isDefault: boolean;
  isReturnLabel: boolean;
  createdAt: string;
  updatedAt: string;
}

export const useAssets = (customerId: number) => {
  const { data, error, mutate, isLoading } = useSWR<IAsset[]>(`${backendUri}/v1/customer/${customerId}/asset`);

  return {
    data: data ?? [],
    error,
    isLoading,
    isError: !!error,
    mutate,
  };
};

export const createAsset = async (
  customerId: number,
  body: Omit<IAsset, 'customerId' | 'assetId' | 'createdAt' | 'updatedAt'>,
) => {
  const res = await client.post(`${backendUri}/v1/customer/${customerId}/asset`, body);

  return res.data;
};

export const deleteAsset = async (customerId: number, assetId: number) => {
  await client.delete(`${backendUri}/v1/customer/${customerId}/asset/${assetId}`);
};
