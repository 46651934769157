import React, { PropsWithChildren, useEffect, useState } from 'react';
import ExportoDrawer from './ExportoDrawer';
import ExportoAppBar from './ExportoAppBar';
import Game from '../game/Game';
import { Box, useMediaQuery } from '@mui/material';
import { Theme, styled } from '@mui/material/styles';
import { DrawerHeader } from './DrawerHeader';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  minWidth: 0, // ensure element doesn't grow larger than body
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

type Props = {};

const DefaultLayout: React.FC<PropsWithChildren<Props>> = ({ children }) => {
  const isSmallMediaOrUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  const [isFunTime, setFunTime] = useState(false);
  const [open, setOpen] = useState(isSmallMediaOrUp);

  useEffect(() => setOpen(isSmallMediaOrUp), [isSmallMediaOrUp]);

  return (
    <>
      <Game
        open={isFunTime}
        onClose={() => setFunTime(false)}
      />

      <Box display="flex">
        <ExportoAppBar {...{ drawerWidth, setFunTime, open, setOpen }} />

        <ExportoDrawer {...{ drawerWidth, open, setOpen }} />

        <Main open={open}>
          {/* Since we use a fixed app bar, we need some spacing here */}
          <DrawerHeader />

          {children}
        </Main>
      </Box>
    </>
  );
};

export default DefaultLayout;
