import { api } from '../../shared/client';

export namespace ImporterService {
  export const retryToursNotification = async (tourIds: number[]) => {
    const response = await api.post<'/importer/notification/retry/tours'>(`/importer/notification/retry/tours`, {
      tourIds,
    });
    return response.data;
  };

  export const retryShipmentNotification = async (shipmentId: number) => {
    const response = await api.post<'/importer/notification/retry/shipment/{shipmentId}'>(
      `/importer/notification/retry/shipment/${shipmentId}`,
    );
    return response.data;
  };
}
