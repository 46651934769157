import React, { useState } from 'react';
import { useEnumParam } from '../../hooks/useParam';
import { ProcessTypeService } from '../../services/process-type-service/process-type.service';
import { ProcessService } from '../../services/process-service/process.service';
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import SelectWithFilter from '../../shared/components/SelectWithFilter';
import { DateTime } from 'luxon';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import Download from '@mui/icons-material/Download';
import { assertIsTruthy } from '../../shared/helper/helper';
import { ShipmentService } from '../../services/shipment-service/shipment.service';
import { useNotifications } from '../../hooks/useNotifications';
import fileDownload from 'js-file-download';
import { isAxiosError } from 'axios';
import { ShipmentState } from '../../shared/backend';
import { shipmentStateOptions } from '../../shared/components/shipment-filter/ShipmentFilter';
import { useTranslation } from 'react-i18next';
import ShipmentStateLabel from '../../shared/components/shipment-filter/ShipmentStateLabel';
import i18next from 'i18next';
import { useAuthentication } from '../../hooks/useAuthentication';

enum Direction {
  OUTBOUND = 'outbound',
  INBOUND = 'inbound',
}

type Props = {};

const ReportsPage: React.FC<Props> = () => {
  const auth = useAuthentication();
  const notifications = useNotifications();
  const { t } = useTranslation();
  const processTypes = ProcessTypeService.useProcessTypes();
  const processes = ProcessService.useProcesses();

  const [direction, setDirection] = useEnumParam('direction', Direction, Direction.OUTBOUND);
  const [processTypeIds, setProcessTypeIds] = useState<number[]>([]);
  const [processIds, setProcessIds] = useState<number[]>([]);
  const [shipmentStates, setShipmentStates] = useState<ShipmentState[]>([]);
  const [startDate, setStartDate] = useState<Date>(DateTime.now().minus({ month: 1 }).toJSDate());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [showBlocked, setShowBlocked] = useState(false);

  const [isProcessing, setProcessing] = useState(false);

  const filterProcessByProcessTypes = (ids: number[]) => {
    return processes.data.filter((process) => ids.includes(process.processTypeId) && process.blocked === showBlocked);
  };

  const availableProcesses = filterProcessByProcessTypes(processTypeIds);

  const isValidTimeRange =
    startDate <= endDate && DateTime.fromJSDate(endDate).diff(DateTime.fromJSDate(startDate)).as('days') <= 31;

  const handleDownload = async () => {
    const start = DateTime.fromJSDate(startDate).startOf('day');
    const end = DateTime.fromJSDate(endDate).endOf('day');

    const fromDate = start.toISO();
    const toDate = end.toISO();

    assertIsTruthy(fromDate);
    assertIsTruthy(toDate);

    setProcessing(true);

    try {
      const response =
        direction === Direction.OUTBOUND
          ? await ShipmentService.getShipmentsSummaryCSV({
              processIds,
              fromDate: fromDate,
              toDate: toDate,
            })
          : await ShipmentService.getRetoureShipmentsCSV({
              processIds,
              fromDate: fromDate,
              toDate: toDate,
              shipmentStates,
            });

      const dateRangeFilename = `${start.toFormat('yyyy-MM-dd')}-${end.toFormat('yyyy-MM-dd')}`;
      const filename = `${direction}_${processIds.join(',')}_(${dateRangeFilename}).csv`;

      fileDownload(new Blob([response]), filename, 'text/csv');
    } catch (error) {
      if (isAxiosError(error) && error.response?.status === 404) {
        notifications.addWarning('Keine Sendungen verfügbar in dem ausgewählten Zeitraum.');
      } else {
        notifications.addError(error);
      }
    } finally {
      setProcessing(false);
    }
  };

  return (
    <Paper sx={{ px: 2, py: 3 }}>
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
        >
          <Typography
            variant="h5"
            gutterBottom
          >
            Reports
          </Typography>
          <Typography
            variant="body1"
            mb={3}
          >
            Hier können Sie Informationen über ausgehende (outbound) und eingehende (inbound) Sendungen herunterladen.
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
        >
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            spacing={3}
            flexWrap="wrap"
            justifyContent="space-between"
            sx={{ borderTop: '1px solid', borderColor: 'divider', pt: 3 }}
          >
            <FormControl disabled={isProcessing}>
              <FormLabel id="direction">Richtung</FormLabel>
              <RadioGroup
                aria-labelledby="direction"
                value={direction ?? ''}
                onChange={(ev) => {
                  setDirection(ev.target.value as Direction);
                }}
              >
                <FormControlLabel
                  value={Direction.OUTBOUND}
                  label="Outbound"
                  control={<Radio color="outbound" />}
                />
                <FormControlLabel
                  value={Direction.INBOUND}
                  label="Inbound (Retouren)"
                  control={<Radio color="inbound" />}
                />
              </RadioGroup>
            </FormControl>

            <FormControl
              component="fieldset"
              disabled={isProcessing}
            >
              <FormLabel component="legend">Prozessgruppen</FormLabel>

              <RadioGroup
                aria-labelledby="direction"
                value={processTypeIds[0] ?? ''}
                onChange={(ev) => {
                  const newProcessTypeId = parseInt(ev.target.value, 10);
                  setProcessTypeIds([newProcessTypeId]);

                  const newAvailableProcesses = filterProcessByProcessTypes([newProcessTypeId]);

                  if (newAvailableProcesses.length === 1) {
                    setProcessIds(newAvailableProcesses.map(({ processId }) => processId));
                  } else {
                    setProcessIds([]);
                  }
                }}
              >
                {processTypes.data
                  .sort((a, b) => a.label.localeCompare(b.label))
                  .map((processType) => {
                    return (
                      <FormControlLabel
                        key={processType.processTypeId}
                        value={processType.processTypeId}
                        label={processType.label}
                        control={<Radio />}
                      />
                    );
                  })}
              </RadioGroup>
            </FormControl>

            <FormControl
              component="fieldset"
              disabled={isProcessing}
            >
              <FormLabel component="legend">Prozess</FormLabel>

              <FormGroup>
                {processTypeIds.length > 0 ? (
                  <>
                    {auth.isStaff() && (
                      <TextField
                        select
                        value={showBlocked ? '1' : '0'}
                        onChange={(ev) => {
                          setProcessIds([]);
                          setShowBlocked(ev.target.value === '1');
                        }}
                        fullWidth
                        size="small"
                        sx={{ mt: 2, minWidth: 180 }}
                      >
                        <MenuItem value="0">{t('Only active')}</MenuItem>
                        <MenuItem value="1">{t('Only blocked')}</MenuItem>{' '}
                      </TextField>
                    )}
                    {availableProcesses.length > 0 ? (
                      <SelectWithFilter
                        values={availableProcesses.map((p) => ({
                          id: p.processId,
                          label: `${p.customer.company} (${p.processId})`,
                        }))}
                        selectedValueIds={processIds}
                        onChange={setProcessIds}
                        fullWidth
                        size="small"
                        sx={{ mt: 2, minWidth: 180 }}
                      />
                    ) : (
                      <Typography
                        variant="body2"
                        color="text.disabled"
                        sx={{ mt: 1 }}
                      >
                        <em>Keine Prozesse verfügbar</em>
                      </Typography>
                    )}
                  </>
                ) : (
                  <Typography
                    variant="body2"
                    color="text.disabled"
                    sx={{ mt: 1 }}
                  >
                    <em>Keine Prozessgruppe ausgewählt</em>
                  </Typography>
                )}
              </FormGroup>
            </FormControl>

            <FormControl
              component="fieldset"
              disabled={isProcessing}
            >
              <FormLabel component="legend">{t('Time period')}</FormLabel>

              <FormGroup>
                {processIds.length > 0 ? (
                  <LocalizationProvider
                    dateAdapter={AdapterLuxon}
                    adapterLocale={i18next.language}
                  >
                    <Stack
                      direction="column"
                      spacing={2}
                      sx={{ mt: 2 }}
                    >
                      <DatePicker
                        disabled={isProcessing}
                        label="Von"
                        value={startDate ? DateTime.fromJSDate(startDate) : null}
                        onChange={(value) => value && setStartDate(value.toJSDate())}
                        slotProps={{ textField: { variant: 'outlined', size: 'small', error: !isValidTimeRange } }}
                        disableFuture
                      />

                      <DatePicker
                        disabled={isProcessing}
                        label="Bis"
                        value={endDate ? DateTime.fromJSDate(endDate) : null}
                        onChange={(value) => value && setEndDate(value.toJSDate())}
                        slotProps={{
                          textField: {
                            variant: 'outlined',
                            size: 'small',
                            error: !isValidTimeRange,
                            helperText: 'Es ist maximal ein Zeitraum von 31 Tagen möglich.',
                          },
                        }}
                        disableFuture
                      />

                      <Stack
                        direction="column"
                        alignItems="flex-start"
                        spacing={1}
                      >
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => {
                            setStartDate(DateTime.now().set({ day: 1 }).toJSDate());
                            setEndDate(new Date());
                          }}
                        >
                          Dieser Monat
                        </Button>

                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => {
                            setStartDate(DateTime.now().minus({ weeks: 4 }).toJSDate());
                            setEndDate(new Date());
                          }}
                        >
                          Letzte 4 Wochen
                        </Button>

                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => {
                            setStartDate(DateTime.now().set({ day: 1 }).minus({ month: 1 }).toJSDate());
                            setEndDate(DateTime.now().minus({ month: 1 }).endOf('month').toJSDate());
                          }}
                        >
                          Letzter Monat
                        </Button>
                      </Stack>
                    </Stack>
                  </LocalizationProvider>
                ) : (
                  <Typography
                    variant="body2"
                    color="text.disabled"
                    sx={{ mt: 1 }}
                  >
                    <em>Kein Prozess ausgewählt</em>
                  </Typography>
                )}
              </FormGroup>
            </FormControl>

            {direction === Direction.INBOUND && (
              <FormControl
                component="fieldset"
                disabled={isProcessing}
              >
                <FormLabel component="legend">{t('State')}</FormLabel>
                <FormGroup>
                  {isValidTimeRange &&
                    processIds.length > 0 &&
                    shipmentStateOptions.map((state) => {
                      return (
                        <FormControlLabel
                          key={state}
                          label={<ShipmentStateLabel state={state} />}
                          control={
                            <Checkbox
                              color="primary"
                              checked={shipmentStates.includes(state)}
                              onChange={(ev) => {
                                const newShipmentStates = ev.target.checked
                                  ? [...shipmentStates, state]
                                  : shipmentStates.filter((s) => s !== state);

                                setShipmentStates(newShipmentStates);
                              }}
                            />
                          }
                        />
                      );
                    })}
                  {(!isValidTimeRange || processIds.length === 0) && (
                    <Typography
                      variant="body2"
                      color="text.disabled"
                      sx={{ mt: 1 }}
                    >
                      <em>Kein Zeitraum ausgewählt</em>
                    </Typography>
                  )}
                </FormGroup>
              </FormControl>
            )}

            <Stack
              direction="column"
              spacing={2}
            >
              <FormControl>
                <FormLabel>{t('Actions')}</FormLabel>
                <FormGroup>
                  <Button
                    sx={{ mt: 1 }}
                    variant="contained"
                    disabled={
                      processIds.length === 0 ||
                      isProcessing ||
                      !isValidTimeRange ||
                      (direction === Direction.INBOUND && shipmentStates.length === 0)
                    }
                    color={direction === Direction.OUTBOUND ? 'outbound' : 'inbound'}
                    startIcon={isProcessing ? <CircularProgress size="1em" /> : <Download />}
                    onClick={() => handleDownload()}
                  >
                    CSV Report
                  </Button>
                </FormGroup>
              </FormControl>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ReportsPage;
