import React from 'react';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { Avatar, Box, CircularProgress, SxProps } from '@mui/material';
import { TourType } from '../../shared/backend';

type Props = {
  tourType: TourType;
  sx?: SxProps;
  isLoading?: boolean;
};

const TourTypeIcon: React.FC<Props> = ({ tourType, sx, isLoading }) => {
  const avatar =
    tourType === TourType.TOUR ? (
      <Avatar sx={{ bgcolor: isLoading ? 'outbound.light' : 'outbound.main', ...sx }}>
        <KeyboardDoubleArrowRightIcon />
      </Avatar>
    ) : (
      <Avatar sx={{ bgcolor: isLoading ? 'inbound.light' : 'inbound.main', ...sx }}>
        <KeyboardDoubleArrowLeftIcon />
      </Avatar>
    );

  return (
    <Box sx={{ position: 'relative' }}>
      {avatar}
      {isLoading && (
        <CircularProgress
          sx={{ position: 'absolute', top: 0, left: 0, zIndex: 10, maxWidth: '100%', maxHeight: '100%' }}
        />
      )}
    </Box>
  );
};

export default TourTypeIcon;
