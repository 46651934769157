import { Login, Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { TextField, InputAdornment, IconButton, Stack } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  onSubmit: (email: string, password: string) => Promise<undefined | false>;
};

const PasswordForm: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [disabled, setDisabled] = useState(false);

  return (
    <form
      onSubmit={(ev) => {
        ev.preventDefault();

        setDisabled(true);

        const formData = new FormData(ev.currentTarget);
        const email = formData.get('email')?.toString() ?? '';
        const password = formData.get('password')?.toString() ?? '';

        props.onSubmit(email, password).then((success) => {
          if (success === false) {
            setDisabled(false);
          }
        });
      }}
    >
      <Stack
        direction="column"
        spacing={2}
      >
        <TextField
          name="email"
          variant="outlined"
          type="email"
          label={t('E-mail')}
          disabled={disabled}
        />

        <TextField
          name="password"
          variant="outlined"
          fullWidth
          label={t('Password')}
          disabled={disabled}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  size="large"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <LoadingButton
          loading={disabled}
          loadingPosition="start"
          startIcon={<Login />}
          type="submit"
          variant="contained"
          color="secondary"
        >
          {t('Log in')}
        </LoadingButton>
      </Stack>
    </form>
  );
};

export default PasswordForm;
