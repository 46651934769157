import {
  Avatar,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNotifications } from '../../hooks/useNotifications';
import { useFidoAuthenticators } from '../../services/user-service/user.service';
import { DateTime } from 'luxon';
import { Clear as ClearIcon, SystemSecurityUpdateGood, VpnKey } from '@mui/icons-material';
import {
  FidoDeviceRegistrationError,
  registerFidoDevice,
  removeFidoDevice,
} from '../../services/authentication-service/authentication.service';
import { useTranslation } from 'react-i18next';

type Props = {};

const FidoForm: React.FC<Props> = () => {
  const { t, i18n } = useTranslation();
  const notification = useNotifications();
  const authenticators = useFidoAuthenticators();
  const [isAddingDevice, setIsAddingDevice] = useState(false);
  const [isWorking, setWorking] = useState(false);
  const [authenticatorIdToBeDeleted, setAuthenticatorIdToBeDeleted] = useState<number | null>(null);

  const DeleteConfirmForm = ({ fidoAuthenticatorId }: { fidoAuthenticatorId: number }) => (
    <form
      onSubmit={(ev) => {
        ev.preventDefault();
        setWorking(true);

        removeFidoDevice(fidoAuthenticatorId)
          .then(() => {
            authenticators.mutate();

            notification.addInfo(t('Security key removed'));
          })
          .catch((error) => {
            notification.addError(error);
          })
          .finally(() => {
            setWorking(false);
          });
      }}
    >
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
      >
        <Button
          size="small"
          type="submit"
          variant="contained"
          color="secondary"
          disabled={isWorking}
        >
          {t('Remove')}
        </Button>
        <IconButton
          onClick={() => setAuthenticatorIdToBeDeleted(null)}
          disabled={isWorking}
        >
          <ClearIcon />
        </IconButton>
      </Stack>
    </form>
  );

  return (
    <>
      <Typography
        variant="h5"
        gutterBottom
      >
        {t('Security key')}
      </Typography>
      <Typography
        variant="body2"
        sx={{ mb: 3 }}
      >
        {t('Instead of using a password, you can also log in to the system with a security key after registration.')}
      </Typography>
      {authenticators.data.length > 0 && (
        <List sx={{ my: 3 }}>
          {authenticators.data.map((authenticator) => (
            <ListItem
              key={authenticator.fidoAuthenticatorId}
              secondaryAction={
                authenticatorIdToBeDeleted === authenticator.fidoAuthenticatorId ? (
                  <DeleteConfirmForm fidoAuthenticatorId={authenticator.fidoAuthenticatorId} />
                ) : (
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => setAuthenticatorIdToBeDeleted(authenticator.fidoAuthenticatorId)}
                    disabled={isWorking}
                  >
                    <DeleteIcon />
                  </IconButton>
                )
              }
            >
              <ListItemAvatar>
                <Avatar>
                  <SystemSecurityUpdateGood />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={`${authenticator.name} (${authenticator.fidoAuthenticatorId})`}
                secondary={t('Last used {{relative}}', {
                  relative: DateTime.fromISO(authenticator.updatedAt).toRelative({ locale: i18n.language }),
                })}
              />
            </ListItem>
          ))}
        </List>
      )}
      {isAddingDevice ? (
        <form
          onSubmit={(ev) => {
            ev.preventDefault();
            setWorking(true);

            const formData = new FormData(ev.currentTarget);

            const name = formData.get('name') as string;
            const password = formData.get('password') as string;

            registerFidoDevice(name, password)
              .then((success) => {
                if (success) {
                  authenticators.mutate();

                  setIsAddingDevice(false);

                  notification.addInfo(t('Security key added'));
                }
              })
              .catch((error) => {
                if (error instanceof FidoDeviceRegistrationError) {
                  notification.addError(error.message);
                } else {
                  notification.addError(error);
                }
              })
              .finally(() => {
                setWorking(false);
              });
          }}
        >
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
          >
            <TextField
              size="small"
              name="password"
              label="Passwort-Kontrolle"
              type="password"
              required
              disabled={isWorking}
              autoComplete="new-password"
            />
            <TextField
              size="small"
              name="device-name"
              label="Gerätename"
              required
              disabled={isWorking}
              autoComplete="false"
            />
            <Button
              size="small"
              type="submit"
              variant="contained"
              color="secondary"
              disabled={isWorking}
            >
              {t('Add')}
            </Button>
            <IconButton
              onClick={() => setIsAddingDevice(false)}
              disabled={isWorking}
            >
              <ClearIcon />
            </IconButton>
          </Stack>
        </form>
      ) : (
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setIsAddingDevice(true)}
          startIcon={<VpnKey />}
          disabled={isWorking}
        >
          {t('Add device')}
        </Button>
      )}
    </>
  );
};

export default FidoForm;
