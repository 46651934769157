import { Alert, AlertTitle } from '@mui/material';
import GradeIcon from '@mui/icons-material/Grade';
import React from 'react';

type Props = {};

const SpecialTreatmentAlert: React.FC<Props> = () => {
  return (
    <Alert
      severity="info"
      icon={<GradeIcon />}
    >
      <AlertTitle>Sonderbehandlung</AlertTitle>
      Ein Prozess hat eine Sonderbehandlung für Retouren.
    </Alert>
  );
};

export default SpecialTreatmentAlert;
