import React, { useState } from 'react';
import DataTable, { TDataTableColumn } from '../../../shared/components/data-table/DataTable';
import { ConsignmentVehicleIdentificationDto, CustomsService } from '../../../services/customs-service/customs.service';
import DeleteButton from '../../../shared/components/delete/DeleteButton';
import { useNotifications } from '../../../hooks/useNotifications';
import { Button, FormControl, Stack, TextField, Typography } from '@mui/material';
import SelectCountryCode from '../../../shared/components/SelectCountryCode';
import { IsoCountryCode } from '../../../shared/backend';
import { Add } from '@mui/icons-material';
import { countryToFlag } from '../../../shared/helper/country';

type Props = {};

const VehicleIdentification: React.FC<Props> = () => {
  const notifications = useNotifications();
  const vehicleIdentification = CustomsService.useVehicleIdentifications();

  const [identification, setIdentification] = useState('');
  const [nationalityCode, setNationalityCode] = useState<IsoCountryCode | ''>('');

  const columns: TDataTableColumn<ConsignmentVehicleIdentificationDto>[] = [
    {
      title: 'Id',
      field: 'consignmentVehicleIdentificationId',
    },
    {
      title: 'Länderkürzel',
      render: ({ nationalityCode }) => (
        <>
          <span>{countryToFlag(nationalityCode)}</span>&nbsp;
          {nationalityCode}
        </>
      ),
    },
    {
      title: 'Kennzeichen',
      field: 'identification',
      cellProps: {
        sx: {
          width: '100%',
        },
      },
    },
    {
      title: '',
      render: (row) => (
        <DeleteButton
          onClick={async () => {
            try {
              await CustomsService.deleteVehicleIdentificationById(row.consignmentVehicleIdentificationId);

              vehicleIdentification.mutate();

              notifications.addSuccess('Fahrzeug gelöscht');
            } catch (error) {
              notifications.addError(error);
            }
          }}
        />
      ),
      cellProps: {
        align: 'right',
      },
    },
  ];

  return (
    <>
      <Typography
        variant="h5"
        mb={3}
      >
        Fahrzeugidentifikationen
      </Typography>

      <DataTable
        columns={columns}
        rows={vehicleIdentification.data.sort((a, b) => (a.nationalityCode > b.nationalityCode ? 1 : -1))}
        getRowKey={(row) => row.consignmentVehicleIdentificationId}
      />

      <form
        onSubmit={async (ev) => {
          ev.preventDefault();

          if (!identification || !nationalityCode) {
            return;
          }

          try {
            await CustomsService.createVehicleIdentification({
              identification,
              nationalityCode,
            });

            vehicleIdentification.mutate();

            setIdentification('');
            setNationalityCode('');

            notifications.addSuccess('Fahrzeug hinzugefügt');
          } catch (error) {
            notifications.addError(error);
          }
        }}
      >
        <Stack
          sx={{ mt: 3 }}
          direction="row"
          spacing={1}
        >
          <TextField
            sx={{ flexGrow: 1 }}
            required
            size="small"
            label="Kennzeichen"
            variant="outlined"
            value={identification}
            onChange={(ev) => setIdentification(ev.target.value)}
          />

          <FormControl>
            <SelectCountryCode
              sx={{ minWidth: 80 }}
              required
              size="small"
              value={nationalityCode}
              onChange={(ev) => setNationalityCode(ev.target.value as IsoCountryCode)}
              commonCountryCodes={[IsoCountryCode.DE, IsoCountryCode.CH, IsoCountryCode.GB, IsoCountryCode.NL]}
            />
          </FormControl>

          <Button
            size="small"
            variant="contained"
            type="submit"
            startIcon={<Add />}
          >
            Hinzufügen
          </Button>
        </Stack>
      </form>
    </>
  );
};

export default VehicleIdentification;
