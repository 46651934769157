import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { ConfirmDialog, confirmable } from 'react-confirm';
import { useUserInfo } from '../../services/user-service/user.service';
import { ReauthenticationForm } from '../../pages/login/components/ReauthenticationForm';
import { createConfirmation } from '../confirm-dialog';
import { DateTime } from 'luxon';

export enum ReauthTimeInMinutes {
  DEFAULT = 30,
  EDIT_PROCESS = 480,
  DECRYPT_CONFIG = 0,
}

type Props = {
  time: ReauthTimeInMinutes;
};

const ReAuthenticationDialog: ConfirmDialog<Props, boolean> = ({ time, proceed, show, dismiss }) => {
  const userInfo = useUserInfo();

  const lastAuthenticated = userInfo.data?.lastAuthenticated;
  const isAlreadyAuthenticated =
    lastAuthenticated &&
    DateTime.fromISO(lastAuthenticated).toMillis() >
      DateTime.now()
        .minus((time as number) === 0 ? { seconds: 30 } : { minutes: time })
        .toMillis();

  useEffect(() => {
    if (isAlreadyAuthenticated) {
      proceed(true);
    }
  }, [isAlreadyAuthenticated, proceed]);

  if (isAlreadyAuthenticated) {
    return <></>;
  }

  return (
    <Dialog
      open={show}
      onClose={() => dismiss()}
      PaperProps={{
        style: {
          maxWidth: 400,
          margin: 0,
        },
      }}
    >
      <DialogTitle
        textAlign="center"
        fontWeight={550}
        fontSize={16}
      >
        Reauthentifizierung benötigt
      </DialogTitle>

      <DialogContent>
        <Typography paddingY={3}>
          Da der letzte Login bereits einige Zeit zurückliegt, muss das Passwort für den Account{' '}
          <strong>{userInfo.data?.email}</strong> bestätigt werden.
        </Typography>
        <ReauthenticationForm onReauthSuccess={() => proceed(true)} />
      </DialogContent>
    </Dialog>
  );
};

const reAuthenticationConfirmation = createConfirmation(confirmable(ReAuthenticationDialog));

export const assertAuthentication = async (time: ReauthTimeInMinutes = ReauthTimeInMinutes.DEFAULT): Promise<void> => {
  if (!(await reAuthenticationConfirmation({ time }))) {
    throw new Error('Reauthentication failed. All changes were rejected.');
  }
};
