import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

interface ILastReturn {
  shipmentId: number;
  processedAt: Date;
}

const lastReturnAtom = atomWithStorage<null | ILastReturn>('view:last-return', null);

export const useLastReturn = (): [null | ILastReturn, (value: ILastReturn | null) => void] => {
  const [get, set] = useAtom(lastReturnAtom);

  return [get ? { shipmentId: get.shipmentId, processedAt: new Date(get.processedAt) } : null, set];
};
