import React from 'react';
import { ShipmentState } from '../../backend';
import { useTranslation } from 'react-i18next';

type Props = {
  state: ShipmentState;
};

const ShipmentStateLabel: React.FC<Props> = ({ state }) => {
  const { t } = useTranslation();

  const shipmentStateLabels: Record<ShipmentState, string> = {
    [ShipmentState.RETOURE_ANNOUNCED]: t('Announced'),
    [ShipmentState.RETOURE_TOUR_READY]: t('Processed'),
    [ShipmentState.RETOURE_TOUR_ASSIGNED]: t('Tour assigned'),
    [ShipmentState.RETOURE_TOUR_CLOSED]: t('Completed'),
    [ShipmentState.RETOURE_REJECTED]: t('Rejected'),
    [ShipmentState.TO_BE_CLARIFIED]: t('To be clarified'),
    [ShipmentState.DISPOSED]: t('Disposed'),
    [ShipmentState.NEW]: t('New'),
    [ShipmentState.LABEL_PRINTED]: t('Processed'),
    [ShipmentState.TOUR_ASSIGNED]: t('Tour assigned'),
    [ShipmentState.CLOSED]: t('Completed'),
    [ShipmentState.REJECTED]: t('Rejected'),
  };

  return <>{shipmentStateLabels[state]}</>;
};

export default ShipmentStateLabel;
