import { IsoCountryCode } from '../backend';

/** This is from customs. */
export const UNKNOWN_COUNTRY_CODE = 'QU';

export const defaultCommonCountryCodes = [
  IsoCountryCode.DE,
  IsoCountryCode.CH,
  IsoCountryCode.GB,
  IsoCountryCode.FR,
  IsoCountryCode.AT,
];

export function countryToFlag(isoCode: string | undefined): string {
  if (!isoCode) {
    return '';
  }

  if (isoCode === UNKNOWN_COUNTRY_CODE) {
    return '👽';
  }

  if (isoCode === '*') {
    return '🌐';
  }

  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode.toUpperCase().replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
    : isoCode;
}
