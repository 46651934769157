import { CircularProgress, IconButton } from '@mui/material';
import React, { useState } from 'react';
import { useNotifications } from '../../hooks/useNotifications';
import { TourDto } from '../../services/tour-service/dto/tour.dto';
import { Lock, LockOpen } from '@mui/icons-material';
import { TourService } from '../../services/tour-service/tour.service';
import { TourState } from '../../shared/backend';

type Props = {
  tour: TourDto;
  mutate: () => Promise<void>;
};

const CloseOpenIconButton: React.FC<Props> = ({ tour, mutate }) => {
  const notificationHandler = useNotifications();
  const [isLoading, setLoading] = useState(false);

  const handleCloseTour = async () => {
    setLoading(true);

    try {
      await TourService.closeTour(tour.tourId);

      await mutate();
      notificationHandler.addInfo('Tour wurde erfolgreich geschlossen');
    } catch (error) {
      notificationHandler.addError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenTour = async () => {
    setLoading(true);

    try {
      await TourService.openTour(tour.tourId);

      mutate();
    } catch (error) {
      notificationHandler.addError(error);
    } finally {
      setLoading(false);
    }
  };

  if (isLoading) {
    return <CircularProgress size="1.5em" />;
  }

  return tour.state === TourState.OPEN ? (
    <IconButton
      onClick={() => handleCloseTour()}
      color="secondary"
    >
      <LockOpen />
    </IconButton>
  ) : (
    <IconButton
      onClick={() => handleOpenTour()}
      color="primary"
    >
      <Lock />
    </IconButton>
  );
};

export default CloseOpenIconButton;
