import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { CustomsService, ShipmentDutiesAndVatDto } from '../../services/customs-service/customs.service';
import { useDateParam, useIntListParam, useIntParam, useStringParam } from '../../hooks/useParam';
import { DateTime } from 'luxon';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import DutiesAndVatTable, { AmountOfMoneyDtoWithPreliminaryFlagDto } from './DutiesAndVatTable';
import Download from '@mui/icons-material/Download';
import SearchIcon from '@mui/icons-material/Search';
import fileDownload from 'js-file-download';
import { StreamParser } from '@json2csv/plainjs';
import { useTranslation } from 'react-i18next';
import i18next, { TFunction } from 'i18next';
import { useNotifications } from '../../hooks/useNotifications';
import _ from 'lodash';
import { ProcessTypeService } from '../../services/process-type-service/process-type.service';
import { ProcessService } from '../../services/process-service/process.service';
import SelectWithFilter from '../../shared/components/SelectWithFilter';

export const getAmountString = (
  amount: AmountOfMoneyDtoWithPreliminaryFlagDto | null,
  translationFunction?: TFunction,
): string => {
  const preliminary = translationFunction ? translationFunction('PRELIMINARY') : 'PRELIMINARY';
  const notDeterminable = translationFunction ? translationFunction('NOT DETERMINABLE') : 'NOT DETERMINABLE';

  return !!amount
    ? `${amount.value?.toFixed(2)} ${amount.currencyIso?.toUpperCase()}${amount.isPreliminary ? ` (${preliminary})` : ''}`
    : notDeterminable;
};

export const getCustomsClearanceTypeString = (
  isTourDeclaration: boolean,
  tourId: number,
  translationFunction?: TFunction,
): string => {
  const collective = translationFunction ? translationFunction('COLLECTIVE') : 'COLLECTIVE';
  const individual = translationFunction ? translationFunction('INDIVIDUAL') : 'INDIVIDUAL';

  return isTourDeclaration ? `${collective} (${tourId})` : individual;
};

interface ICsvLine {
  company: string;
  date: string;
  consigneeName: string;
  destinationCountry: string;
  packetIdentifier: string;
  customsClearanceType: string;
  customsRegistrationNumber: string;
  customsDuties: string;
  importVat: string;
  declarationState: string;
}

const csvFields: (keyof ICsvLine)[] = [
  'company',
  'date',
  'consigneeName',
  'destinationCountry',
  'packetIdentifier',
  'customsClearanceType',
  'customsRegistrationNumber',
  'customsDuties',
  'importVat',
  'declarationState',
] as const;

const mapDataToCsvLine = (shipmentDutiesAndVatDto: ShipmentDutiesAndVatDto): ICsvLine => ({
  company: shipmentDutiesAndVatDto.company,
  date: shipmentDutiesAndVatDto.date.toString(),
  consigneeName: shipmentDutiesAndVatDto.consigneeName,
  destinationCountry: shipmentDutiesAndVatDto.destinationCountry,
  packetIdentifier: shipmentDutiesAndVatDto.identifier,
  customsRegistrationNumber: shipmentDutiesAndVatDto.customsRegistrationNumber,
  customsClearanceType: getCustomsClearanceTypeString(
    shipmentDutiesAndVatDto.isTourDeclaration,
    shipmentDutiesAndVatDto.tourId,
  ),
  customsDuties: getAmountString(shipmentDutiesAndVatDto.customsDuties),
  importVat: getAmountString(shipmentDutiesAndVatDto.importVat),
  declarationState: shipmentDutiesAndVatDto.statusCode,
});

const getFilename = (query: { start?: Date; end?: Date; id?: string }): string => {
  const tail = [
    query.start ? DateTime.fromJSDate(query.start).toISODate() : undefined,
    query.end ? DateTime.fromJSDate(query.end).toISODate() : undefined,
    query.id,
  ]
    .filter((s) => !!s)
    .join('_');

  return `duties-and-vat${tail ? `_${tail}` : ''}.csv`;
};

const downloadCsv = async (
  processIds: number[],
  query: {
    fromDate?: Date;
    toDate?: Date;
    id?: string;
  },
  setDownloadProgressPercentage: (value: number | undefined) => void,
  isActive: () => boolean,
): Promise<void> => {
  const limit = 100;
  let offset = 0;
  let csv: string = '';

  const { fromDate, toDate, id } = query;

  const parser = new StreamParser({ fields: csvFields, delimiter: ';', transforms: [mapDataToCsvLine] });

  parser.onData = (chunk) => (csv += chunk);

  parser.onEnd = () => {
    if (isActive()) {
      fileDownload(new Blob([csv]), getFilename(query), 'text/csv');
    }

    setDownloadProgressPercentage(undefined);
  };

  parser.onError = (err) => {
    setDownloadProgressPercentage(undefined);
    throw err;
  };

  const options = {
    fromDate: fromDate?.toISOString(),
    toDate: toDate?.toISOString(),
    identifier: id,
    processIds,
    limit,
  };

  let res = await CustomsService.getDutiesAndVat({ ...options, offset: 0 });

  res.data.forEach((line) => parser.pushLine(line));

  const { count } = res.meta;

  for (let i = offset + limit; i <= count; i += limit) {
    if (!isActive()) {
      return;
    }

    res = await CustomsService.getDutiesAndVat({ ...options, offset: i });

    setDownloadProgressPercentage(Math.floor((i / count) * 100));

    res.data.forEach((line) => parser.pushLine(line));
  }

  parser.end();
};

type Props = {};

const DutiesAndVatPage: React.FC<Props> = () => {
  const { t } = useTranslation();
  const notifications = useNotifications();
  const processTypes = ProcessTypeService.useProcessTypes();
  const processes = ProcessService.useProcesses();

  const [page, setPage] = useIntParam('page', 0);
  const [pageSize] = useIntParam('pageSize', 100);

  const [selectedIdentifier, setSelectedIdentifier] = useStringParam('identifier', '');
  const [selectedFromDate, setSelectedFromDate] = useDateParam('from');
  const [selectedToDate, setSelectedToDate] = useDateParam('to');
  const [selectedProcessTypeIds, setSelectedProcessTypeIds] = useIntListParam('processTypeIds', []);
  const [selectedProcessIds, setSelectedProcessIds] = useIntListParam('processIds', []);

  const [fromDate, setFromDate] = useState<Date | undefined>(selectedFromDate?.toJSDate());
  const [toDate, setToDate] = useState<Date | undefined>(selectedToDate?.toJSDate());
  const [identifier, setIdentifier] = useState<string | undefined>(selectedIdentifier || undefined);
  const [processIds, setProcessIds] = useState<number[]>([]);

  const [downloadProgressPercentage, setDownloadProgressPercentage] = useState<number | undefined>();

  const [isProcessing, setProcessing] = useState(false);
  const isActive = useRef(false);

  const dutiesAndVats = CustomsService.useDutiesAndVat({
    limit: pageSize,
    offset: page * pageSize,
    processIds,
    fromDate: fromDate?.toISOString(),
    toDate: toDate?.toISOString(),
    identifier,
  });

  const isValidTimeRange =
    !!selectedFromDate &&
    !!selectedToDate &&
    selectedFromDate <= selectedToDate &&
    selectedToDate.diff(selectedFromDate).as('days') <= 31;
  const isIdentifierDirty = selectedIdentifier !== (identifier ?? '');
  const isStartDateDirty = !(
    (!fromDate && !selectedFromDate) ||
    !!(fromDate && selectedFromDate?.equals(DateTime.fromJSDate(fromDate)))
  );
  const isEndDateDirty = !(
    (!toDate && !selectedToDate) ||
    !!(toDate && selectedToDate?.equals(DateTime.fromJSDate(toDate)))
  );
  const isProcessIdsDirty = !_.isEqual(processIds, selectedProcessIds);

  const filterProcessByProcessTypes = (ids: number[]) => {
    return processes.data.filter((process) => ids.includes(process.processTypeId) && !process.blocked);
  };

  const availableProcesses = filterProcessByProcessTypes(selectedProcessTypeIds);

  const handleSearch = () => {
    const from = selectedFromDate?.toJSDate();
    const to = selectedToDate?.toJSDate();

    setFromDate(from);
    setToDate(to);
    setIdentifier(selectedIdentifier || undefined);
    setProcessIds(selectedProcessIds);
    setPage(0);
  };

  const handleDownload = () => {
    const from = selectedFromDate?.toJSDate();
    const to = selectedToDate?.toJSDate();
    const id = selectedIdentifier || undefined;

    setProcessing(true);

    downloadCsv(
      selectedProcessIds,
      {
        fromDate: from,
        toDate: to,
        id,
      },
      setDownloadProgressPercentage,
      () => isActive.current,
    )
      .then(() => {
        if (isActive.current) {
          notifications.addSuccess(t('Download successfully completed'));
        }
      })
      .catch((err) => notifications.addError(err))
      .finally(() => setProcessing(false));
  };

  const setTimeFrame = ({ from, to }: { from?: DateTime | null; to?: DateTime | null }) => {
    if (from) {
      setSelectedFromDate(from);
    }

    if (to) {
      setSelectedToDate(to);
    }

    if (from === null || to === null) {
      setSelectedFromDate(undefined);
      setSelectedToDate(undefined);
    }
  };

  useEffect(() => {
    isActive.current = true;

    return () => {
      isActive.current = false;
    };
  }, []);

  return (
    <Paper sx={{ px: 2, py: 3 }}>
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
        >
          <Typography
            variant="h5"
            gutterBottom
          >
            {t('Duties & VAT')}
          </Typography>
          <Typography
            variant="body1"
            mb={3}
          >
            {t(
              'Here you can view and download information on the customs duties and VAT for your shipments and download it.',
            )}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            spacing={3}
            flexWrap="wrap"
            justifyContent="space-between"
            sx={{ borderTop: '1px solid', borderColor: 'divider', pt: 3 }}
          >
            <FormControl
              component="fieldset"
              disabled={isProcessing}
            >
              <FormLabel component="legend">{t('Process group')}</FormLabel>

              <RadioGroup
                aria-labelledby="direction"
                value={selectedProcessTypeIds[0] ?? ''}
                onChange={(ev) => {
                  const newProcessTypeId = parseInt(ev.target.value, 10);
                  setSelectedProcessTypeIds([newProcessTypeId]);

                  const newAvailableProcesses = filterProcessByProcessTypes([newProcessTypeId]);

                  if (newAvailableProcesses.length === 1) {
                    setSelectedProcessIds(newAvailableProcesses.map(({ processId }) => processId));
                  } else {
                    setSelectedProcessIds([]);
                  }
                }}
              >
                {processTypes.data
                  .sort((a, b) => a.label.localeCompare(b.label))
                  .map((processType) => {
                    return (
                      <FormControlLabel
                        key={processType.processTypeId}
                        value={processType.processTypeId}
                        label={processType.label}
                        control={<Radio />}
                      />
                    );
                  })}
              </RadioGroup>
            </FormControl>

            <FormControl
              component="fieldset"
              disabled={isProcessing}
            >
              <FormLabel component="legend">{t('Process')}</FormLabel>

              <FormGroup>
                {availableProcesses.length > 0 ? (
                  <SelectWithFilter
                    values={availableProcesses.map((p) => ({
                      id: p.processId,
                      label: `${p.customer.company} (${p.processId})`,
                    }))}
                    selectedValueIds={selectedProcessIds}
                    onChange={setSelectedProcessIds}
                    fullWidth
                    size="small"
                    sx={{ mt: 2, minWidth: 180 }}
                  />
                ) : (
                  <Typography
                    variant="body2"
                    color="text.disabled"
                    sx={{ mt: 1 }}
                  >
                    <em>{t('No process group selected')}</em>
                  </Typography>
                )}
              </FormGroup>
            </FormControl>

            <FormControl
              component="fieldset"
              disabled={isProcessing}
            >
              <FormLabel component="legend">{t('Time period')}</FormLabel>

              <FormGroup>
                {selectedProcessIds.length > 0 ? (
                  <LocalizationProvider
                    dateAdapter={AdapterLuxon}
                    adapterLocale={i18next.language}
                  >
                    <Stack
                      direction="column"
                      spacing={2}
                      sx={{ mt: 2 }}
                    >
                      <DatePicker
                        disabled={isProcessing}
                        label={t('From')}
                        value={selectedFromDate ? selectedFromDate : null}
                        onChange={(value) => {
                          value && setTimeFrame({ from: value });
                        }}
                        slotProps={{
                          textField: {
                            variant: 'outlined',
                            size: 'small',
                            error: !((!selectedFromDate && !selectedToDate) || isValidTimeRange),
                          },
                          field: {
                            clearable: true,
                            onClear: () => {
                              setTimeFrame({ from: null });
                            },
                          },
                        }}
                        disableFuture
                      />

                      <DatePicker
                        disabled={isProcessing}
                        label={t('To')}
                        value={selectedToDate ? selectedToDate : null}
                        onChange={(value) => {
                          value && setTimeFrame({ to: value });
                        }}
                        slotProps={{
                          textField: {
                            variant: 'outlined',
                            size: 'small',
                            error: !((!selectedFromDate && !selectedToDate) || isValidTimeRange),
                            helperText: t('A maximum period of 31 days is possible.'),
                          },
                          field: {
                            clearable: true,
                            onClear: () => {
                              setTimeFrame({ to: null });
                            },
                          },
                        }}
                        disableFuture
                      />

                      <Stack
                        direction="column"
                        alignItems="flex-start"
                        spacing={1}
                      >
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => {
                            setTimeFrame({
                              from: DateTime.now().set({ day: 1 }).startOf('day'),
                              to: DateTime.now().endOf('day'),
                            });
                          }}
                        >
                          {t('This month')}
                        </Button>

                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => {
                            setTimeFrame({
                              from: DateTime.now().minus({ weeks: 4 }).startOf('day'),
                              to: DateTime.now().endOf('day'),
                            });
                          }}
                        >
                          {t('Last 4 weeks')}
                        </Button>

                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => {
                            setTimeFrame({
                              from: DateTime.now().set({ day: 1 }).minus({ month: 1 }),
                              to: DateTime.now().minus({ month: 1 }).endOf('month'),
                            });
                          }}
                        >
                          {t('Last month')}
                        </Button>
                      </Stack>
                    </Stack>
                  </LocalizationProvider>
                ) : (
                  <Typography
                    variant="body2"
                    color="text.disabled"
                    sx={{ mt: 1 }}
                  >
                    <em>{t('No processes selected')}</em>
                  </Typography>
                )}
              </FormGroup>
            </FormControl>

            <FormControl
              component="fieldset"
              disabled={isProcessing}
            >
              <FormLabel component="legend">{t('Search package identifier')}</FormLabel>
              <FormGroup>
                {isValidTimeRange && selectedProcessIds.length > 0 && (
                  <TextField
                    size="small"
                    disabled={isProcessing}
                    variant={'outlined'}
                    sx={{ mt: 2 }}
                    value={selectedIdentifier}
                    onChange={(ev) => {
                      setSelectedIdentifier(ev.target.value);
                    }}
                    placeholder={t('Optional identifier')}
                  />
                )}
                {(!isValidTimeRange || selectedProcessIds.length === 0) && (
                  <Typography
                    variant="body2"
                    color="text.disabled"
                    sx={{ mt: 1 }}
                  >
                    <em>{t('No time period selected')}</em>
                  </Typography>
                )}
              </FormGroup>
            </FormControl>

            <FormControl>
              <FormLabel>{t('Actions')}</FormLabel>
              <FormGroup>
                <Stack
                  direction="column"
                  spacing={1}
                  minWidth={250}
                  sx={{ mt: 2 }}
                >
                  <Button
                    variant="contained"
                    disabled={
                      isProcessing ||
                      !selectedProcessIds.length ||
                      (!selectedIdentifier && !isValidTimeRange) ||
                      (!isIdentifierDirty && !isStartDateDirty && !isEndDateDirty && !isProcessIdsDirty)
                    }
                    startIcon={dutiesAndVats.isLoading ? <CircularProgress size="1em" /> : <SearchIcon />}
                    onClick={() => handleSearch()}
                  >
                    {t('Search')}
                  </Button>

                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={isProcessing || !selectedProcessIds.length || (!selectedIdentifier && !isValidTimeRange)}
                    startIcon={isProcessing ? <CircularProgress size="1em" /> : <Download />}
                    onClick={() => handleDownload()}
                  >
                    {`${t('CSV Document')}${downloadProgressPercentage ? ` (${downloadProgressPercentage} %)` : ''}`}
                  </Button>
                </Stack>
              </FormGroup>
            </FormControl>
          </Stack>
        </Grid>

        {(!!dutiesAndVats.data?.data || dutiesAndVats.isLoading) && (
          <Grid
            item
            xs={12}
          >
            <DutiesAndVatTable
              dutiesAndVat={dutiesAndVats.data || { data: [], meta: { count: 0 } }}
              isLoading={dutiesAndVats.isLoading}
            />
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default DutiesAndVatPage;
