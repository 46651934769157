import React from 'react';
import DataTable, { TDataTableColumn } from '../../../shared/components/data-table/DataTable';
import DeleteButton from '../../../shared/components/delete/DeleteButton';
import { useNotifications } from '../../../hooks/useNotifications';
import { Button, CircularProgress, IconButton, InputAdornment, Stack, Typography } from '@mui/material';
import { Add, Clear } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import FormTextField from '../../../shared/components/react-hook-form/FormTextField';
import { CustomsService } from '../../../services/customs-service/customs.service';

type Props = {};

const GoodsDescription: React.FC<Props> = () => {
  const notifications = useNotifications();
  const descriptions = CustomsService.useGoodsDescription();

  const form = useForm<{ description: string }>({
    mode: 'onTouched',
  });

  const columns: TDataTableColumn<{ index: number; description: string }>[] = [
    {
      title: '#',
      field: 'index',
    },
    {
      title: 'Beschreibung',
      field: 'description',
      cellProps: { sx: { width: '100%' } },
    },
    {
      title: '',
      render: (row) => (
        <DeleteButton
          onClick={async () => {
            try {
              const values = descriptions.data.filter((d) => d !== row.description);
              await CustomsService.updateGoodsDescription(values);

              descriptions.mutate(values);

              notifications.addSuccess('Beschreibung gelöscht');
            } catch (error) {
              notifications.addError(error);
            }
          }}
        />
      ),
      cellProps: {
        align: 'right',
      },
    },
  ];

  return (
    <>
      <Typography
        variant="h5"
        mb={3}
      >
        Warenbeschreibung
      </Typography>

      <DataTable
        columns={columns}
        rows={descriptions.data.map((description, index) => ({ description, index: index + 1 }))}
        getRowKey={(row) => `${row.index}-${row.description}`}
      />

      <form
        onSubmit={form.handleSubmit(async (data) => {
          try {
            const values = [...descriptions.data, data.description];

            await CustomsService.updateGoodsDescription(values);

            descriptions.mutate(values);

            form.reset();

            notifications.addSuccess('Beschreibung hinzugefügt');
          } catch (error) {
            notifications.addError(error);
          }
        })}
        noValidate
      >
        <Stack
          sx={{ mt: 3 }}
          spacing={1}
          direction="row"
          justifyContent="flex-end"
        >
          <FormTextField
            name="description"
            label="Beschreibung"
            size="small"
            required
            sx={{ flexGrow: 1 }}
            control={form.control}
            InputProps={{
              endAdornment: form.formState.isDirty ? (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    color="secondary"
                    onClick={() => form.reset()}
                    edge="end"
                  >
                    <Clear fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ) : undefined,
            }}
          />

          <Button
            size="small"
            variant="contained"
            type="submit"
            startIcon={form.formState.isSubmitting ? <CircularProgress sx={{ fontSize: '1em' }} /> : <Add />}
            disabled={!form.formState.isValid || form.formState.isSubmitting}
          >
            Hinzufügen
          </Button>
        </Stack>
      </form>
    </>
  );
};

export default GoodsDescription;
