import React, { useEffect, useState } from 'react';
import * as AuthService from '../../services/authentication-service/authentication.service';
import { Alert, Box, CircularProgress } from '@mui/material';
import { Navigate } from 'react-router-dom';
import { mutate } from 'swr';
import axios, { HttpStatusCode } from 'axios';
import { clearSessionStorageProvider } from '../../contexts/SWRProvider';

type Props = {};

const LogoutPage: React.FC<Props> = () => {
  const [done, setDone] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const logOut = async () => {
      try {
        await AuthService.logOut();

        // This is from the official documentation to clear all data
        mutate(() => true, undefined, { revalidate: false });

        clearSessionStorageProvider();

        setTimeout(() => setDone(true), 1000);
      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.status === HttpStatusCode.Unauthorized) {
          setTimeout(() => setDone(true), 1000);

          return;
        }

        setError(error instanceof Error ? error.toString() : 'Error during logout process');
      }
    };

    // for some reason this page was rendered twice
    const rerenderProtection = window.setTimeout(() => logOut(), 100);

    return () => window.clearTimeout(rerenderProtection);
  }, []);

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: {
          xs: 'flex-end',
          sm: 'center',
        },
        alignItems: 'center',
        flexDirection: 'column',
        backgroundImage: `linear-gradient(45deg,#1a294a 60%,#114688)`,
        backgroundRepeat: 'no-repeat',
      }}
    >
      {done && <Navigate to="/login" />}
      {!done && !error && (
        <CircularProgress
          color="secondary"
          size="5em"
        />
      )}
      {error && <Alert severity="warning">{error}</Alert>}
    </Box>
  );
};

export default LogoutPage;
