import { Chip, FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import React, { FC, useRef, useEffect, useState } from 'react';
import * as CarrierOptionService from '../../../../../../services/carrier-option-service/carrierOption.service';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const getInitialCarrierOptionsViewState = (): CarrierOptionsViewState => {
  return {
    selectedOptions: [],
  };
};

interface CarrierOptionsViewState {
  selectedOptions: string[];
}

export interface CarrierOptionsViewProps {
  carrierOptions: CarrierOptionService.CarrierOptionDto[];
  availableCarrierOptions: string[];
  onCarrierOptionCreate: (
    body: CarrierOptionService.CreateCarrierOptionDto,
  ) => Promise<CarrierOptionService.CarrierOptionDto | undefined>;
  onCarrierOptionDelete: (carrierOptionId: number) => Promise<true | undefined>;
  disabled?: boolean;
}

const CarrierOptionsView: FC<CarrierOptionsViewProps> = (props: CarrierOptionsViewProps) => {
  const [selectedOptions, setSelectedOptions] = useState<CarrierOptionsViewState['selectedOptions']>(
    getInitialCarrierOptionsViewState().selectedOptions,
  );

  /**
   * !!! The following algorithm only works, because the names of the carrierOptions
   * Entity are always unique !!!
   *
   * Because the Select Component isn't capable of holding complex
   * objects as values it is necessary to compare the state "selectedOptions"
   * (which is an array of only optionNames) with the names
   * of the actual props.carrierOptions (which is
   * the correct representation of the carrierOption Entity)
   * In order to determine wheter an option should be deleted or created we
   * additionally need to check, if the selectedOptions array is larger or smaller
   * in comparison to the new array provided by the ChangeEvent
   */
  const handleChange = async (newSelectedOptions: string[]) => {
    let prevSelectedOptions = [...selectedOptions];

    //a carrierOption needs to be created on the database
    //therefore we take the last element that was added to the selection
    if (newSelectedOptions.length > selectedOptions.length) {
      const newCarrierOption: CarrierOptionService.CarrierOptionDto | undefined = await props.onCarrierOptionCreate({
        name: newSelectedOptions[newSelectedOptions.length - 1],
      });
      if (newCarrierOption) {
        setSelectedOptions(newSelectedOptions);
      }
    }

    //a carrierOption needs to be deleted from the database
    //Therefore we need to compare the prev with the actual state
    //to determine, which value was deleted
    //after that we find the corresponding value from the props to get the actual id
    else {
      const carrierOptionMatch = prevSelectedOptions.find((option) => !newSelectedOptions.includes(option));

      if (carrierOptionMatch) {
        const carrierOptionToDelete: CarrierOptionService.CarrierOptionDto | undefined =
          findPropCarrierOptionByOptionName(carrierOptionMatch);
        if (carrierOptionToDelete) {
          const deletedCarrierOption = await props.onCarrierOptionDelete(carrierOptionToDelete.carrierOptionId);
          if (deletedCarrierOption) {
            setSelectedOptions(newSelectedOptions);
          }
        }
      }
    }
  };

  const findPropCarrierOptionByOptionName = (name: string): CarrierOptionService.CarrierOptionDto | undefined => {
    return props.carrierOptions.find((option) => option.name === name);
  };

  const carrierOptionsDidChange = useRef(() => {});
  carrierOptionsDidChange.current = async () => {
    let newSelectedOptions: string[] = [];
    for (const option of props.carrierOptions) {
      newSelectedOptions.push(option.name);
    }
    setSelectedOptions(newSelectedOptions);
  };

  useEffect(() => {
    carrierOptionsDidChange.current();
  }, [carrierOptionsDidChange, props.carrierOptions.length]);

  return (
    <FormControl
      variant="outlined"
      sx={{ minWidth: 120 }}
      fullWidth
    >
      <InputLabel id="carrier-options-view-label">Versandoptionen</InputLabel>
      <Select
        label="Versandoptionen"
        labelId="carrier-options-view-label"
        multiple
        size="small"
        value={selectedOptions}
        onChange={(event) => handleChange(event.target.value as string[])}
        disabled={!!props.disabled}
        renderValue={(selected) => (
          <Stack
            direction="row"
            spacing={1}
          >
            {selected.map((value) => (
              <Chip
                key={value}
                label={value}
                size="small"
              />
            ))}
          </Stack>
        )}
        MenuProps={MenuProps}
        sx={{ minHeight: 54 }}
      >
        {props.availableCarrierOptions.map((option) => (
          <MenuItem
            key={option}
            value={option}
          >
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CarrierOptionsView;
