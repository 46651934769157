import { MenuItem, TextField, TextFieldProps } from '@mui/material';
import React from 'react';
import { Control, Controller, FieldPath, FieldPathValue, FieldValues, RegisterOptions } from 'react-hook-form';
import { AddressDto } from '../../../services/address-service/address.service';

export const getDisplayedAddressOption = (address: AddressDto) => {
  const fields = [address.name, address.street, address.zip + ' ' + address.city];
  if (fields.every((field) => !field?.trim())) {
    return address.addressId;
  } else {
    return fields.filter((field) => !!field.trim()).join(', ');
  }
};

type Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<TextFieldProps, 'onChange' | 'value' | 'error'> & {
  name: TName;
  rules?: Omit<RegisterOptions<TFieldValues, TName>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
  defaultValue?: FieldPathValue<TFieldValues, TName>;
  control?: Control<TFieldValues>;
  addresses: AddressDto[];
};

const FormSelectAddress = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  rules,
  defaultValue,
  control,
  addresses,
  ...textFieldProps
}: Props<TFieldValues, TName>) => {
  return (
    <Controller
      name={name}
      rules={{ required: textFieldProps.required, ...rules }}
      defaultValue={defaultValue}
      control={control}
      render={({ field: { name, onChange, value }, fieldState: { error } }) => (
        <TextField
          name={name}
          error={!!error}
          onChange={onChange}
          value={value ?? ''}
          {...textFieldProps}
          select
          helperText={error?.message ?? textFieldProps.helperText}
        >
          {!!value && !addresses.find(({ addressId }) => addressId === value) && (
            <MenuItem value={value}>{value}</MenuItem>
          )}
          {addresses.map((address) => (
            <MenuItem
              key={address.addressId}
              value={address.addressId}
            >
              {getDisplayedAddressOption(address)}
            </MenuItem>
          ))}
        </TextField>
      )}
    />
  );
};

export default FormSelectAddress;
