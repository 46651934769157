import { Info } from '@mui/icons-material';
import {
  Grid,
  FormControl,
  Checkbox,
  TextField,
  FormControlLabel,
  InputAdornment,
  Tooltip,
  Box,
  MenuItem,
} from '@mui/material';
import React from 'react';
import { JsonEditor } from '../../../../../shared/components/json-editor/JsonEditor';
import { SchemaObject } from 'openapi3-ts/dist/oas31';

type Props = {
  credentialSchema: SchemaObject;
  credential: Record<string, unknown>;
  disabled?: boolean;
  onCredentialChange: (credential: Record<string, unknown>) => Promise<boolean>;
};

type SchemaObjectType = 'integer' | 'number' | 'string' | 'boolean' | 'object' | 'null' | 'array';

const CredentialEditor: React.FC<Props> = ({ credentialSchema, credential, disabled, onCredentialChange }) => {
  const fields = {
    boolean: (name: string, value: unknown, options: SchemaObject = {}) => (
      <FormControlLabel
        control={
          <Checkbox
            color="secondary"
            checked={!!value}
            onChange={(ev) =>
              onCredentialChange({
                ...credential,
                [name]: ev.target.checked,
              })
            }
            disabled={!!disabled}
          />
        }
        label={
          <Box
            component="span"
            sx={{ whiteSpace: 'nowrap' }}
          >
            {options.title ?? name}{' '}
            {options.description && (
              <Tooltip
                title={options.description}
                sx={(theme) => ({ color: theme.palette.text.disabled, verticalAlign: 'middle' })}
              >
                <Info fontSize="small" />
              </Tooltip>
            )}
          </Box>
        }
      />
    ),
    number: (name: string, value: unknown, options: SchemaObject = {}) => (
      <FormControl
        variant="standard"
        fullWidth
      >
        <TextField
          fullWidth
          label={options.title ?? name}
          disabled={!!disabled}
          InputProps={{
            inputProps: { min: options.minimum, max: options.maximum },
            endAdornment: options.description ? (
              <InputAdornment position="end">
                <Tooltip title={options.description}>
                  <Info fontSize="small" />
                </Tooltip>
              </InputAdornment>
            ) : undefined,
          }}
          value={value ?? ''}
          onChange={(ev) =>
            onCredentialChange({
              ...credential,
              [name]: ev.target.value ? Number(ev.target.value) : null,
            })
          }
          variant="outlined"
          required={credentialSchema?.required?.includes(name)}
          type="number"
          placeholder={options.default}
        />
      </FormControl>
    ),
    string: (name: string, value: unknown, options: SchemaObject = {}) => (
      <TextField
        fullWidth
        label={options.title ?? name}
        value={value ?? ''}
        select={!!options.enum}
        disabled={!!disabled}
        onChange={(ev) =>
          onCredentialChange({
            ...credential,
            [name]: ev.target.value,
          })
        }
        variant="outlined"
        required={credentialSchema?.required?.includes(name)}
        type={'format' in options && options.format === 'url' ? 'url' : 'text'}
        placeholder={options.default}
        InputProps={{
          startAdornment:
            options.description || options.default ? (
              <InputAdornment position="start">
                <Tooltip
                  title={[options.description, options.default ? `Default: ${options.default}` : undefined]
                    .filter((title) => !!title)
                    .join('')}
                >
                  <Info fontSize="small" />
                </Tooltip>
              </InputAdornment>
            ) : undefined,
        }}
      >
        {!!options.enum && !credentialSchema?.required?.includes(name) && (
          <MenuItem value="">
            <em>Keine Auswahl</em>
          </MenuItem>
        )}
        {options.enum?.map((value) => (
          <MenuItem
            key={value}
            value={value}
          >
            {value}
          </MenuItem>
        ))}
      </TextField>
    ),
    array: (name: string, value: unknown, options: SchemaObject = {}) => (
      <TextField
        fullWidth
        label={options.title ?? name}
        value={Array.isArray(value) ? value.join(',') : value ?? ''}
        disabled={!!disabled}
        onChange={(ev) =>
          onCredentialChange({
            ...credential,
            [name]: ev.target.value
              .split(',')
              .map((v) =>
                options.items && 'type' in options.items && options.items.type === 'number' && v !== null
                  ? Number(v.trim())
                  : v.trim(),
              )
              .filter((v) => (typeof v === 'number' ? isFinite(v) : !!v)),
          })
        }
        variant="outlined"
        required={credentialSchema?.required?.includes(name)}
        placeholder={options.default}
        InputProps={{
          startAdornment:
            options.description || options.default ? (
              <InputAdornment position="start">
                <Tooltip
                  title={[options.description, options.default ? `Default: ${options.default}` : undefined]
                    .filter((title) => !!title)
                    .join('')}
                >
                  <Info fontSize="small" />
                </Tooltip>
              </InputAdornment>
            ) : undefined,
        }}
      ></TextField>
    ),
  };

  return credentialSchema?.properties ? (
    <>
      {Object.entries(credentialSchema.properties).map(([name, options]) => {
        let schemaObjectType: SchemaObjectType | undefined;
        if (options && typeof options === 'object' && 'type' in options) {
          schemaObjectType = Array.isArray(options.type) ? options.type[0] : options.type;
        }
        const type = schemaObjectType ?? 'string';
        const fieldType = type in fields ? (type as keyof typeof fields) : 'string';

        return (
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            xl={2}
            key={name}
          >
            {fields[fieldType](name, credential[name], options as SchemaObject)}
          </Grid>
        );
      })}
    </>
  ) : (
    <>
      {!disabled && (
        <Grid
          item
          xs={12}
        >
          <JsonEditor
            label="Credential"
            value={credential}
            onValueChange={onCredentialChange}
          />
        </Grid>
      )}
    </>
  );
};

export default CredentialEditor;
