import React, { FC, useEffect, useState } from 'react';
import { Box, Stack, TextField, Typography } from '@mui/material';
import { useNotifications } from '../../../hooks/useNotifications';
import {
  DescriptionDto,
  LanguageDto,
  postDescription,
  putDescription,
} from '../../../services/process-settings-service/processSettings.service';
import { useTranslation } from 'react-i18next';

export interface IFormDescription {
  languageId: number;
  text: string;
}
export interface DescriptionPanelProps {
  languages: LanguageDto[];
  descriptions: DescriptionDto[];
  processId: number;
  onDescriptionChange: () => void;
}

const DescriptionPanel: FC<DescriptionPanelProps> = ({ languages, descriptions, processId, onDescriptionChange }) => {
  const { t } = useTranslation();
  const notificationHandler = useNotifications();

  const [formFields, setFormFields] = useState<IFormDescription[]>([]);

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    languageId: number,
  ) => {
    const newDescription = event.target.value;
    setFormFields((prev) => {
      const newFields = [...prev];
      const description = newFields.find((field) => field.languageId === languageId);
      if (description) {
        description.text = newDescription;
      }
      return newFields;
    });
  };

  const handleDescriptionBlur = async (languageId: number) => {
    const formField = formFields.find((desc) => desc.languageId === languageId);
    if (!formField) {
      return;
    }

    const description = descriptions.find((desc) => desc.languageId === languageId);
    try {
      if (description) {
        await putDescription({ text: formField.text }, processId, description.descriptionId);
      } else {
        await postDescription({ languageId, text: formField.text }, processId);
      }
      onDescriptionChange();
      notificationHandler.addInfo(`Description saved`);
    } catch (error) {
      notificationHandler.addError(error);
    }
  };

  useEffect(() => {
    const fields = [];
    for (const language of languages) {
      fields.push({
        languageId: language.languageId,
        text: descriptions.find((desc) => desc.languageId === language.languageId)?.text ?? '',
      });
    }

    setFormFields(fields);
  }, [languages, descriptions]);

  return (
    <Box>
      <Typography
        variant="h3"
        mb={3}
      >
        {t('Login description')}
      </Typography>

      <Stack spacing={2}>
        {formFields.map((description: IFormDescription) => (
          <TextField
            key={description.languageId}
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            value={description.text}
            label={languages.find((lang) => lang.languageId === description.languageId)?.languageCode ?? ''}
            onChange={(event) => handleDescriptionChange(event, description.languageId)}
            onBlur={(event) => handleDescriptionBlur(description.languageId)}
          ></TextField>
        ))}
      </Stack>
    </Box>
  );
};

export default DescriptionPanel;
