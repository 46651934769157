import * as SimpleWebauthn from '@simplewebauthn/browser';
import client, { api } from '../../shared/client';
import { backendUri } from '../../shared/helper/env/helper';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';
import { UserRole } from '../../shared/backend';

export type TLoginOptions =
  | {
      email: string;
      password: string;
    }
  | {
      email: string;
      expectedChallenge: string;
      mac: string;
      credential: unknown;
    };

export enum LoginResult {
  Success = 'success',
  Totp = 'totp',
  PasswordChange = 'password-change',
}

export enum PasswordChangeReason {
  INSECURE = 'insecure',
  NEW_PASSWORD = 'new',
}

type TSuccessLoginResponse = { jwt: string; result: LoginResult.Success };

export type TLoginResponse =
  | TSuccessLoginResponse
  | {
      email: string;
      mac: string;
      result: LoginResult.Totp;
    }
  | {
      email: string;
      mac: string;
      reason: PasswordChangeReason;
      result: LoginResult.PasswordChange;
    };

export const sendSecondFactor = async (email: string, mac: string, token: string) => {
  const response = await client.post<TLoginResponse>(
    `${backendUri}/auth/login/2fa`,
    {
      email,
      mac,
      token,
    },
    {
      withCredentials: true,
    },
  );

  return response.data;
};

export const reauthenticateUser = async (password: string) => {
  const response = await client.post<TLoginResponse>(`${backendUri}/auth/reauth`, { password });
  return response.data;
};

export const loginUser = async (body: TLoginOptions) => {
  const response = await client.post<TLoginResponse>(`${backendUri}/auth/login`, body, {
    withCredentials: true,
  });

  return response.data;
};

export const resetPassword = async (email: string, mac: string, password: string) => {
  const response = await client.post<TSuccessLoginResponse>(
    `${backendUri}/auth/reset-password`,
    {
      email,
      mac,
      password,
    },
    {
      withCredentials: true,
    },
  );

  return response.data;
};

export const logOut = async () => {
  localStorage.removeItem('session');

  await client.delete('auth/logout');
};

export const impersonateCustomer = async (customerId: number) => {
  await api.post<'/auth/impersonate'>(`${backendUri}/auth/impersonate`, { role: UserRole.CUSTOMER, customerId });
};

export class FidoDeviceRegistrationError extends Error {}

export async function registerFidoDevice(name: string, password: string): Promise<boolean> {
  const response = await client.get(`${backendUri}/auth/register/fido`);
  const { options, mac } = response.data;

  try {
    const attestationResponse = await SimpleWebauthn.startRegistration(options);

    const verificationResponse = await client.post(`${backendUri}/auth/register/fido`, {
      name,
      password,
      expectedChallenge: options.challenge,
      mac,
      credential: attestationResponse,
    });

    if (verificationResponse.data.verified) {
      return true;
    }

    throw new FidoDeviceRegistrationError(
      verificationResponse.data.message || 'Schlüssel konnte nicht überprüft werden.',
    );
  } catch (error) {
    // Some basic error handling
    if (error instanceof Error && error.name === 'InvalidStateError') {
      throw new FidoDeviceRegistrationError('Schlüssel vermutlich schon registriert.');
    }

    throw error;
  }
}

export async function removeFidoDevice(authenticatorId: number): Promise<void> {
  await client.delete(`${backendUri}/user/fido/authenticator/${authenticatorId}`);
}

export interface SessionInfoDto {
  current: boolean;
  userAgent: string;
  login: string | null;
  lastAuthenticated: string;
  validUntil: string;
  id: string;
}

export const useSessions = () => {
  const url = `${backendUri}/auth/sessions`;
  const { data, error, mutate, isLoading } = useSWR<SessionInfoDto[]>(url);

  return {
    data: data ?? [],
    error,
    isLoading,
    isError: !!error,
    mutate,
  };
};

export const useSessionMutation = () => {
  const { trigger, isMutating } = useSWRMutation(
    `${backendUri}/auth/sessions`,
    async (url, { arg }: { arg: { sessionId: string } }) => {
      await client.delete(`auth/session/${arg.sessionId}`);
    },
  );

  return {
    trigger: (sessionId: string) => trigger({ sessionId }),
    isMutating,
  };
};

export const generateReturnPortalMac = async (orderId: number) => {
  const res = await client.post<{ mac: string }>(`${backendUri}/public/return-portal-token`, {
    orderId,
  });
  return res.data;
};
