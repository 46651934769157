import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';

type Props = {
  isShipoutAllowed: boolean;
};

const ShipoutState: React.FC<Props> = ({ isShipoutAllowed }) => {
  return (
    <Stack
      pl={1}
      pr={2}
      sx={{ height: '100%' }}
    >
      <Typography
        variant="body2"
        color="text.secondary"
      >
        Shipout-Erlaubnis
      </Typography>
      <Typography variant="h5">
        {isShipoutAllowed ? (
          <DoneIcon sx={{ color: 'success.main', mt: 0.5 }} />
        ) : (
          <Box
            component="span"
            sx={{ color: 'error.main' }}
          >
            Ausstehend
          </Box>
        )}
      </Typography>
    </Stack>
  );
};

export default ShipoutState;
