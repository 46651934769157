import ArticleIcon from '@mui/icons-material/Article';
import BlockIcon from '@mui/icons-material/Block';
import RuleIcon from '@mui/icons-material/Rule';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import FontDownloadOffIcon from '@mui/icons-material/FontDownloadOff';
import React, { forwardRef } from 'react';
import { SxProps, Theme } from '@mui/material';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';

export enum TourWarningType {
  NONE,
  SHIPMENT_EMPTY,
  TOUR_EMPTY,
  ARTICLE_BLOCKED,
  ARTICLE_WARNING,
  ARTICLE_INCOMPLETE,
  ARTICLE_INVALID,
  ARTICLE_MISSING,
  NOT_DERIVABLE_LINE_ITEM_PRICE,
  MISSING_SHIPPING_WEIGHTS,
  FAULTY_SHIPPING_WEIGHTS,
}

type TourWarningIconProps = {
  type?: TourWarningType;
  sx?: SxProps<Theme>;
};

// Forward ref is needed to be able to use this element as direct child of the
// tooltip component
const TourWarningIcon = forwardRef<SVGSVGElement, TourWarningIconProps>(({ type, ...props }, ref) => {
  return (
    <>
      {type === TourWarningType.ARTICLE_BLOCKED && (
        <BlockIcon
          color="error"
          {...props}
          ref={ref}
        />
      )}
      {type === TourWarningType.ARTICLE_WARNING && (
        <ArticleIcon
          color="warning"
          {...props}
          ref={ref}
        />
      )}
      {type === TourWarningType.ARTICLE_INCOMPLETE && (
        <RuleIcon
          color="error"
          {...props}
          ref={ref}
        />
      )}
      {type === TourWarningType.ARTICLE_INVALID && (
        <FontDownloadOffIcon
          color="warning"
          {...props}
          ref={ref}
        />
      )}
      {type === TourWarningType.SHIPMENT_EMPTY && (
        <CheckBoxOutlineBlankIcon
          color="warning"
          {...props}
          ref={ref}
        />
      )}
      {type === TourWarningType.TOUR_EMPTY && (
        <IndeterminateCheckBoxIcon
          color="error"
          {...props}
          ref={ref}
        />
      )}
      {type === TourWarningType.ARTICLE_MISSING && (
        <HighlightOffIcon
          color="error"
          {...props}
          ref={ref}
        />
      )}
      {type === TourWarningType.NOT_DERIVABLE_LINE_ITEM_PRICE && (
        <MoneyOffIcon
          color="warning"
          {...props}
          ref={ref}
        />
      )}
      {type === TourWarningType.MISSING_SHIPPING_WEIGHTS && (
        <FitnessCenterIcon
          color="error"
          {...props}
          ref={ref}
        />
      )}
      {type === TourWarningType.FAULTY_SHIPPING_WEIGHTS && (
        <FitnessCenterIcon
          color="warning"
          {...props}
          ref={ref}
        />
      )}
    </>
  );
});

export default TourWarningIcon;
