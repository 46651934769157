import { keyframes } from '@emotion/react';
import { Box } from '@mui/system';
import React from 'react';
import ExportoIcon from '../ExportoIcon';

const rotate = keyframes`
0% {
    rotate: 0deg;
}
60% {
    rotate: 360deg;
}
100% {
    rotate: 360deg;
}
`;

type Props = {};

const PageLoading: React.FC<Props> = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{
        position: 'fixed',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
      }}
    >
      <Box
        sx={{
          position: 'relative',
          transition: '0.5s ease-in-out',
          animation: `${rotate} 1.5s infinite cubic-bezier(0.48,-0.46, 0.61, 0.66)`, //
        }}
      >
        <ExportoIcon
          color="secondary"
          sx={{ fontSize: '10rem', color: 'action.selected' }}
        />
      </Box>
    </Box>
  );
};

export default PageLoading;
