import React from 'react';
import { Button, CircularProgress, FormControl, MenuItem, Paper, Stack, TextField, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import UpdateIcon from '@mui/icons-material/Update';
import { getJobProgressState, useJobsInfinite, ReducedJobDto } from '../../services/job-service/job.service';

import { useEnumListParam, useStringParam } from '../../hooks/useParam';
import DisplayJobState from './components/DisplayJobStatus';
import SelectJobState from './components/SelectJobState';
import DataTable, { TDataTableColumn } from '../../shared/components/data-table/DataTable';
import JobDownloadCell from './components/JobDownloadCell';
import { JobQueueJobs, JobStatus } from '../../shared/backend';

const USER_JOB_TYPES = [
  JobQueueJobs.INVOICE_CREATION,
  JobQueueJobs.GERMAN_IMPORT_DOCUMENTS_CREATION,
  JobQueueJobs.COLLECTIVE_REFERENCE_CREATION,
  JobQueueJobs.EMK_DOCUMENTS_CREATION,
];

const jobLabels: Partial<Record<JobQueueJobs, string>> = {
  [JobQueueJobs.GERMAN_IMPORT_DOCUMENTS_CREATION]: `DE Import Dokumente`,
  [JobQueueJobs.INVOICE_CREATION]: `Versandrechnung`,
  [JobQueueJobs.COLLECTIVE_REFERENCE_CREATION]: `Sammelbezugsschein`,
  [JobQueueJobs.EMK_DOCUMENTS_CREATION]: `EMK Dokumente`,
};

const JobPage = () => {
  const [jobType, setJobType] = useStringParam('jobType');
  const [jobStates, setJobStates] = useEnumListParam('jobState', JobStatus, []);

  const userJobs = useJobsInfinite(jobStates);
  const filteredJobs = userJobs.data?.flat().filter((jobData) => !jobType || jobType === jobData.name);

  const columns: TDataTableColumn<ReducedJobDto>[] = [
    {
      title: 'Status',
      render: (job) => <DisplayJobState job={job} />,
    },
    {
      title: 'Job',
      render: (job) => getJobDescription(job),
    },
    {
      title: 'Start',
      render: (job) => (job.processedOn ? DateTime.fromMillis(job.processedOn).toRelative() : ''),
    },
    {
      title: 'Dauer',
      render: (job) => {
        const processedOn = job.processedOn ? DateTime.fromMillis(job.processedOn) : null;
        if (processedOn) {
          const duration = DateTime.fromMillis(job.finishedOn ?? DateTime.now().toMillis()).diff(processedOn);
          return !!duration && duration.toFormat('hh:mm:ss.SSS');
        }
      },
    },
    {
      title: 'Ergebnis',
      render: (job) => (
        <Stack
          direction="row"
          alignItems="center"
        >
          {!!job.failedReason && <Typography>{job.failedReason}</Typography>}
          {hasDownloadableFile(job) && <JobDownloadCell job={job}></JobDownloadCell>}
        </Stack>
      ),
    },
  ];

  const getJobDescription = (job: ReducedJobDto): string => {
    const tourIds = job.data.parameters?.tourIds;

    switch (job.name) {
      case JobQueueJobs.GERMAN_IMPORT_DOCUMENTS_CREATION:
      case JobQueueJobs.COLLECTIVE_REFERENCE_CREATION:
      case JobQueueJobs.EMK_DOCUMENTS_CREATION:
        return `${jobLabels[job.name]} (Touren: ${tourIds ? tourIds.join(', ') : 'unbekannt'})`;
      case JobQueueJobs.INVOICE_CREATION:
        const shipmentId = job.data.parameters?.shipmentId;
        return `${jobLabels[job.name]} (Shipment: ${shipmentId ?? 'unbekannt'})`;
      default:
        return '';
    }
  };

  const hasDownloadableFile = (job: ReducedJobDto): boolean => {
    const state = getJobProgressState(job);
    return USER_JOB_TYPES.includes(job.name) && state === 'completed';
  };

  return (
    <Paper sx={{ p: 2 }}>
      <Stack
        direction="row"
        alignItems="center"
        spacing={4}
      >
        <Typography
          variant="h5"
          gutterBottom
          flexGrow={1}
        >
          Erzeugte Dokumente
        </Typography>

        <SelectJobState
          sx={{ minWidth: '200px' }}
          selected={jobStates}
          onSelect={setJobStates}
        />

        <FormControl sx={{ minWidth: '200px' }}>
          <TextField
            size="small"
            select
            label="Job"
            value={jobType ?? ''}
            onChange={(ev) => setJobType(ev.target.value)}
          >
            <MenuItem value="">Alle</MenuItem>
            {USER_JOB_TYPES.map((jobType) => (
              <MenuItem
                key={jobType}
                value={jobType}
              >
                {jobLabels[jobType]}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
      </Stack>

      <Stack
        spacing={2}
        mt={2}
      >
        <DataTable
          isLoading={userJobs.isLoading}
          columns={columns}
          rows={filteredJobs}
        />

        <Button
          onClick={() => userJobs.setSize(userJobs.size + 1)}
          startIcon={userJobs.isLoadingMore ? <CircularProgress size="1em" /> : <UpdateIcon />}
          disabled={userJobs.isReachingEnd}
        >
          Mehr
        </Button>
      </Stack>
    </Paper>
  );
};

export default JobPage;
