import React from 'react';
import { useParams } from 'react-router-dom';
import { ProcessService } from '../../../services/process-service/process.service';
import { Alert, Grid, Skeleton, Stack } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import ProcessDetails from './ProcessDetails';
import ProcessSettings from './ProcessSettings';
import BreadcrumbsGridItem from '../BreadcrumbsGridItem';
import ProcessFiscalRepresentation from './settings/ProcessFiscalRepresentation';
import ProcessShippingMethods from './settings/ProcessShippingMethods';
import ProcessTechnicalUser from './settings/ProcessTechnicalUser';
import ProcessDangerzone from './settings/ProcessDangerzone';

type Props = {};

const ProcessPage: React.FC<Props> = () => {
  const params = useParams();
  const processId = parseInt(params.processId ?? '', 10);

  const process = ProcessService.useProcess(processId);

  return (
    <Grid
      container
      spacing={3}
    >
      {process.data && (
        <BreadcrumbsGridItem
          items={[
            { href: '/crm/customers', label: 'Kunden' },
            {
              href: `/crm/customers/${process.data.customerId}`,
              label: process.data.customer.company,
            },
            { label: `Process ${processId} (${process.data.processType.label})` },
          ]}
        />
      )}

      <Grid
        item
        xs={12}
      >
        {process.isLoading ? (
          <Skeleton
            variant="rounded"
            height={80}
          />
        ) : process.data ? (
          <>
            <Helmet>
              <title>{`Process ${process.data.processId} (${process.data.customer.company})`}</title>
            </Helmet>

            <Stack
              spacing={3}
              pb={12}
            >
              <ProcessDetails process={process.data} />

              <ProcessSettings process={process.data} />

              <ProcessFiscalRepresentation process={process.data} />

              <ProcessShippingMethods process={process.data} />

              <ProcessTechnicalUser process={process.data} />

              <ProcessDangerzone process={process.data} />
            </Stack>
          </>
        ) : (
          <Alert severity="warning">{process.error?.toString()}</Alert>
        )}
      </Grid>
    </Grid>
  );
};

export default ProcessPage;
