export const parseSearchQueryToBarcode = (query: string): string => {
  if (query.length > 18) {
    // It could be a DPD Label
    if (query.charAt(0) === '%') {
      //1D-Barcode
      return query.substring(8, 22);
    } else if (query.charAt(0) === '[') {
      //2D-Barcode
      const positionEnd = query.indexOf(' GEOP');
      const positionStart = positionEnd - 14;

      return query.substring(positionStart, positionEnd);
    }
  }

  return query;
};
