import { Alert, AlertTitle } from '@mui/material';
import React from 'react';
import TourWarningIcon, { TourWarningType } from './TourWarningIcon';

type Props = {};

const IncompleteArticleAlert: React.FC<Props> = () => {
  return (
    <Alert
      severity="error"
      icon={<TourWarningIcon type={TourWarningType.ARTICLE_INCOMPLETE} />}
    >
      <AlertTitle>Unvollständige Artikel</AlertTitle>
      Diese Tour beinhaltet Artikel die unvollständige Daten haben.
    </Alert>
  );
};

export default IncompleteArticleAlert;
