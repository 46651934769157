import {
  Alert,
  AlertTitle,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { ProcessTypeService } from '../../../services/process-type-service/process-type.service';
import { IsoCountryCode, TourType } from '../../../shared/backend';
import FactoryIcon from '@mui/icons-material/Factory';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useEnumParam } from '../../../hooks/useParam';
import { ProcessService } from '../../../services/process-service/process.service';
import { Check, Clear } from '@mui/icons-material';
import Catastic from './Catastic';
import { TourService } from '../../../services/tour-service/tour.service';
import { useNotifications } from '../../../hooks/useNotifications';
import { Link } from 'react-router-dom';
import { getShortBatchId } from '../../../shared/helper/text';

enum Direction {
  OUTBOUND = 'outbound',
  INBOUND = 'inbound',
}

type Props = {};

const TourCreationPage: React.FC<Props> = () => {
  const notifications = useNotifications();

  const processTypes = ProcessTypeService.useProcessTypes();
  const processes = ProcessService.useProcesses();

  const [direction, setDirection] = useEnumParam('direction', Direction, Direction.OUTBOUND);
  const [processTypeIds, setProcessTypeIds] = useState<number[]>([]);
  const [processIds, setProcessIds] = useState<number[]>([]);
  const [note, setNote] = useState('');

  const [processListExpanded, setProcessListExpanded] = useState(false);
  const [tourIsGenerating, setTourIsGenerating] = useState(false);
  const [isTourCreated, setTourCreated] = useState(false);
  const [lastCreatedTourBatchId, setLastCreatedTourBatchId] = useState<string | null>(null);

  const [route, setRoute] = useState<{ dispatchCountry: IsoCountryCode; destinationCountry: IsoCountryCode } | null>(
    null,
  );

  const routes = [
    ...new Set(
      processTypes.data.map(({ dispatchCountry, destinationCountry }) => `${dispatchCountry}-${destinationCountry}`),
    ),
  ]
    .sort()
    .map((label) => label.split('-') as [IsoCountryCode, IsoCountryCode]);

  const warehouses = processTypes.data.reduce(
    (warehouses, processType) => {
      if (processType.outboundWarehouse) {
        const entry = warehouses.find(
          ({ warehouse, dispatchCountry, destinationCountry }) =>
            warehouse === processType.outboundWarehouse &&
            dispatchCountry === processType.dispatchCountry &&
            destinationCountry === processType.destinationCountry,
        );

        if (entry) {
          entry.processTypeIds.push(processType.processTypeId);
        } else {
          warehouses.push({
            warehouse: processType.outboundWarehouse,
            processTypeIds: [processType.processTypeId],
            dispatchCountry: processType.dispatchCountry as IsoCountryCode,
            destinationCountry: processType.destinationCountry as IsoCountryCode,
            direction: Direction.OUTBOUND,
          });
        }
      }

      if (processType.inboundWarehouse) {
        const entry = warehouses.find(
          ({ warehouse, dispatchCountry, destinationCountry }) =>
            warehouse === processType.inboundWarehouse &&
            dispatchCountry === processType.dispatchCountry &&
            destinationCountry === processType.destinationCountry,
        );

        if (entry) {
          entry.processTypeIds.push(processType.processTypeId);
        } else {
          warehouses.push({
            warehouse: processType.inboundWarehouse,
            processTypeIds: [processType.processTypeId],
            dispatchCountry: processType.dispatchCountry as IsoCountryCode,
            destinationCountry: processType.destinationCountry as IsoCountryCode,
            direction: Direction.INBOUND,
          });
        }
      }

      return warehouses;
    },
    [] as {
      warehouse: string;
      processTypeIds: number[];
      dispatchCountry: IsoCountryCode;
      destinationCountry: IsoCountryCode;
      direction: Direction;
    }[],
  );

  const filterProcessTypeByRoute = (
    dispatchCountry: IsoCountryCode | undefined,
    destinationCountry: IsoCountryCode | undefined,
  ) => {
    return processTypes.data.filter(
      (processType) =>
        processType.destinationCountry === destinationCountry && processType.dispatchCountry === dispatchCountry,
    );
  };

  const filterProcessByProcessTypes = (ids: number[]) => {
    return processes.data.filter((process) => ids.includes(process.processTypeId) && !process.blocked);
  };

  const availableProcessTypes = filterProcessTypeByRoute(route?.dispatchCountry, route?.destinationCountry);
  const availableProcesses = filterProcessByProcessTypes(processTypeIds);

  const generateTours = async () => {
    try {
      setTourIsGenerating(true);
      setLastCreatedTourBatchId(null);

      const result = await TourService.generateTours({
        type: direction === Direction.OUTBOUND ? TourType.TOUR : TourType.RETOUR,
        processIds,
        note,
      });

      if (!result.tours.length) {
        notifications.addWarning('Keine Touren erstellt, da es keine verarbeiteten Sendungen gibt.');
      } else {
        const weightInKg = result.weight > 10_000 ? Math.round(result.weight / 1000) : result.weight / 1000;
        const message = `${result.tours.length} Touren wurden erstellt mit einem Gewicht von ${weightInKg}kg.`;

        notifications.addSuccess(message, 30_000);

        setLastCreatedTourBatchId(result.tours[0].tourBatchId);
        setTourCreated(true);
      }

      setNote('');
    } catch (error) {
      notifications.addError(error);
    } finally {
      setTourIsGenerating(false);

      setTimeout(() => setTourCreated(false), 1000);
    }
  };

  const processesFormGroup = (
    <FormGroup>
      {availableProcesses
        .sort((a, b) => (a.customer.company.toLowerCase() > b.customer.company.toLowerCase() ? 1 : -1))
        .map((process) => {
          return (
            <FormControlLabel
              key={process.processId}
              label={`${process.customer.company} (${process.processId})`}
              control={
                <Checkbox
                  color="primary"
                  checked={processIds.includes(process.processId)}
                  onChange={(ev) => {
                    if (ev.target.checked) {
                      setProcessIds([...processIds, process.processId].sort((a, b) => a - b));
                    } else {
                      setProcessIds(processIds.filter((id) => id !== process.processId));
                    }
                  }}
                />
              }
            />
          );
        })}

      {availableProcesses.length === 0 && (
        <Typography
          variant="body2"
          color="text.disabled"
          sx={{ mt: 1 }}
        >
          <em>Keine Prozessgruppe ausgewählen</em>
        </Typography>
      )}

      {processIds.length > 0 && (
        <Button
          size="small"
          startIcon={<Clear />}
          onClick={() => setProcessIds([])}
        >
          Auswahl aufheben
        </Button>
      )}
      {availableProcesses.length > 0 && processIds.length < availableProcesses.length && (
        <Button
          size="small"
          color="secondary"
          startIcon={<Check />}
          onClick={() => setProcessIds(availableProcesses.map(({ processId }) => processId))}
        >
          Alle auswählen
        </Button>
      )}
    </FormGroup>
  );

  return (
    <Paper sx={{ px: 2, py: 3 }}>
      <Catastic open={isTourCreated} />

      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
        >
          <Typography
            variant="h5"
            gutterBottom
          >
            Zwischentour ziehen
          </Typography>
          <Typography
            variant="body1"
            mb={3}
          >
            Eine Zwischentour beinhaltet alle Sendungen die sich im Status verarbeitet befinden und somit bereit für die
            Verzollung sind.
          </Typography>
        </Grid>
        {!!warehouses.length && (
          <Grid
            item
            xs={12}
          >
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              flexWrap="wrap"
              alignItems="flex-start"
              spacing={1}
            >
              {warehouses
                .sort((a, b) => (a.warehouse.toLowerCase() > b.warehouse.toLowerCase() ? 1 : -1))
                .map(({ warehouse, processTypeIds, dispatchCountry, destinationCountry, direction }) => (
                  <Button
                    key={`${warehouse}-${dispatchCountry}-${destinationCountry}`}
                    onClick={() => {
                      setRoute({ dispatchCountry, destinationCountry });
                      setProcessTypeIds(processTypeIds);
                      setProcessIds(filterProcessByProcessTypes(processTypeIds).map(({ processId }) => processId));
                      setDirection(direction);
                    }}
                    startIcon={<FactoryIcon />}
                    color={direction === Direction.OUTBOUND ? 'outbound' : 'inbound'}
                    variant="outlined"
                    size="small"
                    sx={{ mb: { md: 1 } }}
                    disabled={tourIsGenerating}
                  >
                    {warehouse} ({direction === Direction.OUTBOUND ? dispatchCountry : destinationCountry} -{' '}
                    {direction === Direction.OUTBOUND ? destinationCountry : dispatchCountry})
                  </Button>
                ))}
            </Stack>
          </Grid>
        )}

        <Grid
          item
          xs={12}
        >
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            spacing={3}
            flexWrap="wrap"
            justifyContent="space-between"
            sx={{ borderTop: '1px solid', borderColor: 'divider', pt: 3 }}
          >
            <FormControl disabled={tourIsGenerating}>
              <FormLabel id="direction">Richtung</FormLabel>
              <RadioGroup
                aria-labelledby="direction"
                value={direction ?? ''}
                onChange={(ev) => {
                  setProcessIds([]);
                  setProcessTypeIds([]);
                  setRoute(null);
                  setDirection(ev.target.value as Direction);
                }}
              >
                <FormControlLabel
                  value={Direction.OUTBOUND}
                  label="Outbound"
                  control={<Radio color="outbound" />}
                />
                <FormControlLabel
                  value={Direction.INBOUND}
                  label="Inbound (Retouren)"
                  control={<Radio color="inbound" />}
                />
              </RadioGroup>
            </FormControl>

            <FormControl disabled={tourIsGenerating || !direction}>
              <FormLabel id="route">Route</FormLabel>
              <RadioGroup
                aria-labelledby="route"
                value={route ? `${route.dispatchCountry}-${route.destinationCountry}` : ''}
                onChange={(ev) => {
                  const [dispatchCountry, destinationCountry] = ev.target.value.split('-') as [
                    IsoCountryCode,
                    IsoCountryCode,
                  ];

                  const processTypes = filterProcessTypeByRoute(dispatchCountry, destinationCountry);

                  setProcessIds([]);
                  setProcessTypeIds(processTypes.length === 1 ? [processTypes[0].processTypeId] : []);
                  setRoute({ dispatchCountry, destinationCountry });
                }}
              >
                {routes.map(([dispatchCountry, destinationCountry]) => {
                  const key = `${dispatchCountry}-${destinationCountry}`;
                  const label =
                    direction === Direction.OUTBOUND
                      ? `${dispatchCountry} - ${destinationCountry}`
                      : `${destinationCountry} - ${dispatchCountry}`;

                  if (
                    (direction === Direction.OUTBOUND && (dispatchCountry as string) === '*') ||
                    (direction === Direction.INBOUND && (destinationCountry as string) === '*')
                  ) {
                    return undefined;
                  }

                  return (
                    <FormControlLabel
                      key={key}
                      value={key}
                      label={label}
                      control={<Radio color="primary" />}
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>

            <FormControl
              component="fieldset"
              disabled={tourIsGenerating}
            >
              <FormLabel component="legend">Prozessgruppen</FormLabel>
              <FormGroup>
                {availableProcessTypes
                  .sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1))
                  .map((processType) => {
                    return (
                      <FormControlLabel
                        key={processType.processTypeId}
                        label={processType.label}
                        control={
                          <Checkbox
                            color="primary"
                            checked={processTypeIds.includes(processType.processTypeId)}
                            onChange={(ev) => {
                              const newProcessTypeIds = ev.target.checked
                                ? [...processTypeIds, processType.processTypeId].sort((a, b) => a - b)
                                : processTypeIds.filter((id) => id !== processType.processTypeId);

                              setProcessTypeIds(newProcessTypeIds);

                              const processes = filterProcessByProcessTypes(newProcessTypeIds);

                              setProcessIds(processes.map(({ processId }) => processId));
                            }}
                          />
                        }
                      />
                    );
                  })}
                {availableProcessTypes.length === 0 && (
                  <Typography
                    variant="body2"
                    color="text.disabled"
                    sx={{ mt: 1 }}
                  >
                    <em>Keine Route ausgewählen</em>
                  </Typography>
                )}
              </FormGroup>
            </FormControl>

            <FormControl
              component="fieldset"
              disabled={tourIsGenerating}
            >
              <FormLabel component="legend">Prozesse</FormLabel>

              {availableProcesses.length > 5 ? (
                <>
                  <Collapse
                    in={processListExpanded}
                    collapsedSize={200}
                    timeout="auto"
                  >
                    {processesFormGroup}
                  </Collapse>

                  <Button
                    sx={{ mt: 3 }}
                    size="small"
                    color="secondary"
                    startIcon={processListExpanded ? <ExpandLess /> : <ExpandMore />}
                    onClick={() => setProcessListExpanded(!processListExpanded)}
                  >
                    {processListExpanded ? 'Einklappen' : `Alle ${availableProcesses.length} Prozesse`}
                  </Button>
                </>
              ) : (
                processesFormGroup
              )}
            </FormControl>

            <Stack
              direction="column"
              spacing={2}
            >
              <FormControl>
                <FormLabel>Aktionen</FormLabel>
                <FormGroup>
                  <Button
                    sx={{ mt: 1 }}
                    variant="contained"
                    disabled={processIds.length === 0 || tourIsGenerating}
                    color={direction === Direction.OUTBOUND ? 'outbound' : 'inbound'}
                    onClick={() => generateTours()}
                    startIcon={tourIsGenerating ? <CircularProgress size="1em" /> : <AddCircleOutlineIcon />}
                  >
                    Touren erstellen
                  </Button>
                </FormGroup>
                {processIds.length > 0 && <FormHelperText>{processIds.length} Prozesse ausgewählt.</FormHelperText>}
              </FormControl>

              <TextField
                size="small"
                fullWidth
                label="Notiz"
                value={note}
                onChange={(ev) => setNote(ev.target.value)}
                multiline
                rows={3}
                disabled={processIds.length === 0 || tourIsGenerating}
              />

              {!!lastCreatedTourBatchId && (
                <Alert severity="success">
                  <AlertTitle>Zwischentour erstellt</AlertTitle>
                  Die{' '}
                  <Link to={`/customs/tour/${lastCreatedTourBatchId}`}>
                    Zwischentour {getShortBatchId(lastCreatedTourBatchId)}
                  </Link>{' '}
                  kann nun verzollt werden.
                </Alert>
              )}
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default TourCreationPage;
