import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      margin: theme.spacing(0, 2, 2),
    },
    customerDeletionFormField: {
      flexShrink: 4,
      flexGrow: 4,
      minWidth: '25ch',
      margin: theme.spacing(1),
    },
    customerDeletionAttentionMessage: {
      padding: theme.spacing(2),
      margin: theme.spacing(2, 2, 2),
      backgroundColor: '#ffabab',
      fontSize: '14p',
    },
    button: {
      marginTop: '15px',
      marginRight: '15px',
    },
  }),
);
