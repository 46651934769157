import { TableRow, TableCell, Typography, Button, Stack } from '@mui/material';
import { ArrowDropDownCircle } from '@mui/icons-material';
import React from 'react';
import { useNotifications } from '../../../../../../hooks/useNotifications';
import { LineItemDto } from '../../../../../../services/line-item-service/lineItem.service';
import { SplitLineItem } from './shipment-view/components/SplitLineItem';
import { ArticleService } from '../../../../../../services/article-service/article.service';
import { OrderType } from '../../../../../../shared/backend';
import { useAuthentication } from '../../../../../../hooks/useAuthentication';

type Props = {
  item: LineItemDto;
  isMultiShipment: boolean;
  isListHandler: boolean;
  orderType: OrderType;
  handleShipmentButtonClick: (event: React.MouseEvent<HTMLButtonElement>, lineItem: LineItemDto) => void;
  reloadShipments: () => Promise<void>;
};

const OrderDetailTableRow: React.FC<Props> = ({
  item,
  isMultiShipment,
  isListHandler,
  orderType,
  handleShipmentButtonClick,
  reloadShipments,
}) => {
  const auth = useAuthentication();
  const notificationHandler = useNotifications();
  const article = ArticleService.useArticleByExternalArticleId(item.processId, item.externalArticleId, auth.isStaff());

  const handleRowClick = (productId: string) => {
    if (auth.isOffice()) {
      if (productId) {
        window.open(
          article.data?.articleId ? `/articles?articleId=${article.data.articleId}` : `/articles?q=${productId}`,
        );
      } else {
        notificationHandler.addInfo('Die externe Artikel Id ist nicht vergeben');
      }
    }
  };

  const { externalArticleId } = item;

  return (
    <TableRow key={item.lineItemId}>
      <TableCell
        component="th"
        scope="row"
      >
        <Typography
          variant="h6"
          component="p"
        >
          {externalArticleId ? (
            <Button
              onClick={() => handleRowClick(externalArticleId)}
              sx={{ textTransform: 'none', '&:hover': { textDecoration: 'underline' } }}
              color="inherit"
            >
              {item.name}
            </Button>
          ) : (
            item.name
          )}
        </Typography>
      </TableCell>
      <TableCell
        component="th"
        scope="row"
      >
        <Typography
          variant="h6"
          component="p"
        >
          {item.priceGross}
        </Typography>
      </TableCell>
      <TableCell
        component="th"
        scope="row"
        sx={{ width: 1 / 5 }}
      >
        <Stack
          display="flex"
          direction="row"
          alignItems="center"
        >
          <Typography
            variant="h6"
            component="p"
            display="inline"
          >
            {item.quantity}
          </Typography>
          <SplitLineItem
            item={item}
            reloadShipments={reloadShipments}
          />
        </Stack>
      </TableCell>
      {isMultiShipment && auth.isStaff() && (
        <TableCell
          align="right"
          component="th"
          scope="row"
        >
          <Typography
            variant="h6"
            component="p"
          >
            <Button
              variant="outlined"
              color="secondary"
              aria-haspopup="true"
              onClick={(event) => handleShipmentButtonClick(event, item)}
              style={{ backgroundColor: 'white' }}
            >
              {orderType === OrderType.ORDER ? 'Paket zuordnen' : 'Retoure zuordnen'}
              {isListHandler && <ArrowDropDownCircle style={{ marginLeft: '5px' }} />}
            </Button>
          </Typography>
        </TableCell>
      )}
    </TableRow>
  );
};

export default OrderDetailTableRow;
