import { CircularProgress, IconButton, InputAdornment, TextField } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import React, { useRef } from 'react';
import { t } from 'i18next';

type Props = {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
};

const SearchField: React.FC<Props> = (props) => {
  const textFieldRef = useRef<HTMLInputElement | null>(null);

  return (
    <TextField
      disabled={props.disabled}
      value={props.value}
      onChange={(ev) => props.onChange(ev.target.value)}
      placeholder={props.placeholder ?? t('Search')}
      autoFocus={props.autoFocus}
      variant="outlined"
      sx={{
        flexGrow: 1,
      }}
      autoComplete="off"
      inputRef={textFieldRef}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {props.isLoading ? (
              <CircularProgress
                size="1.5em"
                color="secondary"
              />
            ) : (
              props.value && (
                <IconButton
                  size="small"
                  color="secondary"
                  onClick={() => {
                    props.onChange('');
                    textFieldRef.current?.focus();
                  }}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              )
            )}
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchField;
