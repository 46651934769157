import { backendUri } from '../../shared/helper/env/helper';
import useSWR from 'swr';
import client, { IUseArrayResponse, IUseObjectResponse, SchemaType, api } from '../../shared/client';
import axios from 'axios';

export interface BaseUserDto extends SchemaType<'BaseUserDto'> {}
export interface CreateUserDto extends SchemaType<'CreateUserDto'> {}
export interface UpdateUserDto extends SchemaType<'UpdateUserDto'> {}
export interface UserInfoDto extends SchemaType<'UserInfoDto'> {}
export interface SafeUserDto extends SchemaType<'SafeUserDto'> {}
export interface FidoAuthenticatorDto extends SchemaType<'FidoAuthenticatorDto'> {}

export const createUser = async (body: CreateUserDto) => {
  const response = await api.post<'/user'>('/user', body);

  return response.data;
};

export const updateUser = async (id: number, body: UpdateUserDto) => {
  const response = await api.put<'/user/{id}'>(`/user/${id}`, body);

  return response.data;
};

export const reactivateUser = async (id: number) => {
  const response = await api.put<'/user/{id}/reactivate'>(`${backendUri}/user/${id}/reactivate`);

  return response.data;
};

export const resetPasswordToRandom = async (id: number) => {
  const response = await api.put<'/user/{id}/reset-password'>(`/user/${id}/reset-password`);

  return response.data;
};

const userInfoFetcher = (url: string) => {
  return client
    .get(url, {
      baseURL: backendUri,
    })
    .then((res) => res.data)
    .catch((error) => {
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        return null;
      }

      throw error;
    });
};

export const useUserInfo = () => {
  const url = `${backendUri}/user/me`;
  const { data, error, mutate, isLoading } = useSWR<UserInfoDto>(url, userInfoFetcher, {
    refreshInterval: 30 * 1000,
  });

  return {
    data,
    error,
    isLoading,
    isError: !!error,
    mutate,
  };
};

export async function updatePasswordForCurrentlyLoggedInUser(oldPassword: string, newPassword: string) {
  const res = await api.put<'/user/me/password'>(`${backendUri}/user/me/password`, {
    oldPassword,
    newPassword,
  });

  return res.data;
}

export const deleteUser = async (id: number) => {
  const res = await api.delete<'/user/{id}'>(`${backendUri}/user/${id}`);

  return res.data;
};

export const useUsers = (): IUseArrayResponse<SafeUserDto> => {
  const { data = [], error, mutate, isLoading, isValidating } = api.useApi('/user');

  return {
    data,
    error,
    isLoading,
    isValidating,
    isError: !!error,
    mutate,
  };
};

export const useUser = (id: number | null): IUseObjectResponse<BaseUserDto> => {
  const { data, error, mutate, isLoading } = api.useApi(id ? { key: '/user/{id}', id } : null);

  return {
    data: data ?? null,
    error,
    isLoading,
    isError: !!error,
    mutate,
  };
};

export const useFidoAuthenticators = (): IUseArrayResponse<FidoAuthenticatorDto> => {
  const { data, error, mutate } = api.useApi<'/user/fido/authenticators'>('/user/fido/authenticators');

  return {
    data: data ?? [],
    error,
    isLoading: !error && !data,
    isError: !!error,
    mutate,
  };
};
