import React from 'react';
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';
import ErrorIcon from '@mui/icons-material/Error';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import EmailIcon from '@mui/icons-material/Email';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import PendingIcon from '@mui/icons-material/Pending';
import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import { Tooltip } from '@mui/material';
import { TourCustomsState } from '../../shared/backend';

const customsStatusToIconMap: Record<TourCustomsState, React.JSX.Element> = {
  [TourCustomsState.NOT_USED]: <DoNotDisturbAltIcon color="disabled" />,
  [TourCustomsState.CONSIGNMENT_NOT_SENT]: <DraftsOutlinedIcon color="disabled" />,
  [TourCustomsState.CONSIGNMENT_PENDING]: <PendingIcon />,
  [TourCustomsState.CONSIGNMENT_SENT]: <MailOutlineIcon />,
  [TourCustomsState.DECLARATIONS_CREATED]: <EmailIcon />,
  [TourCustomsState.DECLARATIONS_AWAITING_USER_ACTION]: <MarkEmailUnreadIcon color="warning" />,
  [TourCustomsState.DECLARATIONS_SENT_TO_CUSTOMS]: <MarkEmailReadIcon />,
  [TourCustomsState.DECLARATIONS_ACCEPTED]: <DoneIcon color="success" />,
  [TourCustomsState.DECLARATIONS_COMPLETE]: <DoneAllIcon color="success" />,
  [TourCustomsState.CONSIGNMENT_ERROR]: <ErrorIcon color="error" />,
  [TourCustomsState.DECLARATIONS_ERROR]: <ErrorIcon color="error" />,
};

const customsStatusToLabelMap: Record<TourCustomsState, string> = {
  [TourCustomsState.NOT_USED]: 'Wird nicht verwendet',
  [TourCustomsState.CONSIGNMENT_NOT_SENT]: 'Consignment nicht gesendet',
  [TourCustomsState.CONSIGNMENT_PENDING]: 'Consignment ausstehend',
  [TourCustomsState.CONSIGNMENT_SENT]: 'Consignment gesendet',
  [TourCustomsState.DECLARATIONS_CREATED]: 'Deklarationen erzeugt',
  [TourCustomsState.DECLARATIONS_AWAITING_USER_ACTION]: 'Benutzereingaben benötigt',
  [TourCustomsState.DECLARATIONS_SENT_TO_CUSTOMS]: 'Deklarationen an Zoll gesendet',
  [TourCustomsState.DECLARATIONS_ACCEPTED]: 'Deklarationen akzeptiert',
  [TourCustomsState.DECLARATIONS_COMPLETE]: 'Deklarationen komplett',
  [TourCustomsState.CONSIGNMENT_ERROR]: 'Consignment Fehler',
  [TourCustomsState.DECLARATIONS_ERROR]: 'Declaration Fehler',
};

type Props = {
  status: TourCustomsState;
};

const CustomsStatusIcon: React.FC<Props> = ({ status }) => {
  return <Tooltip title={customsStatusToLabelMap[status]}>{customsStatusToIconMap[status]}</Tooltip>;
};

export default CustomsStatusIcon;
