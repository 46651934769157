import React from 'react';
import { Navigate, Route, Routes, useSearchParams } from 'react-router-dom';
import { useUserInfo } from '../../services/user-service/user.service';
import logo from './../../assets/img/logo/exporto-logo.svg';
import { Login } from './components/login';
import ResetPasswordPage from './ResetPasswordPage';
import world from './../../assets/img/world.min.svg';
import { Box, CircularProgress } from '@mui/material';

export const LoginPage: React.FC = () => {
  const user = useUserInfo();
  const [searchParams] = useSearchParams();

  const ref = searchParams.get('ref');

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: {
          xs: 'flex-end',
          sm: 'center',
        },
        alignItems: 'center',
        flexDirection: 'column',
        backgroundImage: `url(${world}), linear-gradient(45deg,#1a294a 60%,#114688)`,
        backgroundPosition: {
          xs: 'top right, center center',
          sm: 'bottom right, center center',
        },
        backgroundRepeat: 'no-repeat',
        backgroundSize: {
          xs: '70% auto, auto',
          sm: '40% auto, auto',
        },
      }}
    >
      <Box
        component="img"
        sx={{ width: '465px', marginBottom: '40px', maxWidth: '90%' }}
        src={logo}
        alt="exporto"
      />

      <Box sx={{ display: 'flex', justifyContent: 'center', width: 'auto' }}>
        {user.isLoading ? (
          <CircularProgress />
        ) : !!user.data ? (
          <Navigate to={ref?.startsWith('/') ? ref : '/'} />
        ) : (
          <Routes>
            <Route
              path="reset-password"
              element={<ResetPasswordPage />}
            />

            <Route
              index
              element={<Login />}
            />
          </Routes>
        )}
      </Box>
    </Box>
  );
};
