import axios from 'axios';
import useSWR from 'swr';

const noCacheFetcher = (url: string) => {
  return axios.get(`${url}?${new Date().getTime()}`).then((res) => res.data);
};

export const useGitId = () => {
  const { data, error, mutate, isLoading } = useSWR<string>(`/build.txt`, noCacheFetcher, {
    refreshInterval: 60_000,
    revalidateIfStale: false,
    revalidateOnFocus: false,
    refreshWhenHidden: false,
  });

  return {
    data: data?.trim() ?? '',
    error,
    isLoading,
    isError: !!error,
    mutate,
  };
};
