import {
  ShipmentDutiesAndVatDto,
  ShipmentDutiesAndVatResponseDto,
} from '../../services/customs-service/customs.service';
import React from 'react';
import { useIntParam } from '../../hooks/useParam';
import DataTable, { TDataTableColumn } from '../../shared/components/data-table/DataTable';
import { Box, CircularProgress, Stack, TablePagination, Typography } from '@mui/material';
import { SchemaType } from '../../shared/client';
import { getAmountString, getCustomsClearanceTypeString } from './DutiesAndVatPage';
import { DateTime } from 'luxon';
import { DATETIME_FORMAT } from '../../shared/helper/date';
import { countryToFlag } from '../../shared/helper/country';
import { useTranslation } from 'react-i18next';
export interface AmountOfMoneyDtoWithPreliminaryFlagDto extends SchemaType<'AmountOfMoneyDtoWithPreliminaryFlagDto'> {}

type Props = {
  dutiesAndVat: ShipmentDutiesAndVatResponseDto;
  isLoading: boolean;
  isValidating?: boolean;
};

const DutiesAndVatTable: React.FC<Props> = ({ dutiesAndVat, isLoading, isValidating }) => {
  const { t } = useTranslation();
  const [page, setPage] = useIntParam('page', 0);
  const [pageSize, setPageSize] = useIntParam('pageSize', 100);

  const columns: TDataTableColumn<ShipmentDutiesAndVatDto>[] = [
    { title: t('Company'), field: 'company' },
    {
      title: t('Date'),
      render: ({ date }) => DateTime.fromISO(date).toFormat(DATETIME_FORMAT),
    },
    {
      title: t('Consignee Name'),
      field: 'consigneeName',
    },
    {
      title: t('Destination Country'),
      render: ({ destinationCountry }) => `${countryToFlag(destinationCountry)} (${destinationCountry})`,
    },
    {
      title: t('Packet Identifier'),
      field: 'identifier',
    },
    {
      title: t('Customs Registration Number'),
      field: 'customsRegistrationNumber',
    },
    {
      title: t('Customs Clearance Type'),
      render: ({ isTourDeclaration, tourId }) => getCustomsClearanceTypeString(isTourDeclaration, tourId, t),
    },
    {
      title: t('Customs Duties'),
      render: ({ customsDuties }) => getAmountString(customsDuties, t),
    },
    {
      title: t('Import VAT'),
      render: ({ importVat }) => getAmountString(importVat, t),
    },
    {
      title: t('Status Code'),
      field: 'statusCode',
    },
  ];

  return (
    <>
      <DataTable
        page={page}
        columns={columns}
        rows={dutiesAndVat.data}
        isLoading={isLoading}
      />

      <Stack
        direction="row"
        alignItems="center"
      >
        {isValidating && (
          <>
            <CircularProgress
              size="1.2em"
              sx={{ marginX: 1, color: 'text.disabled' }}
            />
            <Typography
              variant="body2"
              color="text.disabled"
            >
              <em>{t('Looking for new data')}...</em>
            </Typography>
          </>
        )}

        <Box flexGrow={1}></Box>

        <TablePagination
          component="div"
          rowsPerPage={pageSize}
          rowsPerPageOptions={[10, 50, 100, 500]}
          onRowsPerPageChange={(ev) => setPageSize(parseInt(ev.target.value, 10))}
          page={page}
          count={dutiesAndVat.meta.count}
          onPageChange={(ev, page) => setPage(page)}
          showFirstButton={true}
          showLastButton={true}
          slotProps={{
            actions: {
              nextButton: {
                disabled: dutiesAndVat.data.length < pageSize,
              },
            },
          }}
        />
      </Stack>
    </>
  );
};

export default DutiesAndVatTable;
