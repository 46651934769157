import React from 'react';
import { Lock, LockOpen } from '@mui/icons-material';
import { ListItemIcon, ListItemText, MenuItem, TextField } from '@mui/material';
import { TourState } from '../../shared/backend';

/** Compared to the TourState, we have a value for both. This is needed if we want to use it as url param. Null can not be used there. */
export enum TourStateOption {
  OPEN = 'open',
  CLOSED = 'closed',
  BOTH = 'both',
}

const tourStateOptions = {
  [TourStateOption.CLOSED]: {
    label: 'Geschlossen',
    icon: <Lock sx={{ verticalAlign: 'bottom', height: 24, width: 24 }} />,
  },
  [TourStateOption.OPEN]: {
    label: 'Offen',
    icon: <LockOpen sx={{ verticalAlign: 'bottom', height: 24, width: 24 }} />,
  },
  [TourStateOption.BOTH]: {
    label: 'Whatever',
    icon: null,
  },
};

const tourStateOptionMap = {
  [TourStateOption.OPEN]: TourState.OPEN,
  [TourStateOption.CLOSED]: TourState.CLOSED,
  [TourStateOption.BOTH]: undefined,
};

export function getTourStateFromTourStateOption(tourStateOption: TourStateOption): TourState | undefined {
  return tourStateOptionMap[tourStateOption];
}

type Props = {
  tourStateOption: TourStateOption;
  onChange: (tourStateOption: TourStateOption) => void;
};

const TourStateOptionSelector: React.FC<Props> = ({ tourStateOption, onChange }) => {
  return (
    <TextField
      select
      label="Tour state"
      value={tourStateOption}
      sx={{ minWidth: 140 }}
      size="small"
      onChange={(ev) => onChange(ev.target.value as TourStateOption)}
      SelectProps={{
        sx: { minHeight: 41 },
        renderValue: (value) => (
          <>
            {tourStateOptions[value as TourStateOption].icon} {tourStateOptions[value as TourStateOption].label}
          </>
        ),
      }}
    >
      {[TourStateOption.BOTH, TourStateOption.OPEN, TourStateOption.CLOSED].map((state) => (
        <MenuItem
          value={state}
          key={state}
        >
          {tourStateOptions[state].icon && <ListItemIcon>{tourStateOptions[state].icon}</ListItemIcon>}
          <ListItemText>{tourStateOptions[state].label}</ListItemText>
        </MenuItem>
      ))}
    </TextField>
  );
};

export default TourStateOptionSelector;
