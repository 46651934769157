import { Box, Modal } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import amazing0 from '../../../../../../../assets/img/images/amazing.jpg';
import amazing1 from '../../../../../../../assets/img/images/amazing-1.jpg';
import amazing2 from '../../../../../../../assets/img/images/amazing-2.jpg';
import amazing3 from '../../../../../../../assets/img/images/amazing-3.jpg';
import amazing4 from '../../../../../../../assets/img/images/amazing-4.jpg';
import amazing5 from '../../../../../../../assets/img/images/amazing-5.jpg';
import amazing6 from '../../../../../../../assets/img/images/amazing-6.jpg';
import amazing7 from '../../../../../../../assets/img/images/amazing-7.jpg';
import amazingXmas from '../../../../../../../assets/img/images/amazing-xmas.png';
import amazingSilvester from '../../../../../../../assets/img/images/amazing-silvester.png';
import amazingEastern from '../../../../../../../assets/img/images/amazing-eastern.png';
import amazingFasching from '../../../../../../../assets/img/images/amazing-fasnet.png';
import { DateTime } from 'luxon';

const defaultImages = [amazing0, amazing1, amazing2, amazing3, amazing4, amazing5, amazing6, amazing7];

enum Month {
  Jan,
  Feb,
  Mar,
  Apr,
  May,
  Jun,
  Jul,
  Aug,
  Sep,
  Oct,
  Nov,
  Dec,
}

interface Props {
  open: boolean;
  onClose: () => void;
}

function getEasterDateForCurrentYear(): Date {
  // all credit goes to Gauss
  // https://www.geeksforgeeks.org/how-to-calculate-the-easter-date-for-a-given-year-using-gauss-algorithm/
  const Y = new Date().getFullYear();

  const A = Y % 19;
  const B = Y % 4;
  const C = Y % 7;
  const P = Math.floor(Y / 100.0);

  const Q = Math.floor((13 + 8 * P) / 25.0);
  const M = Math.floor(15 - Q + P - Math.floor(P / 4)) % 30;
  const N = Math.floor(4 + P - Math.floor(P / 4)) % 7;
  const D = Math.floor(19 * A + M) % 30;
  const E = Math.floor(2 * B + 4 * C + 6 * D + N) % 7;

  const days = Math.floor(22 + D + E);

  return D === 29 && E === 6
    ? new Date(`${Y}-04-19`)
    : D === 28 && E === 6
      ? new Date(`${Y}-04-18`)
      : days > 31
        ? new Date(`${Y}-04-${days - 31}`)
        : new Date(`${Y}-03-${days}`);
}

function isEasterTime(): boolean {
  const date = getEasterDateForCurrentYear();
  const diffDays = DateTime.fromJSDate(date).diffNow('days').days;

  return Math.abs(diffDays) <= 3;
}

function isFaschingTime(): boolean {
  const date = getEasterDateForCurrentYear();
  const aschermittwoch = DateTime.fromJSDate(date).minus({ days: 46 });
  const now = DateTime.now();

  return now > aschermittwoch.minus({ days: 10 }) && now < aschermittwoch;
}

function getImage(randomValue: number) {
  const date = new Date();
  const month = date.getMonth();

  if (month === Month.Dec && date.getDate() < 30) {
    return amazingXmas;
  }

  if (month === Month.Jan && date.getDate() <= 10) {
    return amazingSilvester;
  }

  if ((month === Month.Feb || month === Month.Mar) && isFaschingTime()) {
    return amazingFasching;
  }

  if ((month === Month.Mar || month === Month.Apr) && isEasterTime()) {
    return amazingEastern;
  }

  return defaultImages[Math.floor(randomValue * defaultImages.length)];
}

export const ShipmentAcceptModal: React.FC<Props> = (props: Props) => {
  const handleClose = () => {
    props.onClose();
  };

  useEffect(() => {
    if (props.open) {
      setTimeout(() => {
        props.onClose();
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  const randomValue = useMemo(() => Math.random(), []);

  const image = getImage(randomValue);

  return (
    <Modal
      open={props.open}
      onClose={handleClose}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          component="img"
          src={image}
          sx={{
            width: '500px',
            maxWidth: '100%',
            ...(/\.jpe?g/.test(image)
              ? {
                  borderRadius: '50%',
                  overflow: 'hidden',
                  border: '3px solid rgba(255,255,255,0.8)',
                }
              : {}),
          }}
          alt="Amazing job"
        ></Box>
      </Box>
    </Modal>
  );
};
