import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React, { FC } from 'react';
import { AddressDto } from '../../../../../../../services/address-service/address.service';
import { getDisplayedAddressOption } from '../../../../../../../shared/components/react-hook-form/FormSelectAddress';

export interface AddressSelectProps {
  value: number | null;
  addresses: AddressDto[];
  onChange: (value: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | SelectChangeEvent<string>) => void;
  onBlur: (value: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => void;
}

const AddressSelect: FC<AddressSelectProps> = (props: AddressSelectProps) => {
  return (
    <Select
      fullWidth
      value={props.value?.toString() || ''}
      onChange={props.onChange}
      onBlur={props.onBlur}
      variant="outlined"
    >
      {!!props.value && !props.addresses.find(({ addressId }) => addressId === props.value) && (
        <MenuItem value={props.value}>ID {props.value}</MenuItem>
      )}
      {props.addresses.map((address) => (
        <MenuItem
          key={address.addressId}
          value={address.addressId}
        >
          {getDisplayedAddressOption(address)}
        </MenuItem>
      ))}
    </Select>
  );
};

export default AddressSelect;
