import { Close as CloseIcon } from '@mui/icons-material';
import { Box, IconButton, Zoom } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

const MIN_WIDTH = 20;
const MIN_HEIGHT = 20;
const MAX_WIDTH = 200;
const MAX_HEIGHT = 200;

const MIN_AREA = MIN_HEIGHT * MIN_WIDTH;
const REL_AREA = MAX_HEIGHT * MAX_WIDTH - MIN_AREA;

const MAX_PACKAGES = 10;

const INTERVAL_DURATION = 800;

interface IPackage {
  id: number;
  x: number;
  y: number;
  width: number;
  height: number;
}

type Props = {
  open: boolean;
  onClose: () => void;
};

const Game: React.FC<Props> = ({ open, onClose }) => {
  const [packages, setPackages] = useState<IPackage[]>([]);
  const [points, setPoints] = useState(0);
  const [packagesScored, setPackagesScored] = useState(0);
  const [scored, setScored] = useState<null | { x: number; y: number; value: number }>(null);

  const timeoutRef = useRef<number>();
  const startDate = useRef(new Date());

  const playDuration = Math.round((new Date().getTime() - startDate.current.getTime()) / 1000);
  const packagesPerHour = (packagesScored / playDuration) * 360;

  useEffect(() => {
    if (!open) {
      setPackages([]);

      return;
    }

    startDate.current = new Date();

    const interval = window.setInterval(() => {
      setPackages((pile) => {
        const duration = Math.round((new Date().getTime() - startDate.current.getTime()) / 1000);

        if (pile.length > MAX_PACKAGES + Math.floor(duration / 120)) {
          pile.shift();
        }

        const newPackage: IPackage = {
          id: Math.round(Math.random() * 1000000),
          x: 5 + Math.floor(Math.random() * 90),
          y: 5 + Math.floor(Math.random() * 90),
          width: MIN_WIDTH + Math.floor(Math.random() * (MAX_WIDTH - MIN_WIDTH)),
          height: MIN_HEIGHT + Math.floor(Math.random() * (MAX_HEIGHT - MIN_WIDTH)),
        };

        return [...pile, newPackage];
      });
    }, INTERVAL_DURATION);

    return () => {
      window.clearInterval(interval);
    };
  }, [open]);

  if (!open) {
    return <></>;
  }

  return (
    <Box sx={{ position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 9999 }}>
      {scored && (
        <Box
          sx={{
            position: 'absolute',
            top: scored.y,
            left: scored.x,
            transform: 'translate(-50%,-50%)',
            zIndex: 100,
          }}
        >
          <Zoom
            in={open}
            mountOnEnter
            unmountOnExit
          >
            <Box
              sx={{
                backgroundColor: scored.value > 1000 ? 'rgba(255, 188, 0, 0.6)' : 'rgba(180,180,180,0.5)',
                padding: 3,
                borderRadius: '50%',
                userSelect: 'none',
              }}
            >
              {scored.value}
            </Box>
          </Zoom>
        </Box>
      )}

      {packages.map(({ id, x, y, width, height }, index) => {
        const value = (1 - (x * y - MIN_AREA) / REL_AREA) * 100 * (packages.length - index);

        return (
          <Box
            key={id}
            sx={(theme) => ({
              cursor: 'pointer',
              boxShadow: theme.shadows[10],
              backgroundColor: 'orange',
              background:
                points > 200_000
                  ? 'linear-gradient(167deg, rgb(255, 128, 255) 0%, rgb(153, 0, 153) 100%)'
                  : 'linear-gradient(167deg, rgba(255,207,0,1) 0%, rgba(222,124,0,1) 100%)',
              borderRadius: 1,
              position: 'absolute',
              top: `calc(${y}% - ${height / 2}px)`,
              left: `calc(${x}% - ${width / 2}px)`,
              width,
              height,
              '&:before': {
                content: '""',
                display: 'block',
                width: '6px',
                marginLeft: '-3px',
                position: 'absolute',
                left: '50%',
                top: 0,
                bottom: 0,
                backgroundColor: points > 100_000 ? '#990099' : '#cc5500',
              },
              '&:after': {
                content: '""',
                display: 'block',
                height: '6px',
                marginTop: '-3px',
                position: 'absolute',
                top: '50%',
                left: 0,
                right: 0,
                backgroundColor: points > 100_000 ? '#990099' : '#cc5500',
              },
            })}
            onClick={(ev) => {
              setPackages((pile) => pile.filter((p) => p.id !== id));
              setPackagesScored((prev) => prev + 1);
              setPoints((points) => points + Math.round(value));

              if (timeoutRef.current) {
                window.clearTimeout(timeoutRef.current);
              }

              setScored({
                value: Math.round(value),
                x: ev.clientX,
                y: ev.clientY,
              });

              timeoutRef.current = window.setTimeout(() => {
                setScored(null);
              }, 400);
            }}
          ></Box>
        );
      })}

      <Box
        sx={{
          position: 'absolute',
          left: 0,
          bottom: 0,
          backgroundColor: 'rgba(255,255,255,0.8)',
          boxShadow: '0 0 5px rgb(0 0 0 / 30%)',
          padding: 1,
        }}
      >
        <strong>{points}</strong> Points in {playDuration} seconds.
      </Box>

      <Box
        sx={{
          position: 'absolute',
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(255,255,255,0.8)',
          boxShadow: '0 0 5px rgb(0 0 0 / 30%)',
          padding: 1,
        }}
      >
        <strong>{packagesScored}</strong> Packages. {packagesPerHour.toFixed(1)} Packages / Hour.
      </Box>

      <Box
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          backgroundColor: 'rgba(255,255,255,0.8)',
          boxShadow: '0 0 5px rgb(0 0 0 / 30%)',
          padding: 1,
        }}
      >
        <IconButton
          onClick={() => {
            setPoints(0);
            setPackagesScored(0);
            onClose();
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Game;
