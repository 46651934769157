import { Scale } from '@mui/icons-material';
import { Alert, Box, Button, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import React from 'react';
import { ScaleContext, scaleContext } from '../../../contexts/ScaleContext';
import { useNotifications } from '../../../hooks/useNotifications';
import DeleteButton from '../delete/DeleteButton';
import { isWebSerialSupported } from '../../scale';
import { useTranslation } from 'react-i18next';

type Props = {};

const ScaleStatus: React.FC<Props> = () => {
  const { t } = useTranslation();
  const notifications = useNotifications();
  const scale = React.useContext<ScaleContext>(scaleContext);
  const { connect, forget } = scale;

  if (!isWebSerialSupported()) {
    return <Alert severity="warning">{t('Your browser is not able to use the scale.')}</Alert>;
  }

  return (
    <Box>
      {scale.connected ? (
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
        >
          <Alert
            severity="warning"
            sx={{ flexGrow: 1, margin: 0, visibility: !!scale.weight && scale.weight < 0 ? 'initial' : 'hidden' }}
          >
            Waage muss tariert sein!
          </Alert>

          {typeof scale.weight === 'undefined' && (
            <Alert
              severity="warning"
              sx={{ flexGrow: 1, margin: 0 }}
            >
              {t('Scale has an invalid value!')}
            </Alert>
          )}

          <TextField
            disabled={true}
            size="small"
            value={`${scale.weight ?? '-'} g`}
            sx={{ width: '150px' }}
            inputProps={{ style: { textAlign: 'right' } }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Scale
                    fontSize="small"
                    color="disabled"
                  />
                </InputAdornment>
              ),
            }}
          />

          {forget && scale.method === 'webserial' && (
            <DeleteButton
              size="small"
              label={t('Disconnect')}
              onClick={() => forget()}
            />
          )}
        </Stack>
      ) : connect ? (
        <Button
          startIcon={<Scale />}
          onClick={() => {
            try {
              connect();
            } catch (error) {
              notifications.addError(error);
            }
          }}
        >
          {t('Connect scale')}
        </Button>
      ) : (
        <Typography>{t('No connection to scale')}</Typography>
      )}
    </Box>
  );
};

export default ScaleStatus;
