import React, { useEffect, useState } from 'react';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';

import BlockedShipmentRow from './BlockedShipmentRow';
import { useNotifications } from '../../../../hooks/useNotifications';
import { Check as CheckIcon } from '@mui/icons-material';
import QrCodeIcon from '@mui/icons-material/QrCode';
import { ProcessDto } from '../../../../services/process-service/process.service';
import { DateTime } from 'luxon';
import { ShipmentState } from '../../../../shared/backend';
import { ShipmentService } from '../../../../services/shipment-service/shipment.service';
import { useTranslation } from 'react-i18next';

type Props = {
  batchBarcode: string;
  onAccept: () => Promise<void>;
  process: ProcessDto | undefined;
};

const BatchBarcodeSummary: React.FC<Props> = ({ batchBarcode, onAccept, process }) => {
  const { t } = useTranslation();
  const notification = useNotifications();

  const [foundBlockedShipmentIds, setFoundBlockedShipmentIds] = useState<number[]>([]);
  const [isAccepting, setAccepting] = useState(false);

  const summary = ShipmentService.useBatchBarcodeSummary(batchBarcode);
  // todo show state
  useEffect(() => {
    setFoundBlockedShipmentIds([]);
  }, [batchBarcode]);

  if (summary.isLoading || !summary.data) {
    return <CircularProgress />;
  }

  const blockedShipments = summary.data.shipments.filter((shipment) => shipment.isBlocked);
  const isNeedToSearch = blockedShipments.length > foundBlockedShipmentIds.length;
  const isNeedToAccept = summary.data.shipments.some((shipment) => shipment.state === ShipmentState.NEW);

  return (
    <>
      <Grid
        container
        spacing={1}
        mb={6}
      >
        {!!process?.lastOrderImport && (
          <Grid
            item
            xs={12}
            md={3}
            sm={6}
          >
            <Box
              sx={
                DateTime.fromISO(process.lastOrderImport).diffNow('hours').hours > -3
                  ? { p: 1 }
                  : { backgroundColor: 'error.light', color: 'error.contrastText', p: 1 }
              }
            >
              <Typography variant="body2">Letzter Import</Typography>
              <Typography variant="h5">
                <Tooltip title={DateTime.fromISO(process.lastOrderImport).toString()}>
                  <span>{DateTime.fromISO(process.lastOrderImport).toRelative()}</span>
                </Tooltip>
              </Typography>
            </Box>
          </Grid>
        )}

        <Grid
          item
          xs={12}
          md={3}
          sm={6}
        >
          <Box sx={{ backgroundColor: 'action.hover', p: 1 }}>
            <Typography variant="body2">{t('Pallet ID')}</Typography>
            <Typography variant="h5">{batchBarcode}</Typography>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          md={3}
          sm={6}
        >
          <Box sx={{ backgroundColor: 'action.hover', p: 1 }}>
            <Typography variant="body2">{t('Total weight')}</Typography>
            <Typography variant="h5">{Math.round(summary.data.weight / 100) / 10} kg</Typography>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          md={3}
          sm={6}
        >
          <Box sx={{ backgroundColor: 'action.hover', p: 1 }}>
            <Typography variant="body2">{t('Number of shipments')}</Typography>
            <Typography variant="h5">{Math.round(summary.data.shipments.length)}</Typography>
          </Box>
        </Grid>
      </Grid>

      {blockedShipments.length > 0 && (
        <TableContainer sx={{ my: 5 }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>{t('Shipment ID')}</TableCell>
                <TableCell>{t('Address')}</TableCell>
                <TableCell>{t('Weight')}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {blockedShipments.map((shipment) => (
                <BlockedShipmentRow
                  key={shipment.shipmentId}
                  shipmentId={shipment.shipmentId}
                  isFound={foundBlockedShipmentIds.includes(shipment.shipmentId)}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {isNeedToSearch && (
        <Box sx={{ mt: 5 }}>
          <Typography
            variant="body2"
            sx={{ mb: 1 }}
          >
            {t(
              'Please sort out blocked consignments and scan the tracking number into this field. Once all have been found, the pallet can be confirmed.',
            )}
            ,
          </Typography>
          <TextField
            fullWidth
            label={t('Consignment number of a blocked consignment')}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <QrCodeIcon />
                </InputAdornment>
              ),
            }}
            onChange={(ev) => {
              const trackingId = ev.target.value;
              const blockedShipment = blockedShipments.find(
                (shipment) => shipment.foreignOutboundTrackingId === trackingId,
              );

              if (blockedShipment) {
                const { shipmentId } = blockedShipment;
                setFoundBlockedShipmentIds((prev) => [...prev, shipmentId]);
                ev.target.value = '';
                notification.addInfo(t(`Blocked shipment {{shipmentId}} found.`, { shipmentId }));
              }
            }}
          />
        </Box>
      )}

      {isNeedToSearch && (
        <Alert
          severity="error"
          sx={{ mt: 3 }}
        >
          <AlertTitle>{t('Blocked shipments')}</AlertTitle>
          {t('There are {{blockedShipmentCount}} blocked shipments that must be sorted out.', {
            blockedShipmentCount: `<strong>${blockedShipments.length}</strong>`,
          })}
        </Alert>
      )}

      {!isNeedToAccept && (
        <Alert
          severity="info"
          sx={{ mt: 3 }}
        >
          <AlertTitle>{t('Already processed')}</AlertTitle>
          {t('All shipments are already marked as processed.')}
        </Alert>
      )}

      {!isNeedToSearch && (
        <Stack
          direction="row"
          alignItems="end"
          justifyContent="center"
          sx={{ mt: 3 }}
        >
          <Box>
            <TextField
              label={t('Sample')}
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <QrCodeIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(ev) => {
                const trackingId = ev.target.value;
                const shipment = summary.data?.shipments.find(
                  (shipment) => shipment.foreignOutboundTrackingId === trackingId,
                );

                if (shipment) {
                  ev.target.value = '';
                  const { shipmentId } = shipment;
                  notification.addInfo(t('Shipment {{shipmentId}} found.', { shipmentId }));
                }
              }}
            />
          </Box>
          <Box flexGrow={1}></Box>
          <Box>
            <Button
              disabled={isAccepting || !isNeedToAccept}
              variant="contained"
              color="printed"
              size="large"
              startIcon={isAccepting ? <CircularProgress size="1em" /> : <CheckIcon />}
              onClick={() => {
                setAccepting(true);

                Promise.all(
                  foundBlockedShipmentIds.map((id) => ShipmentService.updateShipment(id, { batchBarcode: null })),
                )
                  .then(() => onAccept())
                  .catch((err) => notification.addError(err))
                  .finally(() => setAccepting(false));
              }}
            >
              {t('Confirm pallet')}
            </Button>
          </Box>
        </Stack>
      )}
    </>
  );
};

export default BatchBarcodeSummary;
