import axios, { HttpStatusCode } from 'axios';
import React, { PropsWithChildren } from 'react';
import { SWRConfig } from 'swr';
import { useNotifications } from '../hooks/useNotifications';
import { backendUri } from '../shared/helper/env/helper';

export function swrFetcherWith404(url: string) {
  return axios
    .get(url, {
      baseURL: backendUri,
      withCredentials: true,
      validateStatus: (status: number) => (status >= 200 && status < 300) || status === 404,
    })
    .then((res) => {
      return res.status === 404 ? undefined : res.data;
    });
}

export function swrFetcherWithUnauthorized(url: string) {
  return axios.get(url, {
    baseURL: backendUri,
    withCredentials: true,
    validateStatus: (status: number) => (status >= 200 && status < 300) || status === HttpStatusCode.Unauthorized,
  });
}

const cacheProviderKey = 'system:cache';

export function clearSessionStorageProvider() {
  sessionStorage.removeItem(cacheProviderKey);
}

function sessionStorageProvider() {
  const map = new Map<string, any>(JSON.parse(sessionStorage.getItem(cacheProviderKey) || '[]'));

  window.addEventListener('beforeunload', () => {
    const appCache = JSON.stringify([...map.entries()].filter(([url]) => /\/v1\/tour\/batch\/[a-z0-9]+/i.test(url)));
    sessionStorage.setItem(cacheProviderKey, appCache);
  });

  return map;
}

export const SWRProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const notificationHandler = useNotifications();

  const fetcher = (url: string) => {
    return axios
      .get(url, {
        baseURL: backendUri,
        withCredentials: true,
      })
      .then((res) => res.data);
  };

  return (
    <SWRConfig
      value={{
        fetcher,
        onError: (error, key) => {
          if (error?.response?.status === 401) {
            // the protected page component will redirect to the login page
            return;
          }

          console.warn('Error while getting data with SWR', { error, key });

          notificationHandler.addError(error);
        },
        provider: sessionStorageProvider,
      }}
    >
      {children}
    </SWRConfig>
  );
};
