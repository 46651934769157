import { Button, CircularProgress, Paper, Stack, Typography } from '@mui/material';
import React from 'react';
import { ProcessService } from '../../services/process-service/process.service';
import { Link, Navigate } from 'react-router-dom';

type Props = {};

export const ReturnSettingsPage: React.FC<Props> = () => {
  const processes = ProcessService.useProcesses();

  if (processes.isLoading) {
    return <CircularProgress />;
  }

  if (processes.data.length === 1) {
    const process = processes.data[0];

    return <Navigate to={`/crm/customers/${process.customerId}/settings/${process.processId}/return`} />;
  }

  return (
    <Paper sx={{ p: 2 }}>
      <Typography
        variant="h5"
        gutterBottom
      >
        Retourenportal
      </Typography>
      <Typography
        variant="body2"
        sx={{ mb: 3 }}
      >
        Für welches Retourenportal sollen die Einstellungen angezeigt werden?
      </Typography>

      <Stack
        direction="row"
        spacing={2}
      >
        {processes.data.map((process) => (
          <Button
            variant="contained"
            key={process.processId}
            component={Link}
            to={`/crm/customers/${process.customerId}/settings/${process.processId}/return`}
          >
            {process.processType.label}
          </Button>
        ))}
      </Stack>
    </Paper>
  );
};
