import { Button, Divider } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useNotifications } from '../../../hooks/useNotifications';
import { backendUri } from '../../../shared/helper/env/helper';
import * as SimpleWebauthn from '@simplewebauthn/browser';
import PasswordForm from './PasswordForm';
import FidoForm from './FidoForm';
import SecondFactorForm from './SecondFactorForm';
import { useUserInfo } from '../../../services/user-service/user.service';
import { Google as GoogleIcon } from '@mui/icons-material';
import KeyIcon from '@mui/icons-material/Key';
import PasswordIcon from '@mui/icons-material/Password';
import {
  TLoginResponse,
  LoginResult,
  loginUser,
  sendSecondFactor,
} from '../../../services/authentication-service/authentication.service';
import { useTranslation } from 'react-i18next';

type TLoginMethods = 'password' | 'fido';

interface ISecondFactorState {
  email: string;
  mac: string;
}

export const Login: React.FC = () => {
  const { t } = useTranslation();
  const notificationHandler = useNotifications();
  const navigate = useNavigate();
  const userInfo = useUserInfo();
  const [loginMethod, setLoginMethod] = useState<TLoginMethods>('password');
  const [secondFactor, setSecondFactor] = useState<ISecondFactorState | null>(null);

  const validateLoginResult = async (result: TLoginResponse) => {
    if (result.result === LoginResult.Success) {
      await userInfo.mutate();

      return;
    }

    if (result.email && result.mac) {
      if (result.result === LoginResult.Totp) {
        setSecondFactor({
          email: result.email,
          mac: result.mac,
        });

        return;
      }

      if (result.result === LoginResult.PasswordChange) {
        navigate(
          `/login/reset-password?email=${encodeURIComponent(result.email)}&mac=${result.mac}&reason=${result.reason}`,
        );

        return;
      }
    }

    throw new Error('Unexpected response received');
  };

  const loginMethods = {
    password: (
      <PasswordForm
        onSubmit={async (email, password) => {
          try {
            const result = await loginUser({ email, password });

            validateLoginResult(result);
          } catch (error) {
            notificationHandler.addError(error);

            return false;
          }
        }}
      />
    ),

    fido: (
      <FidoForm
        onSubmit={async (email) => {
          const url = new URL(`${backendUri}/auth/login/fido`);

          url.searchParams.set('email', email);

          const response = await axios.get(url.href);
          const { options, mac } = response.data;

          try {
            const attestationResponse = await SimpleWebauthn.startAuthentication(options);

            const result = await loginUser({
              email,
              expectedChallenge: options.challenge,
              mac,
              credential: attestationResponse,
            });

            validateLoginResult(result);
          } catch (error) {
            notificationHandler.addError(error);

            return false;
          }
        }}
      />
    ),
  };

  useEffect(() => {
    if (!secondFactor) {
      return;
    }

    const timeout = window.setTimeout(
      () => {
        setSecondFactor(null);
      },
      10 * 60 * 1000,
    );

    return () => window.clearTimeout(timeout);
  }, [secondFactor]);

  useEffect(() => {
    const listener = (ev: MessageEvent) => {
      if (ev.data.result === 'success' && backendUri && ev.origin === new URL(backendUri).origin) {
        userInfo.mutate();
      }
    };

    window.addEventListener('message', listener, false);

    return () => {
      window.removeEventListener('message', listener, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  return (
    <div>
      <Card sx={{ maxWidth: '100%' }}>
        {secondFactor ? (
          <CardContent sx={{ maxWidth: '400px' }}>
            <SecondFactorForm
              onSubmit={async (token) => {
                try {
                  const loginResult = await sendSecondFactor(secondFactor.email, secondFactor.mac, token);

                  validateLoginResult(loginResult);
                } catch (error) {
                  notificationHandler.addError(error);

                  return false;
                }
              }}
            />
          </CardContent>
        ) : (
          <CardContent sx={{ maxWidth: '400px' }}>
            {loginMethods[loginMethod]}

            <Divider sx={{ m: 3 }} />

            {loginMethod !== 'fido' && (
              <Button
                onClick={() => setLoginMethod('fido')}
                fullWidth
                startIcon={<KeyIcon />}
              >
                {t('Use security key')}
              </Button>
            )}
            {loginMethod !== 'password' && (
              <Button
                onClick={() => setLoginMethod('password')}
                fullWidth
                startIcon={<PasswordIcon />}
              >
                {t('Use password')}
              </Button>
            )}
            <Button
              onClick={() => window.open(`${backendUri}/auth/login/google`, 'Google Login', 'width=600, height=700')}
              fullWidth
              startIcon={<GoogleIcon />}
            >
              {t('Employee login')}
            </Button>
          </CardContent>
        )}
      </Card>
    </div>
  );
};
