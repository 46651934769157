import { Grid, Paper } from '@mui/material';
import React from 'react';
import VehicleIdentification from './VehicleIdentification';
import PersonInCharge from './PersonInCharge';
import ConsignmentParty from './ConsignmentParty';
import GoodsDescription from './GoodsDescription';

type Props = {};

const SettingsCustomsPage: React.FC<Props> = () => {
  return (
    <Grid
      container
      spacing={3}
    >
      <Grid
        item
        xs={12}
      >
        <Paper sx={{ px: 1, py: 2 }}>
          <PersonInCharge />
        </Paper>
      </Grid>

      <Grid
        item
        xs={12}
      >
        <Paper sx={{ px: 1, py: 2 }}>
          <ConsignmentParty />
        </Paper>
      </Grid>

      <Grid
        item
        xs={12}
        md={6}
      >
        <Paper sx={{ px: 1, py: 2 }}>
          <VehicleIdentification />
        </Paper>
      </Grid>

      <Grid
        item
        xs={12}
        md={6}
      >
        <Paper sx={{ px: 1, py: 2 }}>
          <GoodsDescription />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default SettingsCustomsPage;
