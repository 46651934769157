import { useSearchParams } from 'react-router-dom';

const PAGE_KEY = 'page';
const PAGE_SIZE_KEY = 'pageSize';

const defaultPage = 0;

interface IPagination {
  page: number;
  pageSize: number;
  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
}

export const usePagination = (defaultPageSize: number = 10): IPagination => {
  const [searchParams, setSearchParams] = useSearchParams();

  const page = parseInt(searchParams.get(PAGE_KEY) ?? '', 10);
  const pageSize = parseInt(searchParams.get(PAGE_SIZE_KEY) ?? '', 10);

  return {
    page: isFinite(page) && page >= 0 ? page : 0,
    pageSize: isFinite(pageSize) && pageSize >= 1 ? pageSize : defaultPageSize,
    setPage: (page: number) => {
      const newSearchParams = new URLSearchParams(window.location.search);

      if (page === defaultPage) {
        newSearchParams.delete(PAGE_KEY);
      } else {
        newSearchParams.set(PAGE_KEY, page.toString());
      }

      setSearchParams(newSearchParams);
    },
    setPageSize: (pageSize: number) => {
      const newSearchParams = new URLSearchParams(window.location.search);

      newSearchParams.delete(PAGE_KEY);

      if (pageSize === defaultPageSize) {
        newSearchParams.delete(PAGE_SIZE_KEY);
      } else {
        newSearchParams.set(PAGE_SIZE_KEY, pageSize.toString());
      }

      setSearchParams(newSearchParams);
    },
  };
};
