import React from 'react';
import * as TourService from '../../../../services/tour-service/tour.service';
import { Tooltip } from '@mui/material';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';

type Props = {
  tour: TourService.TourAndChecksDto['tour'];
  checks: TourService.TourAndChecksDto['checks'];
};

const TourShipmentNotificationsCell: React.FC<Props> = ({ tour, checks }) => {
  const shipmentNotifications = checks?.shipmentNotifications;

  if (!tour.process.notificationsEnabled) {
    return <NotificationsOffIcon color="disabled" />;
  }

  if (shipmentNotifications?.failedShipmentIds.length) {
    return (
      <>
        {shipmentNotifications.failedShipmentIds.length}x
        <NotificationImportantIcon
          color="error"
          sx={{ verticalAlign: 'middle' }}
        />
      </>
    );
  }

  if (tour.shipments.length && shipmentNotifications?.deliveredCount === tour.shipments.length) {
    return <NotificationsIcon color="success" />;
  }

  return (
    <>
      <Tooltip title="Pending">
        <span>{shipmentNotifications?.pendingCount ?? 0}</span>
      </Tooltip>{' '}
      /{' '}
      <Tooltip title="Delivered">
        <span>{shipmentNotifications?.deliveredCount ?? 0}</span>
      </Tooltip>
    </>
  );
};

export default TourShipmentNotificationsCell;
