import React from 'react';
import { CircularProgress, TableCell, TableRow, Typography } from '@mui/material';
import { Block as BlockIcon, Check, QuestionMark } from '@mui/icons-material';
import { ShipmentService } from '../../../../services/shipment-service/shipment.service';

type Props = {
  shipmentId: number;
  isFound: boolean;
};

const BlockedShipmentRow: React.FC<Props> = ({ shipmentId, isFound }) => {
  const shipment = ShipmentService.useShipment(shipmentId);

  return (
    <TableRow>
      <TableCell>
        <BlockIcon
          color="error"
          sx={{ verticalAlign: 'bottom' }}
        />{' '}
        {shipmentId}
      </TableCell>
      {shipment.data ? (
        <>
          <TableCell>
            <Typography variant="h6">{shipment.data.order.name}</Typography>
            {shipment.data.order.company && <Typography variant="body2">{shipment.data.order.company}</Typography>}
            <Typography variant="body2">{shipment.data.order.address}</Typography>
            {shipment.data.order.additionalAddress && (
              <Typography variant="body2">{shipment.data.order.additionalAddress}</Typography>
            )}
            <Typography variant="body2">
              {shipment.data.order.zip} {shipment.data.order.city}
            </Typography>
          </TableCell>
          <TableCell>{Math.round((shipment.data.shippingWeight ?? 0) / 100) / 10} kg</TableCell>
          <TableCell align="right">{isFound ? <Check color="success" /> : <QuestionMark color="warning" />}</TableCell>
        </>
      ) : (
        <TableCell colSpan={3}>
          <CircularProgress />
        </TableCell>
      )}
    </TableRow>
  );
};

export default BlockedShipmentRow;
