import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import React from 'react';
import { useGitId } from '../../services/build-service/build.service';
import { useBuildInfo } from '../hooks/build-info';
import bundleBuildInfo from '../helper/buildInfo';

type Props = {};

const NewAppVersionDetector: React.FC<Props> = () => {
  const { data: serverGitId } = useGitId();
  const [clientBuildInfo, setClientBuildInfo] = useBuildInfo();

  return (
    <Dialog
      open={!!serverGitId && serverGitId !== clientBuildInfo?.gitId && bundleBuildInfo.gitId !== serverGitId}
      disableEscapeKeyDown={true}
      aria-labelledby="refresh-dialog-title"
      aria-describedby="refresh-dialog-description"
    >
      <DialogTitle id="refresh-dialog-title">🎉 New app version detected</DialogTitle>
      <DialogContent>
        <DialogContentText id="refresh-dialog-description">
          We have deployed a new version of our application. Therefore you have to reload this site. Thanks for your
          assistance.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={() => setClientBuildInfo({ gitId: serverGitId })}
        >
          Ignore
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            const url = new URL(window.location.href);

            url.searchParams.set('v', new Date().getTime().toString());

            window.location.href = url.href;
          }}
          autoFocus
        >
          Reload page now
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewAppVersionDetector;
