import { TourChecksDto, TourCustomsStateProperty, TourDto } from '../../../services/tour-service/tour.service';
import { ShipmentReturnHandling, TourCustomsState, TourType } from '../../backend';

export const hasCustomsState = (
  customsStateProperty: TourCustomsStateProperty,
  customsState: TourCustomsState | TourCustomsState[],
  { tour }: { tour: TourDto },
) =>
  Array.isArray(customsState)
    ? customsState.includes(tour[customsStateProperty])
    : tour[customsStateProperty] === customsState;

export const getHasCustomsStatePredicate =
  (customsStateProperty: TourCustomsStateProperty, customsState: TourCustomsState | TourCustomsState[]) =>
  ({ tour }: { tour: TourDto }) =>
    hasCustomsState(customsStateProperty, customsState, { tour });

export const usesCustomsState = (customsStateProperty: TourCustomsStateProperty, { tour }: { tour: TourDto }) =>
  !hasCustomsState(customsStateProperty, TourCustomsState.NOT_USED, { tour });

export const getUsesCustomsStatePredicate =
  (customsStateProperty: TourCustomsStateProperty) =>
  ({ tour }: { tour: TourDto }) => {
    return usesCustomsState(customsStateProperty, { tour });
  };

export const getTourIdFromTour = ({ tour }: { tour: TourDto }) => tour.tourId;

export const transitDeparturePredicate = ({ tour }: { tour: TourDto }) =>
  hasCustomsState('transitDepartureCustomsState', TourCustomsState.CONSIGNMENT_NOT_SENT, { tour }) &&
  hasCustomsState(
    'exportCustomsState',
    [TourCustomsState.DECLARATIONS_ACCEPTED, TourCustomsState.DECLARATIONS_COMPLETE],
    { tour },
  ) &&
  hasCustomsState(
    'importCustomsState',
    [
      TourCustomsState.DECLARATIONS_SENT_TO_CUSTOMS,
      TourCustomsState.DECLARATIONS_ACCEPTED,
      TourCustomsState.DECLARATIONS_COMPLETE,
    ],
    { tour },
  );

export const transitArrivalPredicate = ({ tour }: { tour: TourDto }) =>
  hasCustomsState('transitArrivalCustomsState', TourCustomsState.CONSIGNMENT_NOT_SENT, { tour }) &&
  hasCustomsState(
    'transitDepartureCustomsState',
    [TourCustomsState.DECLARATIONS_ACCEPTED, TourCustomsState.DECLARATIONS_COMPLETE],
    { tour },
  );

export const transitManifestPredicate = ({ tour }: { tour: TourDto }) =>
  hasCustomsState(
    'exportCustomsState',
    [TourCustomsState.DECLARATIONS_ACCEPTED, TourCustomsState.DECLARATIONS_COMPLETE],
    { tour },
  ) && hasCustomsState('transitManifestCustomsState', TourCustomsState.CONSIGNMENT_NOT_SENT, { tour });

export const transitConsignmentPredicate = ({ tour }: { tour: TourDto }) =>
  hasCustomsState(
    'exportCustomsState',
    [TourCustomsState.DECLARATIONS_ACCEPTED, TourCustomsState.DECLARATIONS_COMPLETE],
    { tour },
  ) && hasCustomsState('transitCustomsState', TourCustomsState.CONSIGNMENT_NOT_SENT, { tour });

export const isTransitCompletePredicate = ({ tour }: { tour: TourDto }) => {
  return (
    getHasCustomsStatePredicate('transitCustomsState', [
      TourCustomsState.DECLARATIONS_ACCEPTED,
      TourCustomsState.DECLARATIONS_COMPLETE,
    ])({ tour }) ||
    getHasCustomsStatePredicate('transitManifestCustomsState', [
      TourCustomsState.DECLARATIONS_ACCEPTED,
      TourCustomsState.DECLARATIONS_COMPLETE,
    ])({ tour })
  );
};

export const sumAPredicate = ({ tour }: { tour: TourDto }) =>
  isTransitCompletePredicate({ tour }) &&
  hasCustomsState(
    'importCustomsState',
    [
      TourCustomsState.DECLARATIONS_SENT_TO_CUSTOMS,
      TourCustomsState.DECLARATIONS_AWAITING_USER_ACTION,
      TourCustomsState.DECLARATIONS_ACCEPTED,
      TourCustomsState.DECLARATIONS_COMPLETE,
    ],
    { tour },
  ) &&
  hasCustomsState('sumACustomsState', TourCustomsState.CONSIGNMENT_NOT_SENT, { tour });

export const importGvmsPredicate = ({ tour }: { tour: TourDto }) =>
  hasCustomsState(
    'importCustomsState',
    [TourCustomsState.DECLARATIONS_ACCEPTED, TourCustomsState.DECLARATIONS_COMPLETE],
    { tour },
  );

export const exportGvmsPredicate = ({ tour }: { tour: TourDto }) =>
  hasCustomsState(
    'exportCustomsState',
    [TourCustomsState.DECLARATIONS_ACCEPTED, TourCustomsState.DECLARATIONS_COMPLETE],
    { tour },
  );

export const collectiveReferencePredicate = ({ tour }: { tour: TourDto }) =>
  hasCustomsState(
    'exportCustomsState',
    [TourCustomsState.DECLARATIONS_ACCEPTED, TourCustomsState.DECLARATIONS_COMPLETE],
    { tour },
  ) &&
  hasCustomsState(
    'importCustomsState',
    [TourCustomsState.DECLARATIONS_ACCEPTED, TourCustomsState.DECLARATIONS_COMPLETE],
    { tour },
  );

export const transitDepartureDocumentPredicate = ({ tour }: { tour: TourDto }) =>
  hasCustomsState(
    'transitDepartureCustomsState',
    [TourCustomsState.DECLARATIONS_ACCEPTED, TourCustomsState.DECLARATIONS_COMPLETE],
    { tour },
  );

export const hasEmptyTourPredicate = ({ checks, tour }: { checks: TourChecksDto; tour: TourDto }) =>
  checks.emptyShipments.length >= tour.shipments.length;

export const hasMissingShippingWeightsPredicate = ({ checks }: { checks: TourChecksDto }) =>
  !!checks.missingShipmentWeights.length;

export const hasMissingArticlePredicate = ({ checks }: { checks: TourChecksDto }) => !!checks.missingArticles.length;

export const hasBlockedArticlesPredicate = ({ checks }: { checks: TourChecksDto }) => !!checks.blockedArticleIds.length;

export const hasIncompleteArticleIdsPredicate = ({ checks }: { checks: TourChecksDto }) =>
  !!checks.incompleteArticleIds.length;

export const hasInvalidArticleIdsPredicate = ({ checks }: { checks: TourChecksDto }) =>
  !!Object.entries(checks.invalidArticleValues).length;

export const hasMissingExportoBarcodePredicate = ({ checks }: { checks: TourChecksDto }) =>
  !!checks.missingExportoBarcode.length;

export const hasProcessWithoutClosedTourPredicate = ({ checks }: { checks: TourChecksDto }) =>
  !checks.hasClosedTourForProcess;

export const hasShipmentsWithEmkPredicate = ({ checks }: { checks: TourChecksDto }) => !!checks.shipmentsWithEmk.length;

export const hasSpecialTreatmentPredicate = ({ tour }: { tour: TourDto }) =>
  tour.type === TourType.RETOUR && tour.process.returnPackageHandling === ShipmentReturnHandling.SPECIAL_TREATMENT;

export const isShipoutAllowedPredicate = ({ tour }: { tour: TourDto }) =>
  hasCustomsState('importCustomsState', [TourCustomsState.DECLARATIONS_COMPLETE], { tour });

export const isVoecHandlingEnabledPredicate = ({ tour }: { tour: TourDto }) => tour.process.usesVoecHandling;
