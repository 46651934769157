import React, { FunctionComponent } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export interface InfoToastProps {
  infoMsg: string;
  open: boolean;
  onClose: Function;
}

const InfoToast: FunctionComponent<InfoToastProps> = (props) => {
  const classes = useStyles();

  const handleClose = (event?: unknown, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    props.onClose();
  };

  return (
    <div className={classes.root}>
      <Snackbar
        open={props.open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity="info"
        >
          {props.infoMsg}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default InfoToast;
