import { Box, ButtonGroup, Button } from '@mui/material';
import { default as React, FC } from 'react';
import { getTypedObjectEntries } from '../../helper/helper';
import { OrderSearchScope } from '../../backend';
import { useTranslation } from 'react-i18next';

/*
t('Tracking IDs')
t('IDs')
t('Extended')
t('Pallet')
*/
const modeLabels = {
  [OrderSearchScope.TRACKING_IDS]: 'Tracking IDs',
  [OrderSearchScope.IDENTIFIER]: 'IDs',
  [OrderSearchScope.EXTENDED]: 'Extended',
  [OrderSearchScope.BATCH]: 'Pallet',
};

interface SearchModeProps {
  scope: OrderSearchScope;
  onChange: (scope: OrderSearchScope) => void;
}

export const SearchMode: FC<SearchModeProps> = (props) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        height: '60px',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <form onSubmit={(event) => event.preventDefault}>
        <ButtonGroup
          size="small"
          sx={{ height: '40px' }}
          disableElevation
        >
          {getTypedObjectEntries(modeLabels).map(([scope, label]) => (
            <Button
              key={scope}
              sx={{ textTransform: 'none' }}
              onClick={() => props.onChange(scope)}
              color={props.scope === scope ? 'secondary' : 'primary'}
              variant={props.scope === scope ? 'contained' : 'outlined'}
            >
              {t(label)}
            </Button>
          ))}
        </ButtonGroup>
      </form>
    </Box>
  );
};
