import { Box, Typography } from '@mui/material';
import React from 'react';
import { ShipmentDto } from '../../../../../../../../services/shipment-service/shipment.service';

interface ExportoBarcodeProps {
  shipment: ShipmentDto;
}

export const ExportoBarcode: React.FC<ExportoBarcodeProps> = (props: ExportoBarcodeProps) => {
  return (
    <Box sx={{ padding: '10px', paddingLeft: '20px' }}>
      <Typography
        variant="body1"
        component="p"
      >
        Exporto-Barcode
      </Typography>
      <Typography
        variant="h6"
        component="p"
      >
        {props.shipment.exportoBarcode ? (
          props.shipment.exportoBarcode
        ) : (
          <Box
            component="span"
            sx={{ fontStyle: 'italic', color: 'secondary.dark' }}
          >
            Kein Label gedruckt
          </Box>
        )}
      </Typography>
    </Box>
  );
};
