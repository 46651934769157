import { Drawer, IconButton, Divider, Box, Typography, useTheme, Tooltip } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { DateTime } from 'luxon';
import React from 'react';
import buildInfo from '../../helper/buildInfo';
import HealthStatus from './HealthStatus';
import Navigation from './Navigation';
import { useAuthentication } from '../../../hooks/useAuthentication';
import { DrawerHeader } from './DrawerHeader';
import { useGitId } from '../../../services/build-service/build.service';
import Warning from '@mui/icons-material/Warning';

type Props = {
  drawerWidth: number;
  open: boolean;
  setOpen: (open: boolean) => void;
};

const ExportoDrawer: React.FC<Props> = ({ drawerWidth, open, setOpen }) => {
  const { data: serverGitId } = useGitId();
  const auth = useAuthentication();
  const theme = useTheme();

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      variant="persistent"
      anchor="left"
      open={open}
    >
      <DrawerHeader>
        <IconButton
          onClick={() => setOpen(false)}
          size="large"
        >
          {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </DrawerHeader>

      <Divider />

      <Navigation />

      <Box flexGrow={1}></Box>

      {auth.isAdmin() && (
        <Box
          mt={3}
          mb={-2}
        >
          <Typography
            variant="body2"
            sx={{
              fontSize: '0.7em',
              textAlign: 'center',
              color: 'text.secondary',
            }}
            gutterBottom
          >
            <HealthStatus />
          </Typography>
        </Box>
      )}

      <Box sx={(theme) => ({ margin: theme.spacing(3, 0, 1, 0) })}>
        <Typography
          variant="body2"
          sx={{
            fontSize: '0.7em',
            textAlign: 'center',
            color: 'text.secondary',
          }}
          gutterBottom
        >
          {!!serverGitId && serverGitId !== buildInfo.gitId && (
            <Tooltip title="There is a new version available. Please reload this page.">
              <Warning
                color="warning"
                sx={{ fontSize: '1.3em', verticalAlign: 'text-bottom', mr: 0.5 }}
              />
            </Tooltip>
          )}
          {DateTime.fromJSDate(buildInfo.buildDate).toFormat('yyyyLLdd')} &bull;{' '}
          <em>{process.env.NODE_ENV === 'development' ? buildInfo.gitBranchName : buildInfo.gitShortId}</em>
        </Typography>
      </Box>
    </Drawer>
  );
};

export default ExportoDrawer;
