import { FormControl, Select, MenuItem } from '@mui/material';
import React from 'react';
import { LineItemDto } from '../../services/line-item-service/lineItem.service';
import { LineItemReturnState } from '../../shared/backend';
import { t } from 'i18next';
import { CustomsService } from '../../services/customs-service/customs.service';

type Props = {
  disableShipmentEditing: boolean;
  item: LineItemDto;
  handleGoodsDescriptionUpdate: (lineItemId: number, goodsDescription: string) => Promise<void>;
};

const GoodsDescriptionSelector: React.FC<Props> = ({ item, disableShipmentEditing, handleGoodsDescriptionUpdate }) => {
  const goodsDescription = CustomsService.useGoodsDescription();

  if (!goodsDescription.data.length) {
    return <em>{t('No selection')}</em>;
  }

  return (
    <FormControl
      sx={{ minWidth: 100 }}
      size="small"
      disabled={
        disableShipmentEditing ||
        item.returnState === LineItemReturnState.ACCEPTED ||
        item.returnState === LineItemReturnState.DECLINED
      }
    >
      <Select<string>
        onChange={(ev) => handleGoodsDescriptionUpdate(item.lineItemId, ev.target.value)}
        value={item.goodsDescription}
      >
        <MenuItem value="">
          <em>{t('None')}</em>
        </MenuItem>
        {goodsDescription.data.map((description) => (
          <MenuItem
            value={description}
            key={description}
          >
            {description}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default GoodsDescriptionSelector;
