import { Link, Stack, Typography } from '@mui/material';
import React from 'react';
import CopyToClipboardButton from '../../../shared/components/CopyToClipboardButton';
import { returnPortalUrl } from '../../../shared/helper/env/helper';
import { ReturnSettingsDto } from '../../../services/process-settings-service/processSettings.service';

export interface ReturnPortalLinkProps {
  settings: ReturnSettingsDto;
}

export const ReturnPortalLink: React.FC<ReturnPortalLinkProps> = ({ settings }) => {
  const returnPortalLink = settings
    ? `${returnPortalUrl}/${settings.processReturnPortalIdentifier}/${settings.processId}/login`
    : '';

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      width="100%"
    >
      <Typography
        textAlign="center"
        borderRadius={1}
        padding={2}
        sx={(theme) => ({
          backgroundColor: theme.palette.action.focus,
        })}
      >
        <Link
          sx={(theme) => ({ color: theme.palette.secondary.main })}
          href={returnPortalLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          {returnPortalLink}
        </Link>
      </Typography>
      <CopyToClipboardButton value={returnPortalLink}></CopyToClipboardButton>
    </Stack>
  );
};
