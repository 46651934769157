export function abbreviate(text: string, maxLength: number): string;
export function abbreviate(text: undefined | string, maxLength: number): undefined;
export function abbreviate(text: string | undefined, maxLength: number): string | undefined {
  if (!text) {
    return text;
  }

  return text.length > maxLength ? `${text.slice(0, maxLength - 3).trim()}...` : text;
}

export const getShortBatchId = (tourBatchId: string): string => tourBatchId.slice(-6);
