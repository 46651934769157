import { Box, CheckboxProps, FormControlLabel, Tooltip } from '@mui/material';
import React from 'react';
import { Control, Controller, FieldPath, FieldPathValue, FieldValues, RegisterOptions } from 'react-hook-form';
import { Checkbox } from '@mui/material';
import { Info } from '@mui/icons-material';

type Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<CheckboxProps, 'onChange' | 'value' | 'error'> & {
  name: TName;
  label: string;
  rules?: Omit<RegisterOptions<TFieldValues, TName>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
  defaultValue?: FieldPathValue<TFieldValues, TName>;
  control?: Control<TFieldValues>;
  description?: string;
};

const FormCheckbox = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  rules,
  defaultValue,
  control,
  label,
  description,
  ...checkboxProps
}: Props<TFieldValues, TName>) => {
  return (
    <Controller
      name={name}
      rules={{ required: checkboxProps.required, ...rules }}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { name, onChange, value }, fieldState: { isDirty } }) => {
        return (
          <FormControlLabel
            label={
              <Box sx={{ display: 'inline-flex', maxWidth: '100%' }}>
                <Box sx={{ flexShrink: 1, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                  <Tooltip title={label}>
                    <span>{label}</span>
                  </Tooltip>
                </Box>
                {description && (
                  <Tooltip
                    title={description}
                    sx={(theme) => ({ color: theme.palette.text.disabled, verticalAlign: 'middle', ml: 1 })}
                  >
                    <Info fontSize="small" />
                  </Tooltip>
                )}
              </Box>
            }
            sx={{
              color: isDirty ? 'warning.main' : undefined,
              maxWidth: '100%',
              '& .MuiFormControlLabel-label': {
                overflow: 'hidden',
              },
            }}
            control={
              <Checkbox
                name={name}
                onChange={onChange}
                checked={value ?? false}
                {...checkboxProps}
              />
            }
          />
        );
      }}
    />
  );
};

export default FormCheckbox;
