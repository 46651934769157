import { Box, Chip, IconButton, Stack, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import React from 'react';
import { FC } from 'react';
import * as LineItemService from '../../services/line-item-service/lineItem.service';
import EditIcon from '@mui/icons-material/Edit';
import { LineItemReturnState } from '../../shared/backend';
import { useAuthentication } from '../../hooks/useAuthentication';
import { t } from 'i18next';

export interface ManageRetoureButtonsProps {
  lineItem: LineItemService.LineItemDto;
  onChangeLineItemState: (lineItemId: number, lineItemReturnState: LineItemReturnState) => Promise<void>;
  onLineItemsRefreshAfterSplit: (
    originalLineItem: LineItemService.LineItemDto,
    newLineItems: LineItemService.LineItemDto[],
  ) => void;
  disableShipmentEditing: boolean;
}

export const ManageRetoureButtons: FC<ManageRetoureButtonsProps> = ({
  lineItem,
  onChangeLineItemState,
  onLineItemsRefreshAfterSplit,
  disableShipmentEditing,
}) => {
  const auth = useAuthentication();

  const handleChangeLineItemState = async (
    event: React.MouseEvent<HTMLButtonElement>,
    lineItemReturnState: LineItemReturnState,
  ) => {
    event.stopPropagation();
    await onChangeLineItemState(lineItem.lineItemId, lineItemReturnState);
  };

  const isAnnounced = lineItem.returnState === LineItemReturnState.ANNOUNCED;
  const isAccepted = lineItem.returnState === LineItemReturnState.ACCEPTED;
  const isDeclined = lineItem.returnState === LineItemReturnState.DECLINED;

  return (
    <Box
      sx={{ mr: 2 }}
      onClick={(event) => event.stopPropagation()}
    >
      {(isAccepted || isDeclined) && (
        <Stack
          alignItems="center"
          direction="row"
          spacing={1}
        >
          <Chip
            label={isAccepted ? t('Accepted') : t('Rejected')}
            color={isAccepted ? 'success' : 'error'}
            size="small"
          />

          {auth.isStaff() && (
            <IconButton
              title={t('Edit')}
              onClick={(event) => handleChangeLineItemState(event, LineItemReturnState.ANNOUNCED)}
              disabled={disableShipmentEditing}
            >
              <EditIcon />
            </IconButton>
          )}
        </Stack>
      )}

      {isAnnounced && (
        <>
          {auth.isStaff() ? (
            <>
              <IconButton
                title={t('Accept')}
                onClick={(event) => handleChangeLineItemState(event, LineItemReturnState.ACCEPTED)}
                disabled={disableShipmentEditing}
                size="large"
              >
                <CheckIcon
                  fontSize="large"
                  style={{
                    color: '#2ed618',
                  }}
                />
              </IconButton>
            </>
          ) : (
            <Typography
              variant="h6"
              component="p"
            >
              {t('Pending')}
            </Typography>
          )}
        </>
      )}
    </Box>
  );
};
