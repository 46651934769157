import React, { FunctionComponent, useState, FormEvent } from 'react';
import { IconButton, Input, Stack, FormControl, Tooltip } from '@mui/material';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { LineItemDto, LineItemService } from '../../../../../../../../services/line-item-service/lineItem.service';
import { useNotifications } from '../../../../../../../../hooks/useNotifications';
import axios, { AxiosError } from 'axios';
import { useAuthentication } from '../../../../../../../../hooks/useAuthentication';
import { t } from 'i18next';

export interface SplitLineItemProps {
  item: LineItemDto;
  reloadShipments: () => Promise<void>;
}

export const SplitLineItem: FunctionComponent<SplitLineItemProps> = ({ item, reloadShipments }: SplitLineItemProps) => {
  const itemId = item.lineItemId;
  const maxQuantity = item.quantity;
  const [useSplitOption, setUseSplitOption] = useState(false);
  const notificationHandler = useNotifications();
  const auth = useAuthentication();

  const setQuantity = async (newQuantity: number) => {
    if (newQuantity > 0) {
      try {
        await LineItemService.splitLineItem(itemId, newQuantity);
      } catch (error) {
        const errorMessage = axios.isAxiosError(error)
          ? (error as AxiosError<{ message: string }>).response?.data?.message
          : error;
        notificationHandler.addError(errorMessage);
      }
      await reloadShipments();
    }
    setUseSplitOption(false);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const quantity = formData.get('quantity');
    if (quantity !== null) {
      await setQuantity(Number(quantity));
    }
  };

  return (
    <>
      {auth.isStaff() && item.quantity > 1 && (
        <Stack>
          {useSplitOption ? (
            <form onSubmit={handleSubmit}>
              <FormControl sx={{ display: 'flex', flexDirection: 'row' }}>
                <IconButton
                  sx={{ mr: 1 }}
                  color="secondary"
                  onClick={() => setUseSplitOption(false)}
                >
                  <CloseIcon />
                </IconButton>
                <Input
                  name="quantity"
                  sx={{
                    width: 50,
                    mr: 1,
                    color: 'secondary',
                    input: { textAlign: 'right' },
                  }}
                  inputProps={{ min: 0, max: maxQuantity - 1 }}
                  type="number"
                  autoFocus={true}
                />

                <Tooltip title={t('Create new order item')}>
                  <IconButton
                    sx={{ mr: 1 }}
                    color="success"
                    type="submit"
                  >
                    <CheckIcon />
                  </IconButton>
                </Tooltip>
              </FormControl>
            </form>
          ) : (
            <Tooltip title={t('Split order item')}>
              <IconButton
                sx={{ mr: 1 }}
                onClick={() => setUseSplitOption(true)}
              >
                <CallSplitIcon />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
      )}
    </>
  );
};
