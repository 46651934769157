import { UploadFile } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import Clear from '@mui/icons-material/Clear';
import {
  Box,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Stack,
  FormControlLabel,
  Checkbox,
  Paper,
  Typography,
  Alert,
  AlertTitle,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useNotifications } from '../../../../../../hooks/useNotifications';
import { createAsset, deleteAsset, useAssets } from '../../../../../../services/customer-service/asset.service';
import DeleteButton from '../../../../../../shared/components/delete/DeleteButton';

const checkerboard =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAAAAACo4kLRAAAAH0lEQVQY02OcxQADZ+AsJgYsYKgIsiD8YTJInEShIAA1NwKQeKc4/QAAAABJRU5ErkJggg==';

const MAX_BYTE_SIZE = 50 * 1024; // "Das Absenderlogo überschreitet die maximale Grösse von 50 KB."
const MAX_PX_WIDTH = 2048; // there is no real limit, but that should be enough
const MAX_PX_HEIGHT = 2048;

type Props = {
  customerId: number;
};

const CustomerAssets: React.FC<Props> = ({ customerId }) => {
  const notifications = useNotifications();
  const assets = useAssets(customerId);

  const [uploadMetaData, setUploadMetaData] = useState<{ size: number; width: number; height: number } | null>(null);
  const [uploadedAsset, setUploadedAsset] = useState<File | null>(null);
  const [uploadConfig, setUploadConfig] = useState({ default: false, return: false });

  const isUploadValid =
    !!uploadMetaData &&
    uploadMetaData.size <= MAX_BYTE_SIZE &&
    uploadMetaData.height <= MAX_PX_HEIGHT &&
    uploadMetaData.width <= MAX_PX_WIDTH;

  if (assets.isLoading) {
    return <CircularProgress />;
  }

  return (
    <Box>
      <TableContainer sx={{ my: 3 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell sx={{ width: '100%' }}>Logo</TableCell>
              <TableCell>Outbound</TableCell>
              <TableCell>Inbound</TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>Created at</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          {assets.data.length === 0 ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={4}>
                  <Typography
                    variant="body2"
                    my={2}
                    align="center"
                    color="textSecondary"
                  >
                    No label icons uploaded yet
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {assets.data.map((asset) => {
                const bytesArray = Uint8Array.from(window.atob(asset.labelIcon), (c) => c.charCodeAt(0));
                const image = new window.Blob([bytesArray], { type: 'image/png' });

                return (
                  <TableRow key={asset.assetId}>
                    <TableCell>{asset.assetId}</TableCell>
                    <TableCell>
                      <Box
                        component="img"
                        alt="Label icon"
                        src={URL.createObjectURL(image)}
                        sx={(theme) => ({
                          maxWidth: 100,
                          maxHeight: 80,
                          ':hover': { backgroundImage: `url(${checkerboard})`, boxShadow: theme.shadows[5] },
                        })}
                      ></Box>
                    </TableCell>

                    <TableCell align="center">
                      {asset.isDefault ? <CheckIcon color="success" /> : <Clear color="error" />}
                    </TableCell>
                    <TableCell align="center">
                      {asset.isReturnLabel ? <CheckIcon color="success" /> : <Clear color="error" />}
                    </TableCell>
                    <TableCell sx={{ whiteSpace: 'nowrap' }}>
                      <Tooltip title={DateTime.fromISO(asset.createdAt).toString()}>
                        <span>{DateTime.fromISO(asset.createdAt).toRelative()}</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <DeleteButton
                        size="small"
                        variant="contained"
                        onClick={() =>
                          deleteAsset(customerId, asset.assetId)
                            .then(() => assets.mutate())
                            .catch((err) => notifications.addError(err))
                        }
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </Table>
      </TableContainer>

      <Box sx={{ mt: 5 }}>
        {uploadedAsset ? (
          <Paper sx={{ p: 2 }}>
            <Typography
              variant="h6"
              sx={{ mt: -1, mb: 3 }}
            >
              New label icon
            </Typography>

            <Stack
              direction="row"
              spacing={1}
              sx={{ alignItems: 'center', ml: 2 }}
            >
              <Box
                component="img"
                alt="Label icon"
                src={URL.createObjectURL(uploadedAsset)}
                sx={(theme) => ({
                  maxWidth: 100,
                  maxHeight: 80,
                  mr: 5,
                  boxShadow: theme.shadows[5],
                  ':hover': { backgroundImage: `url(${checkerboard})` },
                })}
              ></Box>

              <FormControlLabel
                control={
                  <Checkbox
                    color="secondary"
                    onChange={(ev) => setUploadConfig((config) => ({ ...config, default: ev.target.checked }))}
                  />
                }
                label="Outbound"
                sx={{ pr: 3 }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="secondary"
                    onChange={(ev) => setUploadConfig((config) => ({ ...config, return: ev.target.checked }))}
                  />
                }
                label="Inbound"
                sx={{ pr: 3 }}
              />

              <Button
                variant="outlined"
                onClick={() => {
                  setUploadedAsset(null);
                  setUploadMetaData(null);
                }}
                startIcon={<Clear />}
                size="small"
              >
                Clear
              </Button>
              <Button
                variant="contained"
                disabled={!isUploadValid}
                onClick={() => {
                  const reader = new FileReader();
                  reader.onloadend = () => {
                    // Use a regex to remove data url part
                    const labelIcon =
                      typeof reader.result === 'string' ? reader.result.replace('data:', '').replace(/^.+,/, '') : null;

                    if (labelIcon) {
                      createAsset(customerId, {
                        isDefault: uploadConfig.default,
                        isReturnLabel: uploadConfig.return,
                        labelIcon,
                      })
                        .then(() => {
                          setUploadMetaData(null);
                          setUploadedAsset(null);
                          setUploadConfig({ default: false, return: false });

                          notifications.addInfo('Label icon uploaded.');

                          assets.mutate();
                        })
                        .catch((err) => notifications.addError(err));
                    }
                  };
                  reader.readAsDataURL(uploadedAsset);
                }}
                startIcon={<UploadFile />}
                color="secondary"
                size="small"
              >
                Upload
              </Button>
            </Stack>
            {!isUploadValid && !!uploadMetaData && (
              <Alert
                severity="warning"
                sx={{ mt: 3 }}
              >
                <AlertTitle>Icon too large</AlertTitle>
                {uploadMetaData.size > MAX_BYTE_SIZE &&
                  `The file size of ${uploadMetaData.size} bytes is more than the allowed maximum of ${MAX_BYTE_SIZE} bytes. `}
                {uploadMetaData.width > MAX_PX_WIDTH &&
                  `The width of ${uploadMetaData.width}px is more than the allowed maximum of ${MAX_PX_WIDTH}px. `}
                {uploadMetaData.height > MAX_PX_HEIGHT &&
                  `The width of ${uploadMetaData.height}px is more than the allowed maximum of ${MAX_PX_HEIGHT}px. `}
              </Alert>
            )}
          </Paper>
        ) : (
          <Box>
            <input
              style={{ display: 'none' }}
              id="logo-upload"
              type="file"
              name="asset"
              accept="image/png"
              onChange={(event) => {
                const file = event.target.files?.[0] ?? null;

                setUploadedAsset(file);

                if (file) {
                  const image = new Image();

                  image.onload = () => {
                    setUploadMetaData({
                      size: file.size,
                      width: image.width,
                      height: image.height,
                    });
                  };

                  image.src = URL.createObjectURL(file);
                }
              }}
            />
            <label htmlFor="logo-upload">
              <Button
                color="primary"
                component="span"
                variant="outlined"
                startIcon={<UploadFile />}
              >
                Upload PNG
              </Button>
            </label>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CustomerAssets;
