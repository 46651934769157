import { IconButton } from '@mui/material';
import ReplyIcon from '@mui/icons-material/Reply';
import React from 'react';
import { OrderDto } from '../../services/order-service/order.service';
import { useProcessSettings } from '../../services/process-settings-service/processSettings.service';
import { generateReturnPortalMac } from '../../services/authentication-service/authentication.service';
import { returnPortalUrl } from '../../shared/helper/env/helper';

type Props = {
  order: OrderDto;
};

const ReturnPortalButton: React.FC<Props> = ({ order }) => {
  const settings = useProcessSettings(order.processId);

  const returnPortalLogin = async () => {
    if (settings.data) {
      const loginMac = await generateReturnPortalMac(order.orderId);
      const loginUrl = new URL(
        `${returnPortalUrl}/${settings.data.processReturnPortalIdentifier}/${order.processId}/login`,
      );
      loginUrl.searchParams.set('mac', loginMac.mac);
      loginUrl.searchParams.set('orderId', order.orderId.toString());
      window.open(loginUrl.href, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <IconButton onClick={returnPortalLogin}>
      <ReplyIcon />
    </IconButton>
  );
};

export default ReturnPortalButton;
