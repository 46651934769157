import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React, { FC } from 'react';
import * as ShipmentMethodService from '../../../../../../../services/shipment-method-service/shipmentMethod.service';
import { useStyles } from './defaultShipmentMethodView.style';
import { Direction } from '../../../../../../../shared/backend';

export interface DefaultShipmentMethodViewProps {
  shipmentMethods: ShipmentMethodService.ShipmentMethodDto[] | undefined;
  onShipmentMethodUpdate: (
    body: Partial<ShipmentMethodService.ShipmentMethodDto>,
    shipmentMethodId: number,
    processId: number,
  ) => Promise<ShipmentMethodService.ShipmentMethodDto | undefined>;
  processId: number;
  returnShipment: boolean;
  disabled?: boolean;
}

const DefaultShipmentMethodView: FC<DefaultShipmentMethodViewProps> = (props: DefaultShipmentMethodViewProps) => {
  const classes = useStyles();
  const direction = props.returnShipment ? Direction.INBOUND : Direction.OUTBOUND;

  return (
    <FormControl
      variant="outlined"
      className={classes.formControl}
    >
      <InputLabel id="default-shipment-method-label">
        {props.returnShipment ? 'Retouren Versandmethode' : 'Standard Versandmethode'}
      </InputLabel>
      <Select
        labelId="default-shipment-method-label"
        id="default-shipment-method"
        disabled={!!props.disabled || props.shipmentMethods?.length === 0}
        value={
          (props.returnShipment
            ? props.shipmentMethods?.find((sm) => sm.isRetourDefault === true)?.shipmentMethodId
            : props.shipmentMethods?.find((sm) => sm.isDefault === true)?.shipmentMethodId) ?? ''
        }
        onChange={(event) => {
          const newShipmentMethod: Partial<ShipmentMethodService.ShipmentMethodDto> = props.returnShipment
            ? {
                isRetourDefault: true,
              }
            : {
                isDefault: true,
              };
          props.onShipmentMethodUpdate(newShipmentMethod, Number(event.target.value), props.processId);
        }}
        label="default-shipment-method"
      >
        {props.shipmentMethods
          ?.filter((sm) => !sm.deletedAt && (!sm.direction || sm.direction === direction))
          .map((shipmentMethod) => (
            <MenuItem
              key={shipmentMethod.shipmentMethodId}
              value={shipmentMethod.shipmentMethodId}
            >
              {shipmentMethod.methodName}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default DefaultShipmentMethodView;
