import { Box, CircularProgress, Modal } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import React, { FC, Fragment, useState } from 'react';
import { useNotifications } from '../../../../../hooks/useNotifications';
import * as ShipmentMethodService from '../../../../../services/shipment-method-service/shipmentMethod.service';
import { ShipmentUpdateDto } from '../../../../../services/shipment-service/dto/shipmentUpdate.dto';
import useStyles from './OrderDetailModal.style';
import ModalBody from './components/ModalBody';
import AddressDetailCard from './components/AddressDetailCard';
import InfoCard from './components/InfoCard';
import { ShipmentUpdateResponseDto } from '../../../../../services/shipment-service/dto/shipmentUpdateResponse.dto';
import { OrderService, OrderUpdateDto } from '../../../../../services/order-service/order.service';
import { ShipmentService } from '../../../../../services/shipment-service/shipment.service';
import { useAuthentication } from '../../../../../hooks/useAuthentication';
import { OrderType } from '../../../../../shared/backend';

export interface OrderDetailModalProps {
  open: boolean;
  onClose: () => void;
  onNextShipment: () => void;
  orderId: number | null;
  shipmentId: number | null;
  scannedTrackingCode?: string;
  preventReset?: boolean;
  showArticleWeights?: boolean;
}

const OrderDetailModal: FC<OrderDetailModalProps> = (props) => {
  const classes = useStyles();
  const notificationHandler = useNotifications();

  const auth = useAuthentication();

  const [isDetailPanelExpanded, setDetailPanelExpanded] = useState<boolean>(false);
  const [editAddressAndShipment, setEditAddressAndShipment] = useState<boolean>(false);

  const order = OrderService.useOrderById(props.orderId);
  const shipments = ShipmentService.useShipmentsByOrder(order.data?.orderId, OrderType.ORDER);
  const shipmentMethods = ShipmentMethodService.useShipmentMethodsByProcess(
    auth.isStaff() ? order.data?.processId : null,
  );

  const isLoading = order.isLoading || shipments.isLoading || shipmentMethods.isLoading;
  const process = order.data?.process ?? null;

  const resetModal = () => {
    setDetailPanelExpanded(false);
    setEditAddressAndShipment(false);
  };

  const handleClose = () => {
    props.onClose();
    resetModal();
  };

  const handleNextShipment = () => {
    props.onNextShipment();

    if (!props.preventReset) {
      resetModal();
    }

    props.onClose();
  };

  const handleShipmentUpdate = async (shipmentId: number, body: ShipmentUpdateDto) => {
    let updatedShipment: ShipmentUpdateResponseDto;

    try {
      updatedShipment = await ShipmentService.updateShipment(shipmentId, body, { referrer: window.location.href });
    } catch (error) {
      notificationHandler.addError(error);

      return;
    }

    const updatedShipments = shipments.data.map((shipment) =>
      shipment.shipmentId === updatedShipment.shipmentId ? { ...shipment, ...updatedShipment } : shipment,
    );

    shipments.mutate(updatedShipments);
  };

  const saveOrder = async (update: OrderUpdateDto): Promise<boolean> => {
    try {
      if (!order.data) {
        throw new Error('Order not selected');
      }

      const updatedOrder = await OrderService.putOrder(update, order.data.orderId, { referrer: window.location.href });

      order.data &&
        order.mutate({
          ...order.data,
          ...updatedOrder,
        });
      setEditAddressAndShipment(false);

      return true;
    } catch (error) {
      notificationHandler.addError(error);
    }

    return false;
  };

  const handleSwitchEditAddress = (edit: boolean) => {
    setEditAddressAndShipment(edit);

    if (edit) {
      setDetailPanelExpanded(true);
    }
  };

  return (
    <Fragment>
      <Modal
        open={props.open}
        onClose={handleClose}
        disableScrollLock
      >
        <Fragment>
          <div className={classes.paper}>
            {!isLoading && order.data && process !== null ? (
              <Fragment>
                <Box className={classes.modalHeader}>
                  <Box
                    sx={(theme) => ({
                      backgroundColor: theme.palette.action.hover,
                      padding: theme.spacing(3),
                    })}
                  >
                    <div className={classes.modalHeaderBody}>
                      <InfoCard
                        order={order.data}
                        customer={process.customer}
                      />

                      <div className={classes.detailCardWrapper}>
                        <AddressDetailCard
                          {...{
                            order: order.data,
                            isPanelExpaned: isDetailPanelExpanded,
                            editAddressAndShipment,
                            setEditAddressAndShipment: handleSwitchEditAddress,
                            saveOrder: saveOrder,
                            shipments: shipments.data,
                          }}
                        />

                        <div
                          style={{
                            position: 'absolute',
                            bottom: -21,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                          }}
                        >
                          <Box
                            className={classes.expandButton}
                            onClick={() => setDetailPanelExpanded(!isDetailPanelExpanded)}
                          >
                            {!isDetailPanelExpanded ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                          </Box>
                        </div>
                      </div>
                    </div>
                  </Box>
                </Box>
                <ModalBody
                  {...{
                    order: order.data,
                    shipments: shipments.data,
                    shipmentMethods: shipmentMethods.data,
                    orderType: OrderType.ORDER,
                    shipmentId: props.shipmentId,
                    scannedTrackingCode: props.scannedTrackingCode,
                    handleNextShipment,
                    mutateOrder: order.mutate,
                    mutateShipments: shipments.mutate,
                    handleShipmentUpdate,
                    handleSwitchEditAdress: handleSwitchEditAddress,
                    editAddressAndShipment: editAddressAndShipment,
                    showArticleWeights: props.showArticleWeights,
                  }}
                />
              </Fragment>
            ) : (
              <div>
                <div className={classes.loadingWrapper}>
                  <CircularProgress color="secondary" />
                </div>
              </div>
            )}
          </div>
        </Fragment>
      </Modal>
    </Fragment>
  );
};

export default OrderDetailModal;
