import { Grid, Paper, Box, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import BreadcrumbsGridItem from '../crm/BreadcrumbsGridItem';
import { useTranslation } from 'react-i18next';
import { SHIPPING_UNIT_BARCODE_REGEX, ShippingService } from '../../services/shipping-service/shipping.service';
import { Send } from '@mui/icons-material';
import { useIntParam, useEnumParam } from '../../hooks/useParam';
import { CustomerService } from '../../services/customer-service/customer.service';
import { CustomerFilter } from '../../shared/components/customer-filter/CustomerFilter';
import ShippingUnitStateOptionSelector, {
  getShippingUnitStateFromShippingUnitStateOption,
  ShippingUnitStateOption,
} from './ShippingUnitStateOptionSelector';
import { useNotifications } from '../../hooks/useNotifications';
import ShippingUnitTable from './ShippingUnitTable';
import { confirmCloseShippingUnit } from './CloseShippingUnitDialog';

type Props = {};

const ShippingUnitsPage: React.FC<Props> = () => {
  const { t } = useTranslation();
  const notifications = useNotifications();
  const customers = CustomerService.useCustomers();

  const [closeUnitBarcode, setCloseUnitBarcode] = useState('');

  const [page, setPage] = useIntParam('page', 0);
  const [pageSize] = useIntParam('pageSize', 10);
  const [stateOption, setStateOption] = useEnumParam('state', ShippingUnitStateOption, ShippingUnitStateOption.BOTH);
  const [customerId, setCustomerId] = useIntParam('customerId');

  const units = ShippingService.useShippingUnits({
    limit: pageSize,
    offset: page * pageSize,
    customerId,
    state: getShippingUnitStateFromShippingUnitStateOption(stateOption),
  });

  return (
    <Grid
      container
      spacing={3}
    >
      <Helmet>
        <title>{`${t('Shipping units')} | ${t('page')} ${page + 1}`}</title>
      </Helmet>

      <BreadcrumbsGridItem items={[{ label: t('Shipping units') }]} />

      <Grid
        item
        xs={12}
      >
        <Paper sx={{ padding: 1, paddingTop: 2 }}>
          <Typography
            variant="h5"
            gutterBottom
          >
            {t('Shipping unit')}
          </Typography>
          <Typography
            variant="body1"
            mb={3}
          >
            {t('We combine multiple shipments in one shipping unit to for example safe shipping costs.')}
          </Typography>

          <Stack
            direction="row"
            spacing={1}
          >
            <ShippingUnitStateOptionSelector
              stateOption={stateOption}
              onChange={(value) => {
                setPage(0);
                setStateOption(value);
              }}
            />

            <CustomerFilter
              customers={customers.data.filter(({ processes }) =>
                processes.some((process) => process.useShippingUnitForReturns),
              )}
              onChange={([id]) => {
                setPage(0);
                setCustomerId(id);
              }}
              selectedCustomerIds={customerId ? [customerId] : []}
              multiple={false}
            />

            <Box flexGrow={1}></Box>

            <form
              onSubmit={async (ev) => {
                ev.preventDefault();

                const barcode = closeUnitBarcode.trim();

                if (!barcode) {
                  return;
                }

                if (!SHIPPING_UNIT_BARCODE_REGEX.test(barcode)) {
                  notifications.addError(t('No shipping unit barcode detected'));

                  return;
                }

                try {
                  await confirmCloseShippingUnit({ barcode });

                  units.mutate();

                  setCloseUnitBarcode('');
                } catch (err) {
                  if (err) {
                    notifications.addError(err);
                  }
                }
              }}
            >
              <TextField
                label={t('Close shipping unit')}
                name="barcode"
                size="small"
                required
                value={closeUnitBarcode}
                onChange={(ev) => setCloseUnitBarcode(ev.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        disabled={!SHIPPING_UNIT_BARCODE_REGEX.test(closeUnitBarcode.trim())}
                        type="submit"
                        edge="end"
                      >
                        <Send />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </form>
          </Stack>
        </Paper>
      </Grid>

      <Grid
        item
        xs={12}
      >
        <Paper>
          <ShippingUnitTable
            units={units.data}
            isLoading={units.isLoading}
            isValidating={units.isValidating ?? false}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ShippingUnitsPage;
