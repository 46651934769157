import { Button, CircularProgress, IconButton, InputAdornment, Stack, TextField } from '@mui/material';

import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import { useScanInput } from '../../hooks/keypress';
import { Search as SearchIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const MIN_SCAN_LENGTH = 4;
const IGNORED_SEARCH_TERMS = ['0509', '0203'];

export interface TableSearchHandles {
  focus: () => void;
}
export interface TableSearchProps {
  disabled?: boolean;
  isLoading?: boolean;
  defaultValue?: string;
  handleSearchChange: (localSearchQuery: string) => void;
  placeholder?: string;
  autoFocus?: boolean;
}
export interface formFields {
  search: string;
}

export const TableSearch = forwardRef((props: TableSearchProps, ref: React.Ref<TableSearchHandles>) => {
  const { t } = useTranslation();
  const textFieldRef = useRef<HTMLInputElement | null>(null);
  const [prevSearchQuery, setPrevSearchQuery] = React.useState(props.defaultValue || '');
  const [searchQuery, setSearchQuery] = React.useState(props.defaultValue || '');
  const [searchInputValue, setSearchInputValue] = React.useState(props.defaultValue || '');

  const processSearchQuery = async () => {
    if (IGNORED_SEARCH_TERMS.includes(searchQuery.trim())) {
      setSearchQuery('');

      return;
    }

    props.handleSearchChange(searchQuery);
  };

  useImperativeHandle(
    ref,
    () => ({
      focus: () => {
        if (null !== textFieldRef?.current) {
          setSearchQuery('');
          textFieldRef.current.focus();
        }
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const scanCallback = useCallback(
    (buffer: string) => {
      if (buffer.length >= MIN_SCAN_LENGTH && !props.disabled) {
        setPrevSearchQuery('');
        setSearchQuery(buffer);
      }
    },
    [props.disabled],
  );

  useScanInput(scanCallback);

  useEffect(() => {
    if (!props.isLoading) {
      textFieldRef.current?.focus();
    }
  }, [props.isLoading]);

  useEffect(() => {
    const isPastedInput = searchQuery.length >= MIN_SCAN_LENGTH && prevSearchQuery.length === 0;

    if (isPastedInput || searchQuery.length === 0) {
      processSearchQuery();
    }

    setPrevSearchQuery(searchQuery);
    setSearchInputValue(searchQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  useEffect(() => {
    setSearchQuery(props.defaultValue || '');
  }, [props.defaultValue]);

  useEffect(() => {
    const debounceTimeout = window.setTimeout(() => {
      setSearchQuery(searchInputValue);
    }, 100);

    return () => window.clearTimeout(debounceTimeout);
  }, [searchInputValue]);

  return (
    <form
      onSubmit={(ev) => {
        ev.preventDefault();

        processSearchQuery();
      }}
    >
      <Stack
        direction="row"
        spacing={1}
      >
        <TextField
          disabled={props.isLoading || props.disabled}
          inputRef={textFieldRef}
          id={'search'}
          value={searchInputValue}
          onChange={(ev) => setSearchInputValue(ev.target.value)}
          placeholder={props.placeholder ?? 'Suche'}
          autoFocus={props.autoFocus}
          variant={'outlined'}
          style={{
            flexGrow: 1,
          }}
          autoComplete="off"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {props.isLoading ? (
                  <CircularProgress
                    size="1.5em"
                    color="secondary"
                  />
                ) : (
                  searchQuery && (
                    <IconButton
                      size="small"
                      color="secondary"
                      onClick={() => {
                        setSearchQuery('');
                        textFieldRef.current?.focus();
                      }}
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  )
                )}
              </InputAdornment>
            ),
          }}
        />

        <Button
          disableElevation
          variant="contained"
          color="secondary"
          type="submit"
          disabled={props.isLoading}
          startIcon={<SearchIcon />}
        >
          {t('Search')}
        </Button>
      </Stack>
    </form>
  );
});
