import { Button, ButtonGroup, CircularProgress, Stack } from '@mui/material';
import React, { useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import PrintIcon from '@mui/icons-material/Print';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ShipmentMethodDto } from '../../../../../../../../services/shipment-method-service/shipmentMethod.service';
import { ShipmentDto } from '../../../../../../../../services/shipment-service/shipment.service';
import { ShipmentState } from '../../../../../../../../shared/backend';

interface ShipmentViewOrderProps {
  shipment: ShipmentDto;
  isShipmentWeightValid: () => boolean;
  disablePrinting?: boolean;
  handleAcceptShipment: () => Promise<void>;
  handlePrint: () => Promise<void>;
  handleDropDownButtonClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleDelete: () => void;
  disableDeletion?: boolean;
  disableShipmentEditing: boolean;
  shipmentMethods: ShipmentMethodDto[];
  selectedShipmentMethodId: number | null;
}

export const ShipmentViewOrder: React.FC<ShipmentViewOrderProps> = (props: ShipmentViewOrderProps) => {
  const [isPrinting, setIsPrinting] = useState(false);

  const isNewOrderTrackingCodeAvailable =
    !!props.shipment.foreignOutboundTrackingId && props.shipment.state === ShipmentState.NEW;

  const isShowDeleteButton = !(props.shipment.foreignOutboundTrackingId && props.shipment.state === ShipmentState.NEW);

  const isPrintingDisabled =
    !props.isShipmentWeightValid() || props.disablePrinting || props.disableShipmentEditing || isPrinting;

  const selectedShipmentMethod = props.shipmentMethods.find((method) =>
    props.selectedShipmentMethodId !== null
      ? method.shipmentMethodId === props.selectedShipmentMethodId
      : method.isDefault,
  );

  return (
    <Stack
      direction="row"
      spacing={1}
      justifyContent="flex-end"
      alignItems="center"
    >
      {isNewOrderTrackingCodeAvailable && (
        <Button
          size="large"
          variant="contained"
          disabled={!props.isShipmentWeightValid() || props.disablePrinting}
          onClick={() => props.handleAcceptShipment()}
          color="printed"
          sx={{ height: '55px' }}
        >
          <CheckIcon />
        </Button>
      )}

      {props.shipmentMethods.length > 0 && (
        <ButtonGroup
          size={isNewOrderTrackingCodeAvailable ? 'small' : 'large'}
          variant={isNewOrderTrackingCodeAvailable ? 'outlined' : 'contained'}
          color={isNewOrderTrackingCodeAvailable ? undefined : 'printed'}
          sx={isNewOrderTrackingCodeAvailable ? undefined : { height: '100%' }}
          disabled={isPrintingDisabled}
          disableElevation={isPrintingDisabled}
        >
          <Button
            onClick={() => {
              setIsPrinting(true);
              props.handlePrint().then(() => setIsPrinting(false));
            }}
            startIcon={
              isPrinting ? (
                <CircularProgress
                  size="1.8em"
                  color="inherit"
                />
              ) : (
                <PrintIcon />
              )
            }
          >
            {selectedShipmentMethod?.methodName?.replaceAll('_', ' ').replace(/(swiss|schweizer) post/i, '')}
          </Button>

          {props.shipmentMethods.length > 1 && (
            <Button
              size="small"
              onClick={props.handleDropDownButtonClick}
            >
              <ArrowDropDownIcon
                style={{ margin: '0' }}
                fontSize="large"
              />
            </Button>
          )}
        </ButtonGroup>
      )}

      {isShowDeleteButton && (
        <Button
          variant="contained"
          color="secondary"
          onClick={props.handleDelete}
          style={{ height: '100%' }}
          disabled={props.disableDeletion || props.disableShipmentEditing}
        >
          <DeleteIcon />
        </Button>
      )}
    </Stack>
  );
};
