import { Alert, Box, Button, CircularProgress, FormControlLabel, Paper, Stack, Switch } from '@mui/material';
import React, { useState } from 'react';
import { useBooleanParam, useEnumListParam, useIntParam, useStringParam } from '../../hooks/useParam';
import { useAuthentication } from '../../hooks/useAuthentication';
import { ShipmentFilter } from '../../shared/components/shipment-filter/ShipmentFilter';
import { ShipmentDto, ShipmentReturnDto } from '../../services/shipment-service/shipment.service';
import { ShipmentService } from '../../services/shipment-service/shipment.service';
import { ShipmentCreateWithLineItemDto } from '../../services/shipment-service/dto/shipmentCreateWithLineItem.dto';
import ReturnShipmentCounter from './ReturnShipmentCounter';
import SearchForm from './SearchForm';
import { OrderService } from '../../services/order-service/order.service';
import { useNotifications } from '../../hooks/useNotifications';
import SelectionWarehouse from './SelectionWarehouse';
import SelectionCustomer from './SelectionCustomer';
import { useHistoryNavigation } from '../../hooks/useHistoryNavigation';
import { ShipmentState, ShipmentType } from '../../shared/backend';
import SelectionProcessType from './SelectionProcessType';
import { useTranslation } from 'react-i18next';

async function createInboundShipment(outboundShipment: ShipmentReturnDto, searchQuery: string): Promise<ShipmentDto> {
  const order = await OrderService.getOrderById(outboundShipment.orderId); // we need all line items
  const returnShipments = await ShipmentService.getRetoureShipmentsByOrder(order.orderId);

  const body: ShipmentCreateWithLineItemDto = {
    lineItems: order.lineItems.map(({ lineItemId }) => ({ lineItemId })),
    foreignInboundTrackingId:
      outboundShipment.foreignInboundTrackingId ??
      (order.returnTrackingId === searchQuery ? order.returnTrackingId : null),
  };

  if (order.lineItems?.length === 1 && order.lineItems[0].quantity === 1 && !returnShipments.length) {
    return await ShipmentService.createRetoureShipment(outboundShipment.shipmentId, body);
  }

  body.lineItems = [];

  return await ShipmentService.createShipmentByOrder(order.orderId, { ...body, shipmentType: ShipmentType.RETOUR });
}

type Props = {
  isLoading: boolean;
  inboundShipment: ShipmentReturnDto | undefined;
  outboundShipment: ShipmentReturnDto | undefined;
  mutate: () => Promise<void>;
};

const InboundShipmentSearch: React.FC<Props> = ({ isLoading, inboundShipment, outboundShipment, mutate }) => {
  const auth = useAuthentication();
  const { t } = useTranslation();
  const { navigateTo } = useHistoryNavigation();
  const notifications = useNotifications();

  const [isCreating, setCreating] = useState(false);

  const [searchQuery, setSearchQuery] = useStringParam('q', '');
  const [useExtendedSearchOverAllFields, setUseExtendedSearchOverAllFields] = useBooleanParam('extended', false);
  const [shipmentStates, setShipmentStates] = useEnumListParam('states', ShipmentState, []);
  const [, setPage] = useIntParam('page', 0);

  const handleCreateReturnShipment = async () => {
    if (isLoading || isCreating) {
      console.log('Do not create return shipment, because something is loading.');
      return;
    }

    if (!outboundShipment) {
      console.log('Do not create return shipment, because we have no outbound shipment.');
      return;
    }

    if (outboundShipment.process.blocked) {
      notifications.addWarning(t('The store is blocked. Do not process shipment.'));
      return;
    }

    setCreating(true);

    try {
      const inboundShipment = await createInboundShipment(outboundShipment, searchQuery);

      // if we don't mutate the search result, and go back in our history another shipment is created
      await mutate();

      navigateTo(`/shipment/${inboundShipment.shipmentId}`);
    } catch (err) {
      notifications.addError(t('Can not create return shipment: {{err}}', { err }));
    } finally {
      setCreating(false);
    }
  };

  const handleOpenInboundShipment = () => {
    if (!inboundShipment) {
      return;
    }

    if (inboundShipment.process.blocked) {
      notifications.addWarning(t('The store is blocked. Do not process shipment.'));
      return;
    }

    navigateTo(`/shipment/${inboundShipment.shipmentId}`);
  };

  return (
    <Paper sx={{ padding: 1 }}>
      <Stack
        direction="row"
        spacing={1}
      >
        <Box flexGrow={1}>
          <SearchForm
            autoFocus={true}
            isLoading={isLoading}
            disabled={isCreating}
            disableButton={!!outboundShipment || !!inboundShipment}
            searchQuery={searchQuery}
            onSubmit={async (value, isFormSubmitted) => {
              if (value === searchQuery) {
                if (isFormSubmitted) {
                  if (outboundShipment) {
                    handleCreateReturnShipment();
                  } else if (inboundShipment) {
                    handleOpenInboundShipment();
                  }
                }
              } else {
                setPage(0);
                setSearchQuery(value);
              }
            }}
          />
        </Box>

        <Button
          variant="contained"
          color="success"
          onClick={() => handleOpenInboundShipment()}
          disabled={!inboundShipment || isLoading || isCreating || inboundShipment.process.blocked}
          style={{
            padding: '0 0.5rem',
            fontSize: '0.8rem',
          }}
        >
          {t('Open')}
        </Button>

        {auth.isStaff() && (
          <>
            <Button
              variant="contained"
              color="warning"
              onClick={() => {
                handleCreateReturnShipment();
              }}
              disabled={!outboundShipment || isLoading || isCreating || outboundShipment.process.blocked}
              style={{
                padding: '0 0.5rem',
                fontSize: '0.8rem',
                minWidth: '110px',
              }}
              startIcon={isCreating ? <CircularProgress size="1em" /> : undefined}
            >
              {t('Register return')}
            </Button>

            <ReturnShipmentCounter />
          </>
        )}
      </Stack>

      <FormControlLabel
        sx={{ pt: 0 }}
        control={
          <Switch
            onChange={(ev) => setUseExtendedSearchOverAllFields(ev.target.checked)}
            checked={useExtendedSearchOverAllFields}
          />
        }
        label={t('Advanced search (address, e-mail, ...)')}
      />

      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
      >
        {auth.isStaff() ? <SelectionWarehouse direction="inbound" /> : <SelectionProcessType />}

        {auth.isStaff() && <SelectionCustomer multiple={true} />}

        <ShipmentFilter onChange={(states) => setShipmentStates(states)} />

        {auth.isStaff() && !!shipmentStates.length && (
          <Alert severity="info">Mit Status-Filter können keine Retouren erstellt werden.</Alert>
        )}

        <Box flexGrow={1}></Box>
      </Stack>
    </Paper>
  );
};

export default InboundShipmentSearch;
