import React from 'react';
import { ProcessDto, ProcessService } from '../../services/process-service/process.service';
import SelectWithFilter from './SelectWithFilter';
import { FilledInputProps, InputProps, OutlinedInputProps, SxProps } from '@mui/material';

type Props = {
  processId: number | null;
  onChange: (processId: number | null) => void;
  filter?: (process: ProcessDto) => boolean;
  disabled?: boolean;
  label?: string;
  fullWidth?: boolean;
  required?: boolean;
  size?: 'small' | 'medium';
  sx?: SxProps;
  InputProps?: Partial<FilledInputProps> | Partial<OutlinedInputProps> | Partial<InputProps>;
};

const SelectProcess: React.FC<Props> = ({ processId, onChange, label, filter, ...props }) => {
  const processes = ProcessService.useProcesses();

  return (
    <SelectWithFilter
      selectedValueIds={[processId ?? '']}
      values={processes.data
        .filter(filter ?? (() => true))
        .sort((a, b) => a.customer.company.localeCompare(b.customer.company))
        .map((process) => ({
          id: process.processId,
          label: `${process.customer.company} (${process.processId})`,
        }))}
      onChange={([id]) => onChange(id || null)}
      label={label ?? 'Prozess'}
      {...props}
    />
  );
};

export default SelectProcess;
