import { Box, Card, CircularProgress, Menu, MenuItem, Stack, Tooltip, Typography } from '@mui/material';
import React, { FC, useEffect, useRef, useState } from 'react';
import { ScaleContext, scaleContext } from '../../../../../../../contexts/ScaleContext';
import { ShipmentUpdateDto } from '../../../../../../../services/shipment-service/dto/shipmentUpdate.dto';
import * as ShipmentMethodService from '../../../../../../../services/shipment-method-service/shipmentMethod.service';
import ShipmentLineItemView from './components/shipmentLineItemView';
import { LineItemUpdateDto } from '../../../../../../../services/line-item-service/lineItem.service';
import { ShipmentStateIcon } from '../../../../../../../shared/components/shipment-state-icon/ShipmentStateIcon';
import { ShipmentAcceptModal } from '../shipment-accept-modal/ShipmentAcceptModal';
import { ShipmentWeight, isShipmentWeightTextValid } from './components/ShipmentWeight';
import { ShipmentViewOrder } from './components/ShipmentViewOrder';
import { OrderClosed } from './components/OrderClosed';
import { ExportoBarcode } from './components/ExportoBarcode';
import { TrackingInformation } from './components/TrackingInformation';
import { useNotifications } from '../../../../../../../hooks/useNotifications';
import UserLabel from '../../../../../../activity/components/UserLabel';
import { ceil } from 'lodash';
import { ShipmentDto, ShipmentService } from '../../../../../../../services/shipment-service/shipment.service';
import { Direction, OrderType, ShipmentState, ShipmentType } from '../../../../../../../shared/backend';
import { OrderWithCustomerDto } from '../../../../../../../services/order-service/order.service';
import { useAuthentication } from '../../../../../../../hooks/useAuthentication';
import { isWeightWarningAccepted } from './WeightWarningDialog';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import HistoryIconButton from '../../../../../../../shared/components/HistoryIconButton';

export interface ShipmentFormProps {
  shippingWeight: string | null;
}
export interface ShipmentViewProps {
  heading: string;
  shipment: ShipmentDto;
  shipmentMethods: ShipmentMethodService.ShipmentMethodDto[];
  disableDeletion?: boolean;
  focused?: boolean;
  disablePrinting?: boolean;
  onDelete: (shipmentId: number) => void;
  onUpdate: (shipmentId: number, body: ShipmentUpdateDto) => Promise<void>;
  onLineItemDelete: (shipmentId: number, lineItemId: number) => Promise<void>;
  onLineItemUpdate: (body: LineItemUpdateDto[]) => void;
  onPrint: (shipmentId: number, shipmentMethodId: number, downloadable?: boolean) => Promise<void>;
  onNextShipment: () => void;
  orderId: number;
  orderTyp: OrderType;
  order: OrderWithCustomerDto;
  reloadShipments: () => Promise<void>;
  triggerEditAdress: (edit: boolean) => void;
  disableShipmentEditing: boolean;
  showArticleWeights?: boolean;
}

const ShipmentView: FC<ShipmentViewProps> = (props) => {
  const auth = useAuthentication();
  const notificationHandler = useNotifications();
  const scale = React.useContext<ScaleContext>(scaleContext);
  const [loadingShipmentMethodId, setLoadingShipmentMethodId] = React.useState<number | null>(null);
  const [shipmentForm, setShipmentForm] = React.useState<ShipmentFormProps>({
    shippingWeight: props.shipment.shippingWeight?.toString() || null,
  });
  const [shippingWeightSaved, setShippingWeightSaved] = React.useState<boolean>();
  const [printMenuAnchor, setPrintMenuAnchor] = React.useState<null | HTMLElement>(null);

  const [isWorking, setWorking] = useState(false);
  const [blockScaleInput, setBlockScaleInput] = useState<boolean>(false);
  const [shipmentAcceptModalOpen, setShipmentAcceptModalOpen] = useState<boolean>(false);

  const [selectedShipmentMethodId, setSelectedShipmentMethodId] = useState<number | null>(null);

  const lineItemsContainerRef = useRef<HTMLDivElement | null>(null);

  const setBlockScaleInputFromChild = (value: boolean) => {
    setBlockScaleInput(value);
  };

  const setShippingWeightSavedFromChild = (value: boolean) => {
    setShippingWeightSaved(value);
  };

  const handleFormChange = (prop: keyof ShipmentFormProps) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setShipmentForm({ ...shipmentForm, [prop]: event.target.value });
  };

  const handleDelete = () => {
    props.onDelete(props.shipment.shipmentId);
  };

  const handlePrint = async () => {
    if (!(await isWeightWarningAccepted(props.shipment.shippingWeight, props.order.process.customer))) {
      return;
    }

    setWorking(true);
    setBlockScaleInput(true);
    const chosenShipmentMethodId =
      selectedShipmentMethodId ?? props.shipmentMethods.find((sm) => sm.isDefault === true)?.shipmentMethodId ?? -1;

    if (
      props.shipment.lineItems.length === 0 &&
      props.shipment.type === ShipmentType.SHIPMENT &&
      props.shipment.state === ShipmentState.NEW
    ) {
      await ShipmentService.handleEmptyShipmentFallback(props.shipment.shipmentId);
    }
    setLoadingShipmentMethodId(chosenShipmentMethodId);
    await props.onPrint(props.shipment.shipmentId, chosenShipmentMethodId);
    setPrintMenuAnchor(null);
    setLoadingShipmentMethodId(null);
    await props.reloadShipments();
    setWorking(false);
  };

  const handleLineItemDelete = async (lineItemId: number) => {
    await props.onLineItemDelete(props.shipment.shipmentId, lineItemId);
  };

  const handleDropDownButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setPrintMenuAnchor(event.currentTarget);
  };

  const handlePrintMenuClose = () => {
    setPrintMenuAnchor(null);
  };

  const isShipmentWeightValid = (): boolean => {
    return isShipmentWeightTextValid(shipmentForm.shippingWeight);
  };

  const handleShipmentAcceptModalClose = async () => {
    setShipmentAcceptModalOpen(false);
    props.onNextShipment();
  };

  const handleAcceptShipment = async () => {
    if (!(await isWeightWarningAccepted(props.shipment.shippingWeight, props.order.process.customer))) {
      return;
    }

    setWorking(true);

    const shipmentId: number = props.shipment.shipmentId;
    const shipmentType = props.shipment.type;

    try {
      switch (shipmentType) {
        case ShipmentType.SHIPMENT: {
          if (props.shipment.lineItems.length === 0 && props.shipment.state === ShipmentState.NEW) {
            await ShipmentService.handleEmptyShipmentFallback(shipmentId);
          }
          await ShipmentService.updateShipment(
            shipmentId,
            {
              state: ShipmentState.LABEL_PRINTED,
            },
            { referrer: window.location.href, scaleWeight: scale.weight },
          );
          break;
        }
      }

      await props.reloadShipments();

      setShipmentAcceptModalOpen(true);
    } catch (error) {
      notificationHandler.addError(error);
    } finally {
      setWorking(false);
    }
  };

  const lineItemsAvailable = (): boolean => {
    return props.shipment.lineItems.length > 0;
  };

  const { focused, onUpdate } = props;
  const externalShipmentId = props.shipment.externalShipmentId;
  const processedAt = props.shipment.processedAt ? new Date(props.shipment.processedAt).toLocaleString() : null;
  const processedByUserId = auth.isStaff() ? props.shipment.processedByUserId : null;
  const shipmentId = props.shipment.shipmentId;
  const shipmentState = props.shipment.state;
  const shipmentWeight = props.shipment.shippingWeight;

  useEffect(() => {
    const handleScaleChange = async () => {
      if (!focused || props.disableShipmentEditing || blockScaleInput || !scale.weight) {
        return;
      }

      const scaleWeight = ceil(scale.weight);
      if (scaleWeight !== shipmentWeight) {
        setShipmentForm((s) => ({
          ...s,
          shippingWeight: scaleWeight.toString(),
        }));
        await onUpdate(shipmentId, {
          shippingWeight: scaleWeight,
        });
        setShippingWeightSaved(true);
      }
    };
    handleScaleChange();
  }, [scale.weight, props.disableShipmentEditing, focused, onUpdate, shipmentId, shipmentWeight, blockScaleInput]);

  return (
    <>
      <Card sx={(theme) => ({ padding: theme.spacing(1, 3, 2) })}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          marginBottom={1}
          padding={1}
          spacing={1}
        >
          <ShipmentStateIcon
            state={shipmentState}
            variant="avatar"
          />

          <Typography
            variant="h5"
            component="h1"
          >
            <Tooltip title={props.heading}>
              <Box
                component="span"
                sx={{
                  display: 'inline-block',
                  maxWidth: '6em',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  verticalAlign: 'text-bottom',
                  textOverflow: 'ellipsis',
                }}
              >
                {props.heading}
              </Box>
            </Tooltip>

            <HistoryIconButton
              entityName="shipment"
              entityId={props.shipment.shipmentId}
            />
          </Typography>
          {props.shipment.state !== ShipmentState.DISPOSED && auth.isStaff() && (
            <ShipmentWeight
              handleAcceptShipment={handleAcceptShipment}
              handleFormChange={handleFormChange}
              handlePrint={handlePrint}
              onUpdateShipment={async (weight: number) => {
                setWorking(true);

                try {
                  await props.onUpdate(props.shipment.shipmentId, {
                    shippingWeight: weight,
                  });
                } finally {
                  setWorking(false);
                }
              }}
              onUpdateForm={async (weight: number | null) =>
                await setShipmentForm({ ...shipmentForm, shippingWeight: weight?.toString() ?? '' })
              }
              setBlockScaleInputFromChild={setBlockScaleInputFromChild}
              setShippingWeightSavedFromChild={setShippingWeightSavedFromChild}
              shipment={props.shipment}
              shipmentForm={shipmentForm}
              shippingWeightSaved={shippingWeightSaved}
              autoFocus={focused && (props.shipment.type === ShipmentType.SHIPMENT || !scale.connected)}
              orderType={props.orderTyp}
              disableShipmentEditing={props.disableShipmentEditing}
              isScaleConnected={scale.connected}
              minWeight={props.order.process.customer.minWeight ?? null}
              maxWeight={props.order.process.customer.maxWeight ?? null}
            />
          )}

          <ExportoBarcode shipment={props.shipment} />

          <TrackingInformation
            shipment={props.shipment}
            process={auth.isPermanentEmployee() ? props.order.process : undefined}
          />

          <Box sx={{ flexGrow: 1 }}></Box>

          {isWorking && <CircularProgress sx={{ mx: 1 }} />}

          {auth.isStaff() && (
            <ShipmentViewOrder
              shipment={props.shipment}
              isShipmentWeightValid={isShipmentWeightValid}
              disablePrinting={props.disablePrinting || isWorking}
              handleAcceptShipment={handleAcceptShipment}
              handlePrint={handlePrint}
              handleDropDownButtonClick={handleDropDownButtonClick}
              handleDelete={handleDelete}
              disableDeletion={props.disableDeletion}
              disableShipmentEditing={props.disableShipmentEditing}
              shipmentMethods={props.shipmentMethods}
              selectedShipmentMethodId={selectedShipmentMethodId}
            />
          )}
        </Stack>

        {props.shipment.state === ShipmentState.CLOSED && <OrderClosed shipment={props.shipment} />}

        <div ref={lineItemsContainerRef}>
          {lineItemsAvailable() ? (
            <ShipmentLineItemView
              lineItems={props.shipment.lineItems}
              onLineItemDelete={handleLineItemDelete}
              reloadShipments={props.reloadShipments}
              disableShipmentEditing={props.disableShipmentEditing}
              showArticleWeights={props.showArticleWeights}
            />
          ) : (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Typography
                variant="h6"
                component="p"
              >
                {t('The package does not contain any items')}
              </Typography>
            </Box>
          )}
        </div>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          marginTop={1}
        >
          <Typography
            variant="body2"
            color="text.disabled"
          >
            <span>{t('Shipment ID: {{shipmentId}}', { shipmentId })}</span>
            {externalShipmentId && <span>; {t('External ID: {{externalShipmentId}}', { externalShipmentId })}</span>}
          </Typography>

          <Typography
            variant="body2"
            color="text.disabled"
          >
            {processedAt && processedByUserId ? (
              <Trans shouldUnescape={true}>
                Processed by <UserLabel userId={processedByUserId} /> at {{ processedAt }}
              </Trans>
            ) : processedByUserId ? (
              <Trans>
                Processed by <UserLabel userId={processedByUserId} />
              </Trans>
            ) : processedAt ? (
              <>{t('Processed at {{processedAt}}', { processedAt })}</>
            ) : (
              ''
            )}

            {auth.isNotPicker() && props.shipment.deliveryDate && (
              <span>
                Delivered at {`${new Date(props.shipment.deliveryDate).toLocaleString()}`}
                {auth.isStaff() && props.shipment.carrierWeight ? (
                  <span> with a weight of {props.shipment.carrierWeight}g</span>
                ) : null}
                .
              </span>
            )}
          </Typography>
        </Stack>
      </Card>

      <Menu
        id="simple-menu"
        anchorEl={printMenuAnchor}
        keepMounted
        open={Boolean(printMenuAnchor)}
        onClose={handlePrintMenuClose}
      >
        {props.shipmentMethods
          .filter(
            (shipmentMethod) =>
              shipmentMethod.direction !== Direction.INBOUND &&
              (selectedShipmentMethodId !== null
                ? shipmentMethod.shipmentMethodId !== selectedShipmentMethodId
                : !shipmentMethod.isDefault),
          )
          .map((shipmentMethod) => (
            <MenuItem
              key={shipmentMethod.shipmentMethodId}
              onClick={() => {
                setSelectedShipmentMethodId(shipmentMethod.shipmentMethodId);
                handlePrintMenuClose();
              }}
            >
              {loadingShipmentMethodId === shipmentMethod.shipmentMethodId && (
                <CircularProgress
                  size={20}
                  color="primary"
                />
              )}
              {shipmentMethod.methodName}
            </MenuItem>
          ))}
      </Menu>

      <ShipmentAcceptModal // opens for accepting clemens picture
        open={shipmentAcceptModalOpen}
        onClose={() => handleShipmentAcceptModalClose()}
      ></ShipmentAcceptModal>
    </>
  );
};

export default ShipmentView;
