import React, { useEffect, useState } from 'react';
import { OrderDto, OrderUpdateDto } from '../../services/order-service/order.service';
import { Button, CircularProgress, Grid, IconButton, InputAdornment, Stack, TextField } from '@mui/material';
import { Clear, Save } from '@mui/icons-material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useNotifications } from '../../hooks/useNotifications';
import { Link } from 'react-router-dom';
import { t } from 'i18next';

class AddressFormFieldRepo {
  private fields: { id: keyof OrderDto; label: string; editable: boolean }[] = [];

  public addField(id: keyof OrderDto, label: string, editable: boolean = true) {
    this.fields.push({ id, label, editable });
  }

  public getFields() {
    return this.fields;
  }
}

type Props = {
  order: OrderDto;
  editing: boolean;
  saveOrder: (update: OrderUpdateDto) => Promise<boolean>;
};

const OrderForm: React.FC<Props> = ({ editing, order, saveOrder }) => {
  const notifications = useNotifications();

  const [changed, setChanged] = useState<OrderUpdateDto>({});
  const [isSaving, setSaving] = useState(false);

  useEffect(() => {
    setChanged({});
  }, [order.orderId]);

  const fieldRepo = new AddressFormFieldRepo();
  fieldRepo.addField('externalOrderId', t('External order ID'), false);
  fieldRepo.addField('externalCreatedAt', t('External created at'), false);
  fieldRepo.addField('orderId', t('Order ID'), false);
  fieldRepo.addField('createdAt', t('Created at'), false);

  if (editing) {
    fieldRepo.addField('name', t('Name'));
    fieldRepo.addField('company', t('Company'));
    fieldRepo.addField('address', t('Address 1'));
    fieldRepo.addField('additionalAddress', t('Address 2'));
    fieldRepo.addField('zip', t('ZIP'));
    fieldRepo.addField('city', t('City'));
    fieldRepo.addField('countryCode', t('Country code'));
    fieldRepo.addField('phone', t('Phone'));
    fieldRepo.addField('email', t('Contact mail'));
    fieldRepo.addField('totalGross', t('Total gross'), false);
    fieldRepo.addField('currency', t('Currency'));
    fieldRepo.addField('returnTrackingId', t('Return tracking ID'), false);
  }

  return (
    <form
      onSubmit={async (ev) => {
        ev.preventDefault();

        setSaving(true);

        try {
          await saveOrder(changed);

          setChanged({});
          notifications.addInfo(t('Order saved'));
        } catch (err) {
          notifications.addError(err);
        } finally {
          setSaving(false);
        }
      }}
    >
      <Grid
        container
        spacing={1}
        my={1}
      >
        {fieldRepo.getFields().map((field) => (
          <Grid
            item
            xs={1}
            sm={2}
            md={3}
            key={field.id}
          >
            <TextField
              id={field.id}
              value={
                field.id.endsWith('At') &&
                !field.editable &&
                (changed[field.id as keyof OrderUpdateDto] || order[field.id])
                  ? new Date(
                      (changed[field.id as keyof OrderUpdateDto] as string) ?? (order[field.id] as string),
                    ).toLocaleString()
                  : changed[field.id as keyof OrderUpdateDto] ?? order[field.id] ?? ''
              }
              label={field.label}
              onChange={(ev) =>
                setChanged({
                  ...changed,
                  [field.id]: ev.target.value.toString(),
                })
              }
              variant="outlined"
              InputLabelProps={field.id in changed ? { sx: { fontWeight: 'bold', color: 'success.main' } } : undefined}
              fullWidth
              disabled={!editing || !field.editable}
              InputProps={{
                endAdornment: field.id === 'orderId' && (
                  <InputAdornment position="end">
                    <IconButton
                      component={Link}
                      to={`/orders/all-orders?orderId=${order[field.id]}`}
                      target="_blank"
                      edge="end"
                    >
                      <OpenInNewIcon fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        ))}
        {editing && (
          <Grid item>
            <Stack
              direction="row"
              spacing={1}
            >
              <Button
                startIcon={isSaving ? <CircularProgress size="1em" /> : <Save />}
                type="submit"
                variant="contained"
                disabled={!Object.keys(changed).length}
              >
                {t('Save')}
              </Button>
              <Button
                startIcon={<Clear />}
                variant="outlined"
                color="secondary"
                onClick={() => setChanged({})}
                disabled={!Object.keys(changed).length}
              >
                {t('Reset')}
              </Button>
            </Stack>
          </Grid>
        )}
      </Grid>
    </form>
  );
};

export default OrderForm;
