import { Monitor as MonitorIcon, PhoneAndroid as PhoneIcon } from '@mui/icons-material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import React from 'react';
import { useSessionMutation, useSessions } from '../../services/authentication-service/authentication.service';
import DeleteButton from '../../shared/components/delete/DeleteButton';
import { useTranslation } from 'react-i18next';

type Props = {};

const SessionsForm: React.FC<Props> = () => {
  const { t, i18n } = useTranslation();
  const sessions = useSessions();
  const sessionMutation = useSessionMutation();

  return (
    <>
      <Typography
        variant="h5"
        gutterBottom
      >
        {t('Sessions')}
      </Typography>
      <Typography
        variant="body2"
        sx={{ mb: 3 }}
      >
        {t(
          'In this list you will find an overview of your current sessions. A session is an active login on the system. Unknown or no longer used sessions should be terminated.',
        )}
      </Typography>

      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t('Device')}</TableCell>
              <TableCell>{t('Login')}</TableCell>
              <TableCell>{t('Last authentication')}</TableCell>
              <TableCell>{t('Valid until')}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sessions.data.map((session) => {
              // https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent#mobile_tablet_or_desktop
              const isMobileDevice = /Mobi/.test(session.userAgent);

              return (
                <TableRow key={session.id}>
                  <TableCell>
                    {session.current ? (
                      t('Current')
                    ) : (
                      <Tooltip title={session.userAgent}>{isMobileDevice ? <PhoneIcon /> : <MonitorIcon />}</Tooltip>
                    )}
                  </TableCell>
                  <TableCell>
                    {session.login
                      ? DateTime.fromISO(session.lastAuthenticated).toRelative({ locale: i18n.language })
                      : 'Unbekannt'}
                  </TableCell>
                  <TableCell>
                    {DateTime.fromISO(session.lastAuthenticated).toRelative({ locale: i18n.language })}
                  </TableCell>
                  <TableCell>{DateTime.fromISO(session.validUntil).toRelative({ locale: i18n.language })}</TableCell>
                  <TableCell align="right">
                    <DeleteButton onClick={() => sessionMutation.trigger(session.id)} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default SessionsForm;
