import { Checkbox, FormControl, Select, Radio, Stack, InputAdornment, IconButton } from '@mui/material';
import Chip from '@mui/material/Chip';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import ClearIcon from '@mui/icons-material/Clear';
import { default as React, FC } from 'react';
import * as ProcessTypeService from '../../../services/process-type-service/process-type.service';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export interface ProcessTypeFilterProps {
  processTypes: ProcessTypeService.ProcessTypeDto[];
  selectedProcessTypeIds: number[];
  onChange: (processTypeIds: number[]) => void;
  multiple?: boolean;
  size?: 'small' | 'medium';
  fullWidth?: boolean;
  required?: boolean;
}

function compareProcessTypesAlphabetically(
  a: ProcessTypeService.ProcessTypeDto,
  b: ProcessTypeService.ProcessTypeDto,
): number {
  if (a.label && b.label) {
    if (a.label === b.label) {
      return a.processTypeId - b.processTypeId;
    }

    return a.label.localeCompare(b.label);
  }

  if (a.destinationCountry === '*' && b.destinationCountry === '*') {
    return a.processTypeId - b.processTypeId;
  }

  if (a.destinationCountry === '*') {
    return 1;
  }

  if (b.destinationCountry === '*') {
    return -1;
  }

  if (a.destinationCountry === b.destinationCountry) {
    return a.processTypeId - b.processTypeId;
  }

  return a.destinationCountry.toUpperCase() > b.destinationCountry.toUpperCase() ? 1 : -1;
}

export const ProcessTypeFilter: FC<ProcessTypeFilterProps> = (props) => {
  const { processTypes, selectedProcessTypeIds, onChange } = props;

  const renderValue = () => {
    return (
      <Stack
        direction="row"
        spacing={1}
      >
        {selectedProcessTypeIds
          .map((id) => processTypes.find((type) => type.processTypeId === id))
          .map((processType) => (
            <Chip
              size="small"
              color="secondary"
              key={processType?.processTypeId}
              label={processType?.label || `${processType?.dispatchCountry} -> ${processType?.destinationCountry}`}
            />
          ))}
      </Stack>
    );
  };

  const value =
    processTypes.length > 0
      ? selectedProcessTypeIds.filter((id) => !!processTypes.find((p) => p.processTypeId === id))
      : [];

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
    >
      <FormControl
        size={props.size ?? 'small'}
        fullWidth={props.fullWidth ?? false}
        required={props.required ?? false}
        variant="outlined"
        sx={{ minWidth: 120 }}
        disabled={processTypes.length === 0}
      >
        {value.length === 0 && <InputLabel sx={{ mt: 0.5 }}>Prozess</InputLabel>}
        <Select
          autoWidth
          sx={{ minHeight: 41 }}
          label={value.length === 0 ? 'Prozess' : undefined}
          disabled={processTypes.length === 0}
          multiple={props.multiple}
          onChange={(event) =>
            onChange(Array.isArray(event.target.value) ? event.target.value : [Number(event.target.value)])
          }
          value={props.multiple ? value : value[0] || ''}
          renderValue={() => renderValue()}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: ITEM_HEIGHT * 10 + ITEM_PADDING_TOP,
              },
            },
          }}
          startAdornment={
            selectedProcessTypeIds.length > 0 &&
            props.multiple && (
              <InputAdornment position="start">
                <IconButton
                  onClick={() => onChange([])}
                  edge="start"
                  size="small"
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
            )
          }
        >
          {processTypes?.sort(compareProcessTypesAlphabetically).map((processType) => {
            const checked = selectedProcessTypeIds.includes(processType.processTypeId);

            return (
              <MenuItem
                key={processType.processTypeId}
                value={processType.processTypeId}
                sx={{ pl: 0 }}
              >
                {props.multiple ? <Checkbox checked={checked} /> : <Radio checked={checked} />}
                <ListItemText
                  primary={processType.label || `${processType.dispatchCountry} -> ${processType.destinationCountry}`}
                />
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Stack>
  );
};
