import { Clear } from '@mui/icons-material';
import { Alert, Button, IconButton, Stack } from '@mui/material';
import React, { useState } from 'react';
import { useNotifications } from '../../../hooks/useNotifications';
import { resetPasswordToRandom } from '../../../services/user-service/user.service';
import CopyToClipboardButton from '../../../shared/components/CopyToClipboardButton';

type Props = {
  id: number;
};

const ResetPasswordForm: React.FC<Props> = ({ id }) => {
  const notifications = useNotifications();

  const [password, setPassword] = useState('');
  const [isResetting, setResetting] = useState(false);

  if (isResetting) {
    return (
      <Stack
        spacing={2}
        direction="row"
      >
        <Button
          onClick={() => {
            resetPasswordToRandom(id)
              .then(({ password }) => {
                setPassword(password);
                setResetting(false);
              })
              .catch((error) => notifications.addError(error));
          }}
          variant="contained"
          fullWidth
        >
          Generieren
        </Button>
        <IconButton onClick={() => setResetting(false)}>
          <Clear />
        </IconButton>
      </Stack>
    );
  }

  return (
    <>
      <Button
        onClick={() => {
          setResetting(true);
          setPassword('');
        }}
        fullWidth
      >
        Neues Passwort
      </Button>
      {!!password && (
        <Alert severity="info">
          Das neue Passwort lautet <strong>{password}</strong> <CopyToClipboardButton value={password} />. Das Passwort
          ist nur jetzt sichtbar.
        </Alert>
      )}
    </>
  );
};

export default ResetPasswordForm;
