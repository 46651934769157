import React, { useEffect } from 'react';

import { Helmet } from 'react-helmet-async';
import { useEnumParam, useIntListParam, useIntParam } from '../../../hooks/useParam';
import { TourService } from '../../../services/tour-service/tour.service';
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import TourBatchTable from './TourBatchTable';
import BreadcrumbsGridItem from '../BreadcrumbsGridItem';
import TourTypeIcon from '../TourTypeIcon';
import { Send } from '@mui/icons-material';
import { TourType } from '../../../shared/backend';
import { useNavigate } from 'react-router-dom';
import { CustomerFilter } from '../../../shared/components/customer-filter/CustomerFilter';
import { CustomerService } from '../../../services/customer-service/customer.service';
import { ProcessTypeFilter } from '../../../shared/components/process-type-filter/ProcessTypeFilter';
import { ProcessTypeService } from '../../../services/process-type-service/process-type.service';
import TourStateOptionSelector, { TourStateOption, getTourStateFromTourStateOption } from '../TourStateOptionSelector';

const tourTypeLabels = {
  [TourType.TOUR]: 'Tour',
  [TourType.RETOUR]: 'Retour',
};

type Props = {};

const ToursPage: React.FC<Props> = () => {
  const navigateTo = useNavigate();
  const processTypes = ProcessTypeService.useProcessTypes();
  const customers = CustomerService.useCustomers();

  const [page, setPage] = useIntParam('page', 0);
  const [pageSize] = useIntParam('pageSize', 10);
  const [tourStateOption, setTourStateOption] = useEnumParam('state', TourStateOption, TourStateOption.OPEN);
  const [tourType, setTourType] = useEnumParam('type', TourType);
  const [processTypeIds, setProcessTypeIds] = useIntListParam('processTypeIds', []);
  const [customerIds, setCustomerIds] = useIntListParam('customerIds', []);

  const batches = TourService.useTourBatches({
    limit: pageSize,
    offset: page * pageSize,
    tourState: getTourStateFromTourStateOption(tourStateOption),
    tourType,
    customerIds,
    processTypeIds,
  });

  useEffect(() => {
    setCustomerIds([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processTypeIds.length]);

  return (
    <Grid
      container
      spacing={3}
    >
      <Helmet>
        <title>{`Tour batches | page ${page + 1}`}</title>
      </Helmet>
      <BreadcrumbsGridItem items={[{ label: 'Zwischentouren' }]} />

      <Grid
        item
        xs={12}
      >
        <Paper sx={{ padding: 1, paddingTop: 2 }}>
          <Typography
            variant="h5"
            gutterBottom
          >
            Zwischentouren
          </Typography>
          <Typography
            variant="body1"
            mb={3}
          >
            Eine Zwischentour beinhaltet alle Touren die bei einer Tour Generierung erstellt wurden.
          </Typography>

          <Stack
            direction="row"
            spacing={1}
          >
            <TextField
              select
              label="Tour type"
              value={tourType ?? ''}
              sx={{ minWidth: 140 }}
              size="small"
              onChange={(ev) => {
                setPage(0);
                setTourType((ev.target.value as TourType) || undefined);
              }}
              SelectProps={{
                sx: { minHeight: 41 },
                renderValue: (value) =>
                  value ? (
                    <Stack
                      direction="row"
                      spacing={0.5}
                      alignItems="center"
                    >
                      <TourTypeIcon
                        tourType={value as TourType}
                        sx={{ height: 24, width: 24 }}
                      />{' '}
                      <Box>{tourTypeLabels[value as TourType]}</Box>
                    </Stack>
                  ) : (
                    <></>
                  ),
              }}
            >
              <MenuItem>
                <em>Whatever</em>
              </MenuItem>
              {[TourType.TOUR, TourType.RETOUR].map((type) => (
                <MenuItem
                  value={type}
                  key={type}
                >
                  <ListItemIcon>
                    <TourTypeIcon
                      tourType={type}
                      sx={{ height: 24, width: 24 }}
                    />
                  </ListItemIcon>
                  <ListItemText>{tourTypeLabels[type]}</ListItemText>
                </MenuItem>
              ))}
            </TextField>

            <TourStateOptionSelector
              tourStateOption={tourStateOption}
              onChange={(value) => {
                setPage(0);
                setTourStateOption(value);
              }}
            />

            <ProcessTypeFilter
              processTypes={processTypes.data}
              selectedProcessTypeIds={processTypeIds}
              onChange={(ids) => {
                setPage(0);
                setProcessTypeIds(ids);
              }}
              multiple={true}
            />

            <CustomerFilter
              customers={customers.data.filter(({ processes }) =>
                processes.some((process) => !processTypeIds.length || processTypeIds.includes(process.processTypeId)),
              )}
              onChange={(ids) => {
                setPage(0);
                setCustomerIds(ids);
              }}
              selectedCustomerIds={customerIds}
              multiple={true}
            />

            <Box flexGrow={1}></Box>

            <form
              onSubmit={(ev) => {
                ev.preventDefault();

                const formData = new FormData(ev.currentTarget);
                const tourId = formData.get('tourId');

                if (tourId) {
                  navigateTo(`/customs/tour-details/${tourId}`);
                }
              }}
            >
              <TextField
                label="Tour Id"
                name="tourId"
                type="number"
                size="small"
                inputProps={{ min: 1 }}
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        type="submit"
                        edge="end"
                      >
                        <Send />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </form>
          </Stack>
        </Paper>
      </Grid>

      <Grid
        item
        xs={12}
      >
        <Paper>
          <TourBatchTable
            batches={batches.data}
            isLoading={batches.isLoading}
            isValidating={batches.isValidating}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ToursPage;
