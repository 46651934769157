import { Divider, MenuItem, TextField, TextFieldProps } from '@mui/material';
import React from 'react';
import { Control, Controller, FieldPath, FieldPathValue, FieldValues, RegisterOptions } from 'react-hook-form';
import { IsoCountryCode } from '../../backend';
import { UNKNOWN_COUNTRY_CODE, countryToFlag, defaultCommonCountryCodes } from '../../helper/country';

type Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<TextFieldProps, 'onChange' | 'value' | 'error'> & {
  name: TName;
  rules?: Omit<RegisterOptions<TFieldValues, TName>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
  defaultValue?: FieldPathValue<TFieldValues, TName>;
  control?: Control<TFieldValues>;
  commonCountryCodes?: (IsoCountryCode | typeof UNKNOWN_COUNTRY_CODE)[];
};

const FormSelectCountryCode = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  rules,
  defaultValue,
  control,
  commonCountryCodes,
  ...textFieldProps
}: Props<TFieldValues, TName>) => {
  const commonCodes = commonCountryCodes ?? defaultCommonCountryCodes;

  return (
    <Controller
      name={name}
      rules={{ required: textFieldProps.required, ...rules }}
      defaultValue={defaultValue}
      control={control}
      render={({ field: { name, onChange, value }, fieldState: { error } }) => (
        <TextField
          name={name}
          error={!!error}
          onChange={onChange}
          value={value ?? ''}
          {...textFieldProps}
          select
          helperText={error?.message ?? textFieldProps.helperText}
        >
          <MenuItem value="">
            <em>Undefiniert</em>
          </MenuItem>
          {commonCodes.map((id) => (
            <MenuItem
              key={id}
              value={id}
            >
              <span>{countryToFlag(id)}</span>&nbsp;
              {id}
            </MenuItem>
          ))}
          <Divider />
          {Object.entries(IsoCountryCode)
            .sort((a, b) => (a[1] < b[1] ? -1 : 1))
            .filter((entry) => !commonCodes.includes(entry[0] as IsoCountryCode))
            .map(([id, label]) => (
              <MenuItem
                key={id}
                value={id}
              >
                <span>{countryToFlag(id)}</span>&nbsp;
                {label}
              </MenuItem>
            ))}
        </TextField>
      )}
    />
  );
};

export default FormSelectCountryCode;
