import React from 'react';
import { ProcessTypeDto } from '../../../services/process-type-service/process-type.service';
import { Stack, Chip, FormControl, InputLabel, Select, MenuItem, Radio, ListItemText, Box } from '@mui/material';
import { getTypedObjectEntries } from '../../helper/helper';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

type Props = {
  processTypes: ProcessTypeDto[];
  selectedProcessTypeIds: number[];
  onChange: (processTypeIds: number[]) => void;
  direction: 'inbound' | 'outbound';
};

const WarehouseFilter: React.FC<Props> = ({ processTypes, selectedProcessTypeIds, onChange, direction }) => {
  const possibleValues = processTypes.reduce<Record<string, number[]>>((data, processType) => {
    const warehouseKey = direction === 'outbound' ? 'outboundWarehouse' : 'inboundWarehouse';
    const warehouse = processType[warehouseKey];

    if (warehouse) {
      if (!data[warehouse]) {
        data[warehouse] = [];
      }

      data[warehouse].push(processType.processTypeId);
    } else {
      data[processType.label || `${processType.dispatchCountry} -> ${processType.destinationCountry}`] = [
        processType.processTypeId,
      ];
    }

    return data;
  }, {});

  const renderValue = () => {
    return (
      <Stack
        direction="row"
        spacing={1}
      >
        {getTypedObjectEntries(possibleValues)
          .filter(
            ([label, processTypeIds]) =>
              processTypeIds.length === selectedProcessTypeIds.length &&
              selectedProcessTypeIds.every((id) => processTypeIds.includes(id)),
          )
          .map(([label, processTypeIds]) => (
            <Chip
              size="small"
              color="secondary"
              key={processTypeIds.join(',')}
              label={label}
            />
          ))}
      </Stack>
    );
  };

  const [value] = getTypedObjectEntries(possibleValues).find(
    ([label, processTypeIds]) =>
      processTypeIds.length === selectedProcessTypeIds.length &&
      selectedProcessTypeIds.every((id) => processTypeIds.includes(id)),
  ) ?? [''];

  return (
    <Box sx={{ height: '60px', display: 'flex', alignItems: 'center' }}>
      <FormControl
        size="small"
        variant="outlined"
        sx={{ minWidth: 120 }}
        disabled={Object.keys(possibleValues).length === 0}
      >
        {!value && <InputLabel sx={{ mt: 0.5 }}>Prozess</InputLabel>}
        <Select<string>
          sx={{ minHeight: 41 }}
          disabled={processTypes.length === 0}
          onChange={(event) => onChange(possibleValues[event.target.value])}
          value={value}
          renderValue={() => renderValue()}
          MenuProps={MenuProps}
        >
          {Object.keys(possibleValues).map((label) => {
            return (
              <MenuItem
                key={label}
                value={label}
              >
                <Radio checked={label === value} />
                <ListItemText primary={label} />
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};

export default WarehouseFilter;
