import { Info } from '@mui/icons-material';
import { Grid, InputAdornment, Tooltip, MenuItem } from '@mui/material';
import React from 'react';
import { SchemaObject, ReferenceObject } from 'openapi3-ts/dist/oas31';
import { Control, FieldPath, FieldValues } from 'react-hook-form';
import FormCheckbox from './FormCheckbox';
import FormTextField from './FormTextField';
import FormArrayTextField from './FormArrayTextField';

type Props<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>> = {
  path?: TName;
  control: Control<TFieldValues>;
  schema: SchemaObject;
};

type SchemaObjectType = 'integer' | 'number' | 'string' | 'boolean' | 'object' | 'null' | 'array';

const FormSchema = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  schema,
  path,
}: Props<TFieldValues, TName>) => {
  const fields = {
    boolean: (formName: TName, name: string, options: SchemaObject = {}) => (
      <FormCheckbox
        name={formName}
        control={control}
        description={options.description}
        label={options.title ?? name}
      />
    ),
    number: (formName: TName, name: string, options: SchemaObject = {}) => (
      <FormTextField
        name={formName}
        control={control}
        fullWidth
        label={options.title ?? name}
        InputProps={{
          inputProps: { min: options.minimum, max: options.maximum },
          endAdornment: options.description ? (
            <InputAdornment position="end">
              <Tooltip title={options.description}>
                <Info fontSize="small" />
              </Tooltip>
            </InputAdornment>
          ) : undefined,
        }}
        required={schema?.required?.includes(name)}
        type="number"
        placeholder={options.default}
      />
    ),
    string: (formName: TName, name: string, options: SchemaObject = {}) => (
      <FormTextField
        name={formName}
        control={control}
        fullWidth
        label={options.title ?? name}
        select={!!options.enum}
        required={schema?.required?.includes(name)}
        type={'format' in options && options.format === 'url' ? 'url' : 'text'}
        placeholder={options.default}
        InputProps={{
          startAdornment:
            options.description || options.default ? (
              <InputAdornment position="start">
                <Tooltip
                  title={[options.description, options.default ? `Default: ${options.default}` : undefined]
                    .filter((title) => !!title)
                    .join('')}
                >
                  <Info fontSize="small" />
                </Tooltip>
              </InputAdornment>
            ) : undefined,
        }}
      >
        {!!options.enum && !schema?.required?.includes(name) && (
          <MenuItem value="">
            <em>Keine Auswahl</em>
          </MenuItem>
        )}
        {options.enum?.map((value) => (
          <MenuItem
            key={value}
            value={value}
          >
            {value}
          </MenuItem>
        ))}
      </FormTextField>
    ),
    array: (formName: TName, name: string, options: SchemaObject = {}) => (
      <FormArrayTextField
        name={formName}
        control={control}
        fullWidth
        label={options.title ?? name}
        required={schema?.required?.includes(name)}
        placeholder={options.default}
        type={options.items && 'type' in options.items && options.items.type === 'number' ? 'number' : undefined}
        InputProps={{
          startAdornment:
            options.description || options.default ? (
              <InputAdornment position="start">
                <Tooltip
                  title={[options.description, options.default ? `Default: ${options.default}` : undefined]
                    .filter((title) => !!title)
                    .join('')}
                >
                  <Info fontSize="small" />
                </Tooltip>
              </InputAdornment>
            ) : undefined,
        }}
      />
    ),
  };

  const getFieldType = (options: SchemaObject | ReferenceObject): keyof typeof fields => {
    let schemaObjectType: SchemaObjectType | undefined;
    if (options && typeof options === 'object' && 'type' in options) {
      schemaObjectType = Array.isArray(options.type) ? options.type[0] : options.type;
    }
    const type = schemaObjectType ?? 'string';
    const fieldType = type in fields ? (type as keyof typeof fields) : 'string';

    return fieldType;
  };

  const fieldProperties = Object.entries(schema.properties ?? {}).map(([name, options]) => {
    return [getFieldType(options), (path ? `${path}.${name}` : name) as TName, name, options] as const;
  });

  const nonBooleanFieldProperties = fieldProperties.filter(([fieldType]) => fieldType !== 'boolean');
  const booleanFieldProperties = fieldProperties.filter(([fieldType]) => fieldType === 'boolean');

  return (
    <>
      {nonBooleanFieldProperties.map(([fieldType, formName, name, options]) => {
        return (
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            xl={2}
            key={name}
          >
            {fields[fieldType](formName, name, options as SchemaObject)}
          </Grid>
        );
      })}

      {!!nonBooleanFieldProperties.length && !!booleanFieldProperties.length && (
        <Grid
          item
          xs={12}
        ></Grid>
      )}

      {booleanFieldProperties.map(([fieldType, formName, name, options]) => {
        return (
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            xl={2}
            key={name}
          >
            {fields[fieldType](formName, name, options as SchemaObject)}
          </Grid>
        );
      })}
    </>
  );
};

export default FormSchema;
