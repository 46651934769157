import { backendUri } from '../../shared/helper/env/helper';
import { ArticleDto } from '../article-service/article.service';
import client, { SchemaType } from '../../shared/client';
import { LineItemReturnState } from '../../shared/backend';

export interface LineItemDto extends SchemaType<'LineItemDto'> {}

export interface ExtendedLineItem extends LineItemDto {
  // Optional fields that are manually set only in frontend for easier usability
  article: ArticleDto;
  hasWarnings: boolean;
}

export interface LineItemUpdateDto {
  lineItemId: number;
  returnState?: LineItemReturnState;
  returnShipmentId?: number;
  goodsDescription?: string;
}

export namespace LineItemService {
  export const updateLineItems = async (data: LineItemUpdateDto[]) => {
    const res = await client.put(`${backendUri}/line-item`, data);

    return res.data;
  };

  export const splitLineItem = async (
    lineItemId: number,
    quantity: number,
  ): Promise<{ newLineItem: LineItemDto; reducedLineItem: LineItemDto }> => {
    const url = `line-item/${lineItemId}/split`;
    const response = await client.post(url, {
      quantity,
    });

    return response.data;
  };
}
