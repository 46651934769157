import { UserRole } from '../shared/backend';
import { useUserInfo } from '../services/user-service/user.service';
import { useCallback, useMemo } from 'react';

export const useAuthentication = () => {
  const userInfo = useUserInfo();

  const userId = userInfo.data?.userId;
  const has2FaEnabled = userInfo.data?.has2FaEnabled ?? false;
  const role = userInfo.data?.role;

  const isSuperAdmin = useCallback(() => userInfo.data?.isSuperAdmin ?? false, [userInfo.data?.isSuperAdmin]);
  const isAdmin = useCallback(() => role === UserRole.ADMIN, [role]);
  const isPicker = useCallback(() => role === UserRole.PICKER, [role]);
  const isCustomer = useCallback(() => role === UserRole.CUSTOMER, [role]);
  const isCustomerCareAgent = useCallback(() => role === UserRole.CUSTOMER_CARE_AGENT, [role]);
  const isCustomsAgent = useCallback(() => role === UserRole.CUSTOMS_AGENT, [role]);
  const isStaff = useCallback(() => !!role && role !== UserRole.CUSTOMER, [role]);
  const isNotPicker = useCallback(() => !!role && role !== UserRole.PICKER, [role]);
  const isWarehouse = useCallback(
    () => !!role && [UserRole.PICKER, UserRole.WAREHOUSE_AGENT, UserRole.WAREHOUSE_SUPERVISOR].includes(role),
    [role],
  );
  const isPermanentEmployee = useCallback(
    () => !!role && role !== UserRole.PICKER && role !== UserRole.CUSTOMER,
    [role],
  );
  const isOffice = useCallback(
    () => !!role && [UserRole.ADMIN, UserRole.CUSTOMER_CARE_AGENT, UserRole.CUSTOMS_AGENT].includes(role),
    [role],
  );

  const value = useMemo(
    () => ({
      userId,
      has2FaEnabled,
      isSuperAdmin,
      role,
      isAdmin,
      isPicker,
      isCustomer,
      isStaff,
      isNotPicker,
      isWarehouse,
      isPermanentEmployee,
      isOffice,
      isCustomerCareAgent,
      isCustomsAgent,
    }),
    [
      userId,
      has2FaEnabled,
      isSuperAdmin,
      role,
      isAdmin,
      isPicker,
      isCustomer,
      isStaff,
      isNotPicker,
      isWarehouse,
      isPermanentEmployee,
      isOffice,
      isCustomerCareAgent,
      isCustomsAgent,
    ],
  );

  return value;
};
