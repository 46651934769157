import { Button, CircularProgress, FormControl, TextField } from '@mui/material';
import React, { FC, Fragment } from 'react';
import { useStyles } from './CustomerDeletion.style';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

export interface CustomerDeletionProps {
  onDeleteCustomer: (customerName: string, password: string) => Promise<boolean>;
}

const CustomerDeletion: FC<CustomerDeletionProps> = (props: CustomerDeletionProps) => {
  const classes = useStyles();
  const [isActive, setIsActive] = React.useState<boolean>(false);
  const [isRunning, setIsRunning] = React.useState<boolean>(false);
  const [company, setCompany] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');

  const handleActivate = () => {
    setIsActive(true);
  };

  const handleDeactivate = () => {
    setIsActive(false);
  };

  const handleChangeCustomerName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCompany(event.target.value);
  };

  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleDeleteCustomer = async () => {
    setIsRunning(true);
    const result = await props.onDeleteCustomer(company, password);
    setIsRunning(false);
    if (result) {
      setIsActive(false);
    }
  };

  return (
    <div className={classes.root}>
      {isActive && (
        <Fragment>
          <div className={classes.customerDeletionAttentionMessage}>
            <p>
              <b>ACHTUNG:</b>
            </p>
            <p>
              <b>- Aktuell werden beim Löschen eines Kunden dessen Touren nicht archiviert!</b>
            </p>
            <p>
              <b>- Der Löschvorgang kann bis zu einer Minute dauern.</b>
            </p>
          </div>
          <FormControl
            variant="outlined"
            className={classes.customerDeletionFormField}
          >
            <TextField
              variant="outlined"
              label="Company-Name"
              value={company}
              onChange={handleChangeCustomerName}
            />
          </FormControl>

          <FormControl
            variant="outlined"
            className={classes.customerDeletionFormField}
          >
            <TextField
              variant="outlined"
              type="password"
              label="Log-In-Passwort"
              value={password}
              onChange={handleChangePassword}
            />
          </FormControl>
        </Fragment>
      )}
      <Button
        className={classes.button}
        variant="contained"
        color="secondary"
        disabled={isRunning}
        onClick={!isActive ? () => handleActivate() : () => handleDeleteCustomer()}
        startIcon={isRunning ? <CircularProgress size="1em" /> : <DeleteForeverIcon />}
      >
        Kunde löschen
      </Button>
      {isActive && (
        <Button
          className={classes.button}
          variant="contained"
          color="secondary"
          disabled={isRunning}
          onClick={() => handleDeactivate()}
          startIcon={<CancelIcon />}
        >
          Deaktivieren
        </Button>
      )}
    </div>
  );
};

export default CustomerDeletion;
