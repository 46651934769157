import { useMediaQuery } from '@mui/material';
import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

const darkModeAtom = atomWithStorage<boolean | null>('theme:dark-mode', null, undefined, { getOnInit: true });

export const useDarkMode = (): [boolean, (darkMode: boolean) => void] => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [darkMode, setDarkMode] = useAtom(darkModeAtom);

  return [darkMode ?? prefersDarkMode, setDarkMode];
};
