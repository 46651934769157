import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import React, { Fragment } from 'react';
import { LineItemDto } from '../../../../../../../../services/line-item-service/lineItem.service';
import ShipmentLineItemViewRow from './ShipmentLineItemViewRow';
import { useAuthentication } from '../../../../../../../../hooks/useAuthentication';
import { t } from 'i18next';

export interface ShipmentLineItemViewProps {
  lineItems: LineItemDto[];
  onLineItemDelete: (lineItemId: number) => void;
  reloadShipments: () => Promise<void>;
  disableShipmentEditing: boolean;
  showArticleWeights?: boolean;
}

const ShipmentLineItemView: React.FC<ShipmentLineItemViewProps> = ({
  lineItems,
  disableShipmentEditing,
  onLineItemDelete,
  reloadShipments,
  showArticleWeights,
}) => {
  const auth = useAuthentication();
  const quantity = lineItems.reduce((lineItemSum, lineItemQuantity) => lineItemSum + lineItemQuantity.quantity, 0);
  return (
    <Fragment>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{t('Article {{quantity}} pcs', { quantity })}</TableCell>
              <TableCell>{t('Price')}</TableCell>
              <TableCell>{t('Quantity')}</TableCell>
              {showArticleWeights && (
                <Fragment>
                  <TableCell>{t('Article weight')}</TableCell>
                  <TableCell>{t('Category article weight')}</TableCell>
                </Fragment>
              )}
              {auth.isStaff() && <TableCell align="right">{t('Package management')}</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {lineItems.map((item) => (
              <ShipmentLineItemViewRow
                key={item.lineItemId}
                {...{
                  item,
                  disableShipmentEditing,
                  onLineItemDelete,
                  reloadShipments,
                  showArticleWeights,
                }}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Fragment>
  );
};

export default ShipmentLineItemView;
