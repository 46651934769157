import { Button, CircularProgress, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { deleteTotp, registerTotp, useTotpSecret } from '../../services/authentication-service/totp.service';

import { QRCodeSVG } from 'qrcode.react';
import { mutate } from 'swr';
import { backendUri } from '../../shared/helper/env/helper';
import { useNotifications } from '../../hooks/useNotifications';
import { Alert } from '@mui/material';
import { useAuthentication } from '../../hooks/useAuthentication';
import { useTranslation } from 'react-i18next';

type Props = {};

const TotpForm: React.FC<Props> = () => {
  const auth = useAuthentication();
  const { t } = useTranslation();
  const [isRegistering, setRegistering] = useState(false);
  const [isDeleting, setDeleting] = useState(false);
  const [token, setToken] = useState('');
  const secret = useTotpSecret(isRegistering);
  const notification = useNotifications();

  useEffect(() => {
    setToken('');
  }, [isDeleting, isRegistering]);

  const register = async () => {
    if (!secret.data || !token) {
      return;
    }

    try {
      await registerTotp(secret.data.secret, secret.data.mac, token);
    } catch (err) {
      notification.addError(t('Token is invalid'));
      setToken('');

      return;
    }

    mutate(`${backendUri}/user/me`);

    notification.addInfo(t('Second factor enabled'));

    setRegistering(false);
    setDeleting(false);
  };

  const doDelete = async () => {
    try {
      await deleteTotp(token);
    } catch (err) {
      notification.addError(t('Token is invalid'));
      setToken('');

      return;
    }

    mutate(`${backendUri}/user/me`);

    notification.addInfo(t('Second factor disabled'));

    setRegistering(false);
    setDeleting(false);
  };

  return (
    <>
      <Typography
        variant="h5"
        gutterBottom
      >
        {t('Second factor')}
      </Typography>
      <Typography
        variant="body2"
        gutterBottom
      >
        {t(
          'If you add a second factor to the login process, you will have a crucial improvement of your account security.',
        )}
      </Typography>

      {auth.has2FaEnabled ? (
        <>
          <Alert severity="success">{t('Second factor already enabled.')}</Alert>
          <div style={{ marginTop: '1rem' }}>
            {isDeleting ? (
              <form
                onSubmit={(ev) => {
                  ev.preventDefault();

                  doDelete();
                }}
              >
                <TextField
                  style={{ marginRight: '1rem' }}
                  size="small"
                  label={t('Code')}
                  value={token}
                  variant="outlined"
                  required
                  inputProps={{
                    pattern: '[0-9]{6}',
                  }}
                  onChange={(ev) => setToken(ev.target.value)}
                />
                <Button
                  style={{ marginRight: '1rem' }}
                  type="submit"
                  color="secondary"
                  variant="contained"
                >
                  {t('Delete')}
                </Button>
                <Button
                  style={{ marginRight: '1rem' }}
                  type="button"
                  variant="outlined"
                  onClick={() => setDeleting(false)}
                >
                  {t('Cancel')}
                </Button>
              </form>
            ) : (
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => setDeleting(true)}
              >
                {t('Remove device')}
              </Button>
            )}
          </div>
        </>
      ) : (
        <>
          {!isRegistering && (
            <>
              <Typography
                variant="body2"
                gutterBottom
              >
                {t(
                  'To secure the login with a second factor, you need an app that generates one-time passwords via TOTP. For example, the Google Authenticator or andOTP.',
                )}
              </Typography>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => setRegistering(true)}
              >
                {t('Add device')}
              </Button>
            </>
          )}

          {secret.isLoading && <CircularProgress size="1em" />}

          {secret.data?.otpAuthUrl && (
            <form
              onSubmit={(ev) => {
                ev.preventDefault();

                register();
              }}
            >
              <div style={{ margin: '1rem 0' }}>
                <QRCodeSVG value={secret.data.otpAuthUrl} />
              </div>

              <Typography
                variant="body2"
                gutterBottom
              >
                {t(
                  'Please register your second factor using the QR code in your authentication app. Then enter a corresponding code for confirmation.',
                )}
              </Typography>

              <TextField
                style={{ marginRight: '1rem' }}
                label="Code"
                size="small"
                value={token}
                variant="outlined"
                required
                inputProps={{
                  pattern: '[0-9]{6}',
                }}
                onChange={(ev) => setToken(ev.target.value)}
              />

              <Button
                style={{ marginRight: '1rem' }}
                color="secondary"
                variant="contained"
                type="submit"
              >
                {t('Enable')}
              </Button>
              <Button
                type="button"
                variant="outlined"
                onClick={() => setRegistering(false)}
              >
                {t('Cancel')}
              </Button>
            </form>
          )}
        </>
      )}
    </>
  );
};

export default TotpForm;
