import { Box, Button, Stack, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/Check';
import FileSaver from 'file-saver';
import PrintIcon from '@mui/icons-material/Print';
import React, { FC, Fragment } from 'react';

import { OrderService, OrderWithCustomerDto } from '../../../../../../../services/order-service/order.service';
import OrderDetailTable from './../../components/orderDetailTable';
import { LineItemDto } from '../../../../../../../services/line-item-service/lineItem.service';
import { getJobResultFileName, JobDto, jobService } from '../../../../../../../services/job-service/job.service';
import { ShipmentDto, ShipmentService } from '../../../../../../../services/shipment-service/shipment.service';
import { useNotifications } from '../../../../../../../hooks/useNotifications';
import { OrderType } from '../../../../../../../shared/backend';
import { useAuthentication } from '../../../../../../../hooks/useAuthentication';
import { pdfFileDownload } from '../../../../../../../shared/helper/pdfFileDownload';
import { ProcessTypeService } from '../../../../../../../services/process-type-service/process-type.service';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { t } from 'i18next';

const allItemsAssigned = (items: LineItemDto[], orderType: OrderType): boolean => {
  switch (orderType) {
    case OrderType.ORDER:
      return !(items.findIndex((item) => item.shipmentId === null) > -1);
    case OrderType.RETOURE:
      return !(items.findIndex((item) => item.returnShipmentId === null) > -1);
    default:
      return false;
  }
};

export interface RegularOrderProps {
  order: OrderWithCustomerDto;
  shipments: ShipmentDto[];
  autofocus: boolean;
  orderTyp: OrderType;
  onLineItemAssign: (shipmentId: number, lineItemId: number) => Promise<void>;
  onLineItemAssignFinish: () => Promise<void>;
  reloadShipments: () => Promise<void>;
}

export const RegularOrderView: FC<RegularOrderProps> = (props) => {
  const notificationHandler = useNotifications();

  const auth = useAuthentication();

  const processTypes = ProcessTypeService.useProcessTypes();
  const processType = processTypes.data.find((pt) => pt.processTypeId === props.order.process.processTypeId);

  const validInvoiceDestinationCountries = ['CH', 'GB', 'DE'];

  const handleLineItemAssign = async (shipmentId: number, lineItemId: number) => {
    await props.onLineItemAssign(shipmentId, lineItemId);
  };

  const handleLineItemAssignFinish = async () => {
    await props.onLineItemAssignFinish();
  };

  const handleCreateOrderInvoice = async () => {
    const jobData = await OrderService.createOrderInvoiceJob(props.order.orderId);

    jobService.addJob(jobData.jobId.toString(), {
      onSuccess: async (jobId, jobObject: JobDto) => {
        const pdfBuffer = jobObject.returnvalue.file;
        if (pdfBuffer) {
          notificationHandler.addInfo(
            t(`The invoice for order #{{orderId}} has been successfully created and is being downloaded.`, {
              orderId: props.order.orderId,
            }),
          );
          pdfFileDownload(pdfBuffer, getJobResultFileName(jobObject));
        } else {
          const jobId = jobData.jobId;
          notificationHandler.addError(t(`Document from job {{jobId}} could not be downloaded`, { jobId }));
        }
      },
      onError: async (jobId, jobObject: JobDto) => {
        const error = jobObject.failedReason;
        notificationHandler.addError(
          t('Error when creating the invoice for order #{{orderId}}: {{error}}', {
            orderId: props.order.orderId,
            error,
          }),
        );
      },
    });
  };

  const printReturnLabel = async () => {
    try {
      // Create an empty return shipment for the order and print the label
      const returnShipment = await ShipmentService.createReturnShipmentWithLabel(props.order.orderId);
      const filename = `return_label_${returnShipment.shipmentId}.pdf`;
      FileSaver.saveAs(`data:application/pdf;base64,${returnShipment.label}`, filename);
    } catch (error) {
      notificationHandler.addError(error);
    }
  };

  return (
    <Fragment>
      <Box sx={{ marginTop: 1, paddingY: 1 }}>
        <Stack
          direction="row"
          spacing={2}
          mb={2}
          alignItems="baseline"
        >
          <Typography variant="body1">
            {t('Total order price')} <strong>{`${props.order.totalGross} ${props.order.currency}`}</strong>
          </Typography>

          <Box flexGrow={1}></Box>

          {auth.isNotPicker() && props.orderTyp === OrderType.ORDER && (
            <Button
              size="small"
              variant="outlined"
              startIcon={<PrintIcon />}
              onClick={() => {
                printReturnLabel();
              }}
            >
              {t('Create new return label')}
            </Button>
          )}

          {props.shipments.some((shipment) => shipment.tourId) &&
            processType &&
            validInvoiceDestinationCountries.includes(processType.destinationCountry) &&
            auth.isOffice() && (
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={handleCreateOrderInvoice}
                startIcon={<ReceiptIcon />}
              >
                {t('Shipping invoice')}
              </Button>
            )}
        </Stack>

        <div>
          {!allItemsAssigned(props.order.lineItems, props.orderTyp) ? (
            <OrderDetailTable
              lineItems={props.order.lineItems}
              shipments={props.shipments}
              multiShipment={props.shipments.length > 0}
              onLineItemAssign={handleLineItemAssign}
              onLineItemAssignFinish={handleLineItemAssignFinish}
              orderTyp={props.orderTyp}
              reloadShipments={props.reloadShipments}
            />
          ) : (
            <Typography
              variant="body1"
              sx={{ color: 'text.disabled', textAlign: 'center' }}
            >
              <CheckCircleIcon
                sx={{
                  marginRight: 1,
                  verticalAlign: 'bottom',
                }}
              />
              {t('Each article was assigned to a package')}
            </Typography>
          )}
        </div>
      </Box>
    </Fragment>
  );
};
