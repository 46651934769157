import { ShipmentDto } from '../../services/shipment-service/shipment.service';
import { equalDeltaPercentage } from '../../shared/helper/helper';

export function getConsignedInboundLineItemIds(shipments: ShipmentDto[]): number[] {
  return shipments
    .filter(({ isConsigned }) => isConsigned)
    .flatMap(({ lineItems }) => lineItems)
    .map((lineItem) => lineItem.lineItemId);
}

export function getTotalOrderWeight(shipments: ShipmentDto[]): number {
  return shipments
    .filter((shipment) => shipment.isConsigned)
    .reduce((sum, shipment) => sum + (shipment.carrierWeight || shipment.shippingWeight || 0), 0);
}

export function isAssignAllLineItems(shipment: ShipmentDto, totalOrderWeight: number, weight?: number): boolean {
  weight = weight ?? shipment.shippingWeight ?? undefined;

  return (
    !shipment.lineItems.length && !!weight && !!totalOrderWeight && equalDeltaPercentage(weight, totalOrderWeight, 0.05)
  );
}
