import React from 'react';
import { useCustomerIdsParam, useProcessTypeIdsParam } from '../../hooks/useParam';
import {
  CustomerDto,
  CustomerService,
  CustomerWithProcessesDto,
} from '../../services/customer-service/customer.service';
import SelectWithFilter from '../../shared/components/SelectWithFilter';
import { t } from 'i18next';

function compareCustomersAlphabetically(a: CustomerDto, b: CustomerDto): number {
  if (a.customerId === 0) {
    return -1;
  }
  return a.company.toUpperCase() > b.company.toUpperCase() ? 1 : -1;
}

type Props = {
  disabled?: boolean;
  multiple?: boolean;
};

const SelectionCustomer: React.FC<Props> = (props) => {
  const [customerIds, setCustomerIds] = useCustomerIdsParam();
  const [processTypeIds] = useProcessTypeIdsParam();

  const customers = CustomerService.useCustomers();

  const availableCustomers = customers.data.filter(
    (customer) =>
      processTypeIds.length === 0 ||
      customer.processes.some((process) => processTypeIds.includes(process.processTypeId)),
  );

  function generateLabelForCustomer(customer: CustomerWithProcessesDto): string {
    const baseLabel = `${customer.company}`;

    const processInfo = customer.processes.length
      ? `(${customer.processes
          .filter((process) => processTypeIds.length === 0 || processTypeIds.includes(process.processTypeId))
          .map((process) => process.processId)
          .join(', ')})`
      : '';
    return `${baseLabel} ${processInfo}`;
  }

  const value = customerIds.filter((id) => !!availableCustomers.find((c) => c.customerId === id));

  return (
    <SelectWithFilter
      values={availableCustomers
        .sort(compareCustomersAlphabetically)
        .map((customer) => ({ label: generateLabelForCustomer(customer), id: customer.customerId }))}
      selectedValueIds={value}
      disabled={props.disabled || (!customerIds.length && !processTypeIds.length) || availableCustomers.length === 0}
      onChange={(ids) => setCustomerIds(ids)}
      multiple={props.multiple}
      label={t('Customers')}
      size="small"
      sx={{
        minWidth: 120,
        '& .MuiInputBase-root': { minHeight: 41 },
        '& .MuiInputLabel-root': { marginTop: 0.5 },
      }}
    />
  );
};

export default SelectionCustomer;
