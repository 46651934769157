import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { CustomerTable } from './components/customerTable';
import { Helmet } from 'react-helmet-async';

const useStyles = makeStyles({
  tableStyle: {
    marginTop: '20px',
    marginBottom: '20px',
  },
});

export const Customers: React.FC<{}> = () => {
  const classes = useStyles();

  return (
    <div className={classes.tableStyle}>
      <Helmet>
        <title>Customers</title>
      </Helmet>

      <CustomerTable></CustomerTable>
    </div>
  );
};
