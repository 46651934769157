/**
 * this component has similarities with
 * shipmentLineItemView and should therefore
 * be centralized
 */

import { Menu, MenuItem } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { Fragment } from 'react';
import { LineItemDto } from '../../../../../../services/line-item-service/lineItem.service';
import OrderDetailTableRow from './OrderDetailTableRow';
import { ShipmentDto } from '../../../../../../services/shipment-service/shipment.service';
import { OrderType } from '../../../../../../shared/backend';
import { useAuthentication } from '../../../../../../hooks/useAuthentication';

interface shipmentLoading {
  shipmentId: number;
  isLoading: boolean;
}

export interface OrderDetailTableProps {
  lineItems: LineItemDto[];
  shipments: ShipmentDto[];
  multiShipment: boolean;
  orderTyp: OrderType;
  onLineItemAssign: (shipmentId: number, lineItemId: number) => Promise<void>;
  onLineItemAssignFinish: () => Promise<void>;
  reloadShipments: () => Promise<void>;
}

const OrderDetailTable: React.FC<OrderDetailTableProps> = (props) => {
  const auth = useAuthentication();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [activeLineItem, setActiveLineItem] = React.useState<LineItemDto | null>(null);

  const [shipmentAssignLoading, setShipmentAssignLoading] = React.useState<shipmentLoading>({
    shipmentId: -1,
    isLoading: false,
  });

  const handleShipmentButtonClick = (event: React.MouseEvent<HTMLButtonElement>, lineItem: LineItemDto) => {
    event.stopPropagation();

    if (props.shipments.length === 1) {
      handleShipmentAssign(props.shipments[0].shipmentId, lineItem);
      return;
    }

    setActiveLineItem(lineItem);
    setAnchorEl(event.currentTarget);
  };

  const handleShipmentAssign = async (shipmentId: number, lineItem: LineItemDto | null) => {
    setShipmentAssignLoading({ shipmentId: shipmentId, isLoading: true });
    if (lineItem && shipmentAssignLoading.isLoading === false) {
      await props.onLineItemAssign(shipmentId, lineItem.lineItemId);
      setAnchorEl(null);
      setShipmentAssignLoading({ shipmentId: -1, isLoading: false });
      await props.onLineItemAssignFinish();
    }
  };

  const handleClose = () => {
    setActiveLineItem(null);
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                Artikel{' '}
                {props.lineItems.reduce((lineItemSum, lineItemQuantity) => lineItemSum + lineItemQuantity.quantity, 0)}{' '}
                Stk
              </TableCell>
              <TableCell>Preis</TableCell>
              <TableCell>Anzahl</TableCell>
              {props.multiShipment && auth.isStaff() && (
                <TableCell align="right">
                  {props.orderTyp === OrderType.ORDER ? 'Paketverwaltung' : 'Retourenverwaltung'}
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.lineItems.map(
              (item) =>
                ((props.orderTyp === OrderType.ORDER && !item.shipmentId) ||
                  (props.orderTyp === OrderType.RETOURE && !item.returnShipmentId)) && (
                  <OrderDetailTableRow
                    {...{
                      handleShipmentButtonClick,
                      isMultiShipment: props.multiShipment,
                      isListHandler: props.shipments.length > 1,
                      item,
                      orderType: props.orderTyp,
                      key: item.lineItemId,
                      reloadShipments: props.reloadShipments,
                    }}
                  />
                ),
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Menu
        id="shipmentMenu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {props.shipments.map((shipment, index) => (
          <MenuItem
            key={shipment.shipmentId}
            onClick={() => handleShipmentAssign(shipment.shipmentId, activeLineItem)}
          >
            {shipmentAssignLoading.isLoading && shipmentAssignLoading.shipmentId === shipment.shipmentId && (
              <CircularProgress
                style={{ marginRight: '5px' }}
                size={20}
                color="primary"
              />
            )}{' '}
            {props.orderTyp === OrderType.ORDER ? 'Paket' : 'Retoure'} {index + 1}
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  );
};

export default OrderDetailTable;
