import React, { useState } from 'react';
import { CircularProgress, IconButton } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { Buffer } from 'buffer';
import { JobDto, ReducedJobDto, getJobResultFileName, jobService } from '../../../services/job-service/job.service';
import { useNotifications } from '../../../hooks/useNotifications';
import { pdfFileDownload } from '../../../shared/helper/pdfFileDownload';
import { JobQueueJobs } from '../../../shared/backend';
import fileDownload from 'js-file-download';

export interface JobDownloadCellProps {
  job: ReducedJobDto;
}

const JobDownloadCell = (props: JobDownloadCellProps) => {
  const notificationHandler = useNotifications();
  const [isDownloading, setIsDownloading] = useState(false);

  const handleFileDownload = async (job: ReducedJobDto): Promise<void> => {
    setIsDownloading(true);

    let jobData: JobDto | null = null;

    try {
      // Returnvalue is not available in job list and has to be fetched separately
      jobData = await jobService.getJob(job.id);
    } catch (error) {
      notificationHandler.addError(error);
      return;
    } finally {
      setIsDownloading(false);
    }

    const filebuffer = jobData?.returnvalue?.file;

    if (filebuffer) {
      const filename = getJobResultFileName(job);

      if (jobData?.name === JobQueueJobs.EMK_DOCUMENTS_CREATION) {
        const zipBuffer = Buffer.from(filebuffer, 'binary');
        fileDownload(zipBuffer, filename, `application/zip`);
      } else {
        pdfFileDownload(filebuffer, filename);
      }
    } else {
      notificationHandler.addError(`Dokument aus Job ${jobData?.id} konnte nicht heruntergeladen werden`);
    }
  };

  return (
    <>
      <IconButton onClick={() => handleFileDownload(props.job)}>
        <DownloadIcon fontSize="small" />
      </IconButton>
      {isDownloading && <CircularProgress size="1em" />}
    </>
  );
};

export default JobDownloadCell;
