import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import React, { useState } from 'react';
import ButtonAsync from '../../shared/components/ButtonAsync';
import { TourService } from '../../services/tour-service/tour.service';
import { useNotifications } from '../../hooks/useNotifications';

type Props = {
  value: string;
  tourIds: number[];
  onClose: () => void;
  mutate?: () => void;
};

const TourNoteDialog: React.FC<Props> = ({ value, tourIds, onClose, mutate }) => {
  const notifications = useNotifications();

  const [note, setNote] = useState(value);

  const updateTours = async () => {
    try {
      for (const tourId of tourIds) {
        await TourService.updateTour(tourId, { note });
      }

      notifications.addSuccess(`We updated the notes of ${tourIds.length} tours`);

      if (mutate) {
        mutate();
      }

      onClose();
    } catch (error) {
      notifications.addError(error);
    }
  };

  return (
    <Dialog
      onClose={() => onClose()}
      open={true}
    >
      <DialogTitle>Notiz bearbeiten</DialogTitle>

      <DialogContent>
        <DialogContentText mb={3}>
          Diese Notiz wird gespeichert für die Touren {tourIds.join(', ')}. Die Änderung kann nicht mehr rückgängig
          gemacht werden.
        </DialogContentText>

        <TextField
          multiline
          rows={5}
          value={note}
          onChange={(ev) => setNote(ev.target.value)}
          fullWidth
        />
      </DialogContent>

      <DialogActions>
        <ButtonAsync
          variant="contained"
          onClick={() => updateTours()}
        >
          Speichern
        </ButtonAsync>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => onClose()}
        >
          Abbrechen
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TourNoteDialog;
