import { Alert, AlertTitle, Box, Card, CircularProgress, Paper, Stack, Typography } from '@mui/material';
import React, { Fragment, FunctionComponent, useCallback, useEffect, useState } from 'react';
import { OrderStatesFilter } from '../../../../shared/components/order-filter/OrderFilter';
import { TableSearch, TableSearchHandles } from '../../../../shared/components/table-search/TableSearch';
import OrderDetailModal from '../../shared/components/order-detail-modal/OrderDetailModal';

import {
  ProcessFilterContainer,
  ProcessFilterContainerValue,
} from '../../../../shared/components/process-filter-container/ProcessFilterContainer';
import { Table } from './Table';
import { useAuthentication } from '../../../../hooks/useAuthentication';
import {
  useBooleanParam,
  useEnumListParam,
  useEnumParam,
  useIntParam,
  useStringParam,
} from '../../../../hooks/useParam';
import { parseSearchQueryToBarcode } from '../../shared/components/order-detail-modal/components/shared-order-retoure/SharedFunctions';
import ScaleStatus from '../../../../shared/components/scale/ScaleStatus';
import { SearchMode } from '../../../../shared/components/search-mode/SearchMode';
import { useSearchMode } from '../../../../shared/hooks/search-mode';
import BatchBarcodeSummary from './BatchBarcodeSummary';
import { ShipmentDto, ShipmentService } from '../../../../services/shipment-service/shipment.service';
import { OrderService } from '../../../../services/order-service/order.service';
import { OrderSearchScope, OrderState, OrderType } from '../../../../shared/backend';
import { useTranslation } from 'react-i18next';

export const OrderTable: FunctionComponent<{}> = () => {
  const { t } = useTranslation();
  const auth = useAuthentication();

  const [modalOrderId, setModalOrderId] = useIntParam('orderId');
  const [modalShipmentId, setModalShipmentId] = useIntParam('shipmentId');
  const [paramSearchMode, setParamSearchMode] = useEnumParam('scope', OrderSearchScope);
  const [searchQuery, setSearchQuery] = useStringParam('search', '');
  const [orderFilterStates, setOrderFilterStates] = useEnumListParam('states', OrderState, []);
  const [modalShowArticleWeights, setModalShowArticleWeights] = useBooleanParam('showArticleWeights', false);

  const [storageSearchMode, setStorageSearchMode] = useSearchMode();

  const searchMode = paramSearchMode ?? storageSearchMode ?? OrderSearchScope.TRACKING_IDS;
  const setSearchMode = (mode: OrderSearchScope) => {
    if (mode !== OrderSearchScope.TRACKING_IDS) {
      setParamSearchMode(mode);
      setStorageSearchMode(mode);
    } else {
      setParamSearchMode(undefined);
      setStorageSearchMode(undefined);
    }
  };

  const SEARCH_PLACEHOLDERS: Record<OrderSearchScope, string> = {
    [OrderSearchScope.EXTENDED]: t('Search for IDs, address, e-mail, etc.'),
    [OrderSearchScope.IDENTIFIER]: t('Search for IDs'),
    [OrderSearchScope.TRACKING_IDS]: t('Search for tracking IDs'),
    [OrderSearchScope.BATCH]: t('Search for pallet'),
  };

  const SEARCH_PLACEHOLDERS_EXPORTO_SUFFIX: Record<OrderSearchScope, string> = {
    [OrderSearchScope.EXTENDED]: t('(slow)'),
    [OrderSearchScope.IDENTIFIER]: t('(fast)'),
    [OrderSearchScope.TRACKING_IDS]: t('(super fast, without customer filter)'),
    [OrderSearchScope.BATCH]: '',
  };

  useEffect(() => {
    if (!paramSearchMode && paramSearchMode !== storageSearchMode) {
      setParamSearchMode(storageSearchMode);
    }
  }, [paramSearchMode, setParamSearchMode, storageSearchMode]);

  const [autoOpen, setAutoOpen] = useState(true);
  const [processIds, setProcessIds] = React.useState<number[]>([]);
  const [processTypeId, setProcessTypeId] = React.useState<number | null>(null);

  const tableSearchRef = React.useRef<TableSearchHandles | null>(null);

  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(5);

  const isSearchDisabled = auth.isPicker() && processIds.length === 0 && searchMode !== OrderSearchScope.TRACKING_IDS;

  const { data, isLoading, isError, error } = OrderService.useOrders(
    processTypeId
      ? {
          search: searchQuery,
          limit: rowsPerPage,
          offset: page * rowsPerPage,
          processIds: processIds.length && searchMode !== OrderSearchScope.TRACKING_IDS ? processIds : undefined,
          processTypeId,
          orderStates: orderFilterStates,
          orderType: OrderType.ORDER,
          scope: searchMode,
        }
      : undefined,
    auth.isNotPicker(),
    isSearchDisabled || processTypeId === null,
  );
  const errorMessage = error ? `(${error.toString()})` : '';

  const batchBarcode = !!data?.length && searchMode === OrderSearchScope.BATCH ? searchQuery : null;

  /**
   * This functions fulfills the purpose of getting an actual shipmentId
   * instead of an order, after searching for its name, barcode value
   * or tracking code.
   */
  const getShipmentIdBySearching = async (orderId: number, searchQuery: string): Promise<number | null> => {
    const shipmentCandidates: ShipmentDto[] = await ShipmentService.getShipmentsByOrder(orderId);

    const filteredShipmentCandidates = shipmentCandidates.filter(
      (shipment) =>
        shipment.externalShipmentId === searchQuery ||
        shipment.foreignOutboundTrackingId === searchQuery ||
        shipment.foreignInboundTrackingId === searchQuery ||
        shipment.exportoBarcode === searchQuery,
    );

    if (filteredShipmentCandidates.length === 1) {
      console.log(`Found shipment for "${searchQuery}".`, filteredShipmentCandidates[0]);
      return filteredShipmentCandidates[0].shipmentId;
    }
    return null;
  };

  const openModal = useCallback(
    (orderId: number, shipmentId?: number) => {
      if (shipmentId) {
        setModalShipmentId(shipmentId);
      }

      setModalOrderId(orderId);
    },
    [setModalOrderId, setModalShipmentId],
  );

  const closeModal = useCallback(() => {
    setModalShipmentId(undefined);
    setModalOrderId(undefined);
    setModalShowArticleWeights(undefined);
  }, [setModalOrderId, setModalShipmentId, setModalShowArticleWeights]);

  const handleProcessFilterChange = async (value: ProcessFilterContainerValue) => {
    // REVIEW Klaus: Make sure change from empty array to another empty array does not trigger data reload
    setProcessIds(value.customerProcesses.map((process) => process.processId).sort());
    setProcessTypeId(value.processTypes.map((processType) => processType.processTypeId)?.[0] ?? null);
  };

  const handleOrderStatesFilterChange = (states: OrderState[]) => {
    // Make sure change from empty array to another empty array does not trigger data reload
    if (orderFilterStates.length === 0 && states.length === 0) {
      return;
    }
    setOrderFilterStates(states);
  };

  useEffect(() => {
    const handleStateChange = async () => {
      if (
        autoOpen &&
        data?.length === 1 &&
        !modalOrderId &&
        data[0].state !== OrderState.BLOCKED &&
        !data[0].process.blocked
      ) {
        const shipmentCandidateId = await getShipmentIdBySearching(data[0].orderId, searchQuery);

        setAutoOpen(false);

        openModal(data[0].orderId, shipmentCandidateId || undefined);
      }
    };

    handleStateChange();
  }, [data, searchQuery, openModal, modalOrderId, autoOpen]);

  useEffect(() => {
    setAutoOpen(true);
  }, [searchQuery]);

  useEffect(() => {
    setPage(0);
  }, [searchQuery, processIds, rowsPerPage, orderFilterStates, searchMode]);

  const placeholder = auth.isCustomer()
    ? SEARCH_PLACEHOLDERS[searchMode]
    : `${SEARCH_PLACEHOLDERS[searchMode]} ${SEARCH_PLACEHOLDERS_EXPORTO_SUFFIX[searchMode]}`.trim();

  return (
    <Fragment>
      {auth.isStaff() && (
        <Box
          mt={-1}
          mb={1}
          display="flex"
          justifyContent="flex-end"
        >
          <ScaleStatus />
        </Box>
      )}
      {
        <Fragment>
          <Card sx={{ mb: 2, p: 2 }}>
            <TableSearch
              disabled={isSearchDisabled}
              isLoading={isLoading}
              defaultValue={searchQuery}
              ref={tableSearchRef}
              handleSearchChange={(query) => {
                const parsedQuery = parseSearchQueryToBarcode(query);

                if (parsedQuery !== searchQuery) {
                  setSearchQuery(parsedQuery);
                }
              }}
              placeholder={placeholder}
              autoFocus={!!modalOrderId}
            ></TableSearch>

            <Stack
              direction="row"
              spacing={1}
            >
              <SearchMode
                scope={searchMode}
                onChange={(scope) => setSearchMode(scope)}
              />
              <ProcessFilterContainer
                onChange={handleProcessFilterChange}
                multipleCustomers={auth.isNotPicker()}
                disableCustomerSelection={searchMode === OrderSearchScope.TRACKING_IDS || auth.isCustomer()}
              />
              <OrderStatesFilter
                orderState={orderFilterStates}
                onChange={handleOrderStatesFilterChange}
              />
            </Stack>
          </Card>

          {isError && (
            <Alert severity="warning">
              <AlertTitle>Serverfehler</AlertTitle>
              {`${t('Search not possible.')}${errorMessage ? ` ${errorMessage}` : ''}`}
            </Alert>
          )}

          {isSearchDisabled && (
            <Alert severity="info">
              <AlertTitle>{t('A customer must be selected')}</AlertTitle>
              {t('To be able to search, a customer must be selected. This is to prevent confusion.')}
            </Alert>
          )}

          {!!batchBarcode && !auth.isCustomer() && (
            <Card sx={{ mb: 2, p: 2 }}>
              <BatchBarcodeSummary
                batchBarcode={batchBarcode}
                onAccept={() =>
                  ShipmentService.markBatchBarcodeAsProcessed(batchBarcode).then(() => {
                    setSearchQuery('');
                  })
                }
                process={data ? data[0].process : undefined}
              />
            </Card>
          )}

          {(auth.isNotPicker() || searchQuery) &&
            !isError &&
            (isLoading ? (
              <Paper style={{ padding: '1rem' }}>
                <CircularProgress
                  color="secondary"
                  style={{ float: 'left', marginRight: '0.5em' }}
                  size="1em"
                />
                <Typography
                  variant="body2"
                  color="textSecondary"
                >
                  {auth.isPicker() ? t('Pacco sifts through the files...') : t('Search is running...')}
                </Typography>
              </Paper>
            ) : (
              <Table
                orders={data || []}
                userRole={auth.role ?? null}
                onOrderSelected={(order) => openModal(order.orderId)}
                {...{ page, setPage, rowsPerPage, setRowsPerPage }}
              />
            ))}
        </Fragment>
      }
      <OrderDetailModal
        orderId={modalOrderId || null}
        shipmentId={modalShipmentId || null}
        open={!!modalOrderId}
        onNextShipment={() => {
          setSearchQuery(undefined);

          tableSearchRef.current?.focus();
        }}
        onClose={() => {
          setAutoOpen(false);

          closeModal();
        }}
        scannedTrackingCode={searchQuery}
        showArticleWeights={modalShowArticleWeights}
      />
    </Fragment>
  );
};
