import { Avatar, Box, Grid, Stack, Typography } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import devCat from '../../assets/img/dev-cat.jpg';

type Props = {
  title: string;
  content: string;
};

const ErrorPage: React.FC<PropsWithChildren<Props>> = ({ title, content, children }) => {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
    >
      <Grid item>
        <Stack
          direction="column"
          alignItems="center"
          sx={{ textAlign: 'center', my: 10 }}
        >
          <Box>
            <Avatar
              sx={{
                width: 180,
                height: 180,
                mb: 5,
              }}
              alt="Dev Cat"
              src={devCat}
            />
          </Box>
          <Typography
            variant="h3"
            gutterBottom
          >
            {title}
          </Typography>
          <Typography
            variant="body2"
            sx={{ mb: 5 }}
          >
            {content}
          </Typography>

          {children}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default ErrorPage;
