type PickByValue<T, V> = Pick<T, { [K in keyof T]: T[K] extends V ? K : never }[keyof T]>;
type ObjectEntries<T> = {
  [K in keyof T]: [keyof PickByValue<T, T[K]>, T[K]];
}[keyof T][];

export function getTypedObjectEntries<T extends {}>(obj: T): ObjectEntries<T> {
  return Object.entries(obj) as ObjectEntries<T>;
}

export function generateHumanReadablePassword(length: number = 16): string {
  const readableChars = 'abcdefgijkmnopqrstwxyzABCDEFGHJKLMNPQRSTWXYZ23456789';

  const indexes = crypto.getRandomValues(new Uint32Array(length));

  const chars = Array.from(indexes).map((index) => readableChars[index % readableChars.length]);

  return chars.join('');
}

export const isEqualArray = (a: unknown[], b: unknown[]) => {
  return a.length === b.length && a.every((value) => b.includes(value));
};

export function assertIsTruthy<T>(
  element: T | null | undefined,
  errorMessage: string = 'Element is not truthy',
): asserts element is T {
  if (!element) {
    throw new Error(errorMessage);
  }
}

export function equalDeltaPercentage(a: number, b: number, delta: number): boolean {
  if (a === 0 && b === 0) {
    return true;
  }

  const p = Math.min(a, b) / Math.max(a, b);

  return 1 - p <= delta;
}
