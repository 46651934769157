import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formFieldSkeletonWrapper: {
      flexGrow: 4,
      flexShrink: 4,
      margin: theme.spacing(1),
    },
    formFieldSkeleton: {
      minWidth: '25ch',
    },
    footer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: theme.spacing(2),
    },
  }),
);
