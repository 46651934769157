import { Alert, AlertTitle } from '@mui/material';
import React from 'react';
import TourWarningIcon, { TourWarningType } from './TourWarningIcon';

type Props = {};

const BlockedArticleAlert: React.FC<Props> = () => {
  return (
    <Alert
      severity="error"
      icon={<TourWarningIcon type={TourWarningType.ARTICLE_BLOCKED} />}
    >
      <AlertTitle>Blockierte Artikel</AlertTitle>
      Diese Tour beinhaltet Artikel die blockiert sind.
    </Alert>
  );
};

export default BlockedArticleAlert;
