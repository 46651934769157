import { IconButton, Typography } from '@mui/material';
import HistoryIcon from '@mui/icons-material/History';
import { DateTime } from 'luxon';
import React from 'react';
import { ProcessDto } from '../../../../services/process-service/process.service';

type Props = {
  process: ProcessDto;
};

const ProcessDateLabel: React.FC<Props> = ({ process }) => {
  const createdAt = process.createdAt ? DateTime.fromISO(process.createdAt) : null;
  const updatedAt = process.updatedAt ? DateTime.fromISO(process.updatedAt) : null;

  if (!createdAt || !updatedAt) {
    return <></>;
  }

  const isModified = updatedAt.diff(createdAt, 'seconds').seconds > 1;

  return (
    <Typography
      variant="body1"
      color="text.disabled"
      textAlign="right"
    >
      Erstellt {createdAt.toRelative()}. {isModified && <span>Geändert {updatedAt.toRelative()}.</span>}
      <IconButton
        component="a"
        href={`/activity?action=process&entityId=${process.processId}`}
        target="_blank"
      >
        <HistoryIcon />
      </IconButton>
    </Typography>
  );
};

export default ProcessDateLabel;
