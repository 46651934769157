// https://gram-group.com/wp-content/uploads/2016/12/MANUAL_Z3_2016_ENG_001.pdf

enum STATUS_MASK {
  GROSS = 0x01,
  TARA_ACTIVE = 0x02,
  RESET = 0x08,
  STABLE = 0x40,
}

const START_FLAG = 0x02;
const END_FLAG = 0x03;

const defaultUsbVendorIds = [0x067b, 0x04d8];

const isFlagged = (status: number | undefined, mask: STATUS_MASK): boolean =>
  status !== undefined && !!((status - 0x20) & mask);

interface IScaleFrame {
  isGrossValue: boolean;
  isTaraActive: boolean;
  isReset: boolean;
  isStable: boolean;
  value: number;
  unit: 'kg' | 'g' | 'oz' | 'lb';
}

export async function openScale(
  port: SerialPort,
  cb: (error: unknown | null, frame?: IScaleFrame) => void,
): Promise<void> {
  await port.open({ baudRate: 57600 });

  console.log('Webserial port connected');

  while (port.readable) {
    const reader = port.readable.getReader();
    const buffer = new Uint8Array(14);

    try {
      let bufferOffset = 0;

      while (true) {
        const { value, done } = await reader.read();

        if (done) {
          break;
        }

        for (let valueOffset = 0; valueOffset < value.byteLength; ) {
          if (bufferOffset === 0 && value.at(valueOffset) !== START_FLAG) {
            valueOffset++;
            continue;
          }

          const remainingSpace = buffer.byteLength - bufferOffset;
          const valueChunk = value.slice(valueOffset, valueOffset + remainingSpace);

          buffer.set(valueChunk, bufferOffset);

          valueOffset = valueOffset + remainingSpace;
          bufferOffset += valueChunk.byteLength;

          if (bufferOffset >= buffer.byteLength) {
            if (buffer.at(13) !== END_FLAG) {
              const startFlagIndex = buffer.indexOf(START_FLAG, 1);

              if (startFlagIndex > 0) {
                buffer.copyWithin(0, startFlagIndex);

                bufferOffset = buffer.byteLength - startFlagIndex;
              } else {
                bufferOffset = 0;
              }

              buffer.fill(0, bufferOffset);

              continue;
            }

            cb(null, parseFrame(buffer));

            bufferOffset = 0;

            buffer.fill(0);
          }
        }
      }
    } catch (error) {
      cb(error);
    } finally {
      reader.releaseLock();
    }
  }
}

function parseFrame(buffer: Uint8Array): IScaleFrame {
  if (buffer.at(0) !== START_FLAG || buffer.at(13) !== END_FLAG) {
    throw new Error('Start and/or end flag is wrong.');
  }

  const textDecoder = new TextDecoder();

  const status = buffer.at(1);
  const sign = textDecoder.decode(buffer.slice(2, 3)).trim();
  const asciiValue = textDecoder.decode(buffer.slice(3, 10)).trim();
  const unit = textDecoder.decode(buffer.slice(10, 12)).trim() as IScaleFrame['unit'];

  if (!['kg', 'g', 'oz', 'lb'].includes(unit)) {
    throw new Error(`Unsupported unit "${unit}"`);
  }

  if (localStorage.getItem('dev:debug-scale') === '1') {
    console.log({ sign, asciiValue, value: parseFloat(sign + asciiValue), buffer: new Uint8Array(buffer) });
  }

  const frame = {
    isGrossValue: isFlagged(status, STATUS_MASK.GROSS),
    isTaraActive: isFlagged(status, STATUS_MASK.TARA_ACTIVE),
    isReset: isFlagged(status, STATUS_MASK.RESET),
    isStable: isFlagged(status, STATUS_MASK.STABLE),
    value: parseFloat(sign + asciiValue),
    unit,
  };

  return frame;
}

export async function getPreviouslyConnectedPorts(usbVendorIds = defaultUsbVendorIds): Promise<SerialPort[]> {
  const serial = (navigator as unknown as Navigator).serial;
  const ports = await serial.getPorts();

  return ports.filter((port) => {
    const vendorId = port.getInfo().usbVendorId;

    return vendorId && usbVendorIds.includes(vendorId);
  });
}

export async function getScalePort(usbVendorIds = defaultUsbVendorIds): Promise<SerialPort> {
  const serial = (navigator as unknown as Navigator).serial;

  const previouslyConnectedPorts = await getPreviouslyConnectedPorts(usbVendorIds);

  if (previouslyConnectedPorts.length) {
    return previouslyConnectedPorts[0];
  }

  try {
    return await serial.requestPort({ filters: usbVendorIds.map((usbVendorId) => ({ usbVendorId })) });
  } catch (error) {
    if (error instanceof Error && error.toString().toLowerCase().includes('selected')) {
      return await serial.requestPort();
    }
    throw error;
  }
}

export async function forgetScalePorts(): Promise<void> {
  const serial = (navigator as unknown as Navigator).serial;
  const ports = await serial.getPorts();

  for (const port of ports) {
    await port.forget();
  }
}

export function isWebSerialSupported(): boolean {
  return 'serial' in navigator;
}

// Type definitions for non-npm package Web Serial API based on spec and Chromium implementation 1.0
// Project: https://wicg.github.io/serial/
// Definitions by: Maciej Mroziński <https://github.com/maciejmrozinski>
// Definitions: https://github.com/DefinitelyTyped/DefinitelyTyped

type EventHandler = (event: Event) => void;

interface SerialPortInfoBase {
  serialNumber: string;
  manufacturer: string;
  locationId: string;
  vendorId: string;
  vendor: string;
  productId: string;
  product: string;
}

interface SerialPortFilter {
  usbVendorId: number;
  usbProductId?: number | undefined;
}

interface SerialPortInfo extends SerialPortInfoBase, SerialPortFilter {} // mix spec and Chromium implementation

type ParityType = 'none' | 'even' | 'odd' | 'mark' | 'space';

type FlowControlType = 'none' | 'hardware';

interface SerialOptions {
  baudRate: number;
  dataBits?: number | undefined;
  stopBits?: number | undefined;
  parity?: ParityType | undefined;
  bufferSize?: number | undefined;
  flowControl?: FlowControlType | undefined;
}

export interface SerialPort extends EventTarget {
  onconnect: EventHandler;
  ondisconnect: EventHandler;
  readonly readable: ReadableStream<Uint8Array>; // Chromium implementation (spec: in)
  readonly writable: WritableStream; // Chromium implementation (spec: out)
  open(options: SerialOptions): Promise<void>;
  close(): Promise<void>;
  getInfo(): Partial<SerialPortInfo>;
  forget(): Promise<void>;
}

interface SerialPortRequestOptions {
  filters: SerialPortFilter[];
}

interface Serial extends EventTarget {
  onconnect: EventHandler;
  ondisconnect: EventHandler;
  getPorts(): Promise<SerialPort[]>;
  requestPort(options?: SerialPortRequestOptions): Promise<SerialPort>; // Chromium implementation (spec: SerialOptions)
}

interface Navigator {
  readonly serial: Serial;
}
