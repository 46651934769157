import { Alert, AlertTitle } from '@mui/material';
import React from 'react';
import TourWarningIcon, { TourWarningType } from './TourWarningIcon';

type Props = {};

const MissingArticleAlert: React.FC<Props> = () => {
  return (
    <Alert
      severity="error"
      icon={<TourWarningIcon type={TourWarningType.ARTICLE_MISSING} />}
    >
      <AlertTitle>Fehlende Artikel</AlertTitle>
      Für manche Sendungen fehlen die dazugehörigen Artikel.
    </Alert>
  );
};

export default MissingArticleAlert;
