import useSWR from 'swr';
import { AxiosResponse } from 'axios';
import { backendUri } from '../../shared/helper/env/helper';
import client, { IUseArrayResponse, SchemaType, TApiPostRequestBody, TApiPutRequestBody } from '../../shared/client';

export interface AddressDto extends SchemaType<'AddressDto'> {}

export type CreateAddressDto = TApiPostRequestBody<'/v1/customer/{customerId}/address'>;
export type UpdateAddressDto = TApiPutRequestBody<'/v1/customer/{customerId}/address/{addressId}'>;

export const useCustomerAddresses = (customerId?: number): IUseArrayResponse<AddressDto> => {
  const url = customerId ? `${backendUri}/v1/customer/${customerId}/address` : null;
  const { data, error, mutate } = useSWR<AddressDto[]>(url);

  return {
    data: data ?? [],
    error,
    isLoading: !!url && !error && typeof data === 'undefined',
    isError: !!error,
    mutate,
  };
};

export async function createAddressForCustomer(customerId: number, address: CreateAddressDto) {
  const url = `v1/customer/${customerId}/address`;

  const res = await client.post<unknown, AxiosResponse<AddressDto>>(url, address);
  return res.data;
}

export async function updateAddressForCustomer(customerId: number, addressId: number, address: UpdateAddressDto) {
  const url = `v1/customer/${customerId}/address/${addressId}`;

  const res = await client.put<unknown, AxiosResponse<void>>(url, address);
  return res.data;
}

export async function deleteAddressForCustomer(customerId: number, addressId: number) {
  const url = `v1/customer/${customerId}/address/${addressId}`;

  const res = await client.delete<unknown, AxiosResponse<AddressDto>>(url);
  return res.data;
}
