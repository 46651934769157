import { Avatar, SxProps, Theme } from '@mui/material';
import getRGB from 'consistent-color-generation';
import React from 'react';
import { UserRole } from '../backend';

type Props = {
  user: { userId: number; firstName: string; lastName: string; role?: UserRole };
  sx?: SxProps<Theme>;
};

const UserAvatar: React.FC<Props> = ({ user, sx = {} }) => {
  const { firstName, lastName } = user;

  return (
    <Avatar
      component="span"
      variant={user.role === UserRole.ADMIN ? 'rounded' : 'circular'}
      sx={{
        backgroundColor: getRGB(user.userId.toString()).toString(),
        ...sx,
      }}
    >
      {firstName[0]?.toUpperCase()}
      {lastName[0]?.toUpperCase()}
    </Avatar>
  );
};

export default UserAvatar;
