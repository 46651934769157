import { cloneDeep, toNumber } from 'lodash';

export const drop = () => undefined;
export const dropIfEmptyObject = (v: object | null | undefined) => {
  if (v === null || v === undefined) {
    return undefined;
  }
  if ('object' === typeof v) {
    return Object.keys(v).length > 0 ? v : undefined;
  }
  console.error(`Unexpected value '${v}'`);
  return v;
};

export const dropIfEmpty = (v: string) => {
  // Text fields that are empty should be updated to null
  if (v === '' || v?.length === 0) {
    return null;
  }
  // Properties that are null should not be further converted/updated
  if (v === null) {
    return undefined;
  }

  return v;
};

export const convertToString = (v: string | undefined | null, allowNull: boolean = false) => {
  if (v === null || v === undefined) {
    return undefined;
  }
  if ('string' === typeof v) {
    const text = v.trim();
    if (text.length === 0) {
      return allowNull ? null : undefined;
    }
    return text;
  }
  console.error(`Unexpected value '${v}'`);
  return v;
};

export const convertToNullableString = (v: string | undefined | null) => {
  return convertToString(v, true);
};

export const convertToBoolean = (v: boolean | undefined | null) => {
  if (v === null || v === undefined) {
    return undefined;
  }
  if ('boolean' === typeof v) {
    return v;
  }
  console.error(`Unexpected value '${v}'`);
  return v;
};

export const convertToNumber = (
  v: number | string | undefined | null,
  allowNull: boolean = false,
): number | null | undefined => {
  if (v === null || v === undefined) {
    return undefined;
  }
  if ('number' === typeof v) {
    return (v || v === 0) && isFinite(v) ? v : undefined;
  }
  if ('string' === typeof v) {
    const text = v.trim();
    if (text.length === 0) {
      return allowNull ? null : undefined;
    }
    const num = toNumber(text);
    return isFinite(num) ? num : undefined;
  }
  console.error(`Unexpected value '${v}'`);
  return v;
};

export const convertToNullableNumber = (v: number | string | undefined | null) => {
  return convertToNumber(v, true);
};

export const convertToStringArray = (
  v: string | string[] | undefined | null,
  allowNull: boolean = false,
): string[] | null | undefined => {
  if (v === null || v === undefined) {
    return undefined;
  }
  if ('string' === typeof v) {
    const text = v.trim();
    if (text.length === 0) {
      return allowNull ? null : undefined;
    }
    return text.split(/,\s*/);
  }
  if (v instanceof Array) {
    return v;
  }
  console.error(`Unexpected value '${v}'`);
  return v;
};

export function convert(conversionMap: Record<string, ((arg: any) => unknown)[]>, data: Record<string, unknown>) {
  const convertedData = cloneDeep(data);
  for (const [key] of Object.entries(convertedData)) {
    const converters = conversionMap[key];
    if (converters) {
      for (const converter of converters) {
        if (convertedData[key] !== undefined) {
          convertedData[key] = converter(convertedData[key]);
        }
      }
    }
  }
  return convertedData;
}
