import { Alert, AlertTitle } from '@mui/material';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import React from 'react';

type Props = {};

const NewProcessAlert: React.FC<Props> = () => {
  return (
    <Alert
      severity="info"
      icon={<FiberNewIcon />}
    >
      <AlertTitle>Neuer Prozess</AlertTitle>
      Ein Prozess hat bisher noch keine abgeschlossene Tour. Das bedeutet unter Umständen muss noch etwas bei AEB
      konfiguriert werden.
    </Alert>
  );
};

export default NewProcessAlert;
