import preval from 'preval.macro';
import packageJson from '../../../package.json';

const buildTimestamp: number = preval`module.exports = new Date().getTime();`;
const gitId: string = preval`
const execSync = require('child_process').execSync;
module.exports = execSync('git log -n 1 --pretty=format:%H', {encoding: 'utf8'});
`;
const gitBranchName: string = preval`
const execSync = require('child_process').execSync;
module.exports = execSync('git name-rev --name-only @', {encoding: 'utf8'});
`;

const buildInfo = {
  version: packageJson.version,
  gitShortId: gitId.slice(0, 7),
  gitId,
  gitBranchName,
  buildDate: new Date(buildTimestamp),
};

export default buildInfo;
