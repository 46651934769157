import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React from 'react';
import ButtonAsync from '../../shared/components/ButtonAsync';
import { useNotifications } from '../../hooks/useNotifications';
import { CustomsService } from '../../services/customs-service/customs.service';
import { getShortBatchId } from '../../shared/helper/text';

type Props = {
  tourIds?: number[];
  tourBatchId?: string;
  onClose: () => void;
};

const UpdateCustomsStatesDialog: React.FC<Props> = ({ tourIds, tourBatchId, onClose }) => {
  const notifications = useNotifications();

  const updateTourCustomsStates = async () => {
    try {
      await CustomsService.updateCustomsStates({ tourIds, tourBatchId });
      onClose();
    } catch (error) {
      notifications.addError(error);
    }
  };

  return (
    <Dialog
      onClose={() => onClose()}
      open={true}
    >
      <DialogTitle>Zollstatus neu berechnen</DialogTitle>

      <DialogContent>
        <DialogContentText marginBottom={3}>
          Der interne Zollstatus wird auf Basis der vorhandenen Consignments und Deklarationen aktualisiert. Dies kann
          z.B. sinnvoll sein, falls Haken nicht erscheinen, obwohl sie es sollten.
        </DialogContentText>

        {tourBatchId && <DialogContentText>- Tour Batch: {getShortBatchId(tourBatchId)}</DialogContentText>}
        {tourIds?.length && <DialogContentText>- Touren: {tourIds.join(', ')}</DialogContentText>}
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => onClose()}
        >
          Abbrechen
        </Button>

        <Box flex={1}></Box>

        <ButtonAsync
          variant="contained"
          onClick={async () => await updateTourCustomsStates()}
        >
          Neu berechnen
        </ButtonAsync>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateCustomsStatesDialog;
