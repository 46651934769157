import useSWR from 'swr';
import client, { SchemaType } from '../../shared/client';
import { backendUri } from '../../shared/helper/env/helper';

export interface ReturnSettingsDto extends SchemaType<'ReturnSettingsDto'> {}
export interface LanguageDto extends SchemaType<'LanguageDto'> {}
export interface ReasonDto extends SchemaType<'ReasonDto'> {}
export interface TranslationDto extends SchemaType<'TranslationDto'> {}
export interface DescriptionDto extends SchemaType<'DescriptionDto'> {}

export interface UpdateSettingsDto extends SchemaType<'UpdateSettingsDto'> {}
export interface UpdateReasonDto extends SchemaType<'UpdateReasonDto'> {}
export interface UpdateDescriptionDto extends SchemaType<'UpdateDescriptionDto'> {}
export interface CreateDescriptionDto extends SchemaType<'CreateDescriptionDto'> {}
export interface CreateLanguageDto extends SchemaType<'CreateLanguageDto'> {}

export const useProcessSettings = (processId: number | null) => {
  const url = processId ? `${backendUri}/v1/process/${processId}/settings` : undefined;
  const { data, error, isLoading, mutate } = useSWR<ReturnSettingsDto>(url);

  return {
    data,
    error,
    isLoading,
    isError: !!error,
    mutate,
  };
};

export const putSettings = async (body: UpdateSettingsDto, processId: number): Promise<void> => {
  const res = await client.put(`${backendUri}/v1/process/${processId}/settings`, body);
  return res.data;
};

export const postLanguage = async (body: CreateLanguageDto, processId: number) => {
  const res = await client.post<LanguageDto>(`${backendUri}/v1/process/${processId}/settings/language`, body);

  return res.data;
};

export const deleteLanguage = async (processId: number, languageId: number) => {
  const res = await client.delete(`${backendUri}/v1/process/${processId}/settings/language/${languageId}`);

  return res.data;
};

export const upsertReasonWithTranslations = async (processId: number, values: UpdateReasonDto) => {
  const res = await client.put<ReasonDto>(`${backendUri}/v1/process/${processId}/settings/reason/`, values);
  return res.data;
};

export const deleteReason = async (processId: number, reasonId: number) => {
  const res = await client.delete(`${backendUri}/v1/process/${processId}/settings/reason/${reasonId}`);
  return res.data;
};

export const putDescription = async (body: UpdateDescriptionDto, processId: number, descriptionId: number) => {
  const res = await client.put<DescriptionDto>(
    `${backendUri}/v1/process/${processId}/settings/description/${descriptionId}`,
    body,
  );

  return res.data;
};

export const postDescription = async (body: CreateDescriptionDto, processId: number) => {
  const res = await client.post<DescriptionDto>(`${backendUri}/v1/process/${processId}/settings/description/`, body);

  return res.data;
};
