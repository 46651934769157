import { useEffect } from 'react';

const BARCODE_PREFIX = '⁶';

export const useScanInput = (cb: (buffer: string) => void) => {
  useEffect(() => {
    let timeout: number;
    let buffer = '';
    let isBarcodeReading = false;

    const evaluateBuffer = () => {
      if (buffer) {
        cb(buffer);
      }

      buffer = '';
      isBarcodeReading = false;
    };

    const handler = (ev: KeyboardEvent) => {
      if (ev.key === undefined || ev.key.length !== 1) {
        return;
      }

      if (timeout) {
        window.clearTimeout(timeout);
      }

      const isBarcodeStart = buffer === '' && ev.key === BARCODE_PREFIX;

      if (isBarcodeStart) {
        isBarcodeReading = true;
      }

      if (isBarcodeReading) {
        ev.preventDefault();
      } else if (ev.target !== document.body) {
        return;
      }

      if (ev.key === 'Enter') {
        evaluateBuffer();
        return;
      }

      if (!isBarcodeStart) {
        buffer += ev.key;
      }

      timeout = window.setTimeout(evaluateBuffer, 40);
    };

    window.addEventListener('keydown', handler);

    return () => {
      window.removeEventListener('keydown', handler);
    };
  }, [cb]);
};
