import {
  Alert,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useActivitiesInfinite, useActivityActions } from '../../services/activity-service/activity.service';
import CachedIcon from '@mui/icons-material/Cached';
import UpdateIcon from '@mui/icons-material/Update';
import ClearIcon from '@mui/icons-material/Clear';
import ImporterActivityTable from './components/ImporterActivityTable';
import { ParamStrategy, useIntParam, useStringParam } from '../../hooks/useParam';
import GeneralActivityTable from './components/GeneralActivityTable';

type Props = {};

const ActivityPage: React.FC<Props> = () => {
  const [action, setAction] = useStringParam('action', '');
  const [entityId, setEntityId] = useIntParam('entityId', 0);
  const [entityName, setEntityName] = useStringParam('entityName', '');

  const isInvalidFilterCondition = !!entityId && !entityName && !action;

  const actions = useActivityActions();

  const activities = useActivitiesInfinite(
    isInvalidFilterCondition || actions.isLoading
      ? null
      : {
          actions:
            action in actions.data
              ? [...actions.data[action], ...(action === 'order' ? actions.data['shipment'] : [])]
              : undefined,
          entityId: entityId > 0 && (action || entityName) ? entityId : undefined,
          entityName: entityName || undefined,
        },
  );

  const { mutate } = activities;

  useEffect(() => {
    mutate();
  }, [mutate, action, entityId, entityName]);

  return (
    <Stack
      direction="column"
      spacing={2}
    >
      <Paper sx={{ p: 2 }}>
        <Grid
          container
          spacing={1}
          alignItems="center"
        >
          <Grid
            item
            xs={12}
            sm={2}
          >
            <TextField
              select
              label="Action"
              variant="outlined"
              value={action}
              fullWidth
              onChange={(ev) => setAction(ev.target.value)}
              error={isInvalidFilterCondition}
            >
              <MenuItem value="">all</MenuItem>
              {Object.keys(actions.data).map((action) => (
                <MenuItem
                  key={action}
                  value={action}
                >
                  {action}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid
            item
            xs={12}
            sm={2}
          >
            <TextField
              fullWidth
              label="Entity Name"
              value={entityName}
              variant="outlined"
              onChange={(ev) => setEntityName(ev.target.value)}
              error={isInvalidFilterCondition}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={2}
          >
            <TextField
              fullWidth
              label="Entity ID"
              type="number"
              value={entityId || ''}
              variant="outlined"
              onChange={(ev) => setEntityId(ev.target.value ? parseInt(ev.target.value, 10) : 0)}
            />
          </Grid>
          <Grid item>
            <IconButton onClick={() => setAction('', ParamStrategy.push)}>
              <ClearIcon />
            </IconButton>
          </Grid>
        </Grid>

        {isInvalidFilterCondition && (
          <Alert
            severity="warning"
            sx={{ mt: 3 }}
          >
            Es kann nur nach einer entity id gesucht werden, wenn mindestens eine action oder ein entity name ausgewählt
            ist.
          </Alert>
        )}
      </Paper>

      <Paper>
        <Grid
          container
          spacing={1}
          p={2}
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h6">Aktivitäten</Typography>
          </Grid>
          <Grid
            item
            flexGrow={1}
          ></Grid>
          <Grid item>
            <IconButton onClick={() => activities.mutate()}>
              <CachedIcon />
            </IconButton>
          </Grid>
        </Grid>

        {action === 'importer' ? (
          <ImporterActivityTable
            activities={activities.data?.flat() ?? []}
            isLoading={activities.isLoading}
          />
        ) : (
          <GeneralActivityTable
            activities={activities.data?.flat() ?? []}
            isLoading={activities.isLoading}
          />
        )}

        <Grid
          container
          justifyContent="center"
          py={3}
        >
          <Grid item>
            <Button
              onClick={() => activities.setSize(activities.size + 1)}
              startIcon={activities.isLoading ? <CircularProgress size="1em" /> : <UpdateIcon />}
              disabled={activities.isReachingEnd}
            >
              Mehr
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Stack>
  );
};

export default ActivityPage;
