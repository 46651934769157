import { CustomerService } from '../services/customer-service/customer.service';
import { TSetValue, useCustomerIdsParam, useProcessTypeIdsParam } from './useParam';

export function useProcessIdsFromCustomerParam(): {
  processIds: number[];
  processTypeIds: number[];
  customerIds: number[];
  setProcessTypeIds: TSetValue<number[]>;
  setCustomerIds: TSetValue<number[]>;
} {
  const [processTypeIds, setProcessTypeIds] = useProcessTypeIdsParam();
  const [customerIds, setCustomerIds] = useCustomerIdsParam();

  const customers = CustomerService.useCustomers();

  const processIds = customers.data
    .filter((customer) => customerIds.includes(customer.customerId))
    .flatMap((customer) => customer.processes)
    .filter((process) => !processTypeIds.length || processTypeIds.includes(process.processTypeId))
    .map((process) => process.processId);

  return {
    processIds,
    processTypeIds,
    setProcessTypeIds,
    customerIds,
    setCustomerIds,
  };
}
