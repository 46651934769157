import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import React, { FC, useState, useEffect, useRef, Fragment } from 'react';
import { useStyles } from './customerForm.style';
import { Skeleton } from '@mui/material';
import AddressSelect from '../customer-address-card/AddressSelect';
import { useCustomerAddresses } from '../../../../../../../services/address-service/address.service';
import {
  CreateCustomerDto,
  CustomerDto,
  UpdateCustomerDto,
} from '../../../../../../../services/customer-service/customer.service';

const getInitialCustomerFormState = (): CustomerFormState => {
  return {
    isLoading: true,
    customerFormFields: {
      company: { name: 'Anzeige Name (Company)', value: '' },
      note: { name: 'Note', value: '' },
      email: { name: 'E-Mail', value: '' },
      minWeight: {
        name: 'Minimum Weight Limit',
        value: 0,
        isNumber: true,
      },
      maxWeight: {
        name: 'Maximum Weight Limit',
        value: 0,
        isNumber: true,
      },
      hubspotId: { name: 'Hubspot ID', value: '' },
      eoriNumberEU: { name: 'EORI number EU', value: '' },
      eoriNumberGB: { name: 'EORI number GB', value: '' },
      addressId: { name: 'Adresse', value: '', isNumber: true },
    },
  };
};

const getCustomerFormFieldsByCustomer = (customer: CustomerDto): CustomerFormFields => {
  let newFormFields: CustomerFormFields = getInitialCustomerFormState().customerFormFields;
  newFormFields.company.value = customer.company;
  newFormFields.note.value = customer.note;
  newFormFields.email.value = customer.email;
  newFormFields.minWeight.value = customer.minWeight;
  newFormFields.maxWeight.value = customer.maxWeight;
  newFormFields.hubspotId.value = customer.hubspotId;
  newFormFields.eoriNumberEU.value = customer.eoriNumberEU;
  newFormFields.eoriNumberGB.value = customer.eoriNumberGB;
  newFormFields.addressId.value = customer.addressId.toString();
  return newFormFields;
};

/**
 * Keys must (partially) match the Keys
 * of CustomerService.CustomerDto
 * for this component to work properly
 */
interface CustomerFormFields {
  company: FormField;
  note: FormField;
  email: FormField;
  minWeight: FormFieldNumber;
  maxWeight: FormFieldNumber;
  hubspotId: FormField;
  eoriNumberEU: FormField;
  eoriNumberGB: FormField;
  addressId: FormField;
}

interface FormField {
  name: string;
  value: string | null;
  isNumber?: boolean;
}

interface FormFieldNumber {
  name: string;
  value: number;
  isNumber: true;
}

interface CustomerFormState {
  isLoading: boolean;
  customerFormFields: CustomerFormFields;
}

export interface CustomerFormProps {
  customer: CustomerDto | null;
  developmentMode: boolean;
  onCustomerCreate: (body: CreateCustomerDto) => Promise<CustomerDto | undefined>;
  onCustomerUpdate: (body: UpdateCustomerDto) => Promise<CustomerDto | undefined>;
}

const CustomerForm: FC<CustomerFormProps> = (props: CustomerFormProps) => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState<CustomerFormState['isLoading']>(getInitialCustomerFormState().isLoading);

  const addresses = useCustomerAddresses(props.customer?.customerId);

  const [customerFormFields, setCustomerFormFields] = useState<CustomerFormState['customerFormFields']>(
    getInitialCustomerFormState().customerFormFields,
  );

  const handleFormFieldChange =
    (prop: keyof CustomerFormState['customerFormFields']) =>
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | SelectChangeEvent<string>): void => {
      let newCustomerFormFields = { ...customerFormFields };
      newCustomerFormFields[prop].value = customerFormFields[prop].isNumber
        ? parseInt(event.target.value, 10)
        : event.target.value;
      setCustomerFormFields(newCustomerFormFields);
    };

  const handleFormFieldBlur =
    (prop: keyof CustomerFormState['customerFormFields']) =>
    async (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (props.customer) {
        const newValue =
          customerFormFields[prop].value && customerFormFields[prop].isNumber
            ? parseInt(String(customerFormFields[prop].value), 10)
            : customerFormFields[prop].value;

        let partialCustomer: UpdateCustomerDto = {
          [prop]: newValue,
        };
        let newCustomer = await props.onCustomerUpdate(partialCustomer);

        if (newCustomer) {
          let newCustomerFormFields: CustomerFormFields = {
            ...customerFormFields,
          };
          setCustomerFormFields(newCustomerFormFields);
        }
      }
    };

  const handleCustomerCreate = async (): Promise<void> => {
    setIsLoading(true);
    let newCustomer: CreateCustomerDto = {
      company: customerFormFields.company.value ?? '',
      note: customerFormFields.note.value ?? undefined,
      email: customerFormFields.email.value ?? undefined,
      hubspotId: customerFormFields.hubspotId.value ?? undefined,
      eoriNumberEU: customerFormFields.eoriNumberEU.value ?? undefined,
      eoriNumberGB: customerFormFields.eoriNumberGB.value ?? undefined,
      minWeight: customerFormFields.minWeight.value,
      maxWeight: customerFormFields.maxWeight.value,
    };

    await props.onCustomerCreate(newCustomer);
    setIsLoading(false);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    console.log(event);
    event.preventDefault();
    handleCustomerCreate();
  };

  const onCustomerChange = useRef(() => {});
  onCustomerChange.current = () => {
    let newCustomerFormFields: CustomerFormState['customerFormFields'] =
      getInitialCustomerFormState().customerFormFields;

    if (props.customer) {
      newCustomerFormFields = getCustomerFormFieldsByCustomer(props.customer);
    } else {
      newCustomerFormFields = getInitialCustomerFormState().customerFormFields;
    }
    setCustomerFormFields(newCustomerFormFields);
  };

  useEffect(() => {
    setIsLoading(true);
    onCustomerChange.current();
    setIsLoading(false);
  }, [props.customer, onCustomerChange]);

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Grid
          container
          spacing={1}
        >
          {Object.entries(customerFormFields).map(([prop, formField]) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              xl={2}
              key={prop}
            >
              {!isLoading ? (
                <FormControl
                  variant="standard"
                  fullWidth
                >
                  {prop === 'addressId' ? (
                    <Fragment>
                      {props.customer && (
                        <Fragment>
                          <AddressSelect
                            addresses={addresses.data ?? []}
                            value={formField.value}
                            onChange={handleFormFieldChange(prop as keyof CustomerFormFields)}
                            onBlur={handleFormFieldBlur(prop as keyof CustomerFormFields)}
                          ></AddressSelect>
                          <FormHelperText id={`${prop}-helper`}>{formField.name}</FormHelperText>
                        </Fragment>
                      )}
                    </Fragment>
                  ) : (
                    <Fragment>
                      <TextField
                        id={prop}
                        type={formField.isNumber ? 'number' : 'text'}
                        value={formField.value ?? ''}
                        onChange={handleFormFieldChange(prop as keyof CustomerFormFields)}
                        onBlur={handleFormFieldBlur(prop as keyof CustomerFormFields)}
                        variant="outlined"
                      />
                      <FormHelperText id={`${prop}-helper`}>{formField.name}</FormHelperText>
                    </Fragment>
                  )}
                </FormControl>
              ) : (
                <div
                  key={prop}
                  className={classes.formFieldSkeletonWrapper}
                >
                  <Skeleton
                    className={classes.formFieldSkeleton}
                    variant="text"
                  />
                </div>
              )}
            </Grid>
          ))}
        </Grid>
        {!props.customer && (
          <div className={classes.footer}>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              disabled={isLoading}
            >
              {!isLoading ? (
                'Kunde anlegen'
              ) : (
                <CircularProgress
                  color="secondary"
                  size={25}
                />
              )}
            </Button>
          </div>
        )}
      </form>
    </div>
  );
};

export default CustomerForm;
