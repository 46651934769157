import { Alert, AlertTitle } from '@mui/material';
import React from 'react';
import TourWarningIcon, { TourWarningType } from './TourWarningIcon';

type Props = {};

const MissingShippingWeightAlert: React.FC<Props> = () => {
  return (
    <Alert
      severity="error"
      icon={<TourWarningIcon type={TourWarningType.MISSING_SHIPPING_WEIGHTS} />}
    >
      <AlertTitle>Fehlende Gewichte</AlertTitle>
      Nicht alle Sendungen haben ein Gewicht hinterlegt.
    </Alert>
  );
};

export default MissingShippingWeightAlert;
