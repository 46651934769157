import FiberNewIcon from '@mui/icons-material/FiberNew';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import BlockIcon from '@mui/icons-material/Block';
import React from 'react';
import { ShipmentStateIcon } from '../../../../shared/components/shipment-state-icon/ShipmentStateIcon';
import { OrderState, ShipmentState } from '../../../../shared/backend';

export const OrderStateIcon = {
  [OrderState.NEW]: <FiberNewIcon color="secondary"></FiberNewIcon>,
  [OrderState.REJECTED]: <RadioButtonUncheckedIcon color="warning"></RadioButtonUncheckedIcon>,
  [OrderState.IN_PROGRESS]: <HourglassEmptyIcon color="printed"></HourglassEmptyIcon>,
  [OrderState.CLOSED]: <ShipmentStateIcon state={ShipmentState.CLOSED}></ShipmentStateIcon>,
  [OrderState.BLOCKED]: <BlockIcon color="error" />,
};
