import { NavigateOptions, To, useLocation, useNavigate } from 'react-router-dom';
import { useCallback } from 'react';

const HISTORY_STATE_REFERRER = 'referrer';

export const useHistoryRef = (): string | null => {
  const { state } = useLocation();

  return state?.[HISTORY_STATE_REFERRER] ?? null;
};

export function useHistoryNavigation(): { navigateTo: (to: To, options?: NavigateOptions) => void } {
  const navigate = useNavigate();

  const navigateTo = useCallback(
    (to: To, options?: NavigateOptions): void => {
      const referrer = `${window.location.pathname}${window.location.search}`;

      navigate(to, { ...options, state: { ...options?.state, [HISTORY_STATE_REFERRER]: referrer } });
    },
    [navigate],
  );

  return { navigateTo };
}
