import React from 'react';
import { Lock, LockOpen } from '@mui/icons-material';
import { ListItemIcon, ListItemText, MenuItem, TextField } from '@mui/material';
import { ShippingUnitState } from '../../shared/backend';

/** Compared to the ShippingUnitState, we have a value for both. This is needed if we want to use it as url param. Null can not be used there. */
export enum ShippingUnitStateOption {
  OPEN = 'open',
  CLOSED = 'closed',
  BOTH = 'both',
}

const stateOptions = {
  [ShippingUnitStateOption.CLOSED]: {
    label: 'Geschlossen',
    icon: <Lock sx={{ verticalAlign: 'bottom', height: 24, width: 24 }} />,
  },
  [ShippingUnitStateOption.OPEN]: {
    label: 'Offen',
    icon: <LockOpen sx={{ verticalAlign: 'bottom', height: 24, width: 24 }} />,
  },
  [ShippingUnitStateOption.BOTH]: {
    label: 'Whatever',
    icon: null,
  },
};

const tourStateOptionMap = {
  [ShippingUnitStateOption.OPEN]: ShippingUnitState.OPEN,
  [ShippingUnitStateOption.CLOSED]: ShippingUnitState.CLOSED,
  [ShippingUnitStateOption.BOTH]: undefined,
};

export function getShippingUnitStateFromShippingUnitStateOption(
  stateOption: ShippingUnitStateOption,
): ShippingUnitState | undefined {
  return tourStateOptionMap[stateOption];
}

type Props = {
  stateOption: ShippingUnitStateOption;
  onChange: (tourStateOption: ShippingUnitStateOption) => void;
};

const ShippingUnitStateOptionSelector: React.FC<Props> = ({ stateOption, onChange }) => {
  return (
    <TextField
      select
      label="Unit state"
      value={stateOption}
      sx={{ minWidth: 140 }}
      size="small"
      onChange={(ev) => onChange(ev.target.value as ShippingUnitStateOption)}
      SelectProps={{
        sx: { minHeight: 41 },
        renderValue: (value) => (
          <>
            {stateOptions[value as ShippingUnitStateOption].icon} {stateOptions[value as ShippingUnitStateOption].label}
          </>
        ),
      }}
    >
      {[ShippingUnitStateOption.BOTH, ShippingUnitStateOption.OPEN, ShippingUnitStateOption.CLOSED].map((state) => (
        <MenuItem
          value={state}
          key={state}
        >
          {stateOptions[state].icon && <ListItemIcon>{stateOptions[state].icon}</ListItemIcon>}
          <ListItemText>{stateOptions[state].label}</ListItemText>
        </MenuItem>
      ))}
    </TextField>
  );
};

export default ShippingUnitStateOptionSelector;
