import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import ReportRoundedIcon from '@mui/icons-material/ReportRounded';
import {
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper,
  Typography,
} from '@mui/material';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import {
  getSystemLabel,
  ICategoryHealthStatus,
  ICommonHealthStatus,
  IFtpServerHealthStatus,
  IImporterHealthStatus,
  IOutdatedStatus,
  useHealthStatus,
} from '../../../services/health-service/health-service';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box } from '@mui/system';
import { ProcessService } from '../../../services/process-service/process.service';
import HistoryIconButton from '../../../shared/components/HistoryIconButton';

const OutdatedCategory: React.FC<{
  importerStatus?: IOutdatedStatus[];
  heading: string;
  action: 'importer' | 'match';
}> = ({ importerStatus, heading, action }) => {
  const processes = ProcessService.useProcesses();

  if (!importerStatus?.length) {
    return <></>;
  }

  return (
    <List
      component="div"
      disablePadding
    >
      {heading && <ListSubheader>{heading}</ListSubheader>}
      {importerStatus.map((status) => {
        const process = processes.data.find((process) => process.processId === status.process);

        return (
          <ListItem
            key={status.process}
            sx={{ pl: 4 }}
            secondaryAction={
              <HistoryIconButton
                size="small"
                action={action}
                entityId={status.process}
              />
            }
          >
            <ListItemIcon>{status.isRunning ? <CircularProgress size="1.5em" /> : <SyncProblemIcon />}</ListItemIcon>
            <ListItemText
              primary={
                <Box
                  component="a"
                  href={`/crm/process/${process?.processId}`}
                  target="_blank"
                  rel="noreferrer noopener"
                  sx={{ color: 'inherit', textDecoration: 'none' }}
                >
                  {process?.customer.company
                    ? `${process?.customer.company} (${status.process})`
                    : `Prozess ${status.process}`}
                </Box>
              }
              secondary={status.lastRun ? DateTime.fromISO(status.lastRun).toRelative({ locale: 'de' }) : 'noch nie'}
            ></ListItemText>
          </ListItem>
        );
      })}
    </List>
  );
};

const isImporter = (
  system: string,
  detail: ICommonHealthStatus | IImporterHealthStatus,
): detail is IImporterHealthStatus => system === 'importers';

const isFtpServer = (
  system: string,
  detail: ICommonHealthStatus | IFtpServerHealthStatus,
): detail is IFtpServerHealthStatus => system === 'ftp-server';

const isCategory = (
  system: string,
  detail: ICommonHealthStatus | ICategoryHealthStatus,
): detail is ICategoryHealthStatus => system === 'categories';

type Props = {};

const SystemHealthPage: React.FC<Props> = () => {
  const health = useHealthStatus(true);
  const [importerOpen, setImporterOpen] = useState(false);
  const [categoryOpen, setCategoryOpen] = useState(false);

  const getDetailText = (
    system: string,
    detail: ICommonHealthStatus | IImporterHealthStatus | IFtpServerHealthStatus,
  ) => {
    if (isImporter(system, detail) || isCategory(system, detail)) {
      return undefined;
    }
    if (isFtpServer(system, detail)) {
      if (detail.availableDiskSpaceInPercent) {
        return `Available free disk space: ${detail.availableDiskSpaceInPercent} %`;
      }
    }

    return detail?.message;
  };

  return (
    <Grid container>
      <Grid
        item
        md={4}
        sm={6}
        xs={12}
      >
        <Paper sx={{ p: 2 }}>
          <Typography
            variant="h5"
            gutterBottom
          >
            System Status
          </Typography>
          <Typography variant="body1">Überblick über den Zustand der individuellen System-Komponenten.</Typography>

          <List sx={{ my: 3 }}>
            {Object.entries(health.data?.details ?? {}).map(([system, detail]) => {
              return (
                <React.Fragment key={system}>
                  <ListItem
                    secondaryAction={
                      isImporter(system, detail) &&
                      [...detail.article, ...detail.dailyArticle, ...detail.order].length > 0 ? (
                        <IconButton
                          edge="end"
                          onClick={() => setImporterOpen(!importerOpen)}
                        >
                          {importerOpen ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                      ) : isCategory(system, detail) && detail.categories.length > 0 ? (
                        <IconButton
                          edge="end"
                          onClick={() => setCategoryOpen(!categoryOpen)}
                        >
                          {categoryOpen ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                      ) : undefined
                    }
                  >
                    <ListItemIcon>
                      {detail.status === 'up' ? (
                        <CheckCircleOutlineRoundedIcon color="success" />
                      ) : (
                        <ReportRoundedIcon color="warning" />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={getSystemLabel(system)}
                      secondary={getDetailText(system, detail)}
                    ></ListItemText>
                  </ListItem>
                  {isImporter(system, detail) && (
                    <Collapse
                      in={importerOpen}
                      timeout="auto"
                      unmountOnExit
                    >
                      <OutdatedCategory
                        heading="Stündliche Artikel"
                        importerStatus={detail.article}
                        action="importer"
                      />
                      <OutdatedCategory
                        heading="Tägliche Artikel"
                        importerStatus={detail.dailyArticle}
                        action="importer"
                      />
                      <OutdatedCategory
                        heading="Bestellungen"
                        importerStatus={detail.order}
                        action="importer"
                      />
                    </Collapse>
                  )}
                  {isCategory(system, detail) && (
                    <Collapse
                      in={categoryOpen}
                      timeout="auto"
                      unmountOnExit
                    >
                      <OutdatedCategory
                        heading=""
                        importerStatus={detail.categories}
                        action="match"
                      />
                    </Collapse>
                  )}
                </React.Fragment>
              );
            })}
          </List>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default SystemHealthPage;
