import React, { useEffect, useState } from 'react';
import { ProcessTypeService } from '../../services/process-type-service/process-type.service';
import { useCustomerIdsParam, useProcessTypeIdsParam } from '../../hooks/useParam';
import { ProcessTypeFilter } from '../../shared/components/process-type-filter/ProcessTypeFilter';

type Props = {};

const SelectionProcessType: React.FC<Props> = () => {
  const [initialized, setInitialized] = useState(false);
  const [processTypeIds, setProcessTypeIds] = useProcessTypeIdsParam();
  const [, setCustomerIds] = useCustomerIdsParam();

  const processTypes = ProcessTypeService.useProcessTypes();
  const processTypeIdList = processTypeIds.join(',');

  useEffect(() => {
    const ids = processTypeIdList
      .split(',')
      .filter((id) => !!id)
      .map((id) => parseInt(id, 10));

    if (!initialized) {
      const storedIds = JSON.parse(localStorage.getItem('process-type:selection') ?? '[]');

      if (!ids.length && storedIds.length) {
        setProcessTypeIds(storedIds, undefined, true);
      }

      setInitialized(true);
    } else {
      localStorage.setItem('process-type:selection', JSON.stringify(ids));
    }
  }, [processTypeIdList, initialized, setProcessTypeIds]);

  return (
    <ProcessTypeFilter
      processTypes={processTypes.data || []}
      selectedProcessTypeIds={processTypeIds}
      onChange={(ids) => {
        setProcessTypeIds(ids);
        setCustomerIds([]);
      }}
    />
  );
};

export default SelectionProcessType;
