import { Alert, AlertTitle, Box } from '@mui/material';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import React from 'react';

type Props = {
  vehicleIdentifications: string[];
};

const VehicleIdentificationsAlert: React.FC<Props> = ({ vehicleIdentifications }) => {
  return (
    <Alert
      severity="info"
      icon={<FingerprintIcon />}
    >
      <AlertTitle>Fahrzeugidentifikationen</AlertTitle>
      {vehicleIdentifications.length === 1 ? (
        vehicleIdentifications[0]
      ) : (
        <Box
          component="ul"
          margin={0}
          paddingLeft={3}
        >
          {vehicleIdentifications.map((id) => (
            <li key={id}>{id}</li>
          ))}
        </Box>
      )}
    </Alert>
  );
};

export default VehicleIdentificationsAlert;
