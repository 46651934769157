import { Visibility, VisibilityOff, Login } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  CardContent,
  IconButton,
  InputAdornment,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useNotifications } from '../../hooks/useNotifications';
import { useUserInfo } from '../../services/user-service/user.service';
import { getPasswordStrength } from '../../shared/helper/password';
import { PasswordChangeReason, resetPassword } from '../../services/authentication-service/authentication.service';

const MIN_PASSWORD_LENGTH = 8;

type Props = {};

const ResetPasswordPage: React.FC<Props> = () => {
  const userInfo = useUserInfo();
  const notifications = useNotifications();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const [showPassword, setShowPassword] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');

  const email = searchParams.get('email') ?? '';
  const mac = searchParams.get('mac') ?? '';
  const reason = searchParams.get('reason') ?? '';

  return (
    <Card sx={{ maxWidth: '100%' }}>
      <CardContent sx={{ maxWidth: '400px' }}>
        <form
          onSubmit={(ev) => {
            ev.preventDefault();

            setDisabled(true);

            if (!email || !mac || !password || !password2) {
              notifications.addError('Daten fehlen.');

              return;
            }

            if (password.length < MIN_PASSWORD_LENGTH) {
              notifications.addError(`Mindestlänge ist ${MIN_PASSWORD_LENGTH} Zeichen.`);
            }

            if (password !== password2) {
              notifications.addError('Passwörter sind unterschiedlich.');

              return;
            }

            resetPassword(email, mac, password)
              .then((result) => {
                userInfo.mutate();

                navigate('/orders/all-orders');
              })
              .catch((error) => {
                notifications.addError(error);

                setDisabled(false);
              });
          }}
        >
          <Stack
            direction="column"
            spacing={2}
          >
            {reason === PasswordChangeReason.INSECURE && (
              <Typography
                variant="body2"
                gutterBottom
              >
                Ihr bestehendes Passwort wurde als unsicher eingestuft. Bitte vergeben Sie ein neues sicheres Passwort.
              </Typography>
            )}
            {reason === PasswordChangeReason.NEW_PASSWORD && (
              <Typography
                variant="body2"
                gutterBottom
              >
                Sie haben ein neues Passwort erhalten. Bitte vergeben Sie nun ein neues.
              </Typography>
            )}

            <TextField
              name="email"
              variant="outlined"
              label="E-Mail"
              disabled={true}
              required
              value={email}
            />

            <Box>
              <TextField
                name="password"
                variant="outlined"
                fullWidth
                label="Neues Passwort"
                error={password.length < MIN_PASSWORD_LENGTH && !!password}
                required
                disabled={disabled}
                onChange={(ev) => setPassword(ev.target.value)}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        size="large"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <LinearProgress
                variant="determinate"
                color="success"
                value={password ? getPasswordStrength(password) : 0}
              />
            </Box>
            <TextField
              name="password2"
              variant="outlined"
              required
              fullWidth
              error={!!password && !!password2 && password !== password2}
              label="Passwort Kontrolle"
              disabled={disabled}
              onChange={(ev) => setPassword2(ev.target.value)}
              type={'password'}
            />

            <LoadingButton
              loading={disabled}
              disabled={password !== password2 || password.length < MIN_PASSWORD_LENGTH}
              loadingPosition="start"
              startIcon={<Login />}
              type="submit"
              variant="contained"
              color="secondary"
            >
              Aktualisieren
            </LoadingButton>
          </Stack>
        </form>
      </CardContent>
    </Card>
  );
};

export default ResetPasswordPage;
