import React, { FC, useEffect } from 'react';

import { IconButton, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import ReactJson, { InteractionProps } from 'react-json-view';
import CopyIcon from '@mui/icons-material/FileCopy';
import PasteIcon from '@mui/icons-material/Add';
import { useDarkMode } from '../../hooks/dark-mode';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    jsonEditor: {
      padding: theme.spacing(1),
      border: 'solid',
      borderWidth: 'thin',
      borderRadius: '4px',
      borderColor: '#CCCCCC',
      width: '100%',
    },
    label: {
      color: 'text.primary',
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: '0.75rem',
    },
    buttonBox: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  }),
);

export interface JsonEditorProps {
  label: string;
  value: object;
  onValueChange: (value: Record<string, unknown>) => Promise<boolean>;
}

export const JsonEditor: FC<JsonEditorProps> = (props: JsonEditorProps) => {
  const classes = useStyles();
  const [darkMode] = useDarkMode();

  const [value, setValue] = React.useState(props.value);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const handleCopy = async () => {
    await navigator.clipboard.writeText(JSON.stringify(value, null, 2));
  };

  const handlePaste = async () => {
    const newValue = JSON.parse(await navigator.clipboard.readText());

    const valueChangeResult = await propagateValueChange(newValue);
    if (valueChangeResult) {
      setValue(newValue);
    }
  };

  const onJsonEdit = async (edit: InteractionProps) => {
    const newValue = edit.updated_src as Record<string, unknown>;

    const valueChangeResult = await propagateValueChange(newValue);
    if (valueChangeResult) {
      setValue(newValue);
    }
    return valueChangeResult;
  };

  const propagateValueChange = async (value: Record<string, unknown>) => {
    return await props.onValueChange(value);
  };

  return (
    <div>
      <div className={classes.jsonEditor}>
        <ReactJson
          style={{ fontSize: '1em' }}
          src={value}
          displayDataTypes={false}
          displayObjectSize={false}
          name={null}
          theme={darkMode ? 'railscasts' : 'rjv-default'}
          enableClipboard={false}
          onEdit={onJsonEdit}
          onAdd={onJsonEdit}
          onDelete={onJsonEdit}
        />
        <div className={classes.buttonBox}>
          <IconButton onClick={handleCopy}>
            <CopyIcon fontSize="small" />
          </IconButton>
          <IconButton onClick={handlePaste}>
            <PasteIcon fontSize="small" />
          </IconButton>
        </div>
      </div>
      <span className={classes.label}>{props.label}</span>
    </div>
  );
};
