import { backendUri } from '../../shared/helper/env/helper';
import useSWR from 'swr';

export const useCarriers = () => {
  const url = `${backendUri}/carrier`;
  const { data, error, mutate } = useSWR<string[]>(url);

  return {
    data,
    error,
    isLoading: !error && typeof data === 'undefined',
    isError: !!error,
    mutate,
  };
};
