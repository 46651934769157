import React from 'react';
import { Box, Tooltip } from '@mui/material';

type Props = {
  batch: {
    notificationPendingCount: number;
    notificationDeliveredCount: number;
    notificationFailedCount: number;
  };
};

const NotificationState: React.FC<Props> = ({ batch }) => {
  return (
    <>
      <Tooltip title="Ausstehend">
        <Box
          component="span"
          sx={{ color: 'info.main' }}
        >
          {batch.notificationPendingCount}
        </Box>
      </Tooltip>{' '}
      /{' '}
      <Tooltip title="Zugestellt">
        <Box
          component="span"
          sx={{ color: 'success.main' }}
        >
          {batch.notificationDeliveredCount}
        </Box>
      </Tooltip>{' '}
      /{' '}
      <Tooltip title="Fehler">
        <Box
          component="span"
          sx={{ color: 'error.main' }}
        >
          {batch.notificationFailedCount}
        </Box>
      </Tooltip>
    </>
  );
};

export default NotificationState;
