import React from 'react';
import HistoryIcon from '@mui/icons-material/History';
import { IconButton } from '@mui/material';
import { useAuthentication } from '../../hooks/useAuthentication';
import { UserRole } from '../backend';

type Props =
  | {
      action: string;
      entityId: number;
      entityName?: string;
    }
  | {
      action?: string;
      entityId: number;
      entityName: string;
    };

const HistoryIconButton: React.FC<Props & { size?: 'small' | 'medium' | 'large' }> = ({
  action,
  entityId,
  entityName,
  size,
}) => {
  const auth = useAuthentication();

  const url = new URL('/activity', window.location.origin);

  url.searchParams.set('entityId', entityId.toString());

  if (action) {
    url.searchParams.set('action', action);
  }

  if (entityName) {
    url.searchParams.set('entityName', entityName);
  }

  return auth.role && [UserRole.ADMIN, UserRole.CUSTOMER_CARE_AGENT, UserRole.CUSTOMS_AGENT].includes(auth.role) ? (
    <IconButton
      size={size}
      component="a"
      href={url.href}
      target="_blank"
    >
      <HistoryIcon />
    </IconButton>
  ) : (
    <></>
  );
};

export default HistoryIconButton;
