import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { Alert, Box, Grid, Paper, Skeleton } from '@mui/material';
import TourBatch from './TourBatch';
import TourTable from './TourTable';
import BreadcrumbsGridItem from '../BreadcrumbsGridItem';
import { TourService } from '../../../services/tour-service/tour.service';
import CustomsStatusIconLegend from '../CustomsStatusIconLegend';
import { TourType } from '../../../shared/backend';
import { getShortBatchId } from '../../../shared/helper/text';

type Props = {};

const TourPage: React.FC<Props> = () => {
  // we do not want to render the slow table at the beginning
  const [showTours, setShowTours] = useState(false);

  const params = useParams();
  const batch = TourService.useTourBatch(params.tourBatchId ?? '');
  const tours = TourService.useToursByBatch(showTours ? params.tourBatchId ?? '' : null);

  useEffect(() => setShowTours(true), []);

  return (
    <Grid
      container
      spacing={3}
    >
      <BreadcrumbsGridItem
        items={[
          { href: '/customs/tours', label: 'Zwischentouren' },
          { label: `Zwischentour ${getShortBatchId(params.tourBatchId ?? '')}` },
        ]}
      />

      <Grid
        item
        xs={12}
      >
        {batch.isLoading ? (
          <Skeleton
            variant="rounded"
            height={80}
          />
        ) : batch.data ? (
          <Paper sx={{ padding: 1, paddingTop: 2 }}>
            <Helmet>
              <title>{`Tour batch ${getShortBatchId(batch.data.tourBatchId)} (${batch.data.processTypeLabels.join(
                ', ',
              )})`}</title>
            </Helmet>
            <TourBatch
              batch={batch.data}
              tours={tours.data}
              isValidating={batch.isValidating || tours.isValidating}
              toursAreLoading={tours.isLoading}
              mutate={() => {
                tours.mutate();
                batch.mutate();
              }}
            />
          </Paper>
        ) : (
          <Alert severity="warning">{batch.error?.toString()}</Alert>
        )}
      </Grid>

      <Grid
        item
        xs={12}
      >
        {tours.isLoading || !showTours ? (
          <Skeleton
            variant="rounded"
            height={240}
          />
        ) : tours.error ? (
          <Alert severity="warning">{tours.error?.toString()}</Alert>
        ) : (
          <Paper sx={{ paddingX: 3, paddingY: 2 }}>
            <TourTable
              toursAndChecks={tours.data}
              tourType={batch.data?.type}
              dispatchCountry={
                batch.data?.type === TourType.TOUR ? batch.data?.dispatchCountry : batch.data?.destinationCountry
              }
              destinationCountry={
                batch.data?.type === TourType.TOUR ? batch.data?.destinationCountry : batch.data?.dispatchCountry
              }
              mutate={async () => {
                await tours.mutate();
                await batch.mutate();
              }}
            />
          </Paper>
        )}
      </Grid>

      <Grid
        item
        xs={12}
      >
        <Box marginTop={3}>
          <CustomsStatusIconLegend />
        </Box>
      </Grid>
    </Grid>
  );
};

export default TourPage;
