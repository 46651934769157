import React, { FunctionComponent } from 'react';
import { Box, FormControl, MenuItem, Select } from '@mui/material';
import { atomWithStorage } from 'jotai/utils';
import { useAtom } from 'jotai';
import { ProcessTypeService } from '../../../services/process-type-service/process-type.service';
import { ShipmentService } from '../../../services/shipment-service/shipment.service';

interface IWeightCounter {}

const weightWarehouseAtom = atomWithStorage<string | null>('weight:warehouse', null);
const useWeightWarehouse = () => useAtom(weightWarehouseAtom);

export const WeightCounter: FunctionComponent<IWeightCounter> = () => {
  const weights = ShipmentService.useWeightsForShipmentsWithStatusLabelPrinted();
  const processTypes = ProcessTypeService.useProcessTypes();
  const [warehouse, setWarehouse] = useWeightWarehouse();

  if (weights.isLoading || processTypes.isLoading) {
    return <></>;
  }

  const warehouses = [
    ...(processTypes.data?.map(({ outboundWarehouse }) => outboundWarehouse) ?? []),
    ...(processTypes.data?.map(({ inboundWarehouse }) => inboundWarehouse) ?? []),
  ]
    .filter((warehouse) => !!warehouse)
    .filter((warehouse, index, arr) => index === arr.indexOf(warehouse));

  return (
    <FormControl fullWidth>
      <Select
        sx={(theme) => ({
          mx: theme.spacing(1),
          '& .MuiOutlinedInput-notchedOutline': {
            border: 0,
          },
          '& .MuiSelect-icon': {
            color: 'inherit',
          },
          color: 'inherit',
        })}
        size="small"
        value={warehouse ?? weights.data[0]?.warehouse ?? ''}
        onChange={(ev) => setWarehouse(ev.target.value)}
      >
        {warehouses
          .sort((a, b) => (a < b ? -1 : 1))
          .map((warehouse) => {
            const { weight } = weights.data.find((item) => item.warehouse === warehouse) ?? { weight: null };

            return (
              <MenuItem
                key={warehouse}
                value={warehouse}
              >
                <Box
                  component="span"
                  sx={{ display: 'inline-block', minWidth: '5em' }}
                >
                  {warehouse}:
                </Box>
                <strong>{weight ? (weight / 1000).toFixed(1) : '-'} kg</strong>
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
};
