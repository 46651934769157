import { Box, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import React, { Fragment } from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import * as ProcessSettingsService from '../../../services/process-settings-service/processSettings.service';
import LanguagePicker from './LanguagePicker';
import { useNotifications } from '../../../hooks/useNotifications';
import { Controller, useForm } from 'react-hook-form';
import { backendUri } from '../../../shared/helper/env/helper';
import { getTypedObjectEntries } from '../../../shared/helper/helper';
import { useTranslation } from 'react-i18next';

interface ReturnSettingsFormFields {
  backgroundUrl: string;
  logoUrl: string;
  customerImprint: string;
  customerPrivacyPolicy: string;
  contactEmail: string;
}

const typeSettingsImageKeyMapper: Partial<Record<keyof ReturnSettingsFormFields, string>> = {
  logoUrl: 'logo',
  backgroundUrl: 'background',
};

export interface SettingsFormProps {
  settings: ProcessSettingsService.ReturnSettingsDto;
  processId: number;
  onUpdateSettings: () => void;
}

const SettingsForm: React.FC<SettingsFormProps> = ({ settings, processId, onUpdateSettings }) => {
  const { t } = useTranslation();
  const notificationHandler = useNotifications();
  const { getValues, reset, control } = useForm<ReturnSettingsFormFields>({
    defaultValues: {
      backgroundUrl: settings.backgroundUrl ?? '',
      logoUrl: settings.logoUrl ?? '',
      customerImprint: settings.customerImprint ?? '',
      customerPrivacyPolicy: settings.customerPrivacyPolicy ?? '',
      contactEmail: settings.contactEmail ?? '',
    },
  });

  const formFieldLabels = {
    logoUrl: t('Logo URL'),
    backgroundUrl: t('Background image URL'),
    customerPrivacyPolicy: t('Privacy policy'),
    customerImprint: t('Imprint'),
    contactEmail: t('Contact email'),
  };

  const putSettings = async (body: Partial<ReturnSettingsFormFields>) => {
    try {
      const updateValues: ProcessSettingsService.UpdateSettingsDto = body;

      if (body.contactEmail === '') {
        updateValues.contactEmail = undefined;
      }

      await ProcessSettingsService.putSettings(updateValues, processId);
      onUpdateSettings();
      notificationHandler.addInfo(`Settings saved`);
    } catch (error) {
      notificationHandler.addError(error);
      // Reset form on error
      reset();
    }
  };

  const handleBlur = async (formField: keyof ReturnSettingsFormFields) => {
    const formFieldValue = getValues()[formField];
    if (formFieldValue !== settings[formField]) {
      await putSettings({ [formField]: formFieldValue });
    }
  };

  const linkFormFields: (keyof ReturnSettingsFormFields)[] = [
    'logoUrl',
    'backgroundUrl',
    'customerPrivacyPolicy',
    'customerImprint',
  ];

  return (
    <Fragment>
      <Grid
        container
        spacing={2}
      >
        {/* Link section*/}
        <Typography
          width="100%"
          variant="h3"
          mt={2}
          ml={2}
        >
          {t('Links and images')}
        </Typography>
        {getTypedObjectEntries(typeSettingsImageKeyMapper)
          .filter((entry): entry is [keyof ReturnSettingsFormFields, string] => !!entry)
          .map(([formField, type]) => (
            <Grid
              item
              key={formField}
              sm={12}
              md={6}
            >
              {!!settings[formField] && (
                <Box
                  component="img"
                  alt={`${type} image`}
                  sx={{ maxWidth: '100%' }}
                  // the last part is only to reload the image on a changed value
                  src={`${backendUri}/public/return/${processId}/${settings.processReturnPortalIdentifier}/settings/image/${type}?url=${settings[formField]}`}
                />
              )}
            </Grid>
          ))}
        {linkFormFields.map((formField) => (
          <Grid
            item
            key={formField}
            sm={12}
            md={6}
          >
            <Controller
              name={formField}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  fullWidth
                  value={value}
                  variant="outlined"
                  label={formFieldLabels[formField]}
                  onChange={onChange}
                  onBlur={() => {
                    handleBlur(formField);
                  }}
                  InputProps={{
                    endAdornment: value.startsWith('https://') ? (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          LinkComponent="a"
                          href={value}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <OpenInNewIcon />
                        </IconButton>
                      </InputAdornment>
                    ) : undefined,
                  }}
                ></TextField>
              )}
            />
          </Grid>
        ))}

        {/* Contact mail */}
        <Grid
          item
          sm={12}
          md={6}
        >
          <Typography
            variant="h3"
            marginY={3}
          >
            {t('Contact email')}
          </Typography>

          <Controller
            name="contactEmail"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                type="email"
                fullWidth
                value={value}
                variant="outlined"
                label={formFieldLabels['contactEmail']}
                onChange={onChange}
                onBlur={() => {
                  handleBlur('contactEmail');
                }}
              ></TextField>
            )}
          />
        </Grid>

        {/* Languages */}
        <Grid
          item
          sm={12}
          md={6}
        >
          <Typography
            variant="h3"
            marginY={3}
          >
            {t('Languages')}
          </Typography>

          <LanguagePicker
            processId={processId}
            languages={settings.languages}
            onChangeLanguages={() => onUpdateSettings()}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default SettingsForm;
