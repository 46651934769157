import React from 'react';
import { ShipmentDto } from '../../services/shipment-service/shipment.service';
import { OrderWithShipmentsAndToursDto } from '../../services/order-service/order.service';
import { ProcessDto } from '../../services/process-service/process.service';
import { Box } from '@mui/material';
import { OrderClosed } from '../orders/shared/components/order-detail-modal/components/shipment-view/components/OrderClosed';
import ShipmentHeader from './ShipmentHeader';
import LineItemTable from './LineItemTable';
import ShipmentFooter from './ShipmentFooter';
import { useAuthentication } from '../../hooks/useAuthentication';
import { ShipmentState } from '../../shared/backend';
import { t } from 'i18next';

type Props = {
  shipment: ShipmentDto;
  order: OrderWithShipmentsAndToursDto;
  process: ProcessDto;
  editMode: boolean;
  readyForNext: () => void;
  acceptShipment: (shippingWeight: number | null) => Promise<void>;
};

const InboundShipment: React.FC<Props> = ({ shipment, order, process, editMode, readyForNext, acceptShipment }) => {
  const auth = useAuthentication();
  const shipmentId = shipment.shipmentId;

  return (
    <Box>
      <ShipmentHeader
        shipment={shipment}
        order={order}
        process={process}
        heading={t('Return {{shipmentId}}', { shipmentId })}
        editMode={editMode}
        readyForNext={readyForNext}
        acceptShipment={acceptShipment}
      />

      {shipment.state === ShipmentState.CLOSED && <OrderClosed shipment={shipment} />}

      <LineItemTable
        shipment={shipment}
        disabled={shipment.isConsigned || shipment.state !== ShipmentState.RETOURE_ANNOUNCED}
      />

      {auth.isStaff() && <ShipmentFooter shipment={shipment} />}
    </Box>
  );
};

export default InboundShipment;
